var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.avatarClass, on: { click: _vm.clickAvatar } },
    [
      _vm.wxType === 1
        ? _c(
            "div",
            { staticClass: "avatar-img" },
            [
              _c("avatar-locked", {
                attrs: {
                  avatar: _vm.avatar,
                  size: _vm.size[0],
                  locked: _vm.locked,
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "avatar-img depart-name" },
            [
              _c("wx-open-data", {
                attrs: {
                  id: _vm.name,
                  type: _vm.wxType,
                  nameData: { name: _vm.name },
                },
              }),
            ],
            1
          ),
      _vm.name
        ? _c(
            "div",
            {
              staticClass: "avatar-name",
              style: "font-size: " + _vm.size[1] + "px;",
            },
            [
              _c("wx-open-data", {
                attrs: {
                  id: _vm.name,
                  nameData: { name: _vm.name },
                  type: _vm.wxType,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._t("text"),
      _vm.canDelete
        ? _c(
            "div",
            {
              staticClass: "close-icon",
              on: {
                mouseenter: function ($event) {
                  return _vm.hoverStateChange(true)
                },
                mouseleave: function ($event) {
                  return _vm.hoverStateChange(false)
                },
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.deleteItem.apply(null, arguments)
                },
              },
            },
            [_c("Icon", { attrs: { "icon-class": "icon-guanbianniu" } })],
            1
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }