var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-popover",
        {
          attrs: {
            trigger: "hover",
            disabled: _vm.adviceDisable,
            "open-delay": 300,
            "popper-class": "advice-pop",
            placement: _vm.placement,
          },
        },
        [
          _c(
            "div",
            { staticClass: "advice-content" },
            [
              _c("div", { staticClass: "advice-head" }, [
                _c("div", { staticClass: "head-title" }, [_vm._v("填写建议")]),
                _c(
                  "div",
                  {
                    staticClass: "ignore-btn",
                    on: { click: _vm.ignoreAdvice },
                  },
                  [_vm._v("忽略")]
                ),
              ]),
              _vm._l(_vm.advices, function (item, index) {
                return _c("div", { key: item.id, staticClass: "advice-item" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.advices.length > 1,
                          expression: "advices.length > 1",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(index) + "、")]
                  ),
                  _vm._v(" " + _vm._s(item.descriptionPrompt) + " "),
                ])
              }),
            ],
            2
          ),
          _vm.listType
            ? _c(
                "span",
                {
                  class: _vm.adviceDisable ? "" : "content-html",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _vm._l(_vm.html, function (item) {
                    return [
                      item.type === "html"
                        ? [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(item.html) },
                            }),
                          ]
                        : [
                            _c(
                              "align-member-card",
                              {
                                attrs: {
                                  userInfoList: {
                                    userId: item.userId,
                                    name: item.userName,
                                    avatar: item.userAvatar,
                                    jobTitle: item.jobTitle,
                                    departName: item.departName,
                                    departNameAll: item.departNameAll,
                                    departUserList: item.departUserList,
                                  },
                                  "is-okr": item.isAlign !== 0 && _vm.isOkr,
                                  "is-align": item.isAlign !== 0,
                                  targetAligningList: item.list,
                                  objectivesAssociationList:
                                    item.objectivesAssociationList,
                                  businessType: _vm.businessType,
                                  dataDetail: _vm.dataDetail,
                                  alignType: "childAlign",
                                },
                                on: {
                                  okrDetail: _vm.getTopMembersAlignmentSelect,
                                  toOKRList: function ($event) {
                                    return _vm.getTopMembersFatherList({
                                      userId: item.userId,
                                      userName: item.userName,
                                      userAvatar: item.userAvatar,
                                    })
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(item.html) },
                                }),
                              ]
                            ),
                          ],
                    ]
                  }),
                ],
                2
              )
            : _c("span", {
                class: _vm.adviceDisable ? "" : "content-html",
                attrs: { slot: "reference" },
                domProps: { innerHTML: _vm._s(_vm.html) },
                slot: "reference",
              }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }