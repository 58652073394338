var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": true,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        size: "580px",
        width: "580px",
        title: "欢迎您使用源目标 AI 助手",
        direction: "rtl",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "add-content-wrap" }, [
        _c("div", { staticClass: "ai-title" }, [
          _vm._v(
            "源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR，同时我们将会帮助您生产指导性的工作任务"
          ),
        ]),
        _c("div", { staticClass: "remind-title" }, [
          _vm._v("请填写以下内容，以便于AI助手更好的帮助您输出内容"),
        ]),
        _c("div", { staticClass: "content-input-wrap" }, [
          _c(
            "div",
            { staticClass: "list-wrap" },
            [
              _c("label", [_vm._v("企业所属行业:")]),
              _c(
                "el-select",
                {
                  attrs: {
                    "poper-append-to-body": false,
                    placeholder: "请选择",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeList(
                        $event,
                        _vm.data.industryList,
                        "industryName",
                        true
                      )
                    },
                  },
                  model: {
                    value: _vm.data.industryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "industryId", $$v)
                    },
                    expression: "data.industryId",
                  },
                },
                _vm._l(_vm.data.industryList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.value, value: item.id },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "el-select-left",
                  attrs: {
                    "poper-append-to-body": false,
                    placeholder: "请选择",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeList(
                        $event,
                        _vm.industryListSon,
                        "industryChildrenName",
                        false
                      )
                    },
                  },
                  model: {
                    value: _vm.data.industryChildrenId,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "industryChildrenId", $$v)
                    },
                    expression: "data.industryChildrenId",
                  },
                },
                _vm._l(_vm.industryListSon, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.value, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "list-wrap" },
            [
              _c("label", [_vm._v("企业规模:")]),
              _c(
                "el-select",
                {
                  staticClass: "float_lf_w320",
                  attrs: {
                    "poper-append-to-body": false,
                    placeholder: "请选择",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeList(
                        $event,
                        _vm.data.userCountList,
                        "scopeName",
                        false
                      )
                    },
                  },
                  model: {
                    value: _vm.data.scopeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "scopeId", $$v)
                    },
                    expression: "data.scopeId",
                  },
                },
                _vm._l(_vm.data.userCountList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.value, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "list-wrap" },
            [
              _c("label", [_vm._v("你的职位:")]),
              _c("el-input", {
                staticClass: "input-focus",
                attrs: { "auto-complete": "off", placeholder: "请输入职位" },
                model: {
                  value: _vm.data.jobTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "jobTitle", $$v)
                  },
                  expression: "data.jobTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "list-wrap" },
            [
              _c("label", [_vm._v("部门人数:")]),
              _c("el-input", {
                staticClass: "input-focus",
                attrs: {
                  "auto-complete": "off",
                  placeholder: "请输入部门人数",
                  type: "text",
                },
                on: {
                  change: function ($event) {
                    return _vm.change($event)
                  },
                },
                model: {
                  value: _vm.data.departUserNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "departUserNum", $$v)
                  },
                  expression: "data.departUserNum",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "bottom-step-buttons" },
          [
            _c(
              "button",
              { staticClass: "left-button", on: { click: _vm.handleClose } },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                on: {
                  click: function ($event) {
                    return _vm.saveBtn()
                  },
                },
              },
              [_vm._v("提交")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }