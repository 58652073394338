<template>
  <el-drawer
    :visible.sync="dialogVisible"
    :append-to-body="true"
    direction="rtl"
    :size="750"
    :modal-append-to-body="true"
    :before-close="dialogClose"
    class="task-detail"
    v-on="$listeners"
  >
    <template v-if="isShowDetails">
      <div class="task-detail-header" @click="clickDraw">
        <div class="task-header">
          <div v-if="isStopDoing" class="stopdoing-state">
            <el-tooltip
              effect="dark"
              :content="
                isDisabledEdit
                  ? '没有权限编辑'
                  : form.status == 3
                  ? '修改为未完成'
                  : '修改为已完成'
              "
              placement="top-start"
            >
              <div
                class="stopdoing-state-button"
                :class="
                  form.status == 3
                    ? `stopdoing-state-button-3 stopdoing-state-button-${isDisabledEdit}`
                    : `stopdoing-state-button-${isDisabledEdit}`
                "
                @click.stop="
                  !isDisabledEdit ? changeStopdoingState(form.status) : ''
                "
              >
                <Icon
                  :icon-class="
                    form.status == 1
                      ? 'icon-a-daibanwanchengdaiban'
                      : 'icon-a-daibanyiwancheng1'
                  "
                  class="status-icon"
                />
                {{ form.status == 1 ? '完成待办' : '已完成' }}
              </div>
            </el-tooltip>
          </div>

          <TaskType
            ref="taskType"
            :type="isStopDoing ? 'stopdoing' : ''"
            :classify-val="
              form.businessType === 3
                ? 'kr'
                : form.businessType === 2
                ? 'project'
                : 'todo'
            "
            :task-id="taskId"
            :disabled="isDisabledEdit"
            :type-data="form"
            @changeClassify="changeClassify"
          />
        </div>
        <div class="header-right">
          <div v-if="form.isShare === 1" class="privacy-div">
            <Icon icon-class="icon-simi" class="task-privacy" />隐私
          </div>
          <div :class="`okr-care okr-care-type-${form.follow}`" @click="taskCareClick(form)">
            <el-tooltip
              placement="top"
              :open-delay="300"
              :content="form.follow === 1 ? '已关注任务' : '关注任务'"
            >
              <Icon
                :icon-class="
                  form.follow === 1 ? 'icon-collected' : 'icon-collect'
                "
                class="icon"
              />
            </el-tooltip>
          </div>
          <div
            v-if="!isDisabledEdit || isLeader || isCreate || form.isDel === 2 || form.isClose === 1"
          >
            <more-menu :items="menuItems" placement="bottom" @menuClick="operateTarget">
              <Icon icon-class="icon-gengduo-yuandianheng" class="task-operate" />
            </more-menu>
          </div>
          <Icon icon-class="icon-close" class="task-operate task-close-icon" @click="dialogClose" />
        </div>
      </div>

      <div class="task-content" @click="clickDraw">
        <div
          ref="loadMoreMain"
          class="task-main-content"
          :class="isStopDoing ? 'task-main-content-stopdoing' : ''"
          @scroll="handleScroll"
        >
          <div v-if="progressList && progressList.processDynamic" class="approve-type-wrap">
            <approve-type-detail
              :progress-list="progressList"
              :task-detail="form"
              @approvalRefresh="getApprovalProcessRecord"
            />
          </div>
          <template v-if="!isStopDoing">
            <div v-for="(fieldItem, index) in fieldDictionaryList" :key="index" class="task-form">
              <!-- 标题 -->
              <div v-if="fieldItem.fieldType == 'title'" ref="taskContMain" class="task-title">
                <el-tooltip
                  :open-delay="300"
                  :content="
                    isDisabledEdit ? '没有权限编辑' : `点击编辑任务名称${isDisabledEdit}`
                  "
                >
                  <el-input
                    v-model.lazy="form.title"
                    type="textarea"
                    autosize
                    :disabled="isDisabledEdit"
                    class="to-edit-input-textarea w-100"
                    :class="isDisabledEdit ? '' : 'input-textarea-hover'"
                    @focus="titleIsFocus = true"
                    @blur="titleBlur"
                    @keydown.enter.native="enterToBlur"
                    @input="keyUpTitle"
                  />
                </el-tooltip>
              </div>
              <div class="task-items">
                <!-- 设置状态 -->
                <div v-if="fieldItem.fieldType == 'status'" class="top-item" style="margin-top: 0">
                  <span v-if="fieldItem.required == 0" class="star">*</span>
                  <div class="top-item-describe">
                    <Icon icon-class="icon-taskstatus" class="svg-icons" />
                    <div class="text-box">{{ fieldItem.name }}:</div>
                  </div>
                  <el-tooltip
                    style="margin-left: 12px"
                    :open-delay="300"
                    :content="
                      isDisabledEdit
                        ? '没有权限编辑'
                        : form.status === 3
                        ? '点击编辑任务状态'
                        : '点击编辑任务状态'
                    "
                  >
                    <!-- @changeStatus="changeStatus" -->
                    <state-picker
                      :status="form.status"
                      :disabled="isDisabledEdit"
                      :item="form"
                      @changeStatus="changeStatusApproval"
                    />
                  </el-tooltip>
                </div>
                <!-- 设置负责人 -->
                <div v-if="fieldItem.fieldType == 'executor'" class="top-item">
                  <span v-if="fieldItem.required == 0" class="star">*</span>
                  <div class="top-item-describe">
                    <Icon icon-class="icon-charge-user" class="svg-icons" />
                    <div class="text-box">{{ fieldItem.name }}:</div>
                  </div>
                  <div class="flex-1">
                    <el-tooltip
                      :open-delay="300"
                      :content="
                        isOriginatorDisabledEdit || form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                          ? '没有权限编辑'
                          : '点击编辑任务负责人'
                      "
                      :disabled="
                        isOriginatorDisabledEdit || form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                      "
                    >
                      <user-picker
                        :disabled="
                          isOriginatorDisabledEdit ||
                          form.taskOperationPermission == 0 ||
                          form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                        "
                        @pick="toriginatorPicker"
                      >
                        <div
                          class="originator-box"
                          :class="
                            isOriginatorDisabledEdit || form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                              ? `to-edit-input-not`
                              : `o-edit-input-allow originator-${originatorHover}`
                          "
                          @mouseover="isColorCloseIcon = false"
                          @mouseout="isColorCloseIcon = true"
                        >
                          <template v-if="form.originatorName === null">
                            <Icon
                              class="head-icon border-radius-20"
                              icon-class="icon-kong-touxiang"
                            />
                            <span class="head-icon-label">待认领</span>
                          </template>
                          <template v-else>
                            <el-image
                              fit="cover"
                              :src="
                                form.originatorAvatar ||
                                require('@/assets/imgs/common/advar.png')
                              "
                              alt
                              class="avatar-img pointer border-radius-20"
                            />
                            <div class="m-l-10 pointer username">
                              <wx-open-data
                                :id="form.originatorName"
                                :name-data="{ name: form.originatorName }"
                              />
                              {{
                              form.originatorUserState == 4 ? '(离职)' : ''
                              }}
                            </div>
                            <Icon
                              v-if="
                                form.originatorName !== null &&
                                !isOriginatorDisabledEdit &&
                                form.submitterType !== 1 &&
                          form.isDel !== 2 &&
                          form.isClose !== 1
                              "
                              :icon-class="
                                isColorCloseIcon ? '' : 'icon-a-Group719-1'
                              "
                              class="close-icon"
                              @mouseenter.native="originatorHover = true"
                              @mouseleave.native="originatorHover = false"
                              @click.native.stop="
                                clearToriginatorPicker(fieldItem)
                              "
                            />
                          </template>
                        </div>
                      </user-picker>
                    </el-tooltip>
                  </div>
                </div>
                <!-- 协作人 -->
                <div v-if="fieldItem.fieldType == 'involves'" class="top-item">
                  <span v-if="fieldItem.required == 0" class="star">*</span>
                  <div class="top-item-describe-start">
                    <Icon icon-class="icon-taskcooperationnew" class="svg-icons" />
                    <div class="text-box">{{ fieldItem.name }} ({{ chargeUserListNew.length || 0 }})</div>
                  </div>
                  <div class="flex-1 charge-user-list">
                    <template v-for="item in chargeUserListInit">
                      <div
                        v-if="item"
                        :key="item.id"
                        class="charge-avatar-box"
                        :class="
                          isDisabledEdit
                            ? 'to-edit-input-not'
                            : 'to-edit-input-allow'
                        "
                      >
                        <el-image
                          fit="cover"
                          :src="
                            item.avatar ||
                            require('@/assets/imgs/common/advar.png')
                          "
                          class="avatar-img border-radius-20"
                        />
                        <i
                          v-if="!isDisabledEdit"
                          class="el-icon-error"
                          @click="chargeUserDelete(item.id, fieldItem)"
                        ></i>
                      </div>
                    </template>
                    <el-tooltip
                      :open-delay="300"
                      :content="
                        isDisabledEdit
                          ? '您不是此任务的负责人或协作人和审批中的任务，无法操作哦'
                          : '点击添加协作人'
                      "
                    >
                      <user-picker-checked
                        :select-people-list="chargeUserListNew"
                        :checked="true"
                        :field-item="fieldItem"
                        :length="40"
                        :disabled="isDisabledEdit"
                        @pick="chargeUserPicker"
                      >
                        <Icon
                          icon-class="icon-tianjia-xuxian"
                          class="cooperation-add"
                          :class="
                            isDisabledEdit ? 'cooperation-add-disbled' : ''
                          "
                        />
                      </user-picker-checked>
                    </el-tooltip>
                  </div>
                </div>
                <!-- 时间 -->
                <div v-if="fieldItem.fieldType == 'startDate'" class="top-item">
                  <span v-if="fieldItem.required == 0" class="star">*</span>
                  <div class="top-item-describe" style="margin-top: 10px">
                    <Icon icon-class="icon-taskcalendars" class="svg-icons" />
                    <div class="text-box">时间:</div>
                  </div>
                  <div class="flex-1 time-right">
                    <set-date-time-detail
                      type="edit"
                      :row="form"
                      :field-item="fieldItem"
                      :disabled="isDisabledEdit"
                      @refresh="needRefreshState"
                      @change="dateTimeChange"
                    />
                  </div>
                </div>
                <!-- 标签 -->
                <div v-if="fieldItem.fieldType == 'tag'" class="top-item">
                  <span v-if="fieldItem.required == 0" class="star">*</span>
                  <div class="top-item-describe-start">
                    <Icon icon-class="icon-biaoqian" class="svg-icons" />
                    <div class="text-box">{{ fieldItem.name }}:</div>
                  </div>
                  <div class="flex-1">
                    <TaskLabel
                      :label-options="labelData"
                      :select-options="form.labelArray"
                      :field-item="fieldItem"
                      :disabled="isDisabledEdit"
                      @save="saveLabels"
                      @update="updateTaskLabel"
                    />
                  </div>
                </div>
                <!-- 优先级 -->
                <div v-if="fieldItem.fieldType == 'priority'" class="top-item">
                  <span v-if="fieldItem.required == 0" class="star">*</span>
                  <div class="top-item-describe">
                    <Icon icon-class="icon-taskprioritynew" class="svg-icons" />
                    <div class="text-box">{{ fieldItem.name }}:</div>
                  </div>
                  <div class="flex-1">
                    <el-tooltip
                      :open-delay="300"
                      :content="
                        isDisabledEdit ? '没有权限编辑' : '点击编辑优先级'
                      "
                      :placement="'top'"
                      :placement-class="'priorityTooltip'"
                    >
                      <!-- v-if="form.priority && form.priority.id" -->
                      <!-- :class="priorityDictionaryList[form.priority.id].class" -->
                      <!--  -->
                      <el-select
                        ref="taskPrioritySelect"
                        v-model="priorityVal"
                        :disabled="isDisabledEdit"
                        class="task-detail-priority-box"
                        placeholder="请选择"
                        :style="`color: ${priorityInfo.colour} !important;width:auto!`"
                        @change="priorityChange(fieldItem, priorityVal)"
                      >
                        <!-- <template v-for="item in priorityDictionaryList"> -->
                        <el-option
                          v-for="(item, key) in priorityDictionaryList"
                          :key="key"
                          :class="key"
                          :label="item.value"
                          :value="item.value"
                        >
                          <!--  -->
                          <span
                            :style="`color: ${
                              priorityDictionaryList[key].colour || ''
                            }`"
                          >{{ item.value }}</span>
                        </el-option>
                        <!-- </template> -->
                      </el-select>
                    </el-tooltip>
                  </div>
                </div>
                <!-- 详情描述 -->
                <div v-if="fieldItem.fieldType == 'note'" class="top-item">
                  <span v-if="fieldItem.required == 0" class="star star-note">*</span>
                  <div class="top-item-describe-start" style="margin-top: 15px">
                    <Icon icon-class="icon-process" class="svg-icons" />
                    <div class="text-box">{{ fieldItem.name }}:</div>
                  </div>

                  <div class="flex-1 top-item-box disc-right">
                    <div class="total_edit">
                      <div class="edit-con" :class="'editState' + editorFocus">
                        <el-tooltip :content="describeContent" placement="top" open-delay="300">
                          <div class="content-fixed">
                            <!-- v-html="filtersText(form.information) || '待添加'" -->
                            <div
                              v-show="!editorClick"
                              id="htmlContent"
                              ref="htmlContent"
                              :class="
                                isDisabledEdit
                                  ? 'htmlContent htmlContentNo'
                                  : 'htmlContent'
                              "
                              @click.stop="ContentClick"
                              v-html="
                                filtersHtml(form.information)
                                  ? form.information
                                  : '待添加'
                              "
                            ></div>
                            <div
                              v-if="!editorClick && isShowMore"
                              class="show-more"
                              @click="showMore"
                            >{{ isEditorShow ? '收起描述' : '显示更多' }}</div>
                            <div id="showMore"></div>
                          </div>
                        </el-tooltip>
                        <transition name="bottom-slide" mode="out-in">
                          <Editor
                            v-if="editorClick"
                            v-model="form.information"
                            style="margin-left: 12px"
                            :height="440"
                            @onFocus="EditorFocus"
                            @onBlur="EditorBlur(arguments, fieldItem)"
                          />
                        </transition>
                        <transition name="bottom-slide" mode="out-in">
                          <div v-if="editorFocus && !isDisabledEdit" class="editor-Btn">
                            <div class="cancel btn" @click="editorChange(1, fieldItem)">取消</div>
                            <div
                              id="saveEditor"
                              class="confirm btn"
                              @click="editorChange(2, fieldItem)"
                            >确定</div>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 关联关键结果 -->
                <div v-if="fieldItem.fieldType == 'subordinate'" class="top-item">
                  <span v-if="fieldItem.required == 0" class="star">*</span>
                  <div class="top-item-describe">
                    <Icon icon-class="icon-taskshu" class="svg-icons" />
                    <div class="text-box">{{ fieldItem.name }}:</div>
                  </div>
                  <div class="flex-1">
                    <!-- 关联KR -->
                    <TaskKr
                      v-if="krVisible"
                      ref="TaskKr"
                      style="margin-left: -8px; margin-top: -6px"
                      :select-kr="copyProps"
                      :todo-add="false"
                      :disabled="
                        isDisabledEdit || form.taskOperationPermission == 1
                      "
                      :type="'details'"
                      @save="saveKrMainDetail"
                    />
                  </div>
                </div>
                <!-- 项目工作流 -->
                <div v-if="form.projectId && fieldItem.fieldType == 'workProcess'" class="top-item">
                  <span v-if="fieldItem.required == 0" class="star">*</span>
                  <div class="top-item-describe">
                    <Icon icon-class="icon-taskCooperate" class="svg-icons" />
                    <div class="text-box">{{ fieldItem.name }}:</div>
                  </div>
                  <div class="flex-1 top-item-box">
                    <div class="project-workflow-select">
                      <TaskProject
                        :project="projectInfo"
                        :process-id="form.processId"
                        :project-disabled="isDisabledEdit"
                        :disabled="
                          isDisabledEdit || form.taskOperationPermission == 1
                        "
                        @change="changeProcess"
                        @changeProject="changeProject"
                      />
                    </div>
                    <!-- <project-workflow :taskOperationPermission="form.taskOperationPermission" :taskId="form.id" :projectId="form.projectId" :processId="form.processId"></project-workflow> -->
                  </div>
                </div>
                <div
                  v-if="fieldItem.fieldType == 'workingHours'"
                  class="task-items"
                  style="width: 100%"
                >
                  <div class="top-item">
                    <span v-if="fieldItem.required == 0" class="star">*</span>
                    <div class="top-item-describe">
                      <Icon icon-class="icon-gongshi" class="svg-icons" />
                      <div class="text-box">{{ fieldItem.name }}:</div>
                    </div>
                    <div class="flex-1 workingHours-right">
                      <span class="workhours-static" @click="openWorkHoursStatic()">工时统计</span>
                    </div>
                  </div>
                  <div class="top-items workingHours-div">
                    <div
                      v-for="(wItem, wIndex) in workingHoursDictionaryList"
                      :key="wIndex"
                      class="workingHours-list"
                    >
                      <div class="workingHours-label">{{ wItem.workingHoursName }}</div>
                      <div class="workingHours-content">
                        <span
                          v-if="
                            !form.taskWorkingHoursStatistics[
                              wItem.workingHoursNameValue
                            ]
                          "
                          class="work-hours-0"
                          :class="isDisabledEdit ? 'work-hours-disabled' : ''"
                          @click.stop="
                            !isDisabledEdit ? setWorkHours(wItem) : ''
                          "
                        >+</span>
                        <span
                          v-else
                          class="work-hours"
                          :class="isDisabledEdit ? 'work-hours-disabled' : ''"
                          @click.stop="
                            !isDisabledEdit ? setWorkHours(wItem) : ''
                          "
                        >
                          {{
                          form.taskWorkingHoursStatistics &&
                          wItem.workingHoursNameValue
                          ? form.taskWorkingHoursStatistics[
                          wItem.workingHoursNameValue
                          ]
                          : 0
                          }}
                        </span>
                        <span
                          :class="
                            !form.taskWorkingHoursStatistics[
                              wItem.workingHoursNameValue
                            ]
                              ? 'work-hours-unit-no'
                              : 'work-hours-unit'
                          "
                        >小时</span>
                      </div>
                    </div>
                    <div class="workinghours-progress">
                      <el-progress
                        class="hours-progress"
                        :stroke-width="10"
                        color="#3A70FF"
                        :percentage="getWorkhours()"
                      />
                    </div>
                  </div>
                </div>

                <!-- 自定义字段 -->
                <div v-if="fieldItem.fieldAttribute" class="top-item">
                  <span v-if="fieldItem.required == 0" class="star">*</span>
                  <div class="top-item-describe" style="margin-top: 11px !important">
                    <Icon
                      v-if="fieldItem.fieldAttribute == 'singleChoice'"
                      icon-class="icon-danxuan1"
                      class="svg-icons"
                    />
                    <Icon
                      v-else-if="fieldItem.fieldAttribute == 'multipleChoice'"
                      icon-class="icon-duoxuan"
                      class="svg-icons"
                    />
                    <Icon
                      v-else-if="fieldItem.fieldAttribute == 'date'"
                      icon-class="icon-kaishishijian"
                      class="svg-icons"
                    />
                    <Icon
                      v-else-if="fieldItem.fieldAttribute == 'number'"
                      icon-class="icon-shuzi"
                      class="svg-icons"
                    />
                    <Icon
                      v-else-if="fieldItem.fieldAttribute == 'text'"
                      icon-class="icon-wenben"
                      class="svg-icons"
                    />
                    <Icon
                      v-else-if="fieldItem.fieldAttribute == 'file'"
                      icon-class="icon-wenjian"
                      class="svg-icons"
                    />
                    <Icon v-else icon-class="icon-zidingyiziduan" class="svg-icons" />
                    <div v-if="fieldItem.name.length <= 4" class="text-box">{{ fieldItem.name }}:</div>
                    <el-tooltip
                      v-else
                      class="item"
                      effect="dark"
                      :content="fieldItem.name"
                      placement="top-start"
                    >
                      <div class="text-box">{{ fieldItem.name }}:</div>
                    </el-tooltip>
                  </div>
                  <div class="flex-1 custom-right">
                    <el-form
                      ref="customInfoForm"
                      :model="customInfoForm"
                      style="width: 100%"
                      @submit.native.prevent
                    >
                      <EditForm
                        ref="childForm"
                        :key="customInfoForm"
                        :disabled="isDisabledEdit"
                        :info-item="fieldItem"
                        :info-form="customInfoForm"
                        :label-data="labelData"
                        @setInfoForm="setInfoForm"
                        @updateDetails="updateDetails"
                      />
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 待办事项 -->
          <div v-if="isStopDoing" class="stop-doing-box">
            <div
              v-for="(fieldItem, index) in shopDoingFieldDictionaryList"
              :key="index"
              :class="
                fieldItem.fieldType == 'title' || fieldItem.fieldType == 'note'
                  ? 'stop-dictionayry-row'
                  : 'stop-dictionayry-row-50'
              "
            >
              <!-- 标题 -->
              <div v-if="fieldItem.fieldType == 'title'" ref="taskContMain" class="task-title">
                <el-tooltip
                  :open-delay="300"
                  :content="
                    isDisabledEdit ? '没有权限编辑' : '点击编辑任务名称'
                  "
                >
                  <el-input
                    v-model.lazy="form.title"
                    type="textarea"
                    autosize
                    :disabled="isDisabledEdit"
                    class="to-edit-input-textarea w-100"
                    :class="isDisabledEdit ? '' : 'input-textarea-hover'"
                    @focus="titleIsFocus = true"
                    @blur="titleBlur"
                    @keydown.enter.native="enterToBlur"
                    @input="keyUpTitle"
                  />
                </el-tooltip>
              </div>

              <div class="stop-doing-list">
                <!-- 负责人 -->
                <div v-if="fieldItem.fieldType == 'executor'" class>
                  <el-tooltip
                    :open-delay="300"
                    :content="
                      isOriginatorDisabledEdit || form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                        ? '没有权限编辑'
                        : '点击编辑任务负责人'
                    "
                    :disabled="isOriginatorDisabledEdit"
                  >
                    <user-picker
                      :disabled="
                        isOriginatorDisabledEdit ||
                        form.taskOperationPermission == 0 ||
                        form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                      "
                      @pick="toriginatorPicker"
                      @click.native.stop
                    >
                      <div
                        class="originator-box stopdoing-originator"
                        :class="
                          isOriginatorDisabledEdit || form.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                            ? `to-edit-input-not`
                            : `o-edit-input-allow stopdoing-originator-${originatorHover}`
                        "
                        @mouseover="isColorCloseIcon = false"
                        @mouseout="isColorCloseIcon = true"
                      >
                        <template v-if="form.originatorName === null">
                          <Icon class="head-icon border-radius-20" icon-class="icon-kong-touxiang" />
                          <span class="head-icon-label">待认领</span>
                        </template>
                        <template v-else>
                          <el-image
                            fit="cover"
                            :src="
                              form.originatorAvatar ||
                              require('@/assets/imgs/common/advar.png')
                            "
                            alt
                            class="avatar-img pointer border-radius-20"
                          />
                          <div class="m-l-10 pointer username">
                            <wx-open-data
                              :id="form.originatorName"
                              :name-data="{ name: form.originatorName }"
                            />
                          </div>
                          <Icon
                            v-if="
                              form.originatorName !== null &&
                              !isOriginatorDisabledEdit &&
                              form.submitterType !== 1 &&
                          form.isDel !== 2 &&
                          form.isClose !== 1
                            "
                            :icon-class="
                              isColorCloseIcon ? '' : 'icon-a-Group719-1'
                            "
                            class="close-icon"
                            @mouseenter.native="originatorHover = true"
                            @mouseleave.native="originatorHover = false"
                            @click.native.stop="
                              clearToriginatorPicker(fieldItem)
                            "
                          />
                        </template>
                      </div>
                    </user-picker>
                  </el-tooltip>
                </div>
                <!-- 协作人 -->
                <div v-if="fieldItem.fieldType == 'involves'">
                  <div class="flex-1 charge-user-list stopdoing-charge-user-list">
                    <div class="stopdoing-charge-user-list-div">
                      <div v-for="item in chargeUserListInit" :key="item.id">
                        <div
                          v-if="item"
                          :key="item.id"
                          class="charge-avatar-box"
                          :class="
                            isDisabledEdit
                              ? 'to-edit-input-not'
                              : 'to-edit-input-allow'
                          "
                        >
                          <el-image
                            fit="cover"
                            :src="
                              item.avatar ||
                              require('@/assets/imgs/common/advar.png')
                            "
                            class="avatar-img border-radius-20"
                          />
                          <span class="icon-error-span">
                            <Icon
                              v-if="!isDisabledEdit"
                              icon-class="icon-a-Group719-1"
                              class="icon-error"
                              @click="chargeUserDelete(item.id, fieldItem)"
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <el-tooltip
                      :open-delay="300"
                      :content="
                        isDisabledEdit
                          ? '您不是此任务的负责人或协作人和审批中的任务，无法操作哦'
                          : '点击添加协作人'
                      "
                    >
                      <user-picker-checked
                        :select-people-list="chargeUserListNew"
                        :checked="true"
                        :field-item="fieldItem"
                        :length="40"
                        :disabled="isDisabledEdit"
                        @pick="chargeUserPicker"
                      >
                        <div class="picker-checked-div">
                          <Icon
                            icon-class="icon-tianjia-xuxian"
                            class="cooperation-add"
                            :class="
                              isDisabledEdit ? 'cooperation-add-disbled' : ''
                            "
                          />
                          <span
                            class="picker-text"
                            :class="
                              chargeUserListInit.length > 0
                                ? 'picker-text-you'
                                : ''
                            "
                          >
                            {{
                            chargeUserListInit.length > 0
                            ? '参与协作'
                            : '添加协作人'
                            }}
                          </span>
                        </div>
                      </user-picker-checked>
                    </el-tooltip>
                  </div>
                </div>
                <!-- 时间 -->
                <div
                  v-if="fieldItem.fieldType == 'endDate'"
                  class="stopdoing-time"
                  @click="endPicker(fieldItem)"
                >
                  <!-- <el-date-picker
                    ref="endDate"
                    class="end-time"
                    v-model="stopdoingEndTime"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="选择日期时间"
                    default-time="19:00:00"
                    :disabled="isDisabledEdit"
                    @change="changeStopdoing"
                  >
                  </el-date-picker>-->

                  <div
                    class="endData-div"
                    :class="
                      isDisabledEdit
                        ? `endData-div-disabled`
                        : `endData-div-${endDateHover}`
                    "
                  >
                    <Icon icon-class="icon-taskcalendars" class="svg-icons" />
                    <set-date-time-detail
                      type="edit"
                      :row="form"
                      source="stopdoing"
                      :field-item="fieldItem"
                      :disabled="isDisabledEdit"
                      @refresh="needRefreshState"
                      @change="dateTimeChange"
                    />
                    <!-- <div class="time" v-if="form.endDateString">
                      {{ form.startDateString - form.endDateString }}
                    </div>
                    <div v-else class="placeholder">请选择截止时间</div>
                    <Icon
                      v-show="form.endDateString"
                      @click.native.stop="timeClear(fieldItem)"
                      icon-class="icon-a-Group719-1"
                      @mouseenter.native="endDateHover = true"
                      @mouseleave.native="endDateHover = false"
                      class="clear-icon"
                    />-->
                  </div>
                </div>
                <!-- 设置提醒 -->
                <div
                  v-if="fieldItem.fieldType == 'remindDate'"
                  :class="
                    isDisabledEdit ? 'stopdoing-remind-time-disabled' : ''
                  "
                >
                  <div
                    class="stopdoing-remind-time"
                    :class="
                      form.remindDate && form.remindDate.dateType
                        ? 'stopdoing-remind-time-you'
                        : ''
                    "
                    @click.stop="remindTimeClick"
                  >
                    <Icon icon-class="icon-naoling" class="remind-icon" />

                    <div
                      v-if="form.remindDate && form.remindDate.dateType"
                      class="remind-time"
                    >{{ remindDateFmt() }}</div>
                    <div v-else class="placeholder">设置提醒</div>
                  </div>
                </div>
                <!-- 重复 -->
                <div
                  v-if="fieldItem.fieldType == 'remindDate'"
                  :class="
                    isDisabledEdit ? 'stopdoing-remind-time-disabled' : ''
                  "
                >
                  <el-tooltip :open-delay="300" content="设置重复" placement="top">
                    <div class="stopdoing-remind-time">
                      <task-cycle-detail
                        :id="
                          form.cycleDate && form.cycleDate.id
                            ? form.cycleDate.id
                            : ''
                        "
                        :task-id="form.id"
                        :row="form"
                        :is-show="true"
                        :disabled="isDisabledEdit"
                        @save="cycleSave"
                      />
                    </div>
                  </el-tooltip>
                </div>
                <!-- 优先级 -->
                <div v-if="fieldItem.fieldType == 'priority'" class="stopdoing-priority">
                  <el-tooltip
                    :open-delay="300"
                    :content="
                      isDisabledEdit ? '没有权限编辑' : '点击编辑优先级'
                    "
                    placement="top"
                  >
                    <!-- v-if="form.priority && form.priority.id" -->
                    <!-- :class="priorityDictionaryList[form.priority.id].class" -->
                    <!--  -->
                    <el-select
                      ref="taskPrioritySelect"
                      v-model="priorityVal"
                      :disabled="isDisabledEdit"
                      class="task-detail-priority-box"
                      placeholder="请选择优先级"
                      :style="`color: ${priorityInfo.colour} !important`"
                      @change="priorityChange(fieldItem, priorityVal)"
                    >
                      <div
                        slot="prefix"
                        class="svg-icons-div"
                        :style="`color: ${priorityInfo.colour} !important`"
                      >
                        <Icon icon-class="icon-youxianji2" class="svg-icons" />
                      </div>
                      <!-- <template v-for="item in priorityDictionaryList"> -->
                      <el-option
                        v-for="(item, key) in priorityDictionaryList"
                        :key="key"
                        :class="key"
                        :label="item.value"
                        :value="item.value"
                      >
                        <!--  -->
                        <span
                          :style="`color: ${
                            priorityDictionaryList[key].colour || ''
                          }`"
                        >{{ item.value }}</span>
                      </el-option>
                      <!-- </template> -->
                    </el-select>
                  </el-tooltip>
                </div>
                <!-- 标签 -->
                <div v-if="fieldItem.fieldType == 'tag'" class="stopdoing-tag">
                  <el-tooltip
                    :open-delay="300"
                    :content="isDisabledEdit ? '没有权限编辑' : '点击编辑标签'"
                    placement="top"
                  >
                    <div class="flex-1">
                      <TaskLabel
                        :label-options="labelData"
                        :select-options="form.labelArray"
                        :field-item="fieldItem"
                        :disabled="isDisabledEdit"
                        source="stopdoing"
                        @save="saveLabels"
                        @update="updateTaskLabel"
                      />
                    </div>
                  </el-tooltip>
                </div>
                <!-- 详情描述 -->
                <div
                  v-if="fieldItem.fieldType == 'note'"
                  class="stopdoing-note"
                  :class="editorClick ? 'stopdoing-note-border' : ''"
                >
                  <div class="flex-1 top-item-box disc-right">
                    <div class="total_edit">
                      <div class="edit-con" :class="'editState' + editorFocus">
                        <el-tooltip :content="describeContent" placement="top" open-delay="300">
                          <div class="content-fixed">
                            <!-- v-html="filtersText(form.information) || '待添加'" -->
                            <div
                              v-show="!editorClick"
                              id="htmlContent"
                              ref="htmlContent"
                              :class="
                                isDisabledEdit
                                  ? 'htmlContent htmlContentNo'
                                  : 'htmlContent'
                              "
                              @click.stop="ContentClick"
                              v-html="
                                filtersHtml(form.information)
                                  ? form.information
                                  : '请添加描述说明'
                              "
                            ></div>
                            <div
                              v-if="!editorClick && isShowMore"
                              class="show-more"
                              @click="showMore"
                            >{{ isEditorShow ? '收起描述' : '显示更多' }}</div>
                            <div id="showMore"></div>
                          </div>
                        </el-tooltip>
                        <transition name="bottom-slide" mode="out-in">
                          <Editor
                            v-if="editorClick"
                            v-model="form.information"
                            :height="260"
                            :width="700"
                            @onFocus="EditorFocus"
                            @onBlur="EditorBlur(arguments, fieldItem)"
                          />
                        </transition>
                        <transition name="bottom-slide" mode="out-in">
                          <div v-if="editorFocus && !isDisabledEdit" class="editor-Btn">
                            <div class="cancel btn" @click="editorChange(1, fieldItem)">取消</div>
                            <div
                              id="saveEditor"
                              class="confirm btn"
                              @click="editorChange(2, fieldItem)"
                            >确定</div>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="task-form">
            <div v-if="form.fatherTid == 0" class="task-items">
              <!-- 添加子任务 -->
              <div class="top-item">
                <div class="top-item-describe">
                  <Icon icon-class="icon-tasksubicon" class="svg-icons" />
                  <div class="text-box">子任务 ({{ subTask.length }})</div>
                </div>
                <div class="flex-1"></div>
              </div>
              <!-- 子任务添加区 -->
              <div class="top-items sub-add-wrap">
                <div class="flex-1 son-flex-1">
                  <ul style="width: 100%" class="sub-ul">
                    <li
                      v-for="(item, index) in subTask"
                      :key="index + Math.random()"
                      class="flex-new"
                      @click.stop="todoSubTask(item)"
                    >
                      <el-tooltip
                        :open-delay="300"
                        :content="
                          isDisabledEdit
                            ? '您不是此任务的负责人或协作人和审批中的任务，无法操作哦'
                            : '点击编辑任务状态'
                        "
                      >
                        <div
                          style="
                            height: 36px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                          @click.stop
                        >
                          <state-picker
                            :type="3"
                            :status="item.status"
                            :item="item"
                            :disabled="
                              isDisabledEdit || item.submitterType === 1 ||
                          form.isDel === 2 ||
                          form.isClose === 1
                            "
                            @changeStatus="subTaskStatus"
                          />
                        </div>
                        <!-- <statePicker
                          :type="1"
                          :disabled="isDisabledEdit"
                          @changeStatus="subTaskStatus"
                        >
                          <div
                            :style="`border: 1px solid ${
                              stateDictColor[item.status]
                            }`"
                            class="son-status-box"
                          >
                            <i
                              v-if="item.status === 3"
                              class="arrow-icon el-icon-check"
                            ></i>
                          </div>
                        </statePicker>-->
                      </el-tooltip>
                      <div class="flex-1 sub-li-content">
                        <p>{{ item.title }}</p>
                      </div>
                      <div class="flex-new sub-right">
                        <transition name="bottom-slide" mode="out-in">
                          <div
                            v-if="item.startDateString || item.endDateString"
                            class="sub-right-icon-time-txt"
                          >
                            <template v-if="item.showDates === 2">{{ item.endDateString }} 截止</template>
                            <template v-else-if="item.showDates === 3">
                              {{ item.startDateString }}
                              <span
                                v-if="
                                  item.startDateString && item.endDateString
                                "
                              >-</span>
                              {{ item.endDateString }}
                            </template>
                            <template v-else-if="item.showDates === 1">{{ item.startDateString }} 开始</template>
                          </div>
                        </transition>
                        <div class="sub-right-icon">
                          <el-image
                            fit="cover"
                            :src="
                              item.originatorAvatar ||
                              require('@/assets/imgs/pm/head-icon.png')
                            "
                            class="avatar-img border-radius-20"
                            alt
                          />
                        </div>
                        <div
                          class="sub-right-icon"
                          style="margin-right: 0"
                          @click.stop="deleteSub(item, index)"
                        >
                          <el-tooltip
                            popper-class="sub-right-icon-del"
                            placement="top-start"
                            trigger="hover"
                            effect="dark"
                            :content="
                              (isLeader || isCreate) && item.submitterType !== 1 &&
                          form.isDel !== 2 &&
                          form.isClose !== 1
                                ? '点击删除子任务'
                                : '只有任务负责人非审批中才可以删除子任务哦'
                            "
                          >
                            <Icon
                              icon-class="icon-shanchu1"
                              class="son-delete-icon"
                              :class="
                                (isLeader || isCreate) &&
                                item.submitterType !== 1 &&
                          form.isDel !== 2 &&
                          form.isClose !== 1
                                  ? 'to-edit-input-allow'
                                  : 'to-edit-input-not'
                              "
                            />
                          </el-tooltip>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <transition name="bottom-slide" mode="out-in">
                  <div v-if="subTaskVisible" class="sub-add-div flex-new">
                    <el-input
                      v-model="sub_obj.title"
                      placeholder="请填写子任务标题"
                      class="flex-1"
                      @input="sub_objInput"
                      @keyup.enter.native="taskAddViewSubmit"
                    />
                    <div class="flex-new sub-right">
                      <div class="sub-right-icon-time" style="cursor: pointer; position: relative">
                        <el-date-picker
                          v-model="sub_obj.startTime"
                          format="yyyy-MM-dd HH:mm"
                          value-format="yyyy-MM-dd HH:mm"
                          type="datetimerange"
                          :default-time="defaultTime"
                          start-placeholder="开始日期"
                          end-placeholder="截止日期"
                          class="sub-task-date-box"
                          popper-class="addTimeClass"
                        />
                        <Icon
                          :style="`color: ${
                            sub_obj.startTime && sub_obj.startTime.length > 0
                              ? '#FF9900'
                              : '#A0A3BD'
                          }`"
                          icon-class="icon-taskcalendars"
                          class="time-set-icon"
                        />
                      </div>
                      <div class="sub-right-icon" style="position: relative; margin-right: 0px">
                        <el-tooltip
                          content="点击编辑任务负责人"
                          :disabled="sub_obj.submitterType === 1 ||
                          sub_obj.isDel === 2 ||
                          sub_obj.isClose === 1"
                        >
                          <user-picker
                            :disabled="sub_obj.submitterType === 1 ||
                          sub_obj.isDel === 2 ||
                          sub_obj.isClose === 1"
                            @pick="subToriginatorPicker"
                            @click.native.stop
                          >
                            <el-image
                              fit="cover"
                              v-if="sub_obj.originatorAvatar"
                              :src="sub_obj.originatorAvatar"
                              class="avatar-img pointer border-radius-20"
                            />
                            <el-image
                              fit="cover"
                              v-else
                              :src="imgConst ? imgConst.logo : userInfo.avatar"
                              class="avatar-img pointer border-radius-20"
                            />
                          </user-picker>
                          <!-- <div
                            class="originator-box"
                            @click.stop="subOriginatorPickerClick"
                          >
                            <el-image
                              v-if="sub_obj.originatorAvatar"
                              :src="sub_obj.originatorAvatar"
                              class="avatar-img pointer"
                            />
                            <el-image
                              v-else
                              :src="imgConst.logo"
                              class="avatar-img pointer"
                            />
                            <user-picker
                              v-if="SubOriginatorPickerVisible"
                              :visible.sync="SubOriginatorPickerVisible"
                              :sub-visible="true"
                              @pick="subToriginatorPicker"
                              @click.native.stop
                            />
                          </div>-->
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="bottom-slide" mode="out-in">
                  <div v-if="subTaskVisible" class="sub-add-div flex-new">
                    <button class="guid_btns" @click="closedialog">取消</button>
                    <!-- 3.3.1后新增开始 chu -->
                    <L-button
                      class="guid_btns common_btn_next_able sub-add-complete"
                      :loading="taskAddLoading"
                      @clickEvt="taskAddViewSubmit"
                    >完成</L-button>
                    <!-- 3.3.1后新增结束 chu -->
                  </div>
                </transition>
                <el-tooltip
                  v-if="!subTaskVisible"
                  :open-delay="300"
                  :content="
                    isDisabledEdit
                      ? '您不是此任务的负责人或协作人和审批中的任务，无法操作哦'
                      : form.status === 3
                      ? '主任务已完成'
                      : '添加子任务'
                  "
                >
                  <div
                    class="sub-add"
                    style="color: #3a78f6"
                    :class="
                      isDisabledEdit
                        ? 'to-edit-input-not'
                        : form.status === 3
                        ? 'to-edit-input-not-add'
                        : 'to-edit-input-allow'
                    "
                    @click.stop="addSubTaskVisible_"
                  >
                    <Icon icon-class="icon-objective_map_blow" class="aub-add-icon" />添加子任务
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>

          <div class="task-dynamic">
            <DynamicContainer
              ref="dynamic"
              :task-id="taskId"
              :progress-list="progressList"
              :task-detail="form"
              @approvalRefresh="getApprovalProcessRecord"
            />
          </div>
        </div>
        <div class="task-comment" style="border: 1px #f7f7fc solid">
          <!-- :source="isStopDoing ? 'stopdoing' : ''" -->
          <comment-add
            ref="commentAdd"
            :task-id="taskId"
            :taskdetail-boolean="false"
            :line-feed="true"
            @save="commentSave"
          />
        </div>
      </div>
    </template>
    <!-- 编辑可见范围 -->
    <set-permissions
      v-if="closePermissionsStatus"
      :visible="closePermissionsStatus"
      :title="'编辑任务可见范围'"
      :permissions-type="'0'"
      :permission-id="form.id"
      :collect_show_object="form"
      @closePermissions="closePermissions"
      @submitPermissions="submitPermissions"
    />
    <div v-if="false" class="go-back-block" @click="goTop">
      <i class="el-icon-upload2 avatar-img"></i>
    </div>
    <div v-if="gobottomStatus == true" class="go-back-block-arrow" @click="goStatusBottom">
      <p class="back-block-arrow">动态</p>
      <i class="el-icon-arrow-down avatar-img_arrow"></i>
    </div>
    <!-- 设置提醒时间dialog -->
    <transition name="fade">
      <task-remind-time-detail
        v-if="remindTimeVisible"
        :id="form.remindDate && form.remindDate.id ? form.remindDate.id : ''"
        :visible.sync="remindTimeVisible"
        :task-id="form.id"
        :row="form"
        @save="remindTimeSave"
      />
    </transition>
    <SetWorkHours
      v-if="isShowSetWorkHours"
      v-model="isShowSetWorkHours"
      :edit-data="editData"
      :title="setWorkHoursTitle"
      :form="form"
      source="task"
      :type="setWorkHoursType"
      :disabled="isDisabledEdit"
    />
    <WorkHoursStatic
      v-if="isShowWorkHoursStatic"
      ref="WorkHoursStatic"
      v-model="isShowWorkHoursStatic"
      :task-id="taskId"
      :disabled="isDisabledEdit"
    />
    <WorkHoursDetailed
      v-if="isShowWorkHoursDetailed"
      ref="WorkHoursDetailed"
      v-model="isShowWorkHoursDetailed"
      :task-id="taskId"
      source="task"
      :disabled="isDisabledEdit"
    />
    <transformToSonTaskDialog v-if="transformToSonTaskVisible" v-model="transformToSonTaskVisible" />
    <copy-task v-model="copyTaskVisible" fromType="taskDetail" :currentTaskList="[form]" />
  </el-drawer>
</template>
<script>
/* eslint-disable */
import { mapState } from 'vuex'
import TaskKr from '../../components/TaskKr/index' // kr
// import TaskKr from '../../components/TaskKr/index' // 关联kr
import statePicker from '@/views/todo/components/TaskStatePicker'
import SetDateTimeDetail from '../../components/TaskTimeSet/SetDateTimeDetail'
import DynamicContainer from '../../components/DynamicContainer.vue'
import CommentAdd from '../../components/CommentAdd.vue'
import taskEnum from '@/utils/enums/task'
import SetPermissions from '@/views/okr/map/SetPermissions' // 编辑可见范围
import EditForm from '../../components/EditForm/index.vue'
import TaskLabel from '../../components/Label/index.vue'
import TaskProject from '../../components/TaskProject' // 所属项目
import TaskType from '../../components/TaskTypeSelect' // 任务分类
import taskUtil from '@/utils/task.js'
import TaskRemindTimeDetail from '../../components/TaskTimeSet/TaskRemindTimeDetail.vue' // 设置提醒时间
import SetWorkHours from '../../components/SetWorkhours/index.vue' // 设置工时
import WorkHoursStatic from '../../components/WorkHoursStatic/index.vue' // 工时统计
import WorkHoursDetailed from '../../components/WorkHoursDetailed/index.vue' // 工时明细
import TaskCycleDetail from '@/views/todo/components/TaskTimeSet/TaskCycleDetail.vue' // 任务重复周期
import approveTypeDetail from '@/views/todo/components/approveTypeDetail'
import getPmApproveState from '@/utils/mixins/getPmApproveState'
import transformToSonTaskDialog from '@/views/todo/dialog/taskDetail/transformToSonTaskDialog'
import copyTask from '@/views/pm/components/common/copyTask.vue' // 复制任务
export default {
  mixins: [getPmApproveState],
  components: {
    TaskCycleDetail,
    statePicker,
    SetDateTimeDetail,
    DynamicContainer,
    CommentAdd,
    TaskKr,
    SetPermissions,
    EditForm,
    TaskLabel,
    TaskProject,
    TaskType,
    TaskRemindTimeDetail,
    SetWorkHours,
    WorkHoursStatic,
    WorkHoursDetailed,
    approveTypeDetail,
    transformToSonTaskDialog,
    copyTask
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean
    },
    taskId: {
      type: String
    },
    // 页面类型
    viewType: {
      type: String,
      required: false
    },
    formPage: {
      type: String,
      required: false
    },
    noticeType: {
      type: String,
      required: false,
      default: ''
    },
    // 2 解决弹窗不会嵌入body
    type: {
      type: Number,
      default: 1
    },
    emitState: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      okrObj: {}, // 关联okr信息
      projectInfo: {}, // 关联项目信息
      priorityVal: '', //优先级值
      taskTypes: [
        {
          id: '1',
          name: '待办'
        },
        {
          id: '2',
          name: 'okr任务'
        }
      ],
      taskTypeVal: '1',
      labelData: [], // 标签数组
      //自定义form
      customInfoForm: {},
      // 任务字典
      fieldDictionaryList: [],
      titleIsFocus: false,
      closePermissionsStatus: false, // 设为公开/私密 显隐
      dialogVisible: this.dialogShow,
      taskDetailId: '',
      needRefreshList: false, // 关闭弹窗时是否要刷新数据
      taskData: {}, // 任务数据
      isDisabledEdit: false,
      isOriginatorDisabledEdit: false, //负责人权限
      isLeader: false,
      isCreate: false,
      form: {
        title: '',
        priority: 1,
        status: ''
      },
      originalForm: {},
      statusDict: taskEnum.TASKSTATUS, // 状态字典
      stateDictColor: taskEnum.TASKSTATUSCOLOR,
      priorityDict: taskEnum.PRIOTITY, // 优先级字典
      krVisible: true, // 关联kr是否显示
      closeHoverBoolean: false, // 小图标滑过
      closeBoolean: false, // 小图标滑过
      btnHoverBoolean: false, // 按钮滑过
      propsUpdateKrObj: {}, // kr对象
      editorClick: false, // 描述
      editorFocus: false,
      editorVisible: false, // 富文本编辑器是否显示
      editorToolbar: false, // 编辑器工具栏(默认不显示)
      isInformationEdit: false, // 评论是否处于编辑状态
      goBackStatus: false, // 回顶部按钮是否显示
      gobottomStatus: false, // 向下箭头是否显示
      chargeUserListNew: [],
      chargeUserListInit: [],
      chargeUserPickerVisible: false, // 协作人选择
      over: false, // 接口请求是否完成
      // priorityObj: {
      //   1: {
      //     name: '非常紧急',
      //     color: '#E83626',
      //     class: 'very-emergency'
      //   },
      //   2: {
      //     name: '紧急',
      //     color: '#FA972B',
      //     class: 'emergency'
      //   },
      //   3: {
      //     name: '普通',
      //     color: '#999999',
      //     class: 'normal'
      //   }
      // },
      subTaskVisible: false, // 添加子任务框是否显示
      subTask: [], // 子任务列表
      // 子元素的信息
      sub_obj: {
        status: 1,
        title: '',
        startTime: []
      },
      goToBooleans: true, // 是否点击到达底部
      add_icons_: false, // 添加图片切换
      processListData: [], // 工作流列表
      cooperateVuexList: '',
      menuItems: [],
      initTitle: '',
      checkedLable: [],
      priorityInfo: {},
      defaultTime: '',
      copyProps: {
        keyId: ''
      },
      isEditorShow: false,
      describeContent: '点击即可编辑',
      isShowMore: false,
      editorFlag: false,
      isColorCloseIcon: true,
      originatorHover: false,
      endDateHover: false,
      taskAddLoading: false,
      isStopDoing: false, //是否是待办
      stopdoingEndTime: null,
      stopdoingTime: [],
      fieldItem: {},
      remindTimeVisible: false,
      workingHoursDictionaryList: [], //工时列表
      isShowSetWorkHours: false, // 设置工时显隐
      setWorkHoursTitle: '', //工时弹框标题
      isShowWorkHoursStatic: false, // 工时统计显隐
      isShowWorkHoursDetailed: false, //工时明细显隐
      setWorkHoursType: 'add', // 新增工时/编辑工时
      editData: {}, // 编辑工时回显数据
      isShowDetails: false,
      shopDoingFieldDictionaryList: [], //待办详情字典
      isBlur: true,
      isConfirm: true,
      progressList: [],
      taskProcessRefresh: false, // 记录审批流是否修改
      transformToSonTaskVisible: false,
      copyTaskVisible: false
    }
  },
  computed: {
    // 背景颜色的改变
    bgColorstate() {
      if (this.form.status === '1') {
        return 'left-box-todo-not'
      } else if (this.form.status === '2') {
        return 'left-box-todo-ing'
      } else if (this.form.status === '3') {
        return 'left-box-todo-done'
      }
      return ''
    },
    ...mapState('pm', {
      cooperateVuexList: 'cooperateVuexList'
    }),
    ...mapState('task', {
      taskTodoOrKrDict: 'taskTodoOrKrDict' // 任务字典
    }),
    ...mapState('user', {
      userInfo: 'userInfo'
    })
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
        if (val === true && this.type === 2) {
          // 获取任务字典
          this.getTaskDictionary(true, 'approvalDetail')
          // this.initTask()
        }
      }
    },
    isDisabledEdit: {
      handler(){
        console.log('isDisabledEdit', this.isDisabledEdit)
      }
    }
  },
  created() {
    console.log('任务详情')
    let defaultStart = ''
    let defaultEnd = ''
    if (this.companyCalendarInfo.amStartTime) {
      defaultStart =
        this.companyCalendarInfo.amStartTime.substring(11, 19) || ''
    }
    if (this.companyCalendarInfo.pmEndTime) {
      defaultEnd = this.companyCalendarInfo.pmEndTime.substring(11, 19) || ''
    }

    this.defaultTime = [defaultStart, defaultEnd]
    this.cooperateVuexList = this.$store.state.pm.cooperateVuexList
    // if (this.type === 1) {
    //   this.initTask()
    //   // this.getTaskDictionary(true)
    // }
    if (this.taskId) {
      this.noticeSetRead()
      // 获取任务字典
      this.getTaskDictionary(true, 'approvalDetail')
    }
  },
  mounted() {
    this.$nextTick(() => {
      // const box = this.$refs.loadMoreMain
      // var $this = this
      // 监听这个dom的scroll事件
      // $this.handleScroll()
      // box.onscroll = () => {

      // }
      //初始化时监测定位动态箭头是否显示
      document.documentElement.addEventListener(
        'resize',
        this.handlegoStatusFunction
      )
      //初始化时监测定位动态箭头是否显示
      this.handlegoStatusFunction()
      // 点击空白处添加人员列表关闭
      document.documentElement.addEventListener(
        'click',
        this.closeAllModel,
        false
      )
      this.bus(this).$on('global:taskDetailsRefresh', (val) => {
        this.taskId = val
        this.getTaskDetail()
      })
      document.body &&
        document.body.addEventListener('mouseover', (e) => {
          if (e.target.nodeName == 'A' || e.target.nodeName === 'IMG') {
            this.describeContent = '点击直接预览'
          }
        })
      document.body &&
        document.body.addEventListener('mouseout', (e) => {
          if (e.target.nodeName == 'A') {
            if (this.isDisabledEdit) {
              this.describeContent = '没有权限编辑'
            } else {
              this.describeContent = '点击即可编辑'
            }
          }
        })
      document.body &&
        document.body.addEventListener('click', (e) => {
          if (
            e &&
            e.target.className &&
            e.target.nodeName == 'A' &&
            e.target.className == 'insertfile'
          ) {
            const fileUrl = e.target.href
            e.preventDefault()
            this.lookEditorImg(fileUrl, 'file')
          }
        })
      document.body &&
        document.body.addEventListener('click', (e) => {
          if (
            (e &&
              e.target.className &&
              e.target.nodeName === 'A' &&
              e.target.className == 'insertlink') ||
            (e &&
              e.target.className &&
              e.target.nodeName === 'A' &&
              e.target.className != 'insertlink' &&
              e.target.className != 'insertfile')
          ) {
            console.log('details-----')
            window.open(e.target.href, '_blank')
          }
        })
      document.body &&
        document.body.addEventListener('click', (e) => {
          if (
            e &&
            e.target.className &&
            e.target.nodeName === 'A' &&
            e.target.className == 'insertimage'
          ) {
            const fileUrl = e.target.currentSrc
            this.lookEditorImg(fileUrl, 'img')
          }
        })
      this.bus(this).$on(
        'global:setWorkHoursRefresh',
        (source, taskId, hourType, totalHour) => {
          console.log('来源--', source, hourType, totalHour)
          if (source == 'task') {
            if (hourType == 1 && totalHour) {
              this.form.taskWorkingHoursStatistics['planHourTotal'] = totalHour
            } else if (hourType == 2 && totalHour) {
              this.form.taskWorkingHoursStatistics['actualHourTotal'] =
                totalHour
            }
            this.form = JSON.parse(JSON.stringify(this.form))
            this.$nextTick(() => {
              if (this.$refs.WorkHoursDetailed) {
                this.$refs.WorkHoursDetailed.getWorkHourDetaileTotal()
              }
              if (this.$refs.WorkHoursStatic) {
                this.$refs.WorkHoursStatic.echartInit()
              }
            })
          }
        }
      )
      // 监听打开工时明细
      this.bus(this).$on('lookDetailed', async (arr) => {
        if (this.isShowWorkHoursDetailed == true) {
          this.isShowWorkHoursDetailed = false
        }
        this.$nextTick(() => {
          this.isShowWorkHoursDetailed = true
        })
      })
      // 监听打开编辑工时
      this.bus(this).$on('setWorkHours', async (title, type, row) => {
        console.log('打开设置工时', title, type, this.isShowSetWorkHours)
        this.setWorkHoursTitle = title
        this.setWorkHoursType = type
        this.editData = {}
        if (type == 'edit') {
          this.editData = row
        }
        this.isShowSetWorkHours = false
        this.$nextTick(() => {
          this.isShowSetWorkHours = true
        })
      })
      // 监听工时设置关闭
      this.bus(this).$on('closeSetWorkHours', async () => {
        this.isShowSetWorkHours = false
      })
      // 监听工时明细关闭
      this.bus(this).$on('closeWorkHoursDetailed', async () => {
        this.isShowWorkHoursDetailed = false
      })
      this.bus(this).$emit('closeWorkHoursStatic', async () => {
        this.isShowWorkHoursDetailed = false
      })
      // 监听点击描述取消
      document.body &&
        document.body.addEventListener('click', (e) => {
          if (e.target.className == 'cancel btn') {
            this.isBlur = false
          } else if (e.target.className == 'confirm btn') {
            this.isBlur = false
          } else {
            this.isBlur = true
          }
        })
      this.bus(this).$on('taskApproveRemindRefresh', () => {
        if (
          this.$store.state.task.taskApproveRemindObj.pageType === 'taskDetail'
        ) {
          this.getApprovalProcessRecord('', true)
        }
      })
    })
  },
  destroyed() {
    document.documentElement.removeEventListener('click', this.closeAllModel)
    document.documentElement.removeEventListener(
      'resize',
      this.handlegoStatusFunction
    )
  },
  methods: {
    async recycleTask() {
      if (this.form.isDel !== 2) {
        const status = await this.$element.confirm('放入回收站后如需恢复请进入Todo>回收站任务中进行操作。', '确定放入回收站?')
        if (!status) {
          return false
        }
      }
      this.$apis.UPDATETASKISDELLISTBYIDS(this.form.isDel === 2 ? 0 : 2, [this.taskId]
      ).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.needRefreshList = true
          this.dialogClose()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    async closeTask() {
      if (this.form.isClose !== 1) {
        const status = await this.$element.confirm('归档任务后如需恢复请进入Todo>归档任务列表中进行操作。', '确定归档任务?')
        if (!status) {
          return false
        }
      }
      this.$apis.UPDATETASKISCLOSELISTBYIDS(this.form.isClose === 1 ? 0 : 1, [this.taskId]).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.needRefreshList = true
          this.dialogClose()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    copyTask() {
      this.copyTaskVisible = true
    },
    // 审批流详情
    getApprovalProcessRecord(deleteState, needRefreshList) {
      if (deleteState) {
        // 删除任务审批
        this.needRefreshList = true
        this.dialogClose()
        return
      }
      this.$apis
        .APPROVALPROCESSRECORD({
          businessId: this.form.projectId,
          businessExtId: this.taskId,
          businessType: 3
        })
        .then((rest) => {
          this.progressList = rest.data
          var approvalStatus = this.form.submitterType === 1
          if (approvalStatus) {
            // 目标共享人 = 项目成员 = 查看者
            this.isDisabledEdit = true
            this.isLeader = false
            this.isCreate = false
            this.describeContent = '没有权限编辑'
          } else {
            const permission = this.form.taskOperationPermission
            if (permission == 0) {
              this.isOriginatorDisabledEdit = true
            }
            if (permission == 0 || permission == 5 || permission == 8 || this.form.isDel === 2 || this.form.isClose === 1) {
              // 目标共享人 = 项目成员 = 查看者
              this.isDisabledEdit = true
              this.isLeader = false
              this.isCreate = false
              this.describeContent = '没有权限编辑'
            } else if (permission == 2 || permission == 7) {
              // 任务负责人 = 项目管理员
              this.isLeader = true
              this.isDisabledEdit = false
              this.isCreate = false
              this.describeContent = '点击即可编辑'
            } else if (permission == 3 || permission == 4 || permission == 6) {
              // 任务创建人 = 目标创建人 = 项目创建人
              this.isLeader = false
              this.isCreate = true
              this.isDisabledEdit = false
              this.describeContent = '点击即可编辑'
            }
          }
          this.needRefreshList = needRefreshList || this.needRefreshList
          if (needRefreshList === true) {
            this.getTaskDetail()
          }
          if (this.progressList.processDynamic && this.progressList.processDynamic.action === 2) {
            this.taskProcessRefresh = true
          }
        })
    },
    cycleChange() {
      this.$refs.taskCycleDetail[0].isShowChange()
    },
    closeSetWorkHours() {
      this.isShowSetWorkHours = false
    },
    changeStopdoingState(state) {
      let status = state == '1' ? '3' : '1'
      this.changeStatus(status)
    },
    // 工时统计
    openWorkHoursStatic() {
      this.isShowWorkHoursStatic = true
    },
    setWorkHours(wItem) {
      console.log('设置工时--', wItem)
      this.editData = {}
      this.setWorkHoursType = 'add'
      this.setWorkHoursTitle = wItem.workingHoursName
      this.isShowSetWorkHours = true
    },
    getWorkhours() {
      let percentage = 0
      if (
        this.form.taskWorkingHoursStatistics &&
        this.form.taskWorkingHoursStatistics['planHourTotal'] &&
        this.form.taskWorkingHoursStatistics['actualHourTotal']
      ) {
        percentage =
          (this.form.taskWorkingHoursStatistics['actualHourTotal'] /
            this.form.taskWorkingHoursStatistics['planHourTotal']) *
          100
        percentage = percentage.toFixed(2)
      } else {
        percentage = 0
      }
      return percentage
    },
    // 提醒时间保存成功事件
    remindTimeSave(row) {
      console.log('提醒时间---', row)
      this.form.remindDate = row.data
      this.form.remindDate.id = row.id
      // this.row.remindDate = row.data
      this.type_remind = row.dateType
      this.needRefreshList = true
      this.getTaskDetail()
      // this.$emit('global:taskDetailRefresh')
      // this.$emit('refresh')
    },
    // 设置提醒时间点击事件
    remindTimeClick() {
      if (this.isDisabledEdit) {
        return
      }
      this.remindTimeVisible = true
    },
    // 提醒时间转化
    remindDateFmt() {
      if (this.isDisabledEdit) {
        return '没有权限编辑'
      }
      return taskUtil.remindDateFmt(this.form.remindDate)
    },
    changeStopdoing(val) {
      this.stopdoingTime = val
      if (this.stopdoingTime.length > 1) {
        this.form.startDate = this.stopdoingTime[0]
        this.form.endDate = this.stopdoingTime[1]
      }
      // this.form.endDate = this.stopdoingEndTime
      const form = JSON.parse(JSON.stringify(this.form))
      // form.type = 'end'
      this.dateTimeChange(form, this.fieldItem)
    },
    timeClear(fieldItem) {
      this.form.endDate = null
      const form = JSON.parse(JSON.stringify(this.form))
      // form.type = 'end'
      form.isDelete = true
      this.dateTimeChange(form, fieldItem)
    },
    // 出现日期面板（结束日期选择）
    endPicker(fieldItem) {
      console.log(this.$refs.endDate)
      this.$refs.endDate[0].focus()
      this.fieldItem = fieldItem
    },
    clickDraw() {
      this.bus.$emit('clickTaskDetail')
    },
    changeClassify() {
      this.needRefreshList = true
      this.getTaskDictionary(true)
    },
    showMore() {
      let dom = document.getElementById('htmlContent')
      this.isEditorShow = !this.isEditorShow
      if (this.isEditorShow) {
        dom.style =
          'height:880px !important;max-height:880px !important;overflow-y: auto !important;'
      } else {
        dom.style =
          'height:440px !important;max-height:440px !important;overflow-y: hidden !important;'
      }
    },
    lookEditorImg(imgURl, type) {
      this.bus(this).$emit('global:Editor:filePreview', imgURl, type)
    },
    updateDetails() {
      this.getTaskDetail()
    },
    // 修改工作流id
    changeProcess(id, projectId) {
      this.form.processId = id
      this.editTaskProcess(this.form.processId, this.form.id, projectId)
    },
    changeProject(id, item) {
      item['type'] = 2
      // 项目取字典
      this.$apis.TASKADDPARAMPROJECT(id).then((res) => {
        if (res.status === 200) {
          this.labelData = JSON.parse(
            JSON.stringify(res.data.labelDictionaryList)
          )
        } else {
          this.$message.error(res.message)
        }
      })
      this.saveKrMainDetail(item)
    },
    setInfoForm(id, value, infoItem) {
      const params = {
        fieldId: id,
        value:
          infoItem.fieldAttribute == 'multipleChoice'
            ? value.join(',')
            : infoItem.fieldAttribute == 'file'
            ? value.length == 0
              ? ''
              : JSON.stringify(value)
            : value,
        fieldType: infoItem.fieldType,
        fieldAttribute: infoItem.fieldAttribute ? infoItem.fieldAttribute : ''
      }
      this.submitTaskUpdate(params)
    },
    updateTaskLabel(labelList) {
      const params = {
        id: this.taskId,
        labelList: labelList
      }
      this.$apis.UPDATETASKLABEL(params).then((res) => {
        if (res.status == 200) {
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.needRefreshList = true
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 获取选中的biaoq
    saveLabels(tags, fieldItem) {
      const value = tags.map((item) => {
        return item.id
      })
      const params = {
        fieldId: fieldItem.id,
        value: value.join(','),
        fieldType: fieldItem.fieldType,
        fieldAttribute: fieldItem.fieldAttribute ? fieldItem.fieldAttribute : ''
      }
      if (!params.value) {
        this.$message({
          msg: `请选择${fieldItem.name}!`,
          type: 'warn'
        })
        return
      }
      this.submitTaskUpdate(params)
    },
    setLabelColor(item) {
      let style = {
        border: '1px ' + item.colour + ' solid !important',
        color: item.colour + ' !important',
        borderRadius: '12px !important'
      }
      return style
    },
    submitLable(fieldItem) {
      const value = this.checkedLable.map((item) => {
        return item.id
      })
      const params = {
        fieldId: fieldItem.id,
        value: value.join(','),
        fieldType: fieldItem.fieldType,
        fieldAttribute: fieldItem.fieldAttribute ? fieldItem.fieldAttribute : ''
      }
      if (!params.value) {
        this.$message({
          msg: `请选择${fieldItem.name}!`,
          type: 'warn'
        })
        return
      }
      this.submitTaskUpdate(params)
    },
    submitTaskUpdate(params) {
      this.$apis.TASKUPDATE(params, this.taskId).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: res.message,
            type: 'success'
          })
          this.needRefreshList = true
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    getTaskDictionary(needDetail, approvalDetail) {
      const params = {
        id: this.taskId
      }
      this.$apis.TASKDICTIONARY(params).then((res) => {
        if (res.status === 200) {
          this.fieldDictionaryList = res.data.fieldDictionaryList
          this.fieldDictionaryList.push({})
          this.fieldDictionaryList.sort(
            (a, b) => Number(a.sort) - Number(b.sort)
          )
          this.priorityDictionaryList = res.data.priorityDictionaryList
          this.workingHoursDictionaryList = res.data.workingHoursDictionaryList
          this.labelData = res.data.labelDictionaryList
          if (needDetail) {
            this.initTask(approvalDetail)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 返回顶部
    goTop() {
      const box = this.$refs.loadMoreMain
      let top = box.scrollTop
      const timeTop = setInterval(() => {
        box.scrollTop = top -= 5
        if (top <= 0) {
          this.goBackStatus = false
          clearInterval(timeTop)
        }
      }, 1)
    },
    // 定位到动态底部
    goStatusBottom() {
      const that = this
      const box = that.$refs.loadMoreMain
      if (document.querySelector('.dynamic-container') && that.goToBooleans) {
        that.goToBooleans = false
        const offsetTop =
          document.querySelector('.dynamic-container').offsetTop +
          document.querySelector('.dynamic-container').offsetHeight -
          80
        let _bottom = 0
        that.timeTop = setInterval(() => {
          box.scrollTop = _bottom += 5
          if (_bottom >= offsetTop - 50) {
            that.gobottomStatus = false
            that.goToBooleans = true
            clearInterval(that.timeTop)
          }
        }, 1)
      }
    },
    //监听滚动条 事件
    handleScroll() {
      let box = this.$refs.loadMoreMain
      let taskContMain = this.$refs.taskContMain
      if (document.querySelector('.dynamic-container') && taskContMain) {
        let offsetHeight =
          document.querySelector('.dynamic-container').offsetHeight
        if (box.scrollTop > 380) {
          this.goBackStatus = true
          this.gobottomStatus = false
        } else {
          this.goBackStatus = false
          if (box.scrollTop > taskContMain.offsetHeight) {
            this.gobottomStatus = true
          }
        }
      }
    },
    //计算初始时 动态位置是否显示定位箭头
    handlegoStatusFunction() {
      setTimeout(() => {
        let box = this.$refs.loadMoreMain
        let taskContMain = this.$refs.taskContMain
        if (
          document.querySelector('.dynamic-container') &&
          taskContMain &&
          box
        ) {
          var offsetHeight =
            document.querySelector('.dynamic-container').offsetHeight

          if (offsetHeight + taskContMain.offsetHeight > box.offsetHeight) {
            this.gobottomStatus = true
          } else {
            this.gobottomStatus = false
          }
        }
      }, 2500)
    },
    // 设置消息为已读 ---update
    noticeSetRead() {
      const _obj = {
        busicId: this.taskId,
        busicType: 2
      }
      this.$apis.CLEARREDPOINTMSG(_obj).then((res) => {})
    },
    submitPermissions() {
      // this.needRefreshState()
    },
    cycleSave(row) {},
    keyUpTitle() {
      let message_ = this.form.title.replace(/\s*/g, '') || ''
      if (message_.replace(/\s*/g, '').length > 150) {
        this.$message.warn('标题最多可输入 150 个字符哦!')
        this.form.title = message_.substring(0, 150)
      }
    },
    needRefreshState() {
      this.needRefreshList = true
    },
    initTask(approvalDetail) {
      // 埋点
      this.$mixpanelUtil.track('View Task Details')
      this.getTaskDetail(approvalDetail)
    },
    // 获取任务详情
    async getTaskDetail(approvalDetail) {
      this.customInfoForm = {}
      this.over = false
      // TASKDETAIL
      await this.$apis.TASKDETAILNEW({}, this.taskId).then((rest) => {
        if (rest.status === 200) {
          this.form = rest.data
          if (approvalDetail === 'approvalDetail' && this.form.projectId) {
            this.getApprovalProcessRecord()
          }
          if (this.form.businessType == 1) {
            // 待办
            this.isStopDoing = true
            this.shopDoingFieldDictionaryList = []
            this.shopDoingFieldDictionaryList.push({
              fieldType: 'remindDate',
              sort: 5
            })
            this.fieldDictionaryList.forEach((item, index) => {
              if (item.fieldType == 'title') {
                item.sort = 1
              }
              if (item.fieldType == 'executor') {
                item.sort = 2
              }
              if (item.fieldType == 'involves') {
                item.sort = 3
              }
              if (item.fieldType == 'endDate') {
                item.sort = 4
              }
              if (item.fieldType == 'remindDate') {
                item.sort = 5
              }
              if (item.fieldType == 'priority') {
                item.sort = 6
              }
              if (item.fieldType == 'tag') {
                item.sort = 7
              }
              if (item.fieldType == 'note') {
                item.sort = 8
              }
              if (
                item.fieldType == 'title' ||
                item.fieldType == 'remindDate' ||
                item.fieldType == 'priority' ||
                item.fieldType == 'note' ||
                item.fieldType == 'executor' ||
                item.fieldType == 'endDate' ||
                item.fieldType == 'endDate' ||
                item.fieldType == 'involves' ||
                item.fieldType == 'tag'
              ) {
                this.shopDoingFieldDictionaryList.push(item)
              }
            })
            this.shopDoingFieldDictionaryList.sort((a, b) => {
              return a.sort - b.sort
            })
            console.log(434343, this.shopDoingFieldDictionaryList)
          } else {
            this.isStopDoing = false
            this.fieldDictionaryList.forEach((itemc, indexc) => {
              if (itemc.fieldType == 'endDate') {
                this.fieldDictionaryList.splice(indexc, 1)
              }
            })
          }

          this.initTitle = this.form.title
          this.menuItems = []
          if (rest.data.isDel !== 2 && rest.data.isClose !== 1) {
            let publicItem = {
              icon: 'icon-public',
              title: '公开模式',
              text: '所有人可见',
              func: 'public'
            }
            if (this.form.isShare === 1) {
              publicItem = {
                icon: 'icon-private',
                title: '隐私模式',
                text: '仅参与者可见',
                func: 'public'
              }
            }
            this.menuItems.push(publicItem)
          }
          if (rest.data.projectId) {
            this.menuItems.push({
              icon: 'icon-fuzhi',
              title: '复制任务',
              func: 'copyTask'
            })
          }
          // this.menuItems.push({
          //   icon: 'icon-move',
          //   title: '转化为子任务',
          //   func: 'transformToSonTask'
          // })
          this.taskDetailId = rest.data.id || '' // 任务id
          // 0302-修改@人显示
          // this.form.keyTitle = this.$utils.tagFilter(
          //   this.$utils.changeToHtml(rest.data.keyTitle)
          // )
          this.form.keyTitle = rest.data.keyTitle
          // 新窗口打开
          const filea = document.querySelectorAll('.attachment a')
          filea.forEach((item) => {
            item['target'] = '_blank'
          })
          const peopleList = rest.data.taskCollaboratorList
          this.chargeUserListNew = peopleList
          this.chargeUserListInit = []
          if (
            rest.data.taskCollaboratorList &&
            rest.data.taskCollaboratorList.length > 0
          ) {
            rest.data.taskCollaboratorList.forEach((item) => {
              this.chargeUserListInit.push(item)
            })
          }

          // 接口请求完成
          // 开始时间   =====   结束时间处理
          this.form.startDateString = rest.data.startDate
            ? this.$utils.formatRecentTime(rest.data.startDate, 'detail')
            : ''
          this.form.endDateString = rest.data.endDate
            ? this.$utils.formatRecentTime(rest.data.endDate, 'detail')
            : ''
          this.over = true
          this.keyId = rest.data.keyId || ''
          this.cycleId = rest.data.cycleId || '' // 周期id
          this.sub_obj.originatorAvatar = rest.data.originatorAvatar || '' // 子任务管理员头像
          this.sub_obj.originatorId = rest.data.originatorId || this.userInfo.id // 子任务管理员id
          this.subTask = rest.data.sonTaskListDto || [] // 子任务列表
          // this.form.information = this.$utils.tagFilter(
          //   this.$utils.changeToHtml(rest.data.information)
          // )
          this.initContent = this.form.information || ''
          for (let i = 0; i < this.subTask.length; i++) {
            this.subTask[i].startDateString = this.subTask[i].startDate
              ? this.$utils.formatRecentTime(this.subTask[i].startDate, 'page')
              : ''
            this.subTask[i].endDateString = this.subTask[i].endDate
              ? this.$utils.formatRecentTime(this.subTask[i].endDate, 'page')
              : ''
            this.subTask[i].showDates = this.$utils.showDates(
              this.subTask[i].startDate,
              this.subTask[i].endDate
            )
          }
          // 每次刷新 将组件隐藏
          this.chargeUserPickerVisible = false

          // 判断任务权限 0查看 1任务协作人 2任务负责人 3任务创建人 4目标创建人，5共享人，6项目创建人，7项目管理员，8项目成员
          const permission = rest.data.taskOperationPermission
          if (permission == 0) {
            this.isOriginatorDisabledEdit = true
          }
          if (
            permission == 0 ||
            permission == 5 ||
            permission == 8 ||
            rest.data.submitterType === 1
          ) {
            // 目标共享人 = 项目成员 = 查看者
            this.isDisabledEdit = true
            this.isLeader = false
            this.isCreate = false
            this.describeContent = '没有权限编辑'
          } else if (permission == 2 || permission == 7) {
            // 任务负责人 = 项目管理员
            this.isLeader = true
            this.isDisabledEdit = false
            this.isCreate = false
            this.describeContent = '点击即可编辑'
          } else if (permission == 3 || permission == 4 || permission == 6) {
            // 任务创建人 = 目标创建人 = 项目创建人
            this.isLeader = false
            this.isCreate = true
            this.isDisabledEdit = false
            this.describeContent = '点击即可编辑'
          }
          // 2任务负责人 3任务创建人 4目标创建人 才能删除
          if (
            (!this.isDisabledEdit || this.isLeader || this.isCreate)  &&
            permission !== 1 && rest.data.isClose === 0
          ) {

            this.menuItems.push({
              icon: 'icon-recycle',
              title: rest.data.isDel === 2 ? '转出回收站' : '放入回收站',
              func:  'recycleTask'
            })
          }
          if (
            (!this.isDisabledEdit || this.isLeader || this.isCreate)  &&
            permission !== 1 && rest.data.isDel === 0
          ) {
            this.menuItems.push({
              icon: 'icon-wenjian2',
              title: rest.data.isClose !== 1 ? '归档任务' : '取消归档',
              func:  'closeTask'
            })
          }
          if (
            (!this.isDisabledEdit || this.isLeader || this.isCreate) &&
            permission !== 1
          ) {
            this.menuItems.push({
              icon: 'icon-delete-icon',
              title: '删除任务',
              func: 'delete',
              color: '#eb0d0d'
            })
          }
          // this.isDisabledEdit = permission === 0 // 是否禁止编辑
          // this.isLeader = permission !== 0 // 是否负责人 创建人 负责人 协作人
          // this.isCreate = permission !== 0 // 创建人
          // this.collaborator = permission !== 0 // 协作人

          if (rest.data.projectId) {
            // 获取项目工作流
            // this.getProjectProcessList(rest.data.projectId)
            this.projectInfo = {
              id: rest.data.projectId,
              name: rest.data.projectName,
              colour: rest.data.projectColour,
              logo: rest.data.projectLogo
            }
          }
          if (rest.data.objId) {
            this.okrObj = {
              id: rest.data.objId,
              title: rest.data.objContent
            }
          }
          if (rest.data.priority) {
            this.priorityInfo = rest.data.priority
          }
          // customInfoForm
          if (rest.data.fieldValueList && rest.data.fieldValueList.length > 0) {
            rest.data.fieldValueList.forEach((item) => {
              if (item.fieldId) {
                if (item.fieldAttribute == 'multipleChoice') {
                  if (item.value) {
                    this.customInfoForm[item.fieldId] = item.value.split(',')
                  } else {
                    this.customInfoForm[item.fieldId] = []
                  }
                } else if (item.fieldAttribute == 'file') {
                  let file = []
                  if (item.value) {
                    file = JSON.parse(item.value)
                  }
                  this.customInfoForm[item.fieldId] = file
                } else if (item.fieldAttribute == 'number') {
                  this.customInfoForm[item.fieldId] = parseInt(item.value)
                } else {
                  this.customInfoForm[item.fieldId] = item.value
                }
              }
            })
            this.customInfoForm = JSON.parse(
              JSON.stringify(this.customInfoForm)
            )
          }
          if (rest.data.priority && rest.data.priority.value) {
            this.priorityVal = rest.data.priority.value
          }
          this.copyProps = {
            type: this.form.type || null,
            objId: this.form.objId || null,
            id: this.form.keyId || null,
            title: this.form.keyTitle || null,
            cycleId: this.form.cycleId || null
          }
          setTimeout(() => {
            if (
              this.$refs.htmlContent &&
              this.$refs.htmlContent[0] &&
              this.$refs.htmlContent[0].scrollHeight > 454
            ) {
              this.isShowMore = true
            } else {
              this.isShowMore = false
            }
          }, 200)
          this.loading = false
          this.isShowDetails = false
          this.$nextTick(() => {
            this.isShowDetails = true
            // this.$refs.dynamic.getComment()
          })
          if (
            rest.data.isClose === 1 || rest.data.isDel === 2
          ) {
            // 目标共享人 = 项目成员 = 查看者
            this.isDisabledEdit = true
            this.isLeader = false
            this.isCreate = false
            this.describeContent = '没有权限编辑'
          }
        } else {
          this.$message.error(rest.message)
          setTimeout(() => {
            this.dialogClose()
          }, 2000)
          this.loading = false
        }
      })
    },
    // 详情页图片处理
    taskImgHandle() {
      // 等比缩放
      const imgs = document.querySelectorAll('.htmlContent img')
      imgs.forEach((item) => {
        if (item.width > 500) {
          const perc = 500 / item.width
          item.maxWidth = '90%'
          item.width = 'auto'
          item.height = 'auto'
        }
      })
    },
    // 回车失去焦点事件
    enterToBlur(event) {
      if (event.keyCode === 13) {
        this.titleBlur() //发送文本
        event.preventDefault() //阻止浏览器默认换行操作
        return false
      }
    },
    //  标题失去焦点，修改任务标题
    async titleBlur() {
      this.titleIsFocus = true
      const title = this.form.title
      if (title === this.originalForm.title) {
        return
      }
      if (title === this.initTitle) {
        this.titleIsFocus = false
        // this.$message.warn('任务标题不能同以前一样哦！')
        return
      }
      if (!title || (!title && title.replace(/\s*/g, '').length === 0)) {
        this.$message.error('任务标题不能为空')
        this.form.title = this.originalForm.title
        return
      }
      const _obj = {}
      _obj.id = this.form.id
      _obj.title = title.replace(/[\r\n]/g, '')
      await this.$apis.TASKTITLEUPDATE(_obj).then((res) => {
        if (res.status === 200) {
          this.$message.success('标题修改成功')
          this.getTaskDetail()
          this.needRefreshList = true
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '标题',
            task_id: _obj.id
          })
          setTimeout(() => {
            this.titleIsFocus = false
          }, 500)
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    async changeStatusApproval(status) {
      if (status == 3 && this.form.projectId) {
        var checkData = await this.getPmApprovalCheck(
          {
            businessType: 3,
            businessId: this.form.projectId,
            businessExtId: this.form.id
          },
          1
        )
        if (checkData.state === true) {
          this.$store.commit('task/taskApproveRemindObj', {
            visible: true,
            taskDetail: this.form,
            businessData: JSON.stringify({
              id: this.form.id,
              title: this.form.title,
              status
            }),
            businessActionType: 1,
            approvalCheckData: checkData.data,
            pageType: 'taskDetail'
          })
        } else {
          this.changeStatus(status)
        }
      } else {
        this.changeStatus(status)
      }
    },
    // 修改任务状态
    changeStatus(status) {
      if (this.isDisabledEdit) {
        return
      }
      const id = this.taskDetailId
      const _obj = {
        id,
        title: this.form.title,
        status
      }
      this.$apis.TASKSTATECHANGE(_obj).then((res) => {
        this.needRefreshList = true
        if (res.status === 200) {
          if (_obj.status === '1') {
            this.$message.success('状态已修改为待处理!')
          } else if (_obj.status === '2') {
            this.$message.success('状态已修改为进行中!')
          } else if (_obj.status === '3') {
            this.$message.success('状态已修改为已完成!')
            // 数据埋点c
            this.$mixpanelUtil.track('Done Task', { task_id: _obj.id })
          } else if (_obj.status === '4') {
            this.$message.success('状态已修改为搁置!')
          }
          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '状态',
            task_id: _obj.id
          })
        } else if (res.status === 71001 || res.status === 71005) {
          this.$message.error(res.message)
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    //  清除负责人
    clearToriginatorPicker(fieldItem) {
      if (this.form.taskOperationPermission == 0) {
        this.$message({
          msg: '查看者不可删除',
          type: 'warn'
        })
        return
      }
      // 判断必填项
      if (fieldItem.required == 0) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      this.form.originatorName = null
      this.toriginatorPicker({ id: null })
    },
    // 负责人选择
    toriginatorPicker(row) {
      const form = {
        originatorId: row.id
        // title: this.form.title,
        // id: this.form.id
      }
      this.$apis.TASKLEADER(form, this.form.id).then((res) => {
        this.needRefreshList = true
        if (res.status === 200) {
          if (!row.id) {
            this.$message.success('负责人删除成功')
          } else {
            this.$message.success('负责人修改成功')
          }

          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '负责人',
            task_id: this.form.id
          })
        } else if (res.status === 70000) {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 日期时间修改
    dateTimeChange(row, fieldItem) {
      if (!row.startDate && !row.endDate && fieldItem.required == 0) {
        this.$message({
          msg: '时间字段为必填项！',
          type: 'warn'
        })
        return
      }
      const form = {
        id: this.form.id,
        title: this.form.title
      }
      const typeMap = {
        start: 1, // 开始时间
        end: 2 // 结束时间
      }
      form.type = typeMap[row.type]
      if (row.type === 'start') {
        form.startDate = row.startDate
        this.$mixpanelUtil.track('Update Task', {
          type: '开始时间',
          task_id: this.form.id
        })
      } else {
        form.endDate = row.endDate
        this.$mixpanelUtil.track('Update Task', {
          type: '截止时间',
          task_id: this.form.id
        })
      }
      if (row.isDelete) {
        // 删除任务时间判断依赖
        this.$apis.TASKGANTTRELATION(this.form.id).then((res) => {
          if (res.status === 200) {
            if (res.data > 0) {
              this.$confirm(
                `此任务设置的有依赖关系，清除时间后将解除依赖关系，请确认是否继续？`,
                '提示',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'none',
                  customClass: 'confirmBox',
                  lockScroll: false
                }
              ).then(
                () => {
                  this.deleteTaskTime(form)
                },
                () => {
                  return
                }
              )
            } else {
              this.deleteTaskTime(form)
            }
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
      } else {
        this.$apis.TASKTIMEUPDATE(form).then((res) => {
          if (res.status === 200) {
            this.needRefreshList = true
            this.$message.success('时间修改成功')
            this.getTaskDetail()
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
            this.getTaskDetail()
          }
        })
      }
      return
    },
    deleteTaskTime(form) {
      this.$apis.TASKTIMEDELETE(form).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.$message.success('时间删除成功')
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
          this.getTaskDetail()
        }
      })
    },
    // 任务优先级变更
    priorityChange(fieldItem, val) {
      // 判断必填项
      if (fieldItem.required == 0 && !val) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      const valObj = this.priorityDictionaryList.find((item) => {
        return val == item.value
      })
      this.priorityInfo = valObj
      const params = {
        fieldId: fieldItem.id,
        value: valObj.id,
        fieldType: fieldItem.fieldType,
        fieldAttribute: fieldItem.fieldAttribute ? fieldItem.fieldAttribute : ''
      }
      this.submitTaskUpdate(params)
      // const _obj = {}
      // _obj.id = this.form.id
      // _obj.priority = this.form.priority
      // _obj.title = this.form.title
      // this.$apis.TASKPRIORITYUPDATE(_obj).then((res) => {
      //   if (res.status === 200) {
      //     this.needRefreshList = true
      //     this.$message.success('优先级修改成功')
      //     this.getTaskDetail()
      //     // 数据埋点c
      //     this.$mixpanelUtil.track('Update Task', {
      //       type: '优先级',
      //       task_id: this.form.id
      //     })
      //   } else {
      //     this.$message({
      //       msg: res.message,
      //       type: 'error'
      //     })
      //     this.getTaskDetail()
      //   }
      // })
    },
    // kr选中
    async krClick(row) {
      this.krClickStatus = true
      // 埋点
      // this.$mixpanelUtil.track('修改关联关键结果')
    },
    // kr标题显示的点击事件
    krTitleClick() {
      if (!this.isLeader && !this.isCreate) {
        return
      } else {
        this.doucmentShadowClose() // 提前执行所有弹框状态重置后 根据条件进行更改
        this.propsUpdateKrObj.cycle_id = this.form.cycleId || null
        this.propsUpdateKrObj.objId = this.form.objId || null
        this.propsUpdateKrObj.keyId = this.form.keyId || null
        this.propsUpdateKrObj.title = this.form.keyTitle || ''
        this.propsUpdateKrObj.projectId = this.form.projectId || ''
        this.propsUpdateKrObj.formPage = 'taskDetails'
        this.propsUpdateKrObj.readyOnly = false
        this.propsUpdateKrObj.default = 1
        if (!this.propsUpdateKrObj.projectId) {
          this.propsUpdateKrObj.selectBooleans = 1
        } else {
          this.propsUpdateKrObj.selectBooleans = 0
        }

        this.krVisible = true
        // 埋点
        // this.$mixpanelUtil.track('点击关联关键结果')
      }
    },
    // 取消kr
    closeKr() {
      this.krVisible = false
    },
    // 保存Kr
    async saveKrMainDetail(obj) {
      console.log('保存Kr-----', obj)
      var form
      if (obj.type === 2) {
        form = {
          id: this.form.id,
          projectId: obj.id,
          keyTitle: obj.name,
          objId: null,
          keyId: null,
          cycleId: null,
          type: '2'
        }
      } else if (obj.type === 1) {
        form = {
          id: this.form.id,
          objId: obj.objId,
          keyId: obj.keyId,
          cycleId: obj.cycle_id,
          keyTitle: obj.keyTitle,
          projectId: null,
          type: '1'
        }
      }
      this.$apis.TASKBELONGUPDATE(form).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.form.keyTitle = this.$utils.tagFilter(obj.keyTitle)
          this.$message.success('更新成功')
          this.getTaskDetail()
          this.krVisible = false
          this.$nextTick(() => {
            this.krVisible = true
          })

          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '任务所属',
            task_id: this.form.id
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
          this.krVisible = false
        }
      })
    },
    // 删除Kr
    TaskDetailMiaoshuDelete() {
      this.deleteKr()
    },
    //删除KR
    async deleteKr() {
      this.$apis.TASKDELETEKR(this.form.id).then((res) => {
        if (res.status === 200) {
          this.$message.success('删除成功')
          this.needRefreshList = true
          this.getTaskDetail()
          // this.dialogClose()
          //this.bus(this).$emit('taskNeedRefresh')
          //数据埋点c
          // this.$mixpanelUtil.track('Update Task', {
          //   type: '任务所属',
          //   task_id: id
          // })
        } else if (res.status == 71100) {
          this.$message({
            msg: '没有操作权限',
            type: 'error'
          })
        } else {
          this.$message({
            msg: '网络错误,稍后重试!',
            type: 'error'
          })
        }
      })
    },
    // 删除kr 图标灰色显示 划过
    cancelenter() {
      this.closeHoverBoolean = true
      this.closeBoolean = false
    },
    // 删除kr 图标蓝色显示 划入
    blueEnter() {
      this.closeBoolean = false
    },
    // 删除kr 图标蓝色显示 划出
    blueLeave() {
      this.closeHoverBoolean = false
      if (this.btnHoverBoolean) {
        this.closeBoolean = true
      } else {
        this.closeBoolean = false
      }
    },
    // 协作人选择
    async chargeUserPicker(row, fieldItem) {
      var chargeUserIdList = ''
      if (row.length == 0 && fieldItem.required == 0) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      if (row.length > 40) {
        this.$message({
          msg: '最多可以选择 40 个协作人哦!',
          type: 'warn'
        })
        return
      }
      if (row.length > 0) {
        row.forEach((item, index) => {
          chargeUserIdList += item.id + ','
        })
      }
      const form = {
        id: this.form.id,
        title: this.form.title,
        originatorId: '',
        chargeUserId: chargeUserIdList
      }
      this.$apis.TASKCHARGEUSERUPDATE(form).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.$message.success('协作人更新成功')
          this.getTaskDetail()
          // this.dialogClose()
          this.needRefreshState()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '协作人',
            task_id: this.form.id
          })
        } else if (res.status === 70000) {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 协作人删除
    async chargeUserDelete(id, fieldItem) {
      if (this.chargeUserListInit.length == 1 && fieldItem.required == 0) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      const form = {
        id: this.form.id,
        title: this.form.title,
        chargeUserId: id
      }
      this.$apis.TASKCHARGEUSERDELETE(form).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.$message.success('协作人删除成功')
          this.getTaskDetail()
          // this.dialogClose()
          this.needRefreshState()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 任务描述点击
    informationClick() {
      // 埋点
      // this.$mixpanelUtil.track('点击任务详情描述')
    },
    editorChange(type, fieldItem) {
      this.isEditorShow = false
      let dom = document.getElementById('htmlContent')

      if (
        this.$refs.htmlContent &&
        this.$refs.htmlContent[0] &&
        this.$refs.htmlContent[0].scrollHeight > 454
      ) {
        dom.style =
          'height:440px !important;max-height:440px !important;overflow-y: hidden !important;'
      } else {
        dom.style =
          'height:auto !important;max-height:440px !important;overflow-y: hidden !important;'
      }

      if (type === 1) {
        // 取消
        this.form.information = this.initContent
        this.editorFocus = false
        this.editorClick = false
        this.editorFlag = false
      } else {
        this.informationBlur(fieldItem)
      }
    },
    EditorFocus(e, flag) {
      this.editorFlag = flag
    },
    EditorBlur(val, fieldItem) {
      console.log('失去焦点-------', val[1], fieldItem)
      setTimeout(() => {
        if (this.isBlur === true) {
          //如果是添加链接就不要失去焦点保存
          if (val[1] === true) {
            return
          }
          this.informationBlur(fieldItem)
        } else {
          this.editorChange(1, fieldItem)
        }
      }, 500)
    },
    onFocus() {
      this.editorFocus = true
      if (this.filtersText(this.form.information)) {
        const content = this.filtersText(this.form.information)
        if (content.length > 0) {
          this.contentFlag = true
        }
      }
    },
    ContentClick(e) {
      console.log(3333, e)
      if (e && e.target.className && e.target.className == 'insertimage') {
        const fileUrl = e.target.currentSrc
        this.lookEditorImg(fileUrl, 'img')
        return
      }
      if (
        (e && e.target.className && e.target.className == 'insertlink') ||
        (e &&
          e.target.nodeName &&
          e.target.nodeName == 'A' &&
          e.target.className != 'insertlink' &&
          e.target.className != 'insertfile')
      ) {
        e.preventDefault()
        window.open(e.target.href, '_blank')
        return
      }
      if (e && e.target.localName && e.target.localName == 'img') {
        const fileUrl = e.target.currentSrc
        this.lookEditorImg(fileUrl, 'img')
        return
      }

      if (
        e &&
        e.target.className &&
        e.target.nodeName == 'A' &&
        e.target.className == 'insertfile'
      ) {
        const fileUrl = e.target.href
        e.preventDefault()
        this.lookEditorImg(fileUrl, 'file')
        return
      } else {
        const fileUrl = e.target.href
        e.preventDefault()
        this.lookEditorImg(fileUrl, 'file')
      }
      if (
        e &&
        e.target.localName &&
        e.target.localName == 'a' &&
        e.target.className == 'insertfile'
      ) {
        const fileUrl = e.target.href
        e.preventDefault()
        this.lookEditorImg(fileUrl, 'file')
        return
      }

      if (this.isDisabledEdit) {
        return
      }
      this.editorClick = true
      this.editorFocus = true
    }, // 点击描述切换编辑状态
    mainTaskDetailScroll() {
      this.$refs.taskPrioritySelect.blur() // 优先级滚动隐藏
    },
    // 任务描述取消
    informationCancel() {
      this.isInformationEdit = false
      this.editorToolbar = false
      this.editorVisible = false
      this.form.information = this.originalForm.information
    },
    // 显示编辑器
    showEditor() {
      this.editorVisible = true
      this.$nextTick(() => {
        this.$refs.simditor.setFocus()
      })
    },
    // 任务描述获得焦点
    informationFocus() {
      this.isInformationEdit = true
      this.editorToolbar = 'remark'
      this.$nextTick(() => {
        this.$refs.simditor.setFocus()
      })
      // 埋点
      // this.$mixpanelUtil.track('点击任务详情描述')
    },
    // 任务描述保存
    async informationSave(val) {
      // debugger
      const information = this.$refs.simditor.getValue()
      if (
        information &&
        this.$utils
          .tagFilter(information.replace(/\s*/g, ''))
          .replace(/\s/g, '').length > 5000
      ) {
        this.$message.warn('详情描述最多可输入 5000 个字符哦!')
        this.form.information = information.substring(0, 5000)
        return
      }
      if (information === this.originalForm.information) {
        this.$message.warn('内容与之前相同哦!')
        return
      }
      const _obj = {}
      _obj.id = this.form.id
      _obj.information = information
      _obj.title = this.form.title
      this.$apis.TASKINFORMATIONUPDATE(_obj).then((res) => {
        if (res.status === 200) {
          this.isInformationEdit = false
          this.editorToolbar = false
          this.$message.success('任务描述添加成功')
          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '描述',
            task_id: this.form.id
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
      // 埋点
      // this.$mixpanelUtil.track('修改任务详情描述')
    },
    informationInput() {
      const { information } = this.form
      if (
        information &&
        this.$utils
          .tagFilter(information.replace(/\s*/g, ''))
          .replace(/\s/g, '').length > 5000
      ) {
        this.$message.warn('详情描述最多可输入 5000 个字符哦!')
        this.form.information = information.substring(0, 5000)
      }
    },
    // 判断富文本是否有内容
    filtersHtml(information) {
      if ((information || '').length > 0) {
        information = information.replace(/&amp;/g, '&')
        information = information.replace(/&lt;/g, '<')
        information = information.replace(/&gt;/g, '>')
        information = information.replace(/&nbsp;/g, ' ')
        information = information.replace(/'/g, "'")
        information = information.replace(/"/g, '"')
        information = information.replace(/<style>[\s\S]*?<\/style>/gi, '')
        information = information.replace(/[ | ]*\n/g, '\n') // 去除行尾空白
        information = information.replace(/\n[\s| | ]*\r/g, '\n') // 去除多余空行
        information = information.replace(/&nbsp;/gi, '') // 去掉&nbsp;
        return (
          (information.includes('<img') ||
            information.replace(/<[^>]+>/g, '').replace(/\s/g, '') ||
            '') !== ''
        )
      } else {
        return false
      }
    },
    // 过滤富文本内容判断
    filtersText(information, type) {
      if (information && information.length > 0) {
        information = information.replace(/&amp;/g, '&')
        information = information.replace(/&lt;/g, '<')
        information = information.replace(/&gt;/g, '>')
        information = information.replace(/&nbsp;/g, ' ')
        information = information.replace(/&ldquo;/g, '“')
        information = information.replace(/&rdquo;/g, '”')
        information = information.replace(/&hellip;/g, '...')
        information = information.replace(/&mdash;/g, '——')
        information = information.replace(/'/g, "'")
        information = information.replace(/"/g, '"')
        // var informationS = information.replace(/<style>[\s\S]*?<\/style>/gi, '')
        // var informationN = informationS.replace(
        //   /(^\s*)|(\s*$)|[\r\n]|&nbsp;/g,
        //   ''
        // )
        // var informationP = informationN.replace(/<\/?p[^>]*>/gi, '')
        // var informationK = informationP.replace(/\s+/g, '')
        var informationK = this.$utils.tagFilter(information)
        if (informationK && informationK.length > 5000) {
          this.$message.warn('详情描述最多可输入 5000 个字符哦!')
          if (type == 1) {
            return false
          } else {
            return informationK
          }
        } else if (informationK.length === 0) {
          return true
        } else if (
          informationK &&
          !this.$utils.tagFilter(informationK).trim() > 0 &&
          !informationK.includes('<img')
        ) {
          return informationK
        } else {
          return informationK
        }
      } else {
        return true
      }
    },
    // 任务描述失去焦点
    async informationBlur(fieldItem) {
      if (fieldItem.required == 0 && !this.form.information) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      const { information } = this.form
      if (this.filtersText(information, 1) || this.contentFlag) {
        const _obj = {}
        _obj.id = this.form.id
        _obj.information = this.form.information
        _obj.title = this.form.title
        this.$apis.TASKINFORMATIONUPDATE(_obj).then((res) => {
          if (res.status === 200) {
            // if (information) {
            this.$message.success('任务描述修改成功')
            this.editorFlag = false
            this.getTaskDetail()
            // 数据埋点c
            this.$mixpanelUtil.track('Update Task', {
              type: '描述',
              task_id: this.form.id
            })
            // }
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
      } else if (this.filtersText(information, 1) == false) {
        this.form.information = this.form.information.substring(0, 5000)
      }
      // else {
      //   this.form.information = ''
      // }
      this.editorFocus = false
      this.editorClick = false
      this.contentFlag = false
    },
    // 获取项目工作流列表
    async getProjectProcessList(projectId) {
      const obj_ = {
        projectId: projectId
      }
      this.$apis.PROJECTPROCESSLIST(obj_).then((res) => {
        if (res.status === 200) {
          this.processListData = res.data
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 修改任务流程
    async editTaskProcess(processId, taskId, projectId) {
      const obj_ = {
        processId,
        taskId
      }
      if (projectId === this.form.projectId) {
        var checkData = await this.getPmApprovalCheck(
          {
            businessType: 3,
            businessId: this.form.projectId,
            businessExtId: this.form.id
          },
          2
        )
        if (checkData.state === true) {
          this.$store.commit('task/taskApproveRemindObj', {
            visible: true,
            taskDetail: this.form,
            businessData: JSON.stringify(obj_),
            businessActionType: 2,
            approvalCheckData: checkData.data,
            pageType: 'taskDetail',
            cancelRefresh: true
          })
        } else {
          this.editTaskProcessSubmit(processId, taskId, projectId, obj_)
        }
      } else {
        this.editTaskProcessSubmit(processId, taskId, projectId, obj_)
      }
    },
    editTaskProcessSubmit(processId, taskId, projectId, obj_) {
      this.$apis.PROJECTUPDATEPROCESS(obj_).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.taskProcessRefresh = true
          this.$message({
            msg: '项目流程修改成功',
            type: 'success'
          })
          if (projectId === this.form.projectId) {
            this.getTaskDetail(
              projectId !== this.form.projectId ? 'approvalDetail' : ''
            ) // 获取详情
          }
          this.getApprovalProcessRecord() // 获取审批流详情
          if (this.cooperateVuexList.tabSelectId === 2) {
            const resData = this.processListData
            resData.forEach((item, index) => {
              if (item.id === processId) {
                this.bus.$emit('global:refreshBoardColWorkList', {
                  processId,
                  indexCol: index
                })
              }
            })
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 显示添加子任务输入框
    addSubTaskVisible_() {
      if (this.isDisabledEdit || this.form.status === 3) {
        return
      }
      this.subTaskVisible = true
    },
    // 获取子任务头像 信息
    setDefaulToriginator() {
      this.sub_obj.originatorId = this.form.originatorId
      this.sub_obj.originatorAvatar = this.form.avatar
    },
    // 删除子任务
    async deleteSub(item, index) {
      if (this.isDisabledEdit) {
        return
      } else if (
        (!this.isLeader && !this.isCreate) ||
        item.submitterType === 1 ||
                          item.isDel === 2 ||
                          item.isClose === 1
      ) {
        return
      }
      const _obj = {}
      _obj.id = item.id
      _obj.fatherTid = item.fatherTid
      if (this.form.projectId) {
        var checkData = await this.getPmApprovalCheck(
          {
            businessType: 3,
            businessId: item.projectId,
            businessExtId: item.id
          },
          3
        )
        if (checkData.state === true) {
          this.$store.commit('task/taskApproveRemindObj', {
            visible: true,
            taskDetail: item,
            businessData: JSON.stringify(_obj),
            businessActionType: 3,
            approvalCheckData: checkData.data,
            pageType: 'taskDetail'
          })
        } else {
          this.deleteSubSubmit(_obj)
        }
      } else {
        this.deleteSubSubmit(_obj)
      }
    },
    async deleteSubSubmit(_obj) {
      const status = await this.$element.confirm('确定删除该子任务?')
      if (!status) {
        return false
      }
      this.$apis.TASKDELETE(_obj).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.$message.success('子任务删除成功!')
          this.getTaskDetail()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 打开子任务抽屉
    todoSubTask(item) {
      this.editorClick = false
      this.editorFocus = false
      item.noticeType = this.noticeType
      if (this.emitState) {
        this.$emit('todoSubTask', item)
      } else {
        this.bus(this).$emit('global:todoSubTask', item)
      }
    },
    // 子任务负责人选择
    async subToriginatorPicker(row) {
      this.doucmentShadowClose() // 提前执行所有弹框状态重置后 根据条件进行更改
      this.sub_obj.originatorId = row.id
      this.sub_obj.originatorAvatar = row.avatar
      this.sub_obj = JSON.parse(JSON.stringify(this.sub_obj))
      // this.SubOriginatorPickerVisible = false
    },
    // 取消子任务提交
    closedialog() {
      this.sub_obj.originatorId = this.form.originatorId
      this.sub_obj.originatorAvatar = this.form.originatorAvatar
      this.sub_obj.status = 1
      this.sub_obj.title = ''
      this.sub_obj.startTime = []
      this.subTaskVisible = false
    },
    // 子任务状态设置
    async subTaskStatus(status, item) {
      this.statusPickerVisible_sub = false
      if (this.isDisabledEdit) {
        return
      }
      const _obj = {}
      _obj.id = item.id
      _obj.title = item.title
      _obj.status = status
      if (status == 3 && this.form.projectId) {
        var checkData = await this.getPmApprovalCheck(
          {
            businessType: 3,
            businessId: item.projectId,
            businessExtId: item.id
          },
          1
        )
        if (checkData.state === true) {
          this.$store.commit('task/taskApproveRemindObj', {
            visible: true,
            taskDetail: item,
            businessData: JSON.stringify(_obj),
            businessActionType: 1,
            approvalCheckData: checkData.data,
            pageType: 'taskDetail'
          })
        } else {
          this.subTaskStatusSubmit(_obj)
        }
      } else {
        this.subTaskStatusSubmit(_obj)
      }
    },
    async subTaskStatusSubmit(_obj) {
      await this.$apis.TASKSTATECHANGE(_obj).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          if (_obj.status === 1) {
            this.$message.success('状态已修改为待处理!')
          } else if (_obj.status === 2) {
            this.$message.success('状态已修改为进行中!')
          } else if (_obj.status === 3) {
            this.$message.success('状态已修改为已完成!')
            // 数据埋点c
            this.$mixpanelUtil.track('Done Task', { task_id: _obj.id })
          } else if (_obj.status === 4) {
            this.$message.success('状态已修改为搁置!')
          }
          this.statusPickerVisible = false
          this.statusPickerVisible_sub = false
          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '状态',
            task_id: _obj.id
          })
        } else if (res.status === 71001) {
          this.$message.error(res.message)
        } else if (res.status === 71005) {
          this.$message({ msg: res.message, type: 'warn' })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    sub_objInput() {
      if (this.sub_obj.title.replace(/\s*/g, '').length > 150) {
        this.$message.warn('标题最多可输入 150 个字符哦!')
        this.sub_obj.title = this.sub_obj.title.substring(0, 150)
      }
    },
    // 子任务提交成功后 重置添加任务字段  返回结果中让后台返回子任务列表
    async taskAddViewSubmit() {
      if (this.taskAddLoading) {
        return
      }
      this.taskAddLoading = true
      if (!this.sub_obj.title) {
        this.$message({ msg: '内容不能为空!', type: 'error' })
        this.taskAddLoading = false
        return
      }
      // ============================================axios /task/add  旧的
      const _obj = {}
      _obj.fatherTid = this.form.id || null // 子任务的父id
      _obj.title = this.sub_obj.title || null // 子任务的标题
      _obj.startDate =
        this.sub_obj.startTime && this.sub_obj.startTime.length > 0
          ? this.sub_obj.startTime[0]
          : '' // 子任务开始时间
      _obj.endDate =
        this.sub_obj.startTime && this.sub_obj.startTime.length > 1
          ? this.sub_obj.startTime[1]
          : '' // 子任务的结束时间
      _obj.originatorId = this.sub_obj.originatorId || null // 子任务负责人id
      _obj.originatorAvatar = this.sub_obj.originatorAvatar || null // 子任务负责人头像
      _obj.status = this.sub_obj.status || null // 子任务状态
      // if (
      //   this.cooperateVuexList.selectProjectId === '' ||
      //   this.cooperateVuexList.selectProjectId === null
      // ) {
      //   _obj.projectId = null
      // } else {
      //   _obj.projectId = this.cooperateVuexList.selectProjectId
      //   _obj.processId = this.form.processId
      // }
      _obj.projectId = this.form.projectId
      _obj.processId = this.form.processId
      _obj.businessType = this.form.businessType
      if (!this.form.id) {
        this.$message({ msg: '主任务缺失!', type: 'error' })
        this.taskAddLoading = false
        return
      } else if (!this.sub_obj.originatorId) {
        this.$message({ msg: '负责人不能为空!', type: 'error' })
        this.taskAddLoading = false
        return
      } else if (_obj.title.replace(/\s*/g, '').length === 0) {
        this.$message({ msg: '内容不能为空!', type: 'error' })
        this.taskAddLoading = false
        return
      } else if (_obj.title.replace(/\s*/g, '').length > 150) {
        this.$message.warn('标题最多可输入 150 个字符哦!')
        this.taskAddLoading = false
        return
      }
      // if ((_obj.objId || '') !== '') {
      //   _obj.businessType = 3
      // } else if ((_obj.projectId || '') !== '') {
      //   _obj.businessType = 2
      // }
      this.$apis.TASKADDE(_obj).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.subTaskVisible = false
          this.sub_obj.originatorId = this.form.originatorId
          this.sub_obj.originatorAvatar = this.form.originatorAvatar
          this.sub_obj.status = 1
          this.sub_obj.title = ''
          this.sub_obj.startTime = []
          this.getTaskDetail()
          // 数据埋点c
          this.$mixpanelUtil.track('Update Task', {
            type: '子任务新增',
            task_id: this.form.id
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
        this.taskAddLoading = false
      })
    },
    // 子任务负责人点击
    subOriginatorPickerClick() {
      this.doucmentShadowClose() // 提前执行所有弹框状态重置后 根据条件进行更改
      // this.SubOriginatorPickerVisible = true
    },
    doucmentShadowClose() {},
    // 弹窗关闭
    dialogClose() {
      this.$store.commit('common/setScrollBgVisible', false)
      if (this.editorFlag) {
        this.$confirm('是否保存描述？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'confirmBox',
          closeOnClickModal: false
        })
          .then(() => {
            var dom = document.getElementById('saveEditor')
            dom.click()
          })
          .catch(() => {
            this.editorFlag = false
            this.dialogClose()
          })
        return
      }
      // 有复制文字 禁止关闭
      const sel = this.$utils.getSelectedClick()
      if (sel) {
        return
      }
      if (this.titleIsFocus) {
        return
      }
      if (this.needRefreshList) {
        const obj = {
          bools_: false,
          taskId: this.taskId,
          noticeType: this.noticeType
        }
        this.bus(this).$emit('taskNeedRefresh', obj)
      }
      if (this.taskProcessRefresh) {
        this.bus(this).$emit(
          'taskDetailTaskProcessRefresh',
          this.form.processId
        )
      }
      this.$refs.taskType && this.$refs.taskType.closePop()
      this.dialogVisible = false
      this.$emit('change', false)
    },
    operateTarget(type) {
      const operateFn = {
        public: this.publicTask,
        delete: this.deleteTask,
        transformToSonTask: this.transformToSonTask,
        copyTask: this.copyTask,
        recycleTask: this.recycleTask,
        closeTask: this.closeTask
      }
      operateFn[type]()
    },
    transformToSonTask() {
      console.log('ddddd')
      this.transformToSonTaskVisible = true
    },
    // 评论保存
    commentSave() {
      this.getTaskDetail()
      this.$refs.commentAdd.commentFefresh()
    },
    // 设置任务公开/私密
    publicTask() {
      const beans = {
        id: this.form.id,
        isShare: this.form.isShare == 0 ? 1 : 0
      }
      this.$apis.TASKSHAREUPDATE(beans).then((res) => {
        if (res.status === 200) {
          this.$message.success('修改成功')
          this.needRefreshState()
          this.getTaskDetail()
        } else {
          that.$message({
            msg: '网络错误,稍后重试!',
            type: 'error'
          })
        }
      })
    },
    closePermissions() {
      this.closePermissionsStatus = false
      this.getTaskDetail()
    },
    // 删除任务
    async deleteTask() {
      const form = {
        id: this.form.id,
        title: this.form.title
      }
      if (!form.id) {
        this.$message.error('id不能为空')
        return
      }
      if (this.form.projectId) {
        var checkData = await this.getPmApprovalCheck(
          {
            businessType: 3,
            businessId: this.form.projectId,
            businessExtId: this.form.id
          },
          3
        )
        if (checkData.state === true) {
          this.$store.commit('task/taskApproveRemindObj', {
            visible: true,
            taskDetail: this.form,
            businessData: JSON.stringify(form),
            businessActionType: 3,
            approvalCheckData: checkData.data,
            pageType: 'taskDetail'
          })
        } else {
          this.deleteTaskSubmit(form)
        }
      } else {
        this.deleteTaskSubmit(form)
      }
    },
    async deleteTaskSubmit(form) {
      const status = await this.$element.confirm('确定删除该任务?')
      if (!status) {
        return false
      }
      this.doucmentShadowClose()
      this.$apis.TASKDELETE(form).then((res) => {
        if (res.status === 200) {
          this.needRefreshList = true
          this.$message.success('删除成功')
          this.$emit('refresh')
          this.dialogClose()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    downloadImg(url) {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.click() // 下载文件
    },
    // 修改关注
    taskCareClick() {
      var url = this.$apis.TASKCARECHANGE
      const dataBeans = {
        id: this.form.id,
        operation: this.form.follow === 1 ? 'cancel' : 'follow'
      }
      url(dataBeans).then((res) => {
        this.$message.success('修改成功')
        this.needRefreshState()
        this.form.follow = this.form.follow === 1 ? 0 : 1
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
@import '../../../../assets/styles/common.less';
::v-deep {
  .picker-checked-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    cursor: pointer;
    .picker-text {
      font-size: 14px;
      font-weight: 400;
      color: #a0a3bd;
    }
    .picker-text-you {
      color: #444558;
    }
    &:hover {
      .picker-text {
        color: #3a70ff;
      }
      .cooperation-add {
        color: #3a70ff !important;
      }
    }
  }

  .task-detail-priority-box {
    .el-input__inner {
      -webkit-box-shadow: 0 0 20px 20px #fff inset;
      box-shadow: 0 0 20px 20px #fff inset;
      border: 1px solid #f5f6fa !important;
      font-size: 14px;
      border-radius: 9px;
      height: 36px;
      line-height: 36px;
      padding: 0px 10px;
      color: inherit !important;
    }
    .el-input__suffix {
      display: none;
    }
    &:hover {
      .el-input__inner {
        background: rgba(217, 219, 233, 0.24) !important;
        box-shadow: none !important;
      }
    }
  }
  // .very-emergency {
  //   .el-input__inner {
  //     color: #e83626 !important;
  //   }
  // }
  // .emergency {
  //   .el-input__inner {
  //     color: #fa972b !important;
  //   }
  // }
  // .normal {
  //   .el-input__inner {
  //     color: #999 !important;
  //   }
  // }
}
::v-deep {
  .atwho-panel {
    bottom: 60px;
    top: auto !important;
    .atwho-view {
      bottom: 0px !important;
      min-height: 200px;
    }
  }
  .show-more {
    font-size: 14px;
    color: #3a78f6;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
  .htmlContent {
    font-size: 14px;
    line-height: 1.5 !important;
    font-weight: 300;
    width: 560px !important;
    box-sizing: border-box;
    cursor: pointer;
    overflow-x: hidden !important;
    height: auto !important;
    max-height: 440px !important;
    overflow-y: hidden !important;
    color: #000 !important;
    .attachment {
      width: 260px;
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      background: #fff !important;
      border-radius: 8px !important;
      margin-bottom: 10px !important;
      padding: 4px 14px !important;
      flex-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-break: break-all;
      border: 1px #f5f6fa solid !important;
      a {
        min-width: 0;
        color: #6e7391 !important;
        text-decoration: none !important;
        font-size: 14px !important;
        margin-left: 10px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        &:hover {
          color: #3a78f6 !important;
        }
      }
    }
    ::v-deep {
      img {
        max-width: 500px !important;
        height: auto;
      }
      p {
        margin: 0;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        color: #606266 !important;
      }
    }
    // width: 100%;
    .min-w(560);
    .lh(24);
    .bd-rd(4);
    padding: 12px;
    &:hover {
      background-color: #f5f6fa;
      color: #3a78f6 !important;
    }
  }
  .htmlContentNo {
    cursor: not-allowed !important;
  }
  .task-header-select.el-select .el-input .el-select__caret {
    color: #005eff !important;
  }
  .el-select.task-header-select {
    .el-input .el-input__inner,
    .el-input__inner:hover {
      border-color: transparent !important;
      background: transparent !important;
      color: #005eff !important;
      padding: 0 15px 0 8px !important;
    }
  }
}
::v-deep .to-edit-input-textarea {
  border: none !important;
}
.star {
  display: inline-block;
  padding: 0 4px;
  position: absolute;
  top: 14px;
  left: -16px;
  color: #a0a3bd;
}
.star-note {
  top: 18px !important;
}
.go-back-block {
  padding: 10px;
  position: absolute;
  right: 30px;
  bottom: 200px;
  cursor: pointer;
  border-radius: 50%;
  background: #fff;
  .avatar-img {
    width: 30px;
    height: 28px;
  }
}
.go-back-block-arrow {
  padding: 10px;
  position: absolute;
  right: 30px;
  top: 100px;
  z-index: 99;
  cursor: pointer;
  border-radius: 50%;
  background: #f0f5ff;
  border: 1px solid rgba(48, 104, 255, 0.4);
  box-shadow: 0px 3px 15px 0px rgba(0, 24, 87, 0.1);
  text-align: center;
  p.back-block-arrow {
    line-height: 20px;
    color: #1666ff;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
  }
  .avatar-img_arrow {
    height: 7px;
    display: block;
    margin: 0 auto;
    color: #3a78f6;
    // transform: rotate(180deg);
  }
}
// ::v-deep .el-dialog__wrapper {
//   z-index: 2009 !important;
// }
.son-status-box {
  background: #fff;
  height: 18px;
  width: 18px;
  border-radius: 4px;
}
.task-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  height: 54px;
  border-bottom: 1px solid #eaebf3;
  .task-header {
    // width: 72px;
    // width: 110px !important;
    // height: 28px;
    // // height: 40px;
    // // background: #d3e9ff;
    // background: rgba(50, 150, 250, 0.1) !important;
    // border-radius: 6px;
    // color: #005eff;
    // cursor: pointer;
    // font-size: 16px;
    // line-height: 28px;
    display: flex;
    // justify-content: center;
    align-items: center;
    // font-weight: 500;
    .stopdoing-state {
      margin: 20px 20px 20px 0px;
      .stopdoing-state-button {
        height: 34px;
        line-height: 34px;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 500;
        color: #3a70ff;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px #3a70ff solid;
        cursor: pointer;
        border-radius: 33px;
        .status-icon {
          font-size: 20px;
          color: #3a70ff;
          margin-right: 8px;
        }
        &:hover {
          border: 1px #548af7 solid;
          color: #548af7;
          .status-icon {
            color: #548af7;
          }
        }
      }
      .stopdoing-state-button-3 {
        color: #00ba88;
        border: 1px #00ba88 solid;
        .status-icon {
          color: #00ba88;
        }
        &:hover {
          border: 1px #49c2a2 solid;
          color: #49c2a2;
          .status-icon {
            color: #49c2a2;
          }
        }
      }
      .stopdoing-state-button-true {
        cursor: not-allowed !important;
      }
      .stopdoing-state-button-false {
        cursor: pointer !important;
      }
    }
    .task-icon {
      font-size: 18px;
      margin-right: 8px;
      margin-left: 8px;
    }
  }
  .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    .privacy-div {
      margin-right: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #a0a3bd;
      width: 76px;
      height: 28px;
      background: #f5f6fa;
      border-radius: 36px 36px 36px 36px;
      font-weight: 400;
      .task-privacy {
        font-size: 14px;
        margin-right: 8px;
      }
    }
    .okr-care {
      margin-right: 16px;
      .icon {
        font-size: 22px;
        cursor: pointer;
        &:hover {
          color: #3a70ff;
        }
      }
    }
    .okr-care-type-1 {
      color: #ff9900;
    }
  }
  .task-operate {
    color: #444558;
    font-size: 20px;
    font-weight: 500;
    width: 33px;
    height: 33px;
    line-height: 33px;
    margin-right: 16px;
    cursor: pointer;
    &:hover {
      background: rgba(58, 120, 246, 0.06);
      border-radius: 7px;
      color: #3a78f6;
    }
  }
}
.task-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 54px);
  .task-main-content {
    overflow-y: auto;
    flex: 1;
    // background: #f7f7fc;
    background: #fff;
    ::v-deep {
      .list-container {
        background: #fff !important;
      }
    }
  }
  .task-main-content-stopdoing {
    background: #fff !important;
    flex: 1;
    overflow-y: auto;
    ::v-deep {
      .list-container {
        background: #fff !important;
      }
    }
  }
  .task-form {
    background: #fff;
    padding: 0px 24px;
    .task-title {
      margin: 16px 0;
      ::v-deep {
        .to-edit-input-textarea .el-textarea__inner {
          font-size: 20px !important;
          line-height: 150%;
          min-height: 26px;
          padding: 16px 10px !important;
          font-weight: 700;
          color: #14142b;
          background: #f5f6fa !important;
          background: #fff !important;
          border-radius: 8px;
          padding-bottom: 16px;
          max-height: 200px;
          resize: none;
          border: none !important;
        }
        .input-textarea-hover:hover {
          color: #548af7 !important;
        }
        .to-edit-input-textarea .el-textarea__inner:hover {
          color: #548af7 !important;
          background: #f5f6fa !important;
        }
        .to-edit-input-textarea .el-textarea__inner:active {
          color: #548af7 !important;
          background: #f5f6fa !important;
        }
        .to-edit-input-textarea .el-textarea__inner:focus {
          color: #548af7 !important;
          background: #f5f6fa !important;
        }
      }
    }
    .task-items {
      font-size: 14px;
      display: flex;
      align-items: flex-start;
      // margin-top: 10px;
      position: relative;
      flex-direction: column;
      ::v-deep {
        .top-item {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 8px;
          position: relative;
          min-height: 40px;
          position: relative;
          .el-input__inner::-webkit-input-placeholder,
          .el-textarea__inner:-webkit-input-placeholder {
            font-size: 14px;
            color: #a0a3bd !important;
          }

          .el-input__inner::-moz-placeholder,
          .el-textarea__inner::-moz-placeholder {
            font-size: 14px;
            color: #a0a3bd !important;
          }

          .el-input__inner::-ms-input-placeholder,
          .el-textarea__inner::-ms-input-placeholder {
            font-size: 14px;
            color: #a0a3bd !important;
          }
          &:hover {
            .el-select .el-input .el-select__caret {
              color: #3a78f6 !important;
            }
            .el-input__inner::-webkit-input-placeholder,
            .el-textarea__inner::-webkit-input-placeholder {
              font-size: 14px;
              color: #3a78f6 !important;
            }

            .el-input__inner::-moz-placeholder,
            .el-textarea__inner::-moz-placeholder {
              font-size: 14px;
              color: #3a78f6 !important;
            }

            .el-input__inner::-ms-input-placeholder,
            .el-textarea__inner::-ms-input-placeholder {
              font-size: 14px;
              color: #3a78f6 !important;
            }
          }
        }
      }

      .top-item-describe {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 120px;
        min-width: 120px;
        color: #a0a3bd;
        align-self: flex-start;
        margin-top: 10px;
        line-height: 20px;
        .text-box {
          width: 100px;
          // align-items: center;
          // align-self: center;
          color: #a0a3bd;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          padding-right: 10px;
        }

        .svg-icons {
          flex-shrink: 0;
          width: 20px;
          font-size: 18px !important;
          margin-right: 8px;
          height: 20px;
        }
      }
      .top-item-describe-start {
        display: flex;
        width: 120px;
        min-width: 120px;
        color: #969fbe;
        align-self: flex-start;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 10px;
        margin-top: 10px;
        line-height: 20px;
        .text-box {
          width: 100px;
          // align-items: center;
          // align-self: center;
          color: #a0a3bd;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          padding-right: 10px;
        }
        .svg-icons {
          width: 20px;
          font-size: 18px !important;
          margin-right: 8px;
          height: 20px;
        }
      }
      .flex-1 {
        display: flex;
        align-items: center;
        flex: 1;
        padding-left: 12px;
        .close-icon {
          display: inline-block;
          width: 18px;
          height: 18px;
          font-size: 16px;
          margin-top: -9px;

          cursor: pointer;
        }
        .originator-box {
          padding: 0 12px 0 6px;
          height: 36px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          position: relative;
          background: #fff;
          max-width: 120px;
          border-radius: 38px 38px 38px 38px;
          border: 1px solid #fff;
          margin-left: -6px;
          .avatar-img.pointer {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 8px;
          }
          .close-icon {
            position: absolute;
            right: -2px;
            top: 0px;
            color: #a0a3bd;
            font-size: 18px;
            width: 18px;
            height: 18px;
            background: #fff;
            border-radius: 50%;
            display: none;
            &:hover {
              color: #ff9900 !important;
            }
          }
          .close-icon.icon-a-guanbi1 {
            background: #fff;
            width: 18px;
            height: 18px;
            border-radius: 50%;
          }
          .head-icon {
            // display: inline-block;
            width: 24px;
            height: 24px;
            font-size: 24px;
            // margin-top: -2px;
            background: #d4d5e0;
          }
          .head-icon-label {
            margin-left: 5px;
            font-weight: 500;
            color: #a0a3bd;
            font-size: 14px;
            padding-right: 12px;
          }
          .username {
            flex: 1;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            font-size: 14px;
            font-weight: 500;
            color: #444558;
          }
          &:hover {
            background: #f5f6fa;
            .close-icon {
              display: block;
            }
          }
        }
        .originator-box {
          &:hover {
            background: #f5f6fa;
            border: 1px solid #f5f6fa;
            .close-icon {
              &:hover {
                color: #ff9900 !important;
              }
            }
          }
        }
        .originator-box.originator-true {
          background: #fff !important;
          border: 1px solid #ff9900 !important;
          &:hover {
            background: #fff !important;
            border: 1px solid #ff9900 !important;
          }
        }
      }
      .disc-right {
        padding-left: 0 !important;
      }
      .workingHours-right {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .workhours-static {
          font-size: 14px;
          font-weight: 400;
          color: #3a70ff;
          cursor: pointer;
        }
      }
      .custom-right {
        padding-left: 0 !important;
      }
      .time-right {
        padding-left: 8px !important;
      }
      .son-flex-1 {
        padding-left: 0px !important;
      }
      .avatar-img {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        overflow: hidden;
        margin-right: 8px;
      }
      .avatar-img + .avatar-img {
        margin-left: 5px;
      }
      .charge-user-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-shrink: 0;
        .charge-avatar-box {
          // margin-right: 10px;
          position: relative;
          margin-top: 5px;
          .charge-avatar {
            border-radius: 50%;
            height: 25px;
            width: 25px;
          }
          .el-icon-error {
            cursor: pointer;
            display: none;
            position: absolute;
            right: 0px;
            top: -5px;
            width: 16px;
            height: 16px;
            font-size: 18px;
            color: #a0a3bd;
            z-index: 1;
            &:hover {
              color: #ff9900;
            }
          }
          .el-icon-error-disabled {
            cursor: not-allowed !important;
            display: none;
            position: absolute;
            right: 0px;
            top: -5px;
            width: 16px;
            height: 16px;
            font-size: 18px;
            color: #a0a3bd;
            z-index: 1;
            &:hover {
              color: #ff9900;
            }
          }
          &:hover {
            .el-icon-error {
              display: block;
            }
          }
        }
        .charge-avatar-box + .charge-avatar-box {
          margin-left: 0px;
        }
        .cooperation-add {
          cursor: pointer;
          color: #a0a3bd;
          font-size: 24px;
          &:hover {
            color: #3068ff;
          }
        }
        .cooperation-add-disbled {
          cursor: not-allowed;
          &:hover {
            color: #a0a3bd;
          }
        }
      }
      .task-belong-to {
        .top-item-right-box {
          display: inline-block;
          padding: 8px 16px;
          background: #f5f6fa;
          border-radius: 20px;
          position: relative;
          cursor: pointer;
          border: 1px solid #fff;
          p.top-item-right-box-txt {
            margin: 0;
            max-width: 500px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 16px;
            &:before {
              content: '';
              display: block;
            }
            .top-item-span {
              font-size: 12px;
              background: #005eff;
              width: 42px;
              height: 20px;
              line-height: 20px;
              display: inline-block;
              text-align: center;
              color: #fff;
              border-radius: 12px;
              margin-right: 9px;
            }
            &.top-item-right-box-txt-blue {
              color: #005eff;
            }
          }
          .task-cen-icon {
            cursor: pointer;
            position: absolute;
            right: -5px;
            top: -5px;
            font-size: 20px;
            width: 20px;
            height: 20px;
            color: #a0a3bd;
            &:hover {
              color: #ff9900;
            }
          }
        }
        .top-item-right-box-kr {
          padding: 8px 16px 8px 0px;
          color: #3a78f6;
          line-height: 16px;
          cursor: pointer;
          font-size: 14px;
          display: flex;
          align-items: center;
        }
        .top-item-right-box-kr.top-item-right-box-kr-disabled {
          cursor: not-allowed !important;
        }
        .task-cen-btns-blue {
          border: 1px solid #ff9900 !important;
        }
        .top-item-right-box:hover {
          background: #f5f6fa;
        }
      }
      .kr-cont {
        position: absolute;
        left: 0;
        top: 50px;
        width: 100%;
        border-radius: 16px;
        // background: #fff;
        z-index: 10;
        .right-box {
          text-align: right;
        }
        .avatar-img {
          border-radius: 50%;
          margin-right: 20px;
          height: 30px;
          width: 30px;
        }
      }
      .descDisabled .edit-con,
      ::v-deep .tox-tinymce--disabled {
        cursor: not-allowed;
      }
      .edit-con {
        position: relative;
        .editor-Btn {
          margin: 16px 8px 0 0;
          text-align: center;
          .flex-r();
          .btn {
            width: 96px;
            // height: 32px;
            line-height: 32px;
            border-radius: 14px;
            border: 1px solid #005eff;
            font-size: 14px;
            margin: 0px 0px 0px 8px;
            cursor: pointer;
          }

          .cancel {
            color: #005eff;
          }
          .confirm {
            color: #f7f7fc;
            background: #3a78f6 !important;
            border-color: #3a78f6 !important;
            &:hover {
              background: rgba(58, 120, 246, 0.87) !important;
              border-color: rgba(58, 120, 246, 0.87) !important;
            }
          }
        }
      }
      .sub-add-wrap {
        border: 1px solid #f5f6fa;
        border-radius: 9px;
        width: 100%;
      }
      .workingHours-div {
        margin-left: 28px;
        .workingHours-list {
          display: flex;
          flex-direction: row;
          height: 32px;
          align-items: center;
          margin-top: 8px;
          .workingHours-label {
            width: 140px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: #a0a3bd;
          }
          .workingHours-content {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 32px;
            color: #444558;
            .work-hours {
              display: inline-block;
              width: 73px;
              height: 32px;
              line-height: 32px;
              border-radius: 12px;
              border: 1px solid #fff;
              text-align: center;
              margin-right: 8px;
              color: #444558;
              cursor: pointer;
              &:hover {
                border: 1px solid #3a70ff;
              }
            }
            .work-hours-0 {
              width: 73px;
              height: 32px;
              line-height: 32px;
              background: #fff;
              color: #a0a3bd;
              margin-right: 8px;
              border-radius: 12px;
              text-align: center;
              cursor: pointer;
              &:hover {
                background: #f5f6fa;
                color: #3a70ff;
              }
            }
            .work-hours-unit-no {
              color: #a0a3bd;
            }
            .work-hours-unit {
              color: #444558;
            }
            .work-hours-disabled {
              cursor: not-allowed;
              &:hover {
                border: 1px solid #fff;
              }
            }
          }
        }
        .workinghours-progress {
          margin-top: 20px;
          width: 400px;
          ::v-deep {
            .el-progress.hours-progress {
              display: flex;
              flex-direction: row;
              align-items: center;
              .el-progress__text {
                margin-left: 24px;
                color: #a0a3bd;
              }
            }
          }
        }
      }
      .sub-add {
        line-height: 42px;
        height: 42px;
        border-top: none;
        padding-left: 18px;
        display: flex;
        align-items: center;
        width: 100%;
        color: #3a78f6 !important;
        font-size: 14px;
        cursor: pointer;
        .aub-add-icon {
          margin-right: 6px;
        }
      }
      .sub-add:hover {
        // border-color: rgb(10, 101, 180);
        background: #fafafc;
      }
      .sub-add-div {
        justify-content: flex-end;
        width: 100%;
        padding: 20px 18px 0px 18px;
        box-sizing: border-box;
        .pane {
          align-items: center;
          margin-right: 12px;
          align-self: center;
        }
        .guid_btns {
          width: 96px;
          height: 32px;
          background: #fff;
          line-height: 32px;
          color: #005eff;
          border: 1px solid #005eff;
          font-size: 14px;
          font-weight: 500;
          border-radius: 14px;
          margin: 20px 0px 20px 8px;
          cursor: pointer !important;
          span {
            display: block;
          }
        }
        .guid_btns:hover {
          cursor: pointer !important;
        }
      }
      .sub-ul {
        padding: 0px 0px 0px 0px;
        li {
          padding: 0px 18px;
          box-sizing: border-box;
          cursor: pointer;
          height: 48px;
          line-height: 48px;
          .pane-center {
            align-items: center;
            align-self: center;
          }
        }
        li:hover {
          background: #fafafc;
        }
        .sub-li-content {
          // margin-left: 12px;
          width: 550px;
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 300px;
            font-size: 14px;
            color: #444558;
            &:before {
              content: '';
              display: block;
            }
          }
        }
      }
      .sub-right {
        align-items: center;
        margin-left: 12px;
        .sub-right-icon-time {
          margin-right: 12px;
          width: 20px;
          overflow: hidden;
          .time-set-icon {
            font-size: 20px;
          }
          .avatar-img {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        .svg-icon {
          width: 18px;
          height: 18px;
        }
        .sub-right-icon-time-txt {
          margin-right: 12px;
        }
        .sub-right-icon {
          margin-right: 12px;
          display: flex;
          flex-direction: row;
          .avatar-img {
            display: inline-block;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            overflow: hidden;
            vertical-align: middle;
          }
          .son-delete-icon {
            width: 18px;
            height: 18px;
            font-size: 18px;
            // color: #444558;
            color: rgb(160, 163, 189);
            &:hover {
              color: #3a78f6;
            }
          }
        }
      }
      ::v-deep .sub-add-complete {
        color: #fff !important;
        background: #3a78f6 !important;
        &:hover {
          background: #005eff !important;
        }
      }
      .sub-add-div .sub-right-icon-time .sub-task-date-box {
        width: 20px;
        height: 20px;
      }
    }
    &:nth-last-child(2) {
      padding-bottom: 20px;
    }
  }
  .to-edit-input-not {
    cursor: not-allowed !important;
    .pointer {
      cursor: not-allowed !important;
    }
  }
  .to-edit-input-not-add {
    cursor: not-allowed !important;
    background: #f5f5f5;
    .pointer {
      cursor: not-allowed !important;
    }
  }
  .to-edit-input-not-edit {
    cursor: not-allowed;
  }
  .flex-new {
    display: flex;
    align-items: center;
  }
}

//待办
.stop-doing-box {
  // height: calc(100vh - 456px);
  background: #fff;
  padding: 0px 24px 24px;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  .stop-dictionayry-row {
    flex: 0 0 100%;
  }
  .stop-dictionayry-row-50 {
    flex: 0 0 auto;
    display: inline;
    margin-bottom: 12px;
  }
  .stop-doing-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    .stopdoing-priority {
      margin-right: 14px;
      ::v-deep {
        .el-select.task-detail-priority-box {
          width: 140px !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          .el-input {
            width: auto;
          }
          .svg-icons-div {
            margin: 10px 8px 0 8px;
            .svg-icons {
              font-size: 18px !important;
            }
          }
          .el-input__inner {
            border: 1px solid #d9dbe9 !important;
            border-radius: 38px;
            padding-left: 34px !important;
            height: 38px !important;
            line-height: 38px !important;
          }
        }
      }
    }
    .stopdoing-tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #d9dbe9 !important;
      height: 38px !important;
      border-radius: 38px;
      padding: 0 12px;
      cursor: pointer;
      .flex-1 {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      ::v-deep {
        .add-btn {
          font-size: 18px !important;
        }
      }
      &:hover {
        background: #f5f6fa;
        ::v-deep {
          .add-btn {
            color: #3068ff !important;
          }
          .tag-text {
            color: #3068ff;
          }
        }
      }
    }
    .stopdoing-note {
      width: 700px;
      border-radius: 12px 12px 12px 12px;
      margin-top: 0px;
      margin-bottom: 140px;
      .edit-con {
        position: relative;
        .editor-Btn {
          margin: 16px 8px 16px 0;
          text-align: center;
          .flex-r();
          .btn {
            width: 78px;
            // height: 32px;
            line-height: 32px;
            border-radius: 14px;
            // border: 1px solid #005eff;
            border: 1px solid #d9dbe9;
            font-size: 14px;
            margin: 0px 0px 0px 8px;
            cursor: pointer;
          }

          .cancel {
            // color: #005eff;
            color: #d9dbe9;

            &:hover {
              color: #a0a3bd !important;
              border: 1px solid #a0a3bd !important;
            }
          }
          .confirm {
            color: #548af7;
            background: #fff !important;
            border-color: #548af7 !important;
            &:hover {
              color: #3a70ff !important;
              border-color: #3a70ff !important;
            }
          }
        }
      }
      ::v-deep {
        .htmlContent {
          width: 700px !important;
          padding: 12px 0 12px !important;
        }
        .htmlContent:hover {
          background: none !important;
        }
        .tox .tox-toolbar-overlord {
          background: none !important;
        }
        .detail-editor .tox .tox-edit-area__iframe {
          border-radius: 0 !important;
          background: none !important;
        }
        .detail-editor .tox .tox-toolbar,
        .detail-editor .tox .tox-toolbar__overflow,
        .detail-editor .tox .tox-toolbar__primary {
          border-bottom: 1px #d9dbe9 solid !important;
        }
      }
    }
    .stopdoing-note-border {
      border: 1px #d9dbe9 solid;
      margin-bottom: 0px;
    }
    .stopdoing-time {
      position: relative;
      height: 40px;
      width: auto;
      cursor: pointer;
      margin-right: 14px;
      .el-date-editor.end-time {
        width: 80px !important;
        margin-right: 14px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        ::v-deep {
          .el-input__inner {
            border: none !important;
            padding-left: 8px !important;
            width: 80px !important;
          }
          .el-input__prefix {
            display: none !important;
          }
        }
      }
      .endData-div {
        display: flex;
        padding: 0 12px;
        height: 38px;
        flex-direction: row;
        align-items: center;
        border: 1px #d9dbe9 solid;
        background: #fff;
        border-radius: 31px;
        padding: 0 12px;
        width: auto;
        position: relative;
        cursor: pointer;
        .time {
          font-size: 14px;
          font-weight: 400;
          color: #444558;
          margin-left: 8px;
          flex: 1;
        }
        .placeholder {
          color: #a0a3bd;
          font-size: 14px;
          font-weight: 400;
          margin-left: 8px;
          flex: 1;
        }
        .svg-icons {
          color: #a0a3bd !important;
          margin-right: 8px;
          font-size: 18px !important;
        }
        .clear-icon {
          position: absolute;
          right: -4px;
          top: -8px;
          color: #a0a3bd;
          display: none;
          font-size: 18px;
          width: 18px;
          height: 18px;
          background: #fff;
          border-radius: 50%;
          &:hover {
            color: #ff9900;
          }
        }
        &:hover {
          // background: #f5f6fa;
          .clear-icon {
            display: block;
          }
        }
      }
      .endData-div-disabled {
        cursor: not-allowed;
        &:hover {
          .svg-icons {
            color: #a0a3bd !important;
          }
          .clear-icon {
            display: none;
          }
        }
      }
      .endData-div-true {
        border: 1px #ff9900 solid;
        background: #fff;
        &:hover {
          border: 1px #ff9900 solid;
          background: #fff;
        }
      }
    }

    .stopdoing-remind-time {
      padding: 0 16px 0 12px;
      height: 38px;
      border-radius: 39px 39px 39px 39px;
      border: 1px solid #d9dbe9;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 14px;
      cursor: pointer;
      .remind-icon {
        color: #a0a3bd;
        font-size: 18px;
        margin-right: 8px;
      }
      .placeholder {
        font-size: 14px;
        font-weight: 400;
        color: #a0a3bd;
      }
      .remind-time {
        font-size: 14px;
        font-weight: 400;
        color: #444558;
      }
      &:hover {
        background: #f5f6fa;
        // .remind-icon {
        //   color: #3a70ff;
        // }
        // .remind-time {
        //   color: #3a70ff;
        // }
      }
    }
    .stopdoing-remind-time-disabled {
      .remind-icon {
        color: #a0a3bd;
      }
      .stopdoing-remind-time {
        cursor: not-allowed;
      }
      &:hover {
        background: #f5f6fa;
        .remind-icon {
          color: #a0a3bd;
        }
        .remind-time {
          color: #a0a3bd;
        }
      }
    }
    .stopdoing-remind-time-you {
      background: #fff;
    }
    .charge-user-list.stopdoing-charge-user-list {
      height: 38px;
      border-radius: 38px;
      border: 1px solid #d9dbe9;
      display: flex;
      flex-direction: row;
      margin-right: 14px;
      flex-shrink: 0;
      padding: 0 12px;
      .stopdoing-charge-user-list-div {
        max-width: 130px;
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
      }
      .charge-avatar-box {
        // margin-right: 10px;
        position: relative;
        margin-top: 9px;
        cursor: pointer;
        .charge-avatar {
          border-radius: 50%;
          height: 25px;
          width: 25px;
        }
        .avatar-img {
          border-radius: 50%;
          height: 18px;
          width: 18px;
          overflow: hidden;
          margin-right: 8px;
        }
        .avatar-img + .avatar-img {
          margin-left: 5px;
        }
        .icon-error-span {
          position: absolute;
          right: -1px;
          top: -6px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 14px;
          height: 14px;
          background: #fff;
          border-radius: 50%;
          display: none;
        }
        .icon-error {
          cursor: pointer;
          width: 14px;
          height: 14px;
          font-size: 14px;
          color: #a0a3bd;
          z-index: 1;
          &:hover {
            color: #ff9900;
          }
        }
        .el-icon-error-disabled {
          cursor: not-allowed !important;
          display: none;
          position: absolute;
          right: 0px;
          top: -5px;
          width: 16px;
          height: 16px;
          font-size: 18px;
          color: #a0a3bd;
          z-index: 1;
          &:hover {
            color: #ff9900;
          }
        }
        &:hover {
          .icon-error-span {
            display: block;
          }
        }
      }
      .charge-avatar-box + .charge-avatar-box {
        margin-left: 0px;
      }
      .cooperation-add {
        cursor: pointer;
        color: #a0a3bd;
        font-size: 18px;
        width: 18px;
        margin-right: 8px;
        &:hover {
          color: #3068ff;
        }
      }
      .cooperation-add-disbled {
        cursor: not-allowed;
        &:hover {
          color: #a0a3bd;
        }
      }
      &:hover {
        background: #f5f6fa;
      }
    }
  }
  //标题
  .task-title {
    padding: 16px 0;
    ::v-deep {
      .to-edit-input-textarea .el-textarea__inner {
        font-size: 20px !important;
        line-height: 26px;
        min-height: 26px;
        padding: 16px 10px !important;
        font-weight: 700;
        color: #14142b;
        background: #f5f6fa !important;
        background: #fff !important;
        border-radius: 8px;
        padding-bottom: 16px;
        max-height: 200px;
        resize: none;
        border: none !important;
      }
      .input-textarea-hover:hover {
        color: #3068ff !important;
      }
      .to-edit-input-textarea .el-textarea__inner:hover {
        color: #3068ff !important;
        background: #f5f6fa !important;
      }
      .to-edit-input-textarea .el-textarea__inner:active {
        color: #3068ff !important;
        background: #f5f6fa !important;
      }
      .to-edit-input-textarea .el-textarea__inner:focus {
        color: #3068ff !important;
        background: #f5f6fa !important;
      }
    }
  }
  //头像
  .originator-box.stopdoing-originator {
    padding: 0 14px 0 12px;
    height: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    background: #fff;
    max-width: 120px;
    border-radius: 38px 38px 38px 38px;
    border: 1px solid #d9dbe9;
    margin-right: 14px;
    .avatar-img.pointer {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 12px;
    }
    .close-icon {
      position: absolute;
      right: -4px;
      top: -8px;
      color: #a0a3bd;
      font-size: 18px;
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 50%;
      display: none;
      &:hover {
        color: #ff9900 !important;
      }
    }
    .close-icon.icon-a-guanbi1 {
      background: #fff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }
    .head-icon {
      // display: inline-block;
      width: 20px;
      height: 20px;
      font-size: 18px;
      background: #d4d5e0;
    }
    .head-icon-label {
      margin-left: 5px;
      font-weight: 500;
      color: #a0a3bd;
      font-size: 14px;
      padding-right: 12px;
    }
    .username {
      flex: 1;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
      color: #444558;
    }
    &:hover {
      background: #f5f6fa;
      .close-icon {
        display: block;
      }
    }
  }
  .originator-box {
    &:hover {
      background: #fff;
      border: 1px solid #ff9900;
    }
  }
  .stopdoing-originator {
    &:hover {
      background: #f5f6fa;
      border: 1px solid #d9dbe9;
      .close-icon {
        &:hover {
          color: #ff9900 !important;
        }
      }
    }
  }
  .originator-box.stopdoing-originator.stopdoing-originator-true {
    background: #fff !important;
    border: 1px solid #ff9900 !important;
    &:hover {
      background: #fff !important;
      border: 1px solid #ff9900 !important;
    }
  }
}
.task-dynamic {
  // margin-top: 24px;
}
::v-deep {
  .el-drawer__header {
    border-bottom: 1px solid #eaebf3;
    display: none;
  }
}
</style>
<style lang="scss">
.sub-task-date-box {
  position: absolute;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}
.htmlContent {
  p > img {
    width: auto;
    max-width: 90%;
    height: auto;
    cursor: pointer;
  }
}
::v-deep {
  .tox .tox-edit-area__iframe {
    border-radius: 16px;
    background: #f5f6fa !important;
  }
}
</style>
