var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "add-okr-dialog-wrap",
      attrs: {
        "append-to-body": true,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        size: "1200px",
        width: "1200px",
        title:
          " " +
          (_vm.aiStep === 2 || _vm.alignObj
            ? "AI 助写关键结果KR"
            : "AI 助写目标 O （我们将为你首先生成目标 O)"),
        direction: "rtl",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "ai-count-wrap" }, [
        _vm._v(" 剩余可调用次数： "),
        _c("span", [_vm._v(_vm._s(_vm.companyInfo.aiUsageCount || 0))]),
        _vm._v(" 次 （更多订阅请咨询售前顾问） "),
      ]),
      _c("div", { staticClass: "add-content-wrap" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.aiStep !== 2 && !_vm.alignObj,
                expression: "aiStep !== 2 && !alignObj",
              },
            ],
            staticClass: "add-content-left",
          },
          [
            _c("div", { staticClass: "ai-title" }, [
              _vm._v(
                "源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR，同时我们将会帮助您生产指导性的工作任务"
              ),
            ]),
            _c("div", { staticClass: "content-input-wrap" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.modeFile,
                      expression: "!modeFile",
                    },
                  ],
                  staticClass: "list-wrap",
                },
                [
                  _c("label", [_vm._v("职位:")]),
                  _c("el-input", {
                    staticClass: "input-focus",
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请输入职位",
                      disabled: _vm.addOkrLoading,
                    },
                    model: {
                      value: _vm.setData.jobTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.setData, "jobTitle", $$v)
                      },
                      expression: "setData.jobTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.modeFile,
                      expression: "!modeFile",
                    },
                  ],
                  staticClass: "list-wrap",
                },
                [
                  _c("label", [_vm._v("目标周期:")]),
                  _c("Select", {
                    attrs: {
                      type: 4,
                      "minor-cycle": _vm.minorCycle,
                      "major-cycle": _vm.majorCycle,
                    },
                    on: { setMinorCycle: _vm.setMinorCycle },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.modeFile,
                      expression: "!modeFile",
                    },
                  ],
                  staticClass: "list-wrap",
                },
                [
                  _c("label", [_vm._v("类型:")]),
                  _c("label-select", {
                    staticClass: "label-select",
                    model: {
                      value: _vm.setDataNew.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.setDataNew, "type", $$v)
                      },
                      expression: "setDataNew.type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.modeFile,
                      expression: "modeFile",
                    },
                  ],
                  staticClass: "upload-wrap",
                },
                [
                  _c(
                    "div",
                    { staticClass: "file-title" },
                    [
                      _c("Icon", {
                        staticClass: "icon",
                        attrs: { "icon-class": "icon-shenpijinggao" },
                      }),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "例如与战略、营销、销售、财务等相关的公司或业务部门报告和分析。"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        "show-file-list": false,
                        action: "",
                        "on-change": _vm.onChange,
                        multiple: "",
                        "http-request": _vm.upLoadFile,
                        "on-exceed": _vm.onExceed,
                        limit: 4 - _vm.fileListUpload.length,
                        drag: "",
                        accept: ".xls, .xlsx, .csv, .txt, .doc, .docx, .pdf",
                        disabled: _vm.fileListUpload.length === 4,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upload-btn-wrap",
                          attrs: { slot: "trigger" },
                          slot: "trigger",
                        },
                        [
                          _c("Icon", {
                            staticClass: "icon",
                            attrs: { "icon-class": "icon-tianjiawenjian" },
                          }),
                          _c(
                            "div",
                            { staticClass: "bottom-step-buttons" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.fileListUpload.length === 4,
                                  },
                                },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "可以直接将文件拖拽到此处进行上传，最多上传4个文件，支持格式：xls、xlsx、csv、txt、doc、docx、pdf"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("upload-file-list", {
                    attrs: {
                      preview: true,
                      filePreview: false,
                      "oss-file-entity-list": _vm.fileListUpload,
                    },
                    on: { delete: _vm.fileDelete },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-input" },
                [
                  _c("label", [_vm._v("解决与突破的方向：")]),
                  _c("el-input", {
                    staticClass: "input-focus",
                    attrs: {
                      type: "textarea",
                      placeholder:
                        "请描述您的目标方向，可以是遇到的问题或是明确的目标 \n例如： \n问题型：希望解决目前部门效率问题。 \n目标型：完成业绩指标100万",
                      rows: 6,
                      disabled: _vm.addOkrLoading,
                    },
                    model: {
                      value: _vm.content,
                      callback: function ($$v) {
                        _vm.content = $$v
                      },
                      expression: "content",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "bottom-step-wrap" }, [
              _c(
                "div",
                { staticClass: "mode-wrap", on: { click: _vm.modeClick } },
                [
                  _c("Icon", {
                    staticClass: "icon",
                    attrs: {
                      "icon-class": !_vm.modeFile
                        ? "icon-wenjian2"
                        : "icon-caidanlan-quanburenwu",
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(!_vm.modeFile ? "文件模式" : "基础模式") + " "
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bottom-step-buttons" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "left-button",
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      attrs: {
                        loading: _vm.addOkrLoading,
                        disabled:
                          _vm.content === "" ||
                          _vm.addOkrLoading ||
                          _vm.submitKrLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveBtn()
                        },
                      },
                    },
                    [_vm._v("生成目标O")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "add-content-right" }, [
          _c("div", { staticClass: "okr-list-wrap" }, [
            _vm.objList.length === 0 && !_vm.alignObj
              ? _c("div", { staticClass: "preiew-title" }, [
                  _vm._v(" 预览 OKR： "),
                  _c("span", [
                    _vm._v("（可以编辑目标和关键结果后一键生成应用OKR）"),
                  ]),
                ])
              : _vm._e(),
            _vm.aiStep === 1 && _vm.objList.length > 0 && !_vm.alignObj
              ? _c("div", { staticClass: "preiew-title-wrap" }, [
                  _c("div", { staticClass: "preiew-title" }, [
                    _vm._v("预览 OKR："),
                  ]),
                  _c("div", { staticClass: "ai-title" }, [
                    _vm._v(
                      "可根据业务需求选择或调整目标 O的内容， 可点击下方生成关键结果KR 按钮OKR）"
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.aiStep === 2 || _vm.alignObj
              ? _c("div", { staticClass: "preiew-title-wrap" }, [
                  _c("div", { staticClass: "ai-title" }, [
                    _vm._v(
                      "源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR，同时我们将会帮助您生产指导性的工作任务"
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.objList.length > 0 && !_vm.addOkrLoading && !_vm.submitKrLoading
              ? _c(
                  "div",
                  { staticClass: "okr-content-detail" },
                  [
                    _vm.alignObjectiveData !== {} && _vm.aiStep === 2
                      ? _c(
                          "div",
                          { staticClass: "align-obj" },
                          [
                            _c("objective-show", {
                              attrs: {
                                "align-hide": true,
                                "obj-data":
                                  _vm.alignObjectiveData.alignObjectiveData,
                                alignType: _vm.alignObjectiveData.alignType,
                                type: "align",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.objList, function (objectiveObj, objIndex) {
                      return _c(
                        "div",
                        { key: "" + JSON.stringify(objectiveObj) },
                        [
                          _c("div", { staticClass: "okr-title" }, [
                            _c("div", { staticClass: "okr-title-left" }, [
                              _vm.aiStep === 1
                                ? _c(
                                    "div",
                                    { staticClass: "okr-check-wrap" },
                                    [
                                      _c("el-checkbox", {
                                        model: {
                                          value: objectiveObj.check,
                                          callback: function ($$v) {
                                            _vm.$set(objectiveObj, "check", $$v)
                                          },
                                          expression: "objectiveObj.check",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "okr-title-num" }, [
                                _vm._v(" 目标 O" + _vm._s(objIndex + 1) + " "),
                              ]),
                            ]),
                            _vm.aiStep === 2
                              ? _c("div", { staticClass: "okr-title-right" }, [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        "kr-btn " +
                                        (objectiveObj.krCheckLength === 0
                                          ? "disabled"
                                          : ""),
                                      on: {
                                        click: function ($event) {
                                          objectiveObj.krCheckLength === 0
                                            ? ""
                                            : _vm.sumbitCheckedKr(objIndex)
                                        },
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "icon",
                                        attrs: { "icon-class": "icon-ai" },
                                      }),
                                      _vm._v("重新生成KR "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "okr-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.okrDelete(objIndex)
                                        },
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: {
                                          "icon-class": "icon-delete-icon",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "okr-title-wrap" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    "open-delay": 300,
                                    content: "点击编辑",
                                  },
                                },
                                [
                                  objectiveObj.okrTitleEdit === false
                                    ? _c("div", {
                                        staticClass: "title",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.changeToHtml(
                                              objectiveObj.content
                                            )
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.okrTitleClick(
                                              true,
                                              objIndex
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              objectiveObj.okrTitleEdit !== false
                                ? _c(
                                    "div",
                                    { staticClass: "okr-title-edit" },
                                    [
                                      _c("at-input", {
                                        ref: "myatinput",
                                        refInFor: true,
                                        attrs: {
                                          limit: "150",
                                          isBgColor: false,
                                          html: _vm.$utils.changeToHtml(
                                            objectiveObj.content
                                          ),
                                          editable: true,
                                          "enter-able": true,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.changeObjTitle(
                                              $event,
                                              objIndex
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.aiStep === 2
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "vueloading",
                                      rawName: "v-vueloading",
                                      value:
                                        _vm.aiStep === 2 &&
                                        objectiveObj.loading,
                                      expression:
                                        "aiStep === 2 && objectiveObj.loading",
                                    },
                                  ],
                                  staticClass: "kr-list-wrap",
                                },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      attrs: {
                                        handle: ".key_result_dragger_icon_box",
                                      },
                                      on: { update: _vm.drop },
                                      model: {
                                        value: objectiveObj.keyresultsList,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            objectiveObj,
                                            "keyresultsList",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "objectiveObj.keyresultsList",
                                      },
                                    },
                                    _vm._l(
                                      objectiveObj.keyresultsList,
                                      function (item, index) {
                                        return _c("div", { key: item.id }, [
                                          _c(
                                            "div",
                                            { staticClass: "okr-kr-item" },
                                            [
                                              _c(
                                                "key-results-list",
                                                {
                                                  attrs: {
                                                    "key-results-li": item,
                                                    "target-object":
                                                      objectiveObj,
                                                    "key-results-li-number":
                                                      index,
                                                    "show-border": true,
                                                    "align-member": true,
                                                    "page-type": "addOkrAiList",
                                                    fromType: "addOkrAiList",
                                                    editState: true,
                                                  },
                                                  on: {
                                                    getTargetDetailsKeywordDetele:
                                                      function ($event) {
                                                        return _vm.getTargetDetailsKeywordDetele(
                                                          index,
                                                          objIndex
                                                        )
                                                      },
                                                    getKeyResultsListTitles:
                                                      function ($event) {
                                                        return _vm.getKeyResultsListTitles(
                                                          index,
                                                          objIndex
                                                        )
                                                      },
                                                    changeObjectiveTitle:
                                                      function ($event) {
                                                        return _vm.changeObjectiveTitle(
                                                          index,
                                                          objIndex
                                                        )
                                                      },
                                                    getKeyResultsListWeights:
                                                      function ($event) {
                                                        return _vm.getKeyResultsListWeights(
                                                          index,
                                                          objIndex
                                                        )
                                                      },
                                                    getKeyHandleChange:
                                                      function ($event) {
                                                        return _vm.getKeyHandleChange(
                                                          $event,
                                                          objIndex
                                                        )
                                                      },
                                                    getKeyHandleChangeBlur:
                                                      function ($event) {
                                                        return _vm.getKeyHandleChangeBlur(
                                                          $event,
                                                          $event,
                                                          objIndex
                                                        )
                                                      },
                                                    saveContent: function (
                                                      $event
                                                    ) {
                                                      return _vm.saveContent(
                                                        $event,
                                                        index,
                                                        objIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "okr-check-wrap",
                                                      attrs: {
                                                        slot: "slotLeft",
                                                      },
                                                      slot: "slotLeft",
                                                    },
                                                    [
                                                      _c("el-checkbox", {
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.getCheckedKr(
                                                              objIndex,
                                                              "length"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value: item.check,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "check",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.check",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _vm.aiStep === 2
                                    ? _c(
                                        "div",
                                        { staticClass: "add-kr-wrap" },
                                        [
                                          objectiveObj.addkeyState === false
                                            ? _c("key-results-add", {
                                                attrs: {
                                                  "no-request": true,
                                                  okrDataObj: objectiveObj,
                                                  isAiAdd: _vm.krAddType,
                                                  fromType: "addOkrAiList",
                                                  fileList:
                                                    _vm.setDataNewInit.fileList,
                                                  sId: _vm.sId,
                                                },
                                                on: {
                                                  saveContent: function (
                                                    $event
                                                  ) {
                                                    return _vm.saveContentAdd(
                                                      $event,
                                                      objIndex
                                                    )
                                                  },
                                                  cancel: function ($event) {
                                                    return _vm.cancel(objIndex)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "btn-list-wrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addKeyClick(
                                                    objIndex
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              objectiveObj.addkeyState &&
                                              objectiveObj.keyresultsList
                                                .length < 10
                                                ? _c("key-results", {
                                                    attrs: {
                                                      "top-members-bean":
                                                        objectiveObj,
                                                    },
                                                    on: {
                                                      getAddkeyList:
                                                        _vm.getAddkeyList,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.objList.length === 0 && !_vm.addOkrLoading
              ? _c(
                  "div",
                  { staticClass: "okr-content-detail" },
                  [
                    _c("no-data", {
                      attrs: {
                        text: "源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR,快去试试吧！",
                        imgUrl: require("@/assets/imgs/common/kpiNull.png"),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.addOkrLoading || _vm.submitKrLoading || _vm.alignObj
              ? _c(
                  "div",
                  { staticClass: "okr-content-detail add-loading-wrap" },
                  [
                    _c(
                      "div",
                      { staticClass: "step-list-wrap" },
                      _vm._l(
                        _vm.addOkrLoading
                          ? _vm.stepTitleListO
                          : _vm.stepTitleListKr,
                        function (item, index) {
                          return index <= _vm.stepLoading
                            ? _c(
                                "div",
                                {
                                  key: "" + item,
                                  class:
                                    "step-list " + (index > _vm.stepLoading),
                                },
                                [
                                  _vm.stepLoading === index
                                    ? _c("i", {
                                        staticClass: "el-icon-loading icon",
                                      })
                                    : _c("Icon", {
                                        class:
                                          "icon " + (index > _vm.stepLoading),
                                        attrs: {
                                          "icon-class":
                                            "icon-a-daibanyiwancheng1",
                                        },
                                      }),
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(_vm._s(item)),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e()
                        }
                      ),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "bottom-step-buttons" },
            [
              _vm.aiStep === 2
                ? _c(
                    "el-button",
                    {
                      staticClass: "left-button",
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _vm.aiStep === 1 && _vm.objList.length > 0
                ? _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      attrs: {
                        disabled:
                          _vm.objList.length === 0 || _vm.submitKrLoading,
                        loading: _vm.submitKrLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveBtnKr()
                        },
                      },
                    },
                    [_vm._v("生成关键结果 KR")]
                  )
                : _vm._e(),
              _vm.aiStep === 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      staticClass: "left-button",
                      attrs: {
                        disabled:
                          _vm.objList.length === 0 || _vm.submitOkrLoading,
                        loading: _vm.submitOkrLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.refreshObj()
                        },
                      },
                    },
                    [_vm._v("重新编辑目标 O")]
                  )
                : _vm._e(),
              _vm.aiStep === 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      attrs: {
                        disabled:
                          _vm.objList.length === 0 ||
                          _vm.submitOkrLoading ||
                          _vm.submitKrLoading,
                        loading: _vm.submitOkrLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveBtn(1)
                        },
                      },
                    },
                    [_vm._v("一键生成OKR")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm.aiSetvisible
        ? _c("ai-set", {
            attrs: { data: JSON.parse(JSON.stringify(_vm.setData)) },
            on: { submit: _vm.submitSet },
            model: {
              value: _vm.aiSetvisible,
              callback: function ($$v) {
                _vm.aiSetvisible = $$v
              },
              expression: "aiSetvisible",
            },
          })
        : _vm._e(),
      _vm.customDialogvisible
        ? _c("custom-dialog", {
            attrs: { type: "kr" },
            on: { submit: _vm.customDialogSubmit },
            model: {
              value: _vm.customDialogvisible,
              callback: function ($$v) {
                _vm.customDialogvisible = $$v
              },
              expression: "customDialogvisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }