var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "转化为子任务",
        visible: _vm.isShow,
        "before-close": _vm.close,
        "append-to-body": "",
        width: "720px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "search-wrap", attrs: { slot: "title" }, slot: "title" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("转化为子任务")]),
          _c("el-input", {
            attrs: {
              placeholder: "请输入任务标题 搜索任务",
              "prefix-icon": "el-icon-search",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchEnter.apply(null, arguments)
              },
            },
            model: {
              value: _vm.searchName,
              callback: function ($$v) {
                _vm.searchName = $$v
              },
              expression: "searchName",
            },
          }),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择项目", width: "200" },
              model: {
                value: _vm.projectId,
                callback: function ($$v) {
                  _vm.projectId = $$v
                },
                expression: "projectId",
              },
            },
            _vm._l(_vm.allProjectList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "main-wrap" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "vueloading",
                rawName: "v-vueloading",
                value: _vm.loading,
                expression: "loading",
              },
              {
                name: "scroll-bottom",
                rawName: "v-scroll-bottom",
                value: _vm.loadMoreList,
                expression: "loadMoreList",
              },
            ],
            staticClass: "task-list-wrap",
          },
          [
            _vm._l(_vm.taskList, function (item, index) {
              return _c("task-item", {
                key: item.id + item.checked,
                attrs: { index: index, item: item },
                on: { selectClick: _vm.clickChange },
              })
            }),
            _vm.taskList.length === 0 && !_vm.loading
              ? _c("div", { staticClass: "no-content" }, [_c("noData")], 1)
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "bottom-step-buttons" }, [
          _c(
            "button",
            { staticClass: "left-button", on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
          _c("button", { on: { click: _vm.save } }, [_vm._v("完成")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }