var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply-content" },
    [
      _c("div", { staticClass: "field-list-content" }, [
        _c("div", { staticClass: "field-list-wrap field-list-flex" }, [
          _c(
            "div",
            { staticClass: "username" },
            [
              _c("el-input", {
                attrs: { type: "text", placeholder: "您的姓名", maxlength: 30 },
                model: {
                  value: _vm.userName,
                  callback: function ($$v) {
                    _vm.userName = $$v
                  },
                  expression: "userName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "username" },
            [
              _c("el-input", {
                staticClass: "login-input",
                attrs: {
                  type: "text",
                  name: "tel",
                  maxlength: "11",
                  oninput: "value=value.replace(/[^\\d]/g,'')",
                  autocomplete: _vm.isAllowSavePassword == 1 ? "off" : "on",
                  placeholder: "请输入手机号",
                },
                model: {
                  value: _vm.telephone,
                  callback: function ($$v) {
                    _vm.telephone = $$v
                  },
                  expression: "telephone",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "field-list-wrap" }, [
          _c("div", { staticClass: "field-title" }, [_vm._v("使用人数：")]),
          _c("div", { staticClass: "field-list" }, [
            _c("div", { staticClass: "price-two-rg-user-range flex-new" }, [
              _c("div", { staticClass: "inputValue" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.countNumber,
                      expression: "countNumber",
                    },
                  ],
                  staticClass: "input-value",
                  attrs: {
                    placeholder: "填写使用人数，最低20人起",
                    type: "text",
                    oninput: "value=value.replace(/[^\\d]/g,'');",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.countNumber },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.countNumber = $event.target.value
                    },
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "price-two-rg-user-range-inner flex-new flex-new-direction-column",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "price-two-rg-user-range-inner-top flex-new",
                      on: {
                        mouseenter: function ($event) {
                          _vm.numAddState = true
                        },
                        mouseleave: function ($event) {
                          _vm.numAddState = false
                        },
                        click: _vm.add,
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "price-two-arrow-img-add",
                        attrs: { "icon-class": "icon-arrow-up" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "price-two-rg-user-range-inner-bottom flex-new",
                      on: {
                        mouseenter: function ($event) {
                          _vm.numReduceState = true
                        },
                        mouseleave: function ($event) {
                          _vm.numReduceState = false
                        },
                        click: _vm.reduce,
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "price-two-arrow-img-add",
                        attrs: { "icon-class": "icon-arrow-down" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "field-list-wrap" }, [
          _c("div", { staticClass: "field-title" }, [_vm._v("版本：")]),
          _c("div", { staticClass: "version-list-wrap" }, [
            _c("div", { staticClass: "version-select" }, [
              _c(
                "div",
                {
                  class:
                    "version-content version-content-" + (_vm.version === 1),
                  on: {
                    click: function ($event) {
                      return _vm.versionClick(1)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "version-title" }, [
                    _vm._v("企业版"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "version-list" },
                    _vm._l(_vm.versionList, function (item, index) {
                      return index < 6
                        ? _c(
                            "div",
                            {
                              key: item,
                              staticClass: "version-name",
                              on: {
                                click: function ($event) {
                                  return _vm.moduleClick(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item))]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                {
                  class:
                    "version-content version-content-" + (_vm.version === 2),
                  on: {
                    click: function ($event) {
                      return _vm.versionClick(2)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "version-title" }, [
                    _vm._v("旗舰版"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "version-list" },
                    _vm._l(_vm.versionList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item,
                          staticClass: "version-name",
                          on: {
                            click: function ($event) {
                              return _vm.moduleClick(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "field-list-wrap field-list-flex" }, [
          _c(
            "div",
            { staticClass: "service-list" },
            _vm._l(_vm.moduleList, function (item, index) {
              return _c(
                "div",
                {
                  key: item,
                  staticClass: "module-name",
                  class:
                    "module-name-" + (_vm.selectModule.indexOf(item) !== -1),
                  on: {
                    click: function ($event) {
                      return _vm.moduleClick(item)
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(item) + " "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: _vm.moduleListTitle[index],
                        placement: "top",
                        "popper-class": "apply-tooltip-wrap",
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "icon-tishi",
                        attrs: { "icon-class": "icon-tishi" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "apply-button bottom-step-buttons" },
        [
          _c(
            "L-button",
            {
              staticClass: "confirm",
              attrs: { loading: _vm.loading },
              on: { clickEvt: _vm.confirm },
            },
            [_vm._v("获取报价方案")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "remind-title" }, [
        _vm._v("我们将在一个工作日内联系您"),
      ]),
      _vm.processDialogVisible
        ? _c("process-dialog", {
            attrs: {
              type: "applyDemo",
              btn: "关闭",
              "dialog-txt": "<p>提交成功</p><p>我们将在一个工作日内联系您</p>",
            },
            on: { close: _vm.processDialogClose },
            model: {
              value: _vm.processDialogVisible,
              callback: function ($$v) {
                _vm.processDialogVisible = $$v
              },
              expression: "processDialogVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-list-wrap service-title" }, [
      _c("div", { staticClass: "field-title" }, [_vm._v("所需服务：")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }