var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kr-content-wrap" }, [
    _c("div", { staticClass: "kr-content" }, [
      _c(
        "div",
        { staticClass: "kr-content-left" },
        [
          _c("div", { staticClass: "kr-tag" }, [
            _vm._v("KR" + _vm._s(_vm.krIndex + 1)),
          ]),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: _vm.$utils.changeToHtml(_vm.krItem.title),
                "open-delay": 300,
                placement: "top",
              },
            },
            [
              _c("div", {
                attrs: { slot: "content" },
                domProps: {
                  innerHTML: _vm._s(_vm.$utils.changeToHtml(_vm.krItem.title)),
                },
                slot: "content",
              }),
              _c("div", {
                staticClass: "kr-title",
                domProps: {
                  innerHTML: _vm._s(_vm.$utils.changeToHtml(_vm.krItem.title)),
                },
              }),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "kr-weight" }, [
        _vm._v(" 权重： "),
        _c("span", [_vm._v(_vm._s(_vm.krItem.weight) + "%")]),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "kr-info",
        on: {
          click: function ($event) {
            $event.stopPropagation()
          },
        },
      },
      [
        _vm.krItem.quantificationType === 1
          ? _c("div", { staticClass: "kr-info-list false" }, [
              _vm._v(
                "完成情况：" +
                  _vm._s(
                    _vm.krItem.quantificationStatus === 0 ? "未完成" : "已完成"
                  )
              ),
            ])
          : [
              _c("div", { staticClass: "kr-info-list" }, [
                _vm._v(" 量化单位： "),
                _c("span", [_vm._v(_vm._s(_vm.krItem.quantificationUnit))]),
              ]),
              _c("div", { staticClass: "kr-info-list" }, [
                _vm._v(" 初始值： "),
                _c("span", [_vm._v(_vm._s(_vm.krItem.quantificationStart))]),
              ]),
              _c("div", { staticClass: "kr-info-list" }, [
                _vm._v(" 目标值： "),
                _c("span", [_vm._v(_vm._s(_vm.krItem.quantificationEnd))]),
              ]),
              _c("div", { staticClass: "kr-info-list false" }, [
                _vm._v(" 完成值： "),
                _c("span", [
                  _vm._v(_vm._s(_vm.krItem.quantificationActualValue)),
                ]),
              ]),
            ],
        _c(
          "div",
          { staticClass: "kr-update" },
          [
            _c(
              "key-results-alert",
              {
                attrs: { "obj-data": _vm.krItem, type: "okr", okrType: "list" },
                on: {
                  getKeyResultsAlertClose: function ($event) {
                    return _vm.changeObjectiveTitle("修改成功")
                  },
                },
              },
              [
                _vm.userInfo.id === _vm.okrData.createUserid
                  ? _c("div", { staticClass: "okr-process-add" }, [
                      _vm._v("手动更新"),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }