// 状态码处理
import route from '@/router'
import Message from '../components/global/Message'
import store from '@/store'
// === 勿删=====
// 40101：token校验不通过
// 40001：token无效
// 40301：微服务之间无效调用
// 40331：微服务之间禁止调用

// token失效
const TOKEN_INVLIDE_TO_LOGIN = (code, data) => {
  if (code === 40101 || code === 40001 || code === 40301 || code === 40331 || code === 52023) {
    if (code === 52023) {
      Message.error(data.message)
    } else if (store.state.user.userInfo !== '') {
      Message.error('登录状态已过期,请重新登录！')
    }
    store.commit('user/setUserInfo', '')
    if (window.location.href.indexOf('/login') === -1) {
      route.replace({
        path: `/login${
          (getQueryString('corpId') || store.state.common.commonCorpId) !== ''
            ? `?from=token&corpId=${
              getQueryString('corpId') || store.state.common.commonCorpId
            }`
            : ''
        }`
      })
    }
    return false
  } else {
    return true
  }
}
// 全局错误处理
const PUB_ERR = (code, message, config) => {
  if (code === 68001 || code === 68002 || code === 68003 || code === 68004) {
    route.replace({
      path: `/loginErrorText`,
      query: {
        message
      }
    })
  } else if (
    code === 52014 ||
    code === 52017 ||
    code === 52012 ||
    code === 52018 ||
    code === 52019 ||
    code === 52020 ||
    code === 54002 ||
    code === 54003
  ) {
    // console.log('全局拦截params', param)
    const params =
      (config.method === 'get' || config.method === 'delete' ?
        config.params :
        JSON.parse(config.data)) || {}
    if ((params.apiStatus || '') === 1) {
      return true
    } else {
      if ((store.state.user.userInfo || '') !== '') {
        store.commit('common/apiRequestData', {
          visible: true,
          type: code,
          companyId: store.state.user.companyInfo.id
        })
      } else {
        Message.error(message)
        store.commit('common/apiRequestData', {
          visible: false,
          type: ''
        })
      }
      return false
    }
  } else {
    return true
  }
}

const getQueryString = (name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return ''
}

export default {
  TOKEN_INVLIDE_TO_LOGIN,
  PUB_ERR
}
