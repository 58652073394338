var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "el-dropdown",
            {
              attrs: { trigger: "click", placement: "bottom-start" },
              on: { command: _vm.handleCommand },
            },
            [
              _vm.type === 1
                ? _c(
                    "div",
                    {
                      staticClass: "status-box",
                      style: "background: " + _vm.stateDictBgColor[_vm.status],
                      on: { click: _vm.slotStatusClick },
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            "status-checked status-task-type-" +
                            (_vm.item && _vm.item.businessType),
                          style:
                            "border: 1px solid " +
                            _vm.stateDictColor[_vm.status],
                        },
                        [
                          _vm.status == 3
                            ? _c("i", { staticClass: "el-icon-check checked" })
                            : !_vm.disabled
                            ? _c("i", {
                                staticClass: "el-icon-caret-bottom hover",
                                style:
                                  "color: " + _vm.stateDictColor[_vm.status],
                              })
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "status-text",
                          style: "color: " + _vm.stateDictColor[_vm.status],
                        },
                        [_vm._v(_vm._s(_vm.stateDict[_vm.status]))]
                      ),
                    ]
                  )
                : _vm.type === 3
                ? _c(
                    "div",
                    {
                      staticClass: "status-box status-box-3",
                      style: "background: " + _vm.stateDictBgColor[_vm.status],
                      on: { click: _vm.slotStatusClick },
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            "status-checked status-task-type-" +
                            (_vm.item && _vm.item.businessType),
                          style:
                            "border: 1px solid " +
                            _vm.stateDictColor[_vm.status],
                        },
                        [
                          _vm.status == 3
                            ? _c("i", { staticClass: "el-icon-check checked" })
                            : !_vm.disabled
                            ? _c("i", {
                                staticClass: "el-icon-caret-bottom hover",
                                style:
                                  "color: " + _vm.stateDictColor[_vm.status],
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "task-status-wrap" },
                    [_vm._t("default")],
                    2
                  ),
              _vm.item && _vm.item.businessType === 1
                ? _c("el-dropdown-menu", {
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  })
                : _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(
                      _vm.item && _vm.item.businessType === 1
                        ? _vm.stateDictWait
                        : _vm.stateDict,
                      function (val, key) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: key,
                            staticClass: "picker-item",
                            attrs: { command: key },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "status-item",
                                style:
                                  "color: " +
                                  _vm.stateDictColor[key] +
                                  ";background: " +
                                  _vm.stateDictBgColor[key],
                              },
                              [_vm._v(_vm._s(val))]
                            ),
                            _vm.status === parseInt(key)
                              ? _c("i", {
                                  staticClass:
                                    "el-icon-check color-icon-check-working",
                                })
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                    1
                  ),
            ],
            1
          )
        : _c("div", [
            _vm.type === 1
              ? _c(
                  "div",
                  {
                    staticClass: "status-box disabled-status-box",
                    style: "background: " + _vm.stateDictBgColor[_vm.status],
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "status-checked",
                        class: _vm.disabled
                          ? "disabled-status-box status-task-type-" +
                            (_vm.item && _vm.item.businessType)
                          : "status-task-type-" +
                            (_vm.item && _vm.item.businessType),
                        style:
                          "border: 1px solid " + _vm.stateDictColor[_vm.status],
                      },
                      [
                        _vm.status == 3
                          ? _c("i", { staticClass: "el-icon-check checked" })
                          : !_vm.disabled
                          ? _c("i", {
                              staticClass: "el-icon-caret-bottom hover",
                              style: "color: " + _vm.stateDictColor[_vm.status],
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "status-text",
                        style: "color: " + _vm.stateDictColor[_vm.status],
                      },
                      [_vm._v(_vm._s(_vm.stateDict[_vm.status]))]
                    ),
                  ]
                )
              : _vm.type === 3
              ? _c(
                  "div",
                  {
                    staticClass: "status-box status-box-3 disabled-status-box",
                    class: _vm.disabled ? "disabled-status-box" : "",
                    style: "background: " + _vm.stateDictBgColor[_vm.status],
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          "status-checked status-task-type-" +
                          (_vm.item && _vm.item.businessType),
                        style:
                          "border: 1px solid " + _vm.stateDictColor[_vm.status],
                      },
                      [
                        _vm.status == 3
                          ? _c("i", { staticClass: "el-icon-check checked" })
                          : !_vm.disabled
                          ? _c("i", {
                              staticClass: "el-icon-caret-bottom hover",
                              style: "color: " + _vm.stateDictColor[_vm.status],
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "task-status-wrap disabled-status-box" },
                  [_vm._t("default")],
                  2
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }