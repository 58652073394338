var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    _vm._g(
      {
        staticClass: "task-detail",
        attrs: {
          visible: _vm.dialogVisible,
          "append-to-body": true,
          direction: "rtl",
          size: 750,
          "modal-append-to-body": true,
          "before-close": _vm.dialogClose,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _vm.isShowDetails
        ? [
            _c(
              "div",
              {
                staticClass: "task-detail-header",
                on: { click: _vm.clickDraw },
              },
              [
                _c(
                  "div",
                  { staticClass: "task-header" },
                  [
                    _vm.isStopDoing
                      ? _c(
                          "div",
                          { staticClass: "stopdoing-state" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: _vm.isDisabledEdit
                                    ? "没有权限编辑"
                                    : _vm.form.status == 3
                                    ? "修改为未完成"
                                    : "修改为已完成",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "stopdoing-state-button",
                                    class:
                                      _vm.form.status == 3
                                        ? "stopdoing-state-button-3 stopdoing-state-button-" +
                                          _vm.isDisabledEdit
                                        : "stopdoing-state-button-" +
                                          _vm.isDisabledEdit,
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        !_vm.isDisabledEdit
                                          ? _vm.changeStopdoingState(
                                              _vm.form.status
                                            )
                                          : ""
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "status-icon",
                                      attrs: {
                                        "icon-class":
                                          _vm.form.status == 1
                                            ? "icon-a-daibanwanchengdaiban"
                                            : "icon-a-daibanyiwancheng1",
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.form.status == 1
                                            ? "完成待办"
                                            : "已完成"
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("TaskType", {
                      ref: "taskType",
                      attrs: {
                        type: _vm.isStopDoing ? "stopdoing" : "",
                        "classify-val":
                          _vm.form.businessType === 3
                            ? "kr"
                            : _vm.form.businessType === 2
                            ? "project"
                            : "todo",
                        "task-id": _vm.taskId,
                        disabled: _vm.isDisabledEdit,
                        "type-data": _vm.form,
                      },
                      on: { changeClassify: _vm.changeClassify },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "header-right" },
                  [
                    _vm.form.isShare === 1
                      ? _c(
                          "div",
                          { staticClass: "privacy-div" },
                          [
                            _c("Icon", {
                              staticClass: "task-privacy",
                              attrs: { "icon-class": "icon-simi" },
                            }),
                            _vm._v("隐私 "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        class: "okr-care okr-care-type-" + _vm.form.follow,
                        on: {
                          click: function ($event) {
                            return _vm.taskCareClick(_vm.form)
                          },
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              "open-delay": 300,
                              content:
                                _vm.form.follow === 1
                                  ? "已关注任务"
                                  : "关注任务",
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "icon",
                              attrs: {
                                "icon-class":
                                  _vm.form.follow === 1
                                    ? "icon-collected"
                                    : "icon-collect",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.isDisabledEdit ||
                    _vm.isLeader ||
                    _vm.isCreate ||
                    _vm.form.isDel === 2 ||
                    _vm.form.isClose === 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "more-menu",
                              {
                                attrs: {
                                  items: _vm.menuItems,
                                  placement: "bottom",
                                },
                                on: { menuClick: _vm.operateTarget },
                              },
                              [
                                _c("Icon", {
                                  staticClass: "task-operate",
                                  attrs: {
                                    "icon-class": "icon-gengduo-yuandianheng",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("Icon", {
                      staticClass: "task-operate task-close-icon",
                      attrs: { "icon-class": "icon-close" },
                      on: { click: _vm.dialogClose },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "task-content", on: { click: _vm.clickDraw } },
              [
                _c(
                  "div",
                  {
                    ref: "loadMoreMain",
                    staticClass: "task-main-content",
                    class: _vm.isStopDoing ? "task-main-content-stopdoing" : "",
                    on: { scroll: _vm.handleScroll },
                  },
                  [
                    _vm.progressList && _vm.progressList.processDynamic
                      ? _c(
                          "div",
                          { staticClass: "approve-type-wrap" },
                          [
                            _c("approve-type-detail", {
                              attrs: {
                                "progress-list": _vm.progressList,
                                "task-detail": _vm.form,
                              },
                              on: {
                                approvalRefresh: _vm.getApprovalProcessRecord,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isStopDoing
                      ? _vm._l(
                          _vm.fieldDictionaryList,
                          function (fieldItem, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "task-form" },
                              [
                                fieldItem.fieldType == "title"
                                  ? _c(
                                      "div",
                                      {
                                        ref: "taskContMain",
                                        refInFor: true,
                                        staticClass: "task-title",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              "open-delay": 300,
                                              content: _vm.isDisabledEdit
                                                ? "没有权限编辑"
                                                : "点击编辑任务名称" +
                                                  _vm.isDisabledEdit,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "to-edit-input-textarea w-100",
                                              class: _vm.isDisabledEdit
                                                ? ""
                                                : "input-textarea-hover",
                                              attrs: {
                                                type: "textarea",
                                                autosize: "",
                                                disabled: _vm.isDisabledEdit,
                                              },
                                              on: {
                                                focus: function ($event) {
                                                  _vm.titleIsFocus = true
                                                },
                                                blur: _vm.titleBlur,
                                                input: _vm.keyUpTitle,
                                              },
                                              nativeOn: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.enterToBlur.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.title,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.title",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "task-items" }, [
                                  fieldItem.fieldType == "status"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "top-item",
                                          staticStyle: { "margin-top": "0" },
                                        },
                                        [
                                          fieldItem.required == 0
                                            ? _c(
                                                "span",
                                                { staticClass: "star" },
                                                [_vm._v("*")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "top-item-describe",
                                            },
                                            [
                                              _c("Icon", {
                                                staticClass: "svg-icons",
                                                attrs: {
                                                  "icon-class":
                                                    "icon-taskstatus",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "text-box" },
                                                [
                                                  _vm._v(
                                                    _vm._s(fieldItem.name) + ":"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticStyle: {
                                                "margin-left": "12px",
                                              },
                                              attrs: {
                                                "open-delay": 300,
                                                content: _vm.isDisabledEdit
                                                  ? "没有权限编辑"
                                                  : _vm.form.status === 3
                                                  ? "点击编辑任务状态"
                                                  : "点击编辑任务状态",
                                              },
                                            },
                                            [
                                              _c("state-picker", {
                                                attrs: {
                                                  status: _vm.form.status,
                                                  disabled: _vm.isDisabledEdit,
                                                  item: _vm.form,
                                                },
                                                on: {
                                                  changeStatus:
                                                    _vm.changeStatusApproval,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  fieldItem.fieldType == "executor"
                                    ? _c("div", { staticClass: "top-item" }, [
                                        fieldItem.required == 0
                                          ? _c(
                                              "span",
                                              { staticClass: "star" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "top-item-describe" },
                                          [
                                            _c("Icon", {
                                              staticClass: "svg-icons",
                                              attrs: {
                                                "icon-class":
                                                  "icon-charge-user",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "text-box" },
                                              [
                                                _vm._v(
                                                  _vm._s(fieldItem.name) + ":"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "flex-1" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  "open-delay": 300,
                                                  content:
                                                    _vm.isOriginatorDisabledEdit ||
                                                    _vm.form.submitterType ===
                                                      1 ||
                                                    _vm.form.isDel === 2 ||
                                                    _vm.form.isClose === 1
                                                      ? "没有权限编辑"
                                                      : "点击编辑任务负责人",
                                                  disabled:
                                                    _vm.isOriginatorDisabledEdit ||
                                                    _vm.form.submitterType ===
                                                      1 ||
                                                    _vm.form.isDel === 2 ||
                                                    _vm.form.isClose === 1,
                                                },
                                              },
                                              [
                                                _c(
                                                  "user-picker",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.isOriginatorDisabledEdit ||
                                                        _vm.form
                                                          .taskOperationPermission ==
                                                          0 ||
                                                        _vm.form
                                                          .submitterType ===
                                                          1 ||
                                                        _vm.form.isDel === 2 ||
                                                        _vm.form.isClose === 1,
                                                    },
                                                    on: {
                                                      pick: _vm.toriginatorPicker,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "originator-box",
                                                        class:
                                                          _vm.isOriginatorDisabledEdit ||
                                                          _vm.form
                                                            .submitterType ===
                                                            1 ||
                                                          _vm.form.isDel ===
                                                            2 ||
                                                          _vm.form.isClose === 1
                                                            ? "to-edit-input-not"
                                                            : "o-edit-input-allow originator-" +
                                                              _vm.originatorHover,
                                                        on: {
                                                          mouseover: function (
                                                            $event
                                                          ) {
                                                            _vm.isColorCloseIcon = false
                                                          },
                                                          mouseout: function (
                                                            $event
                                                          ) {
                                                            _vm.isColorCloseIcon = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.form
                                                          .originatorName ===
                                                        null
                                                          ? [
                                                              _c("Icon", {
                                                                staticClass:
                                                                  "head-icon border-radius-20",
                                                                attrs: {
                                                                  "icon-class":
                                                                    "icon-kong-touxiang",
                                                                },
                                                              }),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "head-icon-label",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "待认领"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : [
                                                              _c("el-image", {
                                                                staticClass:
                                                                  "avatar-img pointer border-radius-20",
                                                                attrs: {
                                                                  fit: "cover",
                                                                  src:
                                                                    _vm.form
                                                                      .originatorAvatar ||
                                                                    require("@/assets/imgs/common/advar.png"),
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "m-l-10 pointer username",
                                                                },
                                                                [
                                                                  _c(
                                                                    "wx-open-data",
                                                                    {
                                                                      attrs: {
                                                                        id: _vm
                                                                          .form
                                                                          .originatorName,
                                                                        "name-data":
                                                                          {
                                                                            name: _vm
                                                                              .form
                                                                              .originatorName,
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.form
                                                                          .originatorUserState ==
                                                                          4
                                                                          ? "(离职)"
                                                                          : ""
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm.form
                                                                .originatorName !==
                                                                null &&
                                                              !_vm.isOriginatorDisabledEdit &&
                                                              _vm.form
                                                                .submitterType !==
                                                                1 &&
                                                              _vm.form.isDel !==
                                                                2 &&
                                                              _vm.form
                                                                .isClose !== 1
                                                                ? _c("Icon", {
                                                                    staticClass:
                                                                      "close-icon",
                                                                    attrs: {
                                                                      "icon-class":
                                                                        _vm.isColorCloseIcon
                                                                          ? ""
                                                                          : "icon-a-Group719-1",
                                                                    },
                                                                    nativeOn: {
                                                                      mouseenter:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.originatorHover = true
                                                                        },
                                                                      mouseleave:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.originatorHover = false
                                                                        },
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.clearToriginatorPicker(
                                                                            fieldItem
                                                                          )
                                                                        },
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ],
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  fieldItem.fieldType == "involves"
                                    ? _c("div", { staticClass: "top-item" }, [
                                        fieldItem.required == 0
                                          ? _c(
                                              "span",
                                              { staticClass: "star" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "top-item-describe-start",
                                          },
                                          [
                                            _c("Icon", {
                                              staticClass: "svg-icons",
                                              attrs: {
                                                "icon-class":
                                                  "icon-taskcooperationnew",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "text-box" },
                                              [
                                                _vm._v(
                                                  _vm._s(fieldItem.name) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.chargeUserListNew
                                                        .length || 0
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-1 charge-user-list",
                                          },
                                          [
                                            _vm._l(
                                              _vm.chargeUserListInit,
                                              function (item) {
                                                return [
                                                  item
                                                    ? _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "charge-avatar-box",
                                                          class:
                                                            _vm.isDisabledEdit
                                                              ? "to-edit-input-not"
                                                              : "to-edit-input-allow",
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticClass:
                                                              "avatar-img border-radius-20",
                                                            attrs: {
                                                              fit: "cover",
                                                              src:
                                                                item.avatar ||
                                                                require("@/assets/imgs/common/advar.png"),
                                                            },
                                                          }),
                                                          !_vm.isDisabledEdit
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "el-icon-error",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.chargeUserDelete(
                                                                        item.id,
                                                                        fieldItem
                                                                      )
                                                                    },
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              }
                                            ),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  "open-delay": 300,
                                                  content: _vm.isDisabledEdit
                                                    ? "您不是此任务的负责人或协作人和审批中的任务，无法操作哦"
                                                    : "点击添加协作人",
                                                },
                                              },
                                              [
                                                _c(
                                                  "user-picker-checked",
                                                  {
                                                    attrs: {
                                                      "select-people-list":
                                                        _vm.chargeUserListNew,
                                                      checked: true,
                                                      "field-item": fieldItem,
                                                      length: 40,
                                                      disabled:
                                                        _vm.isDisabledEdit,
                                                    },
                                                    on: {
                                                      pick: _vm.chargeUserPicker,
                                                    },
                                                  },
                                                  [
                                                    _c("Icon", {
                                                      staticClass:
                                                        "cooperation-add",
                                                      class: _vm.isDisabledEdit
                                                        ? "cooperation-add-disbled"
                                                        : "",
                                                      attrs: {
                                                        "icon-class":
                                                          "icon-tianjia-xuxian",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _vm._e(),
                                  fieldItem.fieldType == "startDate"
                                    ? _c("div", { staticClass: "top-item" }, [
                                        fieldItem.required == 0
                                          ? _c(
                                              "span",
                                              { staticClass: "star" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "top-item-describe",
                                            staticStyle: {
                                              "margin-top": "10px",
                                            },
                                          },
                                          [
                                            _c("Icon", {
                                              staticClass: "svg-icons",
                                              attrs: {
                                                "icon-class":
                                                  "icon-taskcalendars",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "text-box" },
                                              [_vm._v("时间:")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "flex-1 time-right" },
                                          [
                                            _c("set-date-time-detail", {
                                              attrs: {
                                                type: "edit",
                                                row: _vm.form,
                                                "field-item": fieldItem,
                                                disabled: _vm.isDisabledEdit,
                                              },
                                              on: {
                                                refresh: _vm.needRefreshState,
                                                change: _vm.dateTimeChange,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  fieldItem.fieldType == "tag"
                                    ? _c("div", { staticClass: "top-item" }, [
                                        fieldItem.required == 0
                                          ? _c(
                                              "span",
                                              { staticClass: "star" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "top-item-describe-start",
                                          },
                                          [
                                            _c("Icon", {
                                              staticClass: "svg-icons",
                                              attrs: {
                                                "icon-class": "icon-biaoqian",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "text-box" },
                                              [
                                                _vm._v(
                                                  _vm._s(fieldItem.name) + ":"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "flex-1" },
                                          [
                                            _c("TaskLabel", {
                                              attrs: {
                                                "label-options": _vm.labelData,
                                                "select-options":
                                                  _vm.form.labelArray,
                                                "field-item": fieldItem,
                                                disabled: _vm.isDisabledEdit,
                                              },
                                              on: {
                                                save: _vm.saveLabels,
                                                update: _vm.updateTaskLabel,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  fieldItem.fieldType == "priority"
                                    ? _c("div", { staticClass: "top-item" }, [
                                        fieldItem.required == 0
                                          ? _c(
                                              "span",
                                              { staticClass: "star" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "top-item-describe" },
                                          [
                                            _c("Icon", {
                                              staticClass: "svg-icons",
                                              attrs: {
                                                "icon-class":
                                                  "icon-taskprioritynew",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "text-box" },
                                              [
                                                _vm._v(
                                                  _vm._s(fieldItem.name) + ":"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "flex-1" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  "open-delay": 300,
                                                  content: _vm.isDisabledEdit
                                                    ? "没有权限编辑"
                                                    : "点击编辑优先级",
                                                  placement: "top",
                                                  "placement-class":
                                                    "priorityTooltip",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    ref: "taskPrioritySelect",
                                                    refInFor: true,
                                                    staticClass:
                                                      "task-detail-priority-box",
                                                    style:
                                                      "color: " +
                                                      _vm.priorityInfo.colour +
                                                      " !important;width:auto!",
                                                    attrs: {
                                                      disabled:
                                                        _vm.isDisabledEdit,
                                                      placeholder: "请选择",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.priorityChange(
                                                          fieldItem,
                                                          _vm.priorityVal
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.priorityVal,
                                                      callback: function ($$v) {
                                                        _vm.priorityVal = $$v
                                                      },
                                                      expression: "priorityVal",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.priorityDictionaryList,
                                                    function (item, key) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: key,
                                                          class: key,
                                                          attrs: {
                                                            label: item.value,
                                                            value: item.value,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              style:
                                                                "color: " +
                                                                (_vm
                                                                  .priorityDictionaryList[
                                                                  key
                                                                ].colour || ""),
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.value
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  fieldItem.fieldType == "note"
                                    ? _c("div", { staticClass: "top-item" }, [
                                        fieldItem.required == 0
                                          ? _c(
                                              "span",
                                              { staticClass: "star star-note" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "top-item-describe-start",
                                            staticStyle: {
                                              "margin-top": "15px",
                                            },
                                          },
                                          [
                                            _c("Icon", {
                                              staticClass: "svg-icons",
                                              attrs: {
                                                "icon-class": "icon-process",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "text-box" },
                                              [
                                                _vm._v(
                                                  _vm._s(fieldItem.name) + ":"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-1 top-item-box disc-right",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "total_edit" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "edit-con",
                                                    class:
                                                      "editState" +
                                                      _vm.editorFocus,
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          content:
                                                            _vm.describeContent,
                                                          placement: "top",
                                                          "open-delay": "300",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content-fixed",
                                                          },
                                                          [
                                                            _c("div", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    !_vm.editorClick,
                                                                  expression:
                                                                    "!editorClick",
                                                                },
                                                              ],
                                                              ref: "htmlContent",
                                                              refInFor: true,
                                                              class:
                                                                _vm.isDisabledEdit
                                                                  ? "htmlContent htmlContentNo"
                                                                  : "htmlContent",
                                                              attrs: {
                                                                id: "htmlContent",
                                                              },
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.filtersHtml(
                                                                      _vm.form
                                                                        .information
                                                                    )
                                                                      ? _vm.form
                                                                          .information
                                                                      : "待添加"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.ContentClick.apply(
                                                                      null,
                                                                      arguments
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            !_vm.editorClick &&
                                                            _vm.isShowMore
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "show-more",
                                                                    on: {
                                                                      click:
                                                                        _vm.showMore,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.isEditorShow
                                                                          ? "收起描述"
                                                                          : "显示更多"
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c("div", {
                                                              attrs: {
                                                                id: "showMore",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "transition",
                                                      {
                                                        attrs: {
                                                          name: "bottom-slide",
                                                          mode: "out-in",
                                                        },
                                                      },
                                                      [
                                                        _vm.editorClick
                                                          ? _c("Editor", {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "12px",
                                                              },
                                                              attrs: {
                                                                height: 440,
                                                              },
                                                              on: {
                                                                onFocus:
                                                                  _vm.EditorFocus,
                                                                onBlur:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.EditorBlur(
                                                                      arguments,
                                                                      fieldItem
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .information,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "information",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.information",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "transition",
                                                      {
                                                        attrs: {
                                                          name: "bottom-slide",
                                                          mode: "out-in",
                                                        },
                                                      },
                                                      [
                                                        _vm.editorFocus &&
                                                        !_vm.isDisabledEdit
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "editor-Btn",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cancel btn",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.editorChange(
                                                                            1,
                                                                            fieldItem
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "取消"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "confirm btn",
                                                                    attrs: {
                                                                      id: "saveEditor",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.editorChange(
                                                                            2,
                                                                            fieldItem
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "确定"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  fieldItem.fieldType == "subordinate"
                                    ? _c("div", { staticClass: "top-item" }, [
                                        fieldItem.required == 0
                                          ? _c(
                                              "span",
                                              { staticClass: "star" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "top-item-describe" },
                                          [
                                            _c("Icon", {
                                              staticClass: "svg-icons",
                                              attrs: {
                                                "icon-class": "icon-taskshu",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "text-box" },
                                              [
                                                _vm._v(
                                                  _vm._s(fieldItem.name) + ":"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "flex-1" },
                                          [
                                            _vm.krVisible
                                              ? _c("TaskKr", {
                                                  ref: "TaskKr",
                                                  refInFor: true,
                                                  staticStyle: {
                                                    "margin-left": "-8px",
                                                    "margin-top": "-6px",
                                                  },
                                                  attrs: {
                                                    "select-kr": _vm.copyProps,
                                                    "todo-add": false,
                                                    disabled:
                                                      _vm.isDisabledEdit ||
                                                      _vm.form
                                                        .taskOperationPermission ==
                                                        1,
                                                    type: "details",
                                                  },
                                                  on: {
                                                    save: _vm.saveKrMainDetail,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.form.projectId &&
                                  fieldItem.fieldType == "workProcess"
                                    ? _c("div", { staticClass: "top-item" }, [
                                        fieldItem.required == 0
                                          ? _c(
                                              "span",
                                              { staticClass: "star" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "top-item-describe" },
                                          [
                                            _c("Icon", {
                                              staticClass: "svg-icons",
                                              attrs: {
                                                "icon-class":
                                                  "icon-taskCooperate",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "text-box" },
                                              [
                                                _vm._v(
                                                  _vm._s(fieldItem.name) + ":"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-1 top-item-box",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "project-workflow-select",
                                              },
                                              [
                                                _c("TaskProject", {
                                                  attrs: {
                                                    project: _vm.projectInfo,
                                                    "process-id":
                                                      _vm.form.processId,
                                                    "project-disabled":
                                                      _vm.isDisabledEdit,
                                                    disabled:
                                                      _vm.isDisabledEdit ||
                                                      _vm.form
                                                        .taskOperationPermission ==
                                                        1,
                                                  },
                                                  on: {
                                                    change: _vm.changeProcess,
                                                    changeProject:
                                                      _vm.changeProject,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  fieldItem.fieldType == "workingHours"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "task-items",
                                          staticStyle: { width: "100%" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "top-item" },
                                            [
                                              fieldItem.required == 0
                                                ? _c(
                                                    "span",
                                                    { staticClass: "star" },
                                                    [_vm._v("*")]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "top-item-describe",
                                                },
                                                [
                                                  _c("Icon", {
                                                    staticClass: "svg-icons",
                                                    attrs: {
                                                      "icon-class":
                                                        "icon-gongshi",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "text-box" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(fieldItem.name) +
                                                          ":"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-1 workingHours-right",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "workhours-static",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openWorkHoursStatic()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("工时统计")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "top-items workingHours-div",
                                            },
                                            [
                                              _vm._l(
                                                _vm.workingHoursDictionaryList,
                                                function (wItem, wIndex) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: wIndex,
                                                      staticClass:
                                                        "workingHours-list",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "workingHours-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              wItem.workingHoursName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "workingHours-content",
                                                        },
                                                        [
                                                          !_vm.form
                                                            .taskWorkingHoursStatistics[
                                                            wItem
                                                              .workingHoursNameValue
                                                          ]
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "work-hours-0",
                                                                  class:
                                                                    _vm.isDisabledEdit
                                                                      ? "work-hours-disabled"
                                                                      : "",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        !_vm.isDisabledEdit
                                                                          ? _vm.setWorkHours(
                                                                              wItem
                                                                            )
                                                                          : ""
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("+")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "work-hours",
                                                                  class:
                                                                    _vm.isDisabledEdit
                                                                      ? "work-hours-disabled"
                                                                      : "",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        !_vm.isDisabledEdit
                                                                          ? _vm.setWorkHours(
                                                                              wItem
                                                                            )
                                                                          : ""
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.form
                                                                          .taskWorkingHoursStatistics &&
                                                                          wItem.workingHoursNameValue
                                                                          ? _vm
                                                                              .form
                                                                              .taskWorkingHoursStatistics[
                                                                              wItem
                                                                                .workingHoursNameValue
                                                                            ]
                                                                          : 0
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                          _c(
                                                            "span",
                                                            {
                                                              class: !_vm.form
                                                                .taskWorkingHoursStatistics[
                                                                wItem
                                                                  .workingHoursNameValue
                                                              ]
                                                                ? "work-hours-unit-no"
                                                                : "work-hours-unit",
                                                            },
                                                            [_vm._v("小时")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "workinghours-progress",
                                                },
                                                [
                                                  _c("el-progress", {
                                                    staticClass:
                                                      "hours-progress",
                                                    attrs: {
                                                      "stroke-width": 10,
                                                      color: "#3A70FF",
                                                      percentage:
                                                        _vm.getWorkhours(),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  fieldItem.fieldAttribute
                                    ? _c("div", { staticClass: "top-item" }, [
                                        fieldItem.required == 0
                                          ? _c(
                                              "span",
                                              { staticClass: "star" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "top-item-describe",
                                            staticStyle: {
                                              "margin-top": "11px !important",
                                            },
                                          },
                                          [
                                            fieldItem.fieldAttribute ==
                                            "singleChoice"
                                              ? _c("Icon", {
                                                  staticClass: "svg-icons",
                                                  attrs: {
                                                    "icon-class":
                                                      "icon-danxuan1",
                                                  },
                                                })
                                              : fieldItem.fieldAttribute ==
                                                "multipleChoice"
                                              ? _c("Icon", {
                                                  staticClass: "svg-icons",
                                                  attrs: {
                                                    "icon-class":
                                                      "icon-duoxuan",
                                                  },
                                                })
                                              : fieldItem.fieldAttribute ==
                                                "date"
                                              ? _c("Icon", {
                                                  staticClass: "svg-icons",
                                                  attrs: {
                                                    "icon-class":
                                                      "icon-kaishishijian",
                                                  },
                                                })
                                              : fieldItem.fieldAttribute ==
                                                "number"
                                              ? _c("Icon", {
                                                  staticClass: "svg-icons",
                                                  attrs: {
                                                    "icon-class": "icon-shuzi",
                                                  },
                                                })
                                              : fieldItem.fieldAttribute ==
                                                "text"
                                              ? _c("Icon", {
                                                  staticClass: "svg-icons",
                                                  attrs: {
                                                    "icon-class": "icon-wenben",
                                                  },
                                                })
                                              : fieldItem.fieldAttribute ==
                                                "file"
                                              ? _c("Icon", {
                                                  staticClass: "svg-icons",
                                                  attrs: {
                                                    "icon-class":
                                                      "icon-wenjian",
                                                  },
                                                })
                                              : _c("Icon", {
                                                  staticClass: "svg-icons",
                                                  attrs: {
                                                    "icon-class":
                                                      "icon-zidingyiziduan",
                                                  },
                                                }),
                                            fieldItem.name.length <= 4
                                              ? _c(
                                                  "div",
                                                  { staticClass: "text-box" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(fieldItem.name) +
                                                        ":"
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: fieldItem.name,
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "text-box",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            fieldItem.name
                                                          ) + ":"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-1 custom-right",
                                          },
                                          [
                                            _c(
                                              "el-form",
                                              {
                                                ref: "customInfoForm",
                                                refInFor: true,
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  model: _vm.customInfoForm,
                                                },
                                                nativeOn: {
                                                  submit: function ($event) {
                                                    $event.preventDefault()
                                                  },
                                                },
                                              },
                                              [
                                                _c("EditForm", {
                                                  key: _vm.customInfoForm,
                                                  ref: "childForm",
                                                  refInFor: true,
                                                  attrs: {
                                                    disabled:
                                                      _vm.isDisabledEdit,
                                                    "info-item": fieldItem,
                                                    "info-form":
                                                      _vm.customInfoForm,
                                                    "label-data": _vm.labelData,
                                                  },
                                                  on: {
                                                    setInfoForm:
                                                      _vm.setInfoForm,
                                                    updateDetails:
                                                      _vm.updateDetails,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.isStopDoing
                      ? _c(
                          "div",
                          { staticClass: "stop-doing-box" },
                          _vm._l(
                            _vm.shopDoingFieldDictionaryList,
                            function (fieldItem, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  class:
                                    fieldItem.fieldType == "title" ||
                                    fieldItem.fieldType == "note"
                                      ? "stop-dictionayry-row"
                                      : "stop-dictionayry-row-50",
                                },
                                [
                                  fieldItem.fieldType == "title"
                                    ? _c(
                                        "div",
                                        {
                                          ref: "taskContMain",
                                          refInFor: true,
                                          staticClass: "task-title",
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                "open-delay": 300,
                                                content: _vm.isDisabledEdit
                                                  ? "没有权限编辑"
                                                  : "点击编辑任务名称",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "to-edit-input-textarea w-100",
                                                class: _vm.isDisabledEdit
                                                  ? ""
                                                  : "input-textarea-hover",
                                                attrs: {
                                                  type: "textarea",
                                                  autosize: "",
                                                  disabled: _vm.isDisabledEdit,
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    _vm.titleIsFocus = true
                                                  },
                                                  blur: _vm.titleBlur,
                                                  input: _vm.keyUpTitle,
                                                },
                                                nativeOn: {
                                                  keydown: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.enterToBlur.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.form.title,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.title",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "stop-doing-list" },
                                    [
                                      fieldItem.fieldType == "executor"
                                        ? _c(
                                            "div",
                                            {},
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    "open-delay": 300,
                                                    content:
                                                      _vm.isOriginatorDisabledEdit ||
                                                      _vm.form.submitterType ===
                                                        1 ||
                                                      _vm.form.isDel === 2 ||
                                                      _vm.form.isClose === 1
                                                        ? "没有权限编辑"
                                                        : "点击编辑任务负责人",
                                                    disabled:
                                                      _vm.isOriginatorDisabledEdit,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "user-picker",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.isOriginatorDisabledEdit ||
                                                          _vm.form
                                                            .taskOperationPermission ==
                                                            0 ||
                                                          _vm.form
                                                            .submitterType ===
                                                            1 ||
                                                          _vm.form.isDel ===
                                                            2 ||
                                                          _vm.form.isClose ===
                                                            1,
                                                      },
                                                      on: {
                                                        pick: _vm.toriginatorPicker,
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "originator-box stopdoing-originator",
                                                          class:
                                                            _vm.isOriginatorDisabledEdit ||
                                                            _vm.form
                                                              .submitterType ===
                                                              1 ||
                                                            _vm.form.isDel ===
                                                              2 ||
                                                            _vm.form.isClose ===
                                                              1
                                                              ? "to-edit-input-not"
                                                              : "o-edit-input-allow stopdoing-originator-" +
                                                                _vm.originatorHover,
                                                          on: {
                                                            mouseover:
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.isColorCloseIcon = false
                                                              },
                                                            mouseout: function (
                                                              $event
                                                            ) {
                                                              _vm.isColorCloseIcon = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.form
                                                            .originatorName ===
                                                          null
                                                            ? [
                                                                _c("Icon", {
                                                                  staticClass:
                                                                    "head-icon border-radius-20",
                                                                  attrs: {
                                                                    "icon-class":
                                                                      "icon-kong-touxiang",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "head-icon-label",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "待认领"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : [
                                                                _c("el-image", {
                                                                  staticClass:
                                                                    "avatar-img pointer border-radius-20",
                                                                  attrs: {
                                                                    fit: "cover",
                                                                    src:
                                                                      _vm.form
                                                                        .originatorAvatar ||
                                                                      require("@/assets/imgs/common/advar.png"),
                                                                    alt: "",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "m-l-10 pointer username",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "wx-open-data",
                                                                      {
                                                                        attrs: {
                                                                          id: _vm
                                                                            .form
                                                                            .originatorName,
                                                                          "name-data":
                                                                            {
                                                                              name: _vm
                                                                                .form
                                                                                .originatorName,
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.form
                                                                  .originatorName !==
                                                                  null &&
                                                                !_vm.isOriginatorDisabledEdit &&
                                                                _vm.form
                                                                  .submitterType !==
                                                                  1 &&
                                                                _vm.form
                                                                  .isDel !==
                                                                  2 &&
                                                                _vm.form
                                                                  .isClose !== 1
                                                                  ? _c("Icon", {
                                                                      staticClass:
                                                                        "close-icon",
                                                                      attrs: {
                                                                        "icon-class":
                                                                          _vm.isColorCloseIcon
                                                                            ? ""
                                                                            : "icon-a-Group719-1",
                                                                      },
                                                                      nativeOn:
                                                                        {
                                                                          mouseenter:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.originatorHover = true
                                                                            },
                                                                          mouseleave:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.originatorHover = false
                                                                            },
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.clearToriginatorPicker(
                                                                                fieldItem
                                                                              )
                                                                            },
                                                                        },
                                                                    })
                                                                  : _vm._e(),
                                                              ],
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      fieldItem.fieldType == "involves"
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-1 charge-user-list stopdoing-charge-user-list",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "stopdoing-charge-user-list-div",
                                                  },
                                                  _vm._l(
                                                    _vm.chargeUserListInit,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        { key: item.id },
                                                        [
                                                          item
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  key: item.id,
                                                                  staticClass:
                                                                    "charge-avatar-box",
                                                                  class:
                                                                    _vm.isDisabledEdit
                                                                      ? "to-edit-input-not"
                                                                      : "to-edit-input-allow",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-image",
                                                                    {
                                                                      staticClass:
                                                                        "avatar-img border-radius-20",
                                                                      attrs: {
                                                                        fit: "cover",
                                                                        src:
                                                                          item.avatar ||
                                                                          require("@/assets/imgs/common/advar.png"),
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "icon-error-span",
                                                                    },
                                                                    [
                                                                      !_vm.isDisabledEdit
                                                                        ? _c(
                                                                            "Icon",
                                                                            {
                                                                              staticClass:
                                                                                "icon-error",
                                                                              attrs:
                                                                                {
                                                                                  "icon-class":
                                                                                    "icon-a-Group719-1",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.chargeUserDelete(
                                                                                      item.id,
                                                                                      fieldItem
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      "open-delay": 300,
                                                      content:
                                                        _vm.isDisabledEdit
                                                          ? "您不是此任务的负责人或协作人和审批中的任务，无法操作哦"
                                                          : "点击添加协作人",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "user-picker-checked",
                                                      {
                                                        attrs: {
                                                          "select-people-list":
                                                            _vm.chargeUserListNew,
                                                          checked: true,
                                                          "field-item":
                                                            fieldItem,
                                                          length: 40,
                                                          disabled:
                                                            _vm.isDisabledEdit,
                                                        },
                                                        on: {
                                                          pick: _vm.chargeUserPicker,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "picker-checked-div",
                                                          },
                                                          [
                                                            _c("Icon", {
                                                              staticClass:
                                                                "cooperation-add",
                                                              class:
                                                                _vm.isDisabledEdit
                                                                  ? "cooperation-add-disbled"
                                                                  : "",
                                                              attrs: {
                                                                "icon-class":
                                                                  "icon-tianjia-xuxian",
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "picker-text",
                                                                class:
                                                                  _vm
                                                                    .chargeUserListInit
                                                                    .length > 0
                                                                    ? "picker-text-you"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .chargeUserListInit
                                                                        .length >
                                                                        0
                                                                        ? "参与协作"
                                                                        : "添加协作人"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      fieldItem.fieldType == "endDate"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "stopdoing-time",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.endPicker(
                                                    fieldItem
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "endData-div",
                                                  class: _vm.isDisabledEdit
                                                    ? "endData-div-disabled"
                                                    : "endData-div-" +
                                                      _vm.endDateHover,
                                                },
                                                [
                                                  _c("Icon", {
                                                    staticClass: "svg-icons",
                                                    attrs: {
                                                      "icon-class":
                                                        "icon-taskcalendars",
                                                    },
                                                  }),
                                                  _c("set-date-time-detail", {
                                                    attrs: {
                                                      type: "edit",
                                                      row: _vm.form,
                                                      source: "stopdoing",
                                                      "field-item": fieldItem,
                                                      disabled:
                                                        _vm.isDisabledEdit,
                                                    },
                                                    on: {
                                                      refresh:
                                                        _vm.needRefreshState,
                                                      change:
                                                        _vm.dateTimeChange,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      fieldItem.fieldType == "remindDate"
                                        ? _c(
                                            "div",
                                            {
                                              class: _vm.isDisabledEdit
                                                ? "stopdoing-remind-time-disabled"
                                                : "",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "stopdoing-remind-time",
                                                  class:
                                                    _vm.form.remindDate &&
                                                    _vm.form.remindDate.dateType
                                                      ? "stopdoing-remind-time-you"
                                                      : "",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.remindTimeClick.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("Icon", {
                                                    staticClass: "remind-icon",
                                                    attrs: {
                                                      "icon-class":
                                                        "icon-naoling",
                                                    },
                                                  }),
                                                  _vm.form.remindDate &&
                                                  _vm.form.remindDate.dateType
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "remind-time",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.remindDateFmt()
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "placeholder",
                                                        },
                                                        [_vm._v("设置提醒")]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      fieldItem.fieldType == "remindDate"
                                        ? _c(
                                            "div",
                                            {
                                              class: _vm.isDisabledEdit
                                                ? "stopdoing-remind-time-disabled"
                                                : "",
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    "open-delay": 300,
                                                    content: "设置重复",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "stopdoing-remind-time",
                                                    },
                                                    [
                                                      _c("task-cycle-detail", {
                                                        attrs: {
                                                          id:
                                                            _vm.form
                                                              .cycleDate &&
                                                            _vm.form.cycleDate
                                                              .id
                                                              ? _vm.form
                                                                  .cycleDate.id
                                                              : "",
                                                          "task-id":
                                                            _vm.form.id,
                                                          row: _vm.form,
                                                          "is-show": true,
                                                          disabled:
                                                            _vm.isDisabledEdit,
                                                        },
                                                        on: {
                                                          save: _vm.cycleSave,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      fieldItem.fieldType == "priority"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "stopdoing-priority",
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    "open-delay": 300,
                                                    content: _vm.isDisabledEdit
                                                      ? "没有权限编辑"
                                                      : "点击编辑优先级",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      ref: "taskPrioritySelect",
                                                      refInFor: true,
                                                      staticClass:
                                                        "task-detail-priority-box",
                                                      style:
                                                        "color: " +
                                                        _vm.priorityInfo
                                                          .colour +
                                                        " !important",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isDisabledEdit,
                                                        placeholder:
                                                          "请选择优先级",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.priorityChange(
                                                            fieldItem,
                                                            _vm.priorityVal
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.priorityVal,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.priorityVal = $$v
                                                        },
                                                        expression:
                                                          "priorityVal",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "svg-icons-div",
                                                          style:
                                                            "color: " +
                                                            _vm.priorityInfo
                                                              .colour +
                                                            " !important",
                                                          attrs: {
                                                            slot: "prefix",
                                                          },
                                                          slot: "prefix",
                                                        },
                                                        [
                                                          _c("Icon", {
                                                            staticClass:
                                                              "svg-icons",
                                                            attrs: {
                                                              "icon-class":
                                                                "icon-youxianji2",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._l(
                                                        _vm.priorityDictionaryList,
                                                        function (item, key) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: key,
                                                              class: key,
                                                              attrs: {
                                                                label:
                                                                  item.value,
                                                                value:
                                                                  item.value,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  style:
                                                                    "color: " +
                                                                    (_vm
                                                                      .priorityDictionaryList[
                                                                      key
                                                                    ].colour ||
                                                                      ""),
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.value
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      fieldItem.fieldType == "tag"
                                        ? _c(
                                            "div",
                                            { staticClass: "stopdoing-tag" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    "open-delay": 300,
                                                    content: _vm.isDisabledEdit
                                                      ? "没有权限编辑"
                                                      : "点击编辑标签",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "flex-1" },
                                                    [
                                                      _c("TaskLabel", {
                                                        attrs: {
                                                          "label-options":
                                                            _vm.labelData,
                                                          "select-options":
                                                            _vm.form.labelArray,
                                                          "field-item":
                                                            fieldItem,
                                                          disabled:
                                                            _vm.isDisabledEdit,
                                                          source: "stopdoing",
                                                        },
                                                        on: {
                                                          save: _vm.saveLabels,
                                                          update:
                                                            _vm.updateTaskLabel,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      fieldItem.fieldType == "note"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "stopdoing-note",
                                              class: _vm.editorClick
                                                ? "stopdoing-note-border"
                                                : "",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-1 top-item-box disc-right",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "total_edit",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "edit-con",
                                                          class:
                                                            "editState" +
                                                            _vm.editorFocus,
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                content:
                                                                  _vm.describeContent,
                                                                placement:
                                                                  "top",
                                                                "open-delay":
                                                                  "300",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "content-fixed",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            !_vm.editorClick,
                                                                          expression:
                                                                            "!editorClick",
                                                                        },
                                                                      ],
                                                                    ref: "htmlContent",
                                                                    refInFor: true,
                                                                    class:
                                                                      _vm.isDisabledEdit
                                                                        ? "htmlContent htmlContentNo"
                                                                        : "htmlContent",
                                                                    attrs: {
                                                                      id: "htmlContent",
                                                                    },
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          _vm.filtersHtml(
                                                                            _vm
                                                                              .form
                                                                              .information
                                                                          )
                                                                            ? _vm
                                                                                .form
                                                                                .information
                                                                            : "请添加描述说明"
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ContentClick.apply(
                                                                            null,
                                                                            arguments
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                  !_vm.editorClick &&
                                                                  _vm.isShowMore
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "show-more",
                                                                          on: {
                                                                            click:
                                                                              _vm.showMore,
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.isEditorShow
                                                                                ? "收起描述"
                                                                                : "显示更多"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c("div", {
                                                                    attrs: {
                                                                      id: "showMore",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "transition",
                                                            {
                                                              attrs: {
                                                                name: "bottom-slide",
                                                                mode: "out-in",
                                                              },
                                                            },
                                                            [
                                                              _vm.editorClick
                                                                ? _c("Editor", {
                                                                    attrs: {
                                                                      height: 260,
                                                                      width: 700,
                                                                    },
                                                                    on: {
                                                                      onFocus:
                                                                        _vm.EditorFocus,
                                                                      onBlur:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.EditorBlur(
                                                                            arguments,
                                                                            fieldItem
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .information,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "information",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.information",
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "transition",
                                                            {
                                                              attrs: {
                                                                name: "bottom-slide",
                                                                mode: "out-in",
                                                              },
                                                            },
                                                            [
                                                              _vm.editorFocus &&
                                                              !_vm.isDisabledEdit
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "editor-Btn",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cancel btn",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.editorChange(
                                                                                  1,
                                                                                  fieldItem
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "取消"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "confirm btn",
                                                                          attrs:
                                                                            {
                                                                              id: "saveEditor",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.editorChange(
                                                                                  2,
                                                                                  fieldItem
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "确定"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", { staticClass: "task-form" }, [
                          _vm.form.fatherTid == 0
                            ? _c("div", { staticClass: "task-items" }, [
                                _c("div", { staticClass: "top-item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "top-item-describe" },
                                    [
                                      _c("Icon", {
                                        staticClass: "svg-icons",
                                        attrs: {
                                          "icon-class": "icon-tasksubicon",
                                        },
                                      }),
                                      _c("div", { staticClass: "text-box" }, [
                                        _vm._v(
                                          "子任务 (" +
                                            _vm._s(_vm.subTask.length) +
                                            ")"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "flex-1" }),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "top-items sub-add-wrap" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex-1 son-flex-1" },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass: "sub-ul",
                                            staticStyle: { width: "100%" },
                                          },
                                          _vm._l(
                                            _vm.subTask,
                                            function (item, index) {
                                              return _c(
                                                "li",
                                                {
                                                  key: index + Math.random(),
                                                  staticClass: "flex-new",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.todoSubTask(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        "open-delay": 300,
                                                        content:
                                                          _vm.isDisabledEdit
                                                            ? "您不是此任务的负责人或协作人和审批中的任务，无法操作哦"
                                                            : "点击编辑任务状态",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            height: "36px",
                                                            display: "flex",
                                                            "justify-content":
                                                              "center",
                                                            "align-items":
                                                              "center",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("state-picker", {
                                                            attrs: {
                                                              type: 3,
                                                              status:
                                                                item.status,
                                                              item: item,
                                                              disabled:
                                                                _vm.isDisabledEdit ||
                                                                item.submitterType ===
                                                                  1 ||
                                                                _vm.form
                                                                  .isDel ===
                                                                  2 ||
                                                                _vm.form
                                                                  .isClose ===
                                                                  1,
                                                            },
                                                            on: {
                                                              changeStatus:
                                                                _vm.subTaskStatus,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-1 sub-li-content",
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-new sub-right",
                                                    },
                                                    [
                                                      _c(
                                                        "transition",
                                                        {
                                                          attrs: {
                                                            name: "bottom-slide",
                                                            mode: "out-in",
                                                          },
                                                        },
                                                        [
                                                          item.startDateString ||
                                                          item.endDateString
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "sub-right-icon-time-txt",
                                                                },
                                                                [
                                                                  item.showDates ===
                                                                  2
                                                                    ? [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.endDateString
                                                                          ) +
                                                                            " 截止"
                                                                        ),
                                                                      ]
                                                                    : item.showDates ===
                                                                      3
                                                                    ? [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.startDateString
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        item.startDateString &&
                                                                        item.endDateString
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "-"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.endDateString
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    : item.showDates ===
                                                                      1
                                                                    ? [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.startDateString
                                                                          ) +
                                                                            " 开始"
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                ],
                                                                2
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sub-right-icon",
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticClass:
                                                              "avatar-img border-radius-20",
                                                            attrs: {
                                                              fit: "cover",
                                                              src:
                                                                item.originatorAvatar ||
                                                                require("@/assets/imgs/pm/head-icon.png"),
                                                              alt: "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sub-right-icon",
                                                          staticStyle: {
                                                            "margin-right": "0",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.deleteSub(
                                                                item,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                "popper-class":
                                                                  "sub-right-icon-del",
                                                                placement:
                                                                  "top-start",
                                                                trigger:
                                                                  "hover",
                                                                effect: "dark",
                                                                content:
                                                                  (_vm.isLeader ||
                                                                    _vm.isCreate) &&
                                                                  item.submitterType !==
                                                                    1 &&
                                                                  _vm.form
                                                                    .isDel !==
                                                                    2 &&
                                                                  _vm.form
                                                                    .isClose !==
                                                                    1
                                                                    ? "点击删除子任务"
                                                                    : "只有任务负责人非审批中才可以删除子任务哦",
                                                              },
                                                            },
                                                            [
                                                              _c("Icon", {
                                                                staticClass:
                                                                  "son-delete-icon",
                                                                class:
                                                                  (_vm.isLeader ||
                                                                    _vm.isCreate) &&
                                                                  item.submitterType !==
                                                                    1 &&
                                                                  _vm.form
                                                                    .isDel !==
                                                                    2 &&
                                                                  _vm.form
                                                                    .isClose !==
                                                                    1
                                                                    ? "to-edit-input-allow"
                                                                    : "to-edit-input-not",
                                                                attrs: {
                                                                  "icon-class":
                                                                    "icon-shanchu1",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "transition",
                                      {
                                        attrs: {
                                          name: "bottom-slide",
                                          mode: "out-in",
                                        },
                                      },
                                      [
                                        _vm.subTaskVisible
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "sub-add-div flex-new",
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "flex-1",
                                                  attrs: {
                                                    placeholder:
                                                      "请填写子任务标题",
                                                  },
                                                  on: {
                                                    input: _vm.sub_objInput,
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.taskAddViewSubmit.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.sub_obj.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.sub_obj,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "sub_obj.title",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex-new sub-right",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sub-right-icon-time",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                          position: "relative",
                                                        },
                                                      },
                                                      [
                                                        _c("el-date-picker", {
                                                          staticClass:
                                                            "sub-task-date-box",
                                                          attrs: {
                                                            format:
                                                              "yyyy-MM-dd HH:mm",
                                                            "value-format":
                                                              "yyyy-MM-dd HH:mm",
                                                            type: "datetimerange",
                                                            "default-time":
                                                              _vm.defaultTime,
                                                            "start-placeholder":
                                                              "开始日期",
                                                            "end-placeholder":
                                                              "截止日期",
                                                            "popper-class":
                                                              "addTimeClass",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.sub_obj
                                                                .startTime,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.sub_obj,
                                                                "startTime",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "sub_obj.startTime",
                                                          },
                                                        }),
                                                        _c("Icon", {
                                                          staticClass:
                                                            "time-set-icon",
                                                          style:
                                                            "color: " +
                                                            (_vm.sub_obj
                                                              .startTime &&
                                                            _vm.sub_obj
                                                              .startTime
                                                              .length > 0
                                                              ? "#FF9900"
                                                              : "#A0A3BD"),
                                                          attrs: {
                                                            "icon-class":
                                                              "icon-taskcalendars",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sub-right-icon",
                                                        staticStyle: {
                                                          position: "relative",
                                                          "margin-right": "0px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              content:
                                                                "点击编辑任务负责人",
                                                              disabled:
                                                                _vm.sub_obj
                                                                  .submitterType ===
                                                                  1 ||
                                                                _vm.sub_obj
                                                                  .isDel ===
                                                                  2 ||
                                                                _vm.sub_obj
                                                                  .isClose ===
                                                                  1,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "user-picker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.sub_obj
                                                                      .submitterType ===
                                                                      1 ||
                                                                    _vm.sub_obj
                                                                      .isDel ===
                                                                      2 ||
                                                                    _vm.sub_obj
                                                                      .isClose ===
                                                                      1,
                                                                },
                                                                on: {
                                                                  pick: _vm.subToriginatorPicker,
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm.sub_obj
                                                                  .originatorAvatar
                                                                  ? _c(
                                                                      "el-image",
                                                                      {
                                                                        staticClass:
                                                                          "avatar-img pointer border-radius-20",
                                                                        attrs: {
                                                                          fit: "cover",
                                                                          src: _vm
                                                                            .sub_obj
                                                                            .originatorAvatar,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "el-image",
                                                                      {
                                                                        staticClass:
                                                                          "avatar-img pointer border-radius-20",
                                                                        attrs: {
                                                                          fit: "cover",
                                                                          src: _vm.imgConst
                                                                            ? _vm
                                                                                .imgConst
                                                                                .logo
                                                                            : _vm
                                                                                .userInfo
                                                                                .avatar,
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "transition",
                                      {
                                        attrs: {
                                          name: "bottom-slide",
                                          mode: "out-in",
                                        },
                                      },
                                      [
                                        _vm.subTaskVisible
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "sub-add-div flex-new",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "guid_btns",
                                                    on: {
                                                      click: _vm.closedialog,
                                                    },
                                                  },
                                                  [_vm._v("取消")]
                                                ),
                                                _c(
                                                  "L-button",
                                                  {
                                                    staticClass:
                                                      "guid_btns common_btn_next_able sub-add-complete",
                                                    attrs: {
                                                      loading:
                                                        _vm.taskAddLoading,
                                                    },
                                                    on: {
                                                      clickEvt:
                                                        _vm.taskAddViewSubmit,
                                                    },
                                                  },
                                                  [_vm._v("完成")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    !_vm.subTaskVisible
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              "open-delay": 300,
                                              content: _vm.isDisabledEdit
                                                ? "您不是此任务的负责人或协作人和审批中的任务，无法操作哦"
                                                : _vm.form.status === 3
                                                ? "主任务已完成"
                                                : "添加子任务",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "sub-add",
                                                class: _vm.isDisabledEdit
                                                  ? "to-edit-input-not"
                                                  : _vm.form.status === 3
                                                  ? "to-edit-input-not-add"
                                                  : "to-edit-input-allow",
                                                staticStyle: {
                                                  color: "#3a78f6",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.addSubTaskVisible_.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("Icon", {
                                                  staticClass: "aub-add-icon",
                                                  attrs: {
                                                    "icon-class":
                                                      "icon-objective_map_blow",
                                                  },
                                                }),
                                                _vm._v("添加子任务 "),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                    _c(
                      "div",
                      { staticClass: "task-dynamic" },
                      [
                        _c("DynamicContainer", {
                          ref: "dynamic",
                          attrs: {
                            "task-id": _vm.taskId,
                            "progress-list": _vm.progressList,
                            "task-detail": _vm.form,
                          },
                          on: { approvalRefresh: _vm.getApprovalProcessRecord },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "task-comment",
                    staticStyle: { border: "1px #f7f7fc solid" },
                  },
                  [
                    _c("comment-add", {
                      ref: "commentAdd",
                      attrs: {
                        "task-id": _vm.taskId,
                        "taskdetail-boolean": false,
                        "line-feed": true,
                      },
                      on: { save: _vm.commentSave },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm.closePermissionsStatus
        ? _c("set-permissions", {
            attrs: {
              visible: _vm.closePermissionsStatus,
              title: "编辑任务可见范围",
              "permissions-type": "0",
              "permission-id": _vm.form.id,
              collect_show_object: _vm.form,
            },
            on: {
              closePermissions: _vm.closePermissions,
              submitPermissions: _vm.submitPermissions,
            },
          })
        : _vm._e(),
      false
        ? _c(
            "div",
            { staticClass: "go-back-block", on: { click: _vm.goTop } },
            [_c("i", { staticClass: "el-icon-upload2 avatar-img" })]
          )
        : _vm._e(),
      _vm.gobottomStatus == true
        ? _c(
            "div",
            {
              staticClass: "go-back-block-arrow",
              on: { click: _vm.goStatusBottom },
            },
            [
              _c("p", { staticClass: "back-block-arrow" }, [_vm._v("动态")]),
              _c("i", { staticClass: "el-icon-arrow-down avatar-img_arrow" }),
            ]
          )
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.remindTimeVisible
            ? _c("task-remind-time-detail", {
                attrs: {
                  id:
                    _vm.form.remindDate && _vm.form.remindDate.id
                      ? _vm.form.remindDate.id
                      : "",
                  visible: _vm.remindTimeVisible,
                  "task-id": _vm.form.id,
                  row: _vm.form,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.remindTimeVisible = $event
                  },
                  save: _vm.remindTimeSave,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isShowSetWorkHours
        ? _c("SetWorkHours", {
            attrs: {
              "edit-data": _vm.editData,
              title: _vm.setWorkHoursTitle,
              form: _vm.form,
              source: "task",
              type: _vm.setWorkHoursType,
              disabled: _vm.isDisabledEdit,
            },
            model: {
              value: _vm.isShowSetWorkHours,
              callback: function ($$v) {
                _vm.isShowSetWorkHours = $$v
              },
              expression: "isShowSetWorkHours",
            },
          })
        : _vm._e(),
      _vm.isShowWorkHoursStatic
        ? _c("WorkHoursStatic", {
            ref: "WorkHoursStatic",
            attrs: { "task-id": _vm.taskId, disabled: _vm.isDisabledEdit },
            model: {
              value: _vm.isShowWorkHoursStatic,
              callback: function ($$v) {
                _vm.isShowWorkHoursStatic = $$v
              },
              expression: "isShowWorkHoursStatic",
            },
          })
        : _vm._e(),
      _vm.isShowWorkHoursDetailed
        ? _c("WorkHoursDetailed", {
            ref: "WorkHoursDetailed",
            attrs: {
              "task-id": _vm.taskId,
              source: "task",
              disabled: _vm.isDisabledEdit,
            },
            model: {
              value: _vm.isShowWorkHoursDetailed,
              callback: function ($$v) {
                _vm.isShowWorkHoursDetailed = $$v
              },
              expression: "isShowWorkHoursDetailed",
            },
          })
        : _vm._e(),
      _vm.transformToSonTaskVisible
        ? _c("transformToSonTaskDialog", {
            model: {
              value: _vm.transformToSonTaskVisible,
              callback: function ($$v) {
                _vm.transformToSonTaskVisible = $$v
              },
              expression: "transformToSonTaskVisible",
            },
          })
        : _vm._e(),
      _c("copy-task", {
        attrs: { fromType: "taskDetail", currentTaskList: [_vm.form] },
        model: {
          value: _vm.copyTaskVisible,
          callback: function ($$v) {
            _vm.copyTaskVisible = $$v
          },
          expression: "copyTaskVisible",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }