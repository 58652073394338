var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select" },
    [
      _vm.type === 1
        ? _c(
            "div",
            { staticClass: "one" },
            [
              _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
              _c(
                "el-select",
                {
                  ref: "selectChangeRef",
                  attrs: {
                    filterable: _vm.isFilterable,
                    multiple: _vm.isMultiple,
                    placeholder: "请选择",
                  },
                  on: {
                    change: _vm.selectedChange,
                    "visible-change": _vm.selectedVisibleChange,
                  },
                  model: {
                    value: _vm.val,
                    callback: function ($$v) {
                      _vm.val = $$v
                    },
                    expression: "val",
                  },
                },
                [
                  _vm._l(_vm.data, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { float: "left" } },
                          [
                            _vm.label === "部门"
                              ? [
                                  _c("wx-open-data", {
                                    attrs: {
                                      type: 2,
                                      id: item.name,
                                      nameData: { name: item.name },
                                    },
                                  }),
                                ]
                              : [_vm._v(_vm._s(item.name))],
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  _vm.label === "部门"
                    ? _vm._t(
                        "default",
                        function () {
                          return [
                            _c("wx-open-data", {
                              attrs: {
                                type: 2,
                                id: _vm.data[_vm.departIndex].name,
                                nameData: {
                                  name: _vm.data[_vm.departIndex].name,
                                },
                              },
                            }),
                          ]
                        },
                        { slot: "prefix" }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.type === 2
        ? _c("div", { staticClass: "two" }, [
            _c(
              "div",
              { staticClass: "one" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.groupName)),
                ]),
                _c(
                  "el-select",
                  {
                    attrs: { multiple: _vm.isMultiple, placeholder: "请选择" },
                    on: { change: _vm.selectedChange },
                    model: {
                      value: _vm.cascadeA,
                      callback: function ($$v) {
                        _vm.cascadeA = $$v
                      },
                      expression: "cascadeA",
                    },
                  },
                  _vm._l(_vm.cascadeDataA, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      },
                      [
                        _c("span", { staticStyle: { float: "left" } }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "one" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.itemName)),
                ]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: _vm.isFilterable,
                      multiple: _vm.isMultiple,
                      placeholder: "请选择",
                    },
                    on: { change: _vm.selectedChildChange },
                    model: {
                      value: _vm.cascadeB,
                      callback: function ($$v) {
                        _vm.cascadeB = $$v
                      },
                      expression: "cascadeB",
                    },
                  },
                  _vm._l(_vm.cascadeDataB, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      },
                      [
                        _c("span", { staticStyle: { float: "left" } }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.type === 3
        ? _c("div", { staticClass: "three" }, [
            _c("div", { staticClass: "type-tips" }, [
              _vm._v("按部门或个人查看"),
            ]),
            _c(
              "div",
              { staticClass: "one" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "0" },
                    on: { change: _vm.selectType },
                    model: {
                      value: _vm.selectedType,
                      callback: function ($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType",
                    },
                  },
                  [_vm._v("部门")]
                ),
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      multiple: _vm.isMultiple,
                      placeholder: "请选择",
                      disabled: _vm.selectedType === "1" ? true : false,
                    },
                    on: { change: _vm.selectedChange },
                    model: {
                      value: _vm.partVal,
                      callback: function ($$v) {
                        _vm.partVal = $$v
                      },
                      expression: "partVal",
                    },
                  },
                  _vm._l(_vm.cascadeDataA, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      },
                      [
                        _c("span", { staticStyle: { float: "left" } }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "one" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    on: { change: _vm.selectType },
                    model: {
                      value: _vm.selectedType,
                      callback: function ($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType",
                    },
                  },
                  [_vm._v("个人")]
                ),
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      disabled: _vm.selectedType === "0" ? true : false,
                      multiple: _vm.isMultiple,
                      placeholder: "请选择",
                    },
                    on: { change: _vm.selectedChildChange },
                    model: {
                      value: _vm.personalVal,
                      callback: function ($$v) {
                        _vm.personalVal = $$v
                      },
                      expression: "personalVal",
                    },
                  },
                  _vm._l(_vm.cascadeDataB, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: item.id,
                        attrs: { label: item.employee, value: item.id },
                      },
                      [
                        _c("span", { staticStyle: { float: "left" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.employee.length > 4
                                  ? item.employee.substring(0, 4) + "..."
                                  : item.employee
                              ) +
                              " "
                          ),
                        ]),
                        _c("span", { staticStyle: { float: "right" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.part.length > 4
                                  ? item.part.substring(0, 4) + "..."
                                  : item.part
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.type === 4 && _vm.getMajorCycleVal
          ? _c("div", { staticClass: "cycle" }, [
              _c(
                "div",
                { staticClass: "one" },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("周期:")]),
                  _c(
                    "el-select",
                    {
                      ref: "getMajorCycleRef",
                      attrs: {
                        multiple: _vm.isMultiple,
                        placeholder: "请选择",
                      },
                      on: {
                        change: _vm.getMajorCycle,
                        "visible-change": _vm.getMajorCycleVisible,
                      },
                      model: {
                        value: _vm.majorCycle,
                        callback: function ($$v) {
                          _vm.majorCycle = $$v
                        },
                        expression: "majorCycle",
                      },
                    },
                    _vm._l(_vm.majorCycleList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "one" },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.itemName)),
                  ]),
                  _c(
                    "el-select",
                    {
                      ref: "getMinorCycleRef",
                      attrs: {
                        filterable: _vm.isFilterable,
                        multiple: _vm.isMultiple,
                        placeholder: "请选择",
                      },
                      on: {
                        change: _vm.getMinorCycle,
                        "visible-change": _vm.getMinorCycleVisible,
                      },
                      model: {
                        value: _vm.minorCycle,
                        callback: function ($$v) {
                          _vm.minorCycle = $$v
                        },
                        expression: "minorCycle",
                      },
                    },
                    _vm._l(_vm.minorCycleList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm.type === 5
        ? _c("div", { staticClass: "cycle" }, [
            _c(
              "div",
              { staticClass: "one" },
              [
                _c("span", { staticClass: "label" }, [_vm._v("周期")]),
                _c(
                  "el-select",
                  {
                    attrs: { multiple: _vm.isMultiple, placeholder: "请选择" },
                    on: { change: _vm.getMajorCycle },
                    model: {
                      value: _vm.majorCycle,
                      callback: function ($$v) {
                        _vm.majorCycle = $$v
                      },
                      expression: "majorCycle",
                    },
                  },
                  _vm._l(_vm.majorCycleList, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      },
                      [
                        _c("span", { staticStyle: { float: "left" } }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }