<template>
  <span>
    <el-popover
      trigger="hover"
      :disabled="adviceDisable"
      :open-delay="300"
      popper-class="advice-pop"
      :placement="placement"
    >
      <div class="advice-content">
        <div class="advice-head">
          <div class="head-title">填写建议</div>
          <div class="ignore-btn" @click="ignoreAdvice">忽略</div>
        </div>
        <div v-for="(item, index) in advices" :key="item.id" class="advice-item">
          <span v-show="advices.length > 1">{{ index }}、</span>
          {{ item.descriptionPrompt }}
        </div>
      </div>
      <span slot="reference" :class="adviceDisable ? '' : 'content-html'" v-if="listType">
        <template v-for="item in html">
          <template v-if="item.type === 'html'">
            <span v-html="item.html"></span>
          </template>
          <template v-else>
            <!-- 多对齐修改 -->
            <align-member-card
              :userInfoList="{
                userId: item.userId,
                name: item.userName,
                avatar: item.userAvatar,
                jobTitle: item.jobTitle,
                departName: item.departName,
                departNameAll: item.departNameAll,
                departUserList: item.departUserList
              }"
              :is-okr="item.isAlign !== 0&&isOkr"
              :is-align="item.isAlign !== 0"
              :targetAligningList="item.list"
              :objectivesAssociationList="item.objectivesAssociationList"
              :businessType="businessType"
              :dataDetail="dataDetail"
              alignType="childAlign"
              @okrDetail="getTopMembersAlignmentSelect"
              @toOKRList="getTopMembersFatherList({
                userId: item.userId,
                userName: item.userName,
                userAvatar: item.userAvatar
              })"
            >
              <span v-html="item.html"></span>
            </align-member-card>
          </template>
        </template>
      </span>
      <span slot="reference" :class="adviceDisable ? '' : 'content-html'" v-html="html" v-else></span>
    </el-popover>
  </span>
</template>
<script>
export default {
  inject: ['writeRules'],
  props: {
    html: {
    },
    // 匹配信息
    dictInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    listType: { // html数据类型 true:数组 false：字符串
      default: false
    },
    isOkr: {
      type: Boolean,
      default: false
    },
    businessType: {
      default: 1
    },
    dataDetail:{}
  },
  data() {
    return {
      adviceDisable: false,
      advices: []
    }
  },
  watch: {
    dictInfo: {
      handler(val) {
        if (!val || !this.show || !val.writeRuleId) {
          this.adviceDisable = true
        } else {
          this.adviceDisable = false
          this.queryAdvice()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getTopMembersAlignmentSelect(data) {
      this.$emit('okrDetail', data)
    },
    getTopMembersFatherList(data) {
      this.$emit('toOKRList', data)
    },
    // 匹配建议
    queryAdvice() {
      this.advices = []
      const rules = this.writeRules()
      rules.forEach((item) => {
        if (
          item.businessType === this.dictInfo.businessType &&
          item.id === this.dictInfo.writeRuleId
        ) {
          this.advices.push(item)
        }
      })
    },
    // 忽略
    ignoreAdvice() {
      const params = {
        businessType: this.dictInfo.businessType, // 业务类型：1：o ，2:kr
        cycleInfoId: this.dictInfo.cycleInfoId,
        objId: this.dictInfo.objId,
        keyId: this.dictInfo.keyId,
        writeRuleId: this.dictInfo.writeRuleId
      }
      this.$apis.OKRIGNOREWRITERULE(params).then((res) => {
        if (res && res.status === 200) {
          this.$emit('ignore')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content-html {
  position: relative;
  padding-top: 2px;
  // border-bottom: 1px solid #ff9900;
  &:hover {
    background: #fdf1df;
    ::v-deep {
      span {
        background: transparent !important;
      }
    }
  }
}
.content-html::before {
  content: '';
  position: absolute;
  bottom: -3px;
  width: 100%;
  height: 0.3em;

  background: linear-gradient(
      135deg,
      transparent,
      transparent 45%,
      #ff9900,
      transparent 55%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent,
      transparent 45%,
      #ff9900,
      transparent 55%,
      transparent 100%
    );
  background-size: 0.6em 0.6em;
  background-repeat: repeat-x, repeat-x;
}
</style>
<style lang="scss">
.advice-pop {
  width: 450px;
  height: auto;
  padding: 24px;
  box-shadow: 0px 12px 56px 0px rgba(17, 17, 17, 0.1);
  .advice-content {
    .advice-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .head-title {
        color: #444558;
        font-weight: 700;
        font-size: 16px;
      }
      .ignore-btn {
        color: #ff9900;
        cursor: pointer;
        font-size: 12px;
        &:hover {
          opacity: 0.54;
        }
      }
    }
    .advice-item {
      width: 400px;
      color: #444558;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 4px;
    }
  }
}
</style>
