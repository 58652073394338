var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            size: 800,
            title: "智能推进详情",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "title-list-wrap",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", { staticClass: "title-name" }, [
                _vm._v("智能推进详情"),
              ]),
              _c(
                "div",
                { staticClass: "title-edit" },
                [
                  _vm.type === 0 && _vm.userInfo.id === _vm.okrData.createUserid
                    ? _c("Icon", {
                        staticClass: "icon-edit",
                        attrs: { "icon-class": "icon-edit-simple" },
                        on: {
                          click: function ($event) {
                            return _vm.progressSetClick()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "vueloading",
                  rawName: "v-vueloading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "brife-detail",
            },
            [
              _c(
                "div",
                { staticClass: "auto-type" },
                [
                  _vm.type == 0
                    ? [
                        _c("div", { staticClass: "type" }, [_vm._v("KR")]),
                        _c("div", {
                          staticClass: "content",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.changeToHtml(_vm.item.title)
                            ),
                          },
                        }),
                      ]
                    : [
                        _c("div", { staticClass: "type" }, [_vm._v("O")]),
                        _c("div", {
                          staticClass: "content",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.changeToHtml(_vm.okrData.content)
                            ),
                          },
                        }),
                      ],
                ],
                2
              ),
              _c("div", { staticClass: "main-okr" }, [
                _c("div", { staticClass: "detail-content-wrap" }, [
                  _vm.data.advanceTo.length > 0
                    ? _c(
                        "div",
                        { staticClass: "progress-list-wrap" },
                        [
                          _c("div", { staticClass: "progress-type" }, [
                            _c(
                              "div",
                              { staticClass: "type-icon" },
                              [
                                _c("Icon", {
                                  attrs: {
                                    "icon-class": "icon-xiangxiatuidong",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "name" },
                              [
                                _c("wx-open-data", {
                                  attrs: {
                                    id: _vm.okrData.userName,
                                    nameData: { name: _vm.okrData.userName },
                                  },
                                }),
                                _vm._v(
                                  " 推进其他同事*" +
                                    _vm._s(_vm.data.advanceTo.length) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "progress-list-content" }, [
                            _c("div", { staticClass: "progress-list" }, [
                              _c("div", {
                                staticClass: "infor-list",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.listData(4, _vm.data.advanceTo)
                                  ),
                                },
                              }),
                            ]),
                          ]),
                          _vm._l(
                            _vm.data.advanceTo,
                            function (listItem, listIndex) {
                              return _c(
                                "div",
                                {
                                  key: listIndex,
                                  staticClass: "detail-list-wrap",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "detail-title" },
                                    [
                                      _c("el-image", {
                                        attrs: { src: listItem[0].userAvatar },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "name" },
                                        [
                                          _c("wx-open-data", {
                                            attrs: {
                                              id: listItem[0].userName,
                                              nameData: {
                                                name: listItem[0].userName,
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._l(listItem, function (keyItem, index) {
                                    return _c(
                                      "div",
                                      { key: keyItem.id },
                                      [
                                        _c("okrItem", {
                                          attrs: {
                                            okrData: _vm.okrData,
                                            item: _vm.item,
                                            keyItem: keyItem,
                                            type: _vm.type,
                                            index: index,
                                            unit: _vm.okrItemUnit,
                                            itemType: "advanceTo",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.data.advanceFrom.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "progress-list-wrap progress-list-span-none",
                        },
                        [
                          _c("div", { staticClass: "progress-type" }, [
                            _c(
                              "span",
                              { staticClass: "type-icon" },
                              [
                                _c("Icon", {
                                  attrs: {
                                    "icon-class": "icon-xiangxiatuidong",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "name" },
                              [
                                _vm._v(" 同事推进 "),
                                _c("wx-open-data", {
                                  attrs: {
                                    id: _vm.okrData.userName,
                                    nameData: { name: _vm.okrData.userName },
                                  },
                                }),
                                _vm._v(
                                  " * " +
                                    _vm._s(_vm.data.advanceFrom.length) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "progress-list-content" }, [
                            _c("div", { staticClass: "progress-list" }, [
                              _vm.data.advanceFrom
                                ? _c("div", {
                                    staticClass: "infor-list",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.listData(1, _vm.data.advanceFrom)
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._l(
                            _vm.data.advanceFromList,
                            function (listItem, listIndex) {
                              return _c(
                                "div",
                                {
                                  key: listIndex,
                                  staticClass: "detail-list-wrap",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "detail-title" },
                                    [
                                      _c("el-image", {
                                        attrs: { src: listItem[0].userAvatar },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "name" },
                                        [
                                          _c("wx-open-data", {
                                            attrs: {
                                              id: listItem[0].userName,
                                              nameData: {
                                                name: listItem[0].userName,
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._l(listItem, function (keyItem, index) {
                                    return _c(
                                      "div",
                                      { key: keyItem.id },
                                      [
                                        keyItem.handleType
                                          ? _c(
                                              "div",
                                              [
                                                _c("okrItem", {
                                                  attrs: {
                                                    okrData: _vm.okrData,
                                                    item: _vm.item,
                                                    keyItem: keyItem,
                                                    type: _vm.type,
                                                    index: index,
                                                    itemType: "advanceFrom",
                                                  },
                                                }),
                                                _vm._l(
                                                  keyItem.keyResultsList,
                                                  function (
                                                    keyResultItem,
                                                    keyResultIndex
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: keyResultItem.id,
                                                        staticClass:
                                                          "kr-list-wrap",
                                                      },
                                                      [
                                                        _c("okrItem", {
                                                          attrs: {
                                                            okrData:
                                                              _vm.okrData,
                                                            item: _vm.item,
                                                            keyItem:
                                                              keyResultItem,
                                                            type: _vm.type,
                                                            index:
                                                              keyResultIndex,
                                                            itemType:
                                                              "advanceFrom",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _c("okrItem", {
                                              attrs: {
                                                okrData: _vm.okrData,
                                                item: _vm.item,
                                                keyItem: keyItem,
                                                type: _vm.type,
                                                index: index,
                                                itemType: "advanceFrom",
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.data.advanceFromTask.length > 0 ||
                  (_vm.data.taskMaxValue || 0) > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "progress-list-wrap progress-list-span-none",
                        },
                        [
                          _c("div", { staticClass: "progress-type" }, [
                            _c("span", { staticClass: "line" }),
                            _c("div", { staticClass: "name" }, [
                              _vm._v(
                                "由任务推进 * " +
                                  _vm._s(_vm.data.advanceFromTask.length)
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "progress-list-content" }, [
                            _c("div", { staticClass: "progress-list" }, [
                              _vm.data.advanceFromTask.length > 0
                                ? _c("div", {
                                    staticClass: "infor-list",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.listData(
                                          2,
                                          _vm.data.advanceFromTask
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.data.advanceFromTask.length === 0 &&
                              (_vm.data.taskMaxValue || 0) > 0
                                ? _c("div", {
                                    staticClass: "infor-list",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.listData(
                                          3,
                                          _vm.data.advanceFromTask
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "detail-list-wrap" },
                            _vm._l(
                              _vm.data.advanceFromTask,
                              function (keyItem, index) {
                                return _c(
                                  "div",
                                  { key: keyItem.id },
                                  [
                                    _c("okrItem", {
                                      attrs: {
                                        okrData: _vm.okrData,
                                        item: _vm.item,
                                        keyItem: keyItem,
                                        type: _vm.type,
                                        index: index,
                                        itemType: "advanceFromTask",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                !_vm.loading && _vm.dataNullState
                  ? _c(
                      "div",
                      { staticClass: "brife-item-default-box" },
                      [_c("noData")],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
      _vm.keyResultsAutoProcessVisible
        ? _c("keyResultsAutoProcess", {
            attrs: { okrData: _vm.okrData, item: _vm.item },
            on: { refresh: _vm.queryObjectiveDetail },
            model: {
              value: _vm.keyResultsAutoProcessVisible,
              callback: function ($$v) {
                _vm.keyResultsAutoProcessVisible = $$v
              },
              expression: "keyResultsAutoProcessVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }