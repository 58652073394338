<template>
  <div>
    <el-popover
      ref="atUserDialogRef"
      popper-class="user-picker-atdialog-pop"
      :visible-arrow="false"
      placement="bottom-start"
      @show="init('show')"
    >
      <div class="user-picker-out">
        <div class="search-box flex-new">
          <el-input
            v-model="name"
            prefix-icon="el-icon-search"
            placeholder="搜索成员"
            class="user-el-input"
            @input="init"
          />
        </div>
        <div class="people-title">成员{{total}}</div>
        <div class="list-box">
          <div class="list-box-wrap" v-scroll-bottom="$loadMoreList">
            <div v-for="item in list" :key="item.id" class="list-item" @click="pick(item)">
              <el-image
                fit="cover"
                :src="item.avatar || require('@/assets/imgs/common/avatar.png')"
                class="avatar-img border-radius-20"
              />
              <div class="p-l-10">
                <wx-open-data :id="item.username" :nameData="{name:item.name}" />
              </div>
              <div class="departName" v-if="item.departName || item.departUserList">
                <DepartUserList :departName="item.departName" :departUserList="item.departUserList"></DepartUserList>
              </div>
            </div>
          </div>
          <div class="list-item peopeAdd" @click.stop="departAdd()">
            <Icon icon-class="icon-a-tianjia2" class="avatar-img" />
            <div class="p-l-10">按组织选择</div>
          </div>
        </div>
      </div>
      <slot slot="reference"></slot>
    </el-popover>
    <invite-by-code v-if="closeInviterCodeBoolean == true" @closeInviterCode="closeInviterCode" />
    <!-- 按组织选择 -->
    <depart-picker
      v-if="departUserVisible"
      v-model="departUserVisible"
      :canDelete="true"
      type="departPerson"
      :limit="50"
      @saveResult="chargeUserPicker"
    />
  </div>
</template>

<script>
export default {
  props: {
    subVisible: {
      type: String,
      required: false
    },
    pageType: {
      type: String,
      required: false,
      default: ''
    },
    quarterId: {
      // 大周期id
      required: false,
      type: Number
    }
  },
  data() {
    return {
      userList: [],
      list: [],
      closeInviterCodeBoolean: false,
      invitationBoolean: false, // 邀请
      name: null, // 员工姓名
      pageNum: 1,
      pageSize: 20,
      total: 0,
      getDataState: true,
      departUserVisible: false,
      businessScope: 1
    }
  },
  created() {
    // 初始化
    // this.init()
  },
  methods: {
    // 关闭邀请二维验证码
    closeInviterCode() {
      this.closeInviterCodeBoolean = false
    },
    invitationFunction() {
      this.closeInviterCodeBoolean = !this.closeInviterCodeBoolean
      this.invitationBoolean = !this.invitationBoolean
    },
    // 初始化
    init(type) {
      // 获取所有成员列表
      // this.$api.getUserList(this.pageType)
      this.getDataState = false
      if (type === 'show') {
        this.name = ''
      } else {
        this.businessScope = 0
      }
      this.getAtUserList(1)
    },
    $loadMoreList() {
      if (parseInt(this.list.length) < parseInt(this.total) && this.getDataState) {
        this.getDataState = false
        this.getAtUserList()
      }
    },
    // 获取@所有成员列表
    async getAtUserList(type) {
      if ((this.$store.state.common.cycle.quarterId || '') !== '') {
        if (type === 1) {
          this.userList = []
          this.list = []
          this.pageNum = 1
        }
        this.$apis.QUERYALLMEMBERSINCOMPANYV2('', {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
          businessScope: this.businessScope // 0: 全部人员 1:推荐人员
        }).then((rest) => {
          const list = rest.data.rows
          if (type === 1) {
            this.userList = []
            this.list = []
          }
          this.userList = this.userList.concat(list)
          this.list = JSON.parse(JSON.stringify(this.list.concat(list)))
          this.total = rest.data.total
          this.pageNum = this.pageNum + 1
          this.getDataState = true
        })
      }
    },
    // 用户选择
    pick(userInfo) {
      let inputHtml = `<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="${userInfo.id}"> @ <ww-open-data type="userName" openid="${userInfo.username || userInfo.name}"><\/ww-open-data></span> </span>`
      if (!this.$isQw) {
        inputHtml = `<span data-at-embedded="" contenteditable="false"> <span class="embedded-item at-name" data-id="${userInfo.id}">@${userInfo.name}</span> </span>`
      }
      this.$emit('selectPerson', inputHtml, userInfo)
      if (this.$refs.atUserDialogRef) {
        this.$refs.atUserDialogRef.doClose()
      }
    },
    departAdd() {
      this.departUserVisible = true
    },
    chargeUserPicker(list) {
      console.log('chargeUserPicker', list)
      if (list.length > 0) {
        list.forEach((item) => {
          item.id = (item.id || item.userId)
          item.userId = (item.id || item.userId)
          this.pick(item)
        })
      }
    }
  }
}
</script>

<style lang="less">
.list-box {
  display: flex;
  flex-direction: column;
  .list-box-wrap {
    flex: 1;
    overflow-y: auto;
  }
}
.user-picker-atdialog-pop {
  width: 280px;
  box-sizing: border-box;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(17, 17, 17, 0.06);
  position: absolute;
  top: 30px;
  .search-box {
    width: 100%;
    .el-input__inner {
      border: 1px solid rgba(217, 219, 233, 0.32) !important;
      border-radius: 16px;
      background: #fff;
      // box-shadow: 0 0 20px 20px #fff inset;
      font-size: 14px;
      cursor: text;
    }
  }
  .user-picker-out {
    overflow: hidden;
    width: 100%;
    .search-icon {
      height: 14px;
      width: 14px;
      margin-right: -10px;
      z-index: 10;
      align-items: center;
      align-self: center;
      margin-left: 4px;
    }
  }
  .list-box {
    // @include scroll-beautify;
    height: 200px;
    padding-top: 5px;
    width: 100%;
    z-index: 9;
    overflow-x: hidden;
    .list-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      width: 100%;
      border-radius: 8px;
      position: relative;
      .departName {
        .text-n(1);
        max-width: 80px;
        margin-left: 8px;
        font-size: 12px;
        color: #a0a3bd;
      }
      .imgSelect {
        position: absolute;
        right: 0px;
      }
      .avatar-img {
        border-radius: 50%;
        height: 30px;
        min-width: 30px;
        width: 30px;
        margin-right: 8px;
      }
      .p-l-10 {
        max-width: 160px;
        word-break: break-word;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        line-height: 30px;
        &:before {
          content: '';
          display: block;
        }
      }
      &:hover {
        background-color: rgba(245, 246, 250, 0.54);
        // color: #fff;
      }
    }
  }
  .peopeAdd {
    height: 40px;
    color: #3a78f6;
    .avatar-img {
      font-size: 22px;
      width: 22px !important;
      height: 22px !important;
      margin-left: 4px;
    }
    &:hover {
      background-color: transparent;
    }
  }
  .people-title {
    font-size: 14px;
    color: #a0a3bd;
    line-height: 20px;
    margin-top: 8px;
  }
}
</style>
