<template>
  <div class="at-content-box">
    <span
      v-for="(item, index) in dataList"
      :key="index"
      :style="item.type === '@' ? `color:${item.color}` : ''"
    >
      <!-- <template v-if="item.type === 'text'">{{item.content}}</template> -->
      <span v-if="item.type === 'text'" v-html="item.content"></span>
      <template v-else>
        <template v-if="type ==='@'">
          @
          <wx-open-data :id="item.content" :nameData="{name:item.content}" />
        </template>
        <template v-else>
          <span v-for="(itemContent, itemIndex) in item.content" :key="itemIndex">
            <wx-open-data :id="itemContent" :nameData="{name:itemContent}" />
            <template v-if="item.content.length>1&&itemIndex!==(item.content.length - 1)">,</template>
          </span>
        </template>
      </template>
    </span>
  </div>
</template>
    </template>
  </div>
</template>
<script>
var data = ''
export default {
  name: 'two',
  data() {
    return {
      dataList:[]
    }
  },
  props: {
    // 输入框内默认内容
    html: {
      type: String
    },
    type:{
      type:String,
      default: '@'
    }
  },
  watch: {
    html(val) {
      this.handleData()
    }
  },
  created(){
    if (this.type === '@') {
      this.handleDataOKr()
    } else {
      this.handleDataDynamic()
    }
  },
  methods:{
    handleDataOKr(){
      var data = this.html
      // var data = 'sdfsfsf[okrt$0|1307235891192963072|@zhouliufang|#3068FF$okrt][okrt$0|1307235890509291520|@ZhangBo|#3068FF$okrt]'
      if (data !== '') {
        var datalist = []
        var reg = /\[okrt\$\d{1,4}\|(.*?)\$okrt]/g
        var reg2 = /\[okrt\~\d{1,4}\|(.*?)\$okrt]/g
        data = data.split(reg)
        var dataNew = []
        data.forEach((item) => {
          dataNew.concat(item.split(reg2))
        })
        data = dataNew
        if (data.length >0){
          data.forEach((item, index) => {
            if(item !=='') {
              var splitData = /\d{1,4}\|(\d+)\|(@.*?)\|\#(.{6})/g
              if (item.indexOf('#3068FF') !== -1&&item.indexOf('@') !== -1) {
                datalist.push({
                  type: '@',
                  content: (item.split('|')[1]).replace('@', ''),
                  id:item.split('|')[0],
                  color: '#3068FF'
                })
              } else {
                datalist.push({
                  type: 'text',
                  content: item
                })
              }
            }
          })
        }
        this.dataList = datalist
      } else {
        this.dataList = []
      }
    },
    handleDataDynamic(){
      var data = this.html
      // var data =' 「$userName=xuefengyun$」添加了  $userName=chushili,xuefengyun$ 为任务协作人 '
      if (data !== '') {
        var datalist = []
        var reg = /\$(.*?)\$/g
        data = data.split(reg)
        console.log('data',data)
        if (data.length >0){
          data.forEach((item, index) => {
            if(item !=='') {
              if (item.indexOf('userName=') !== -1) {
                var content = item.split('userName=')[1]
                var contentVal = []
                if (content.indexOf(',') !== -1) {
                  contentVal = content.split(',')
                } else {
                  contentVal.push(content)
                }
                datalist.push({
                  type: '@',
                  content: contentVal,
                  id:item.split('|')[0],
                  color: ''
                })
              } else {
                datalist.push({
                  type: 'text',
                  content: item
                })
              }
            }
          })
        }
        this.dataList = datalist
        console.log('datalist',this.datalist)
      } else {
        this.dataList = []
      }
    }
  }
}
</script>