var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      attrs: {
        placement: "top",
        "open-delay": 500,
        content: _vm.content,
        disabled: _vm.content === "" ? true : false,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "avatar-img",
          class: _vm.canClick ? "avatar-click" : "",
          style:
            "width:" +
            _vm.size +
            "px;height:" +
            _vm.size +
            "px;margin-right:" +
            _vm.marginRight +
            "px",
          on: { click: _vm.clickAvatar },
        },
        [
          _vm.avatar
            ? _c("img", {
                staticClass: "border-radius-20",
                style: "width:" + _vm.size + "px;height:" + _vm.size + "px;",
                attrs: { fit: "cover", src: _vm.avatar },
              })
            : _c("img", {
                staticClass: "border-radius-20",
                style: "width:" + _vm.size + "px;height:" + _vm.size + "px;",
                attrs: { fit: "cover", src: "/common/avatar.png" },
              }),
          _vm.locked
            ? _c("div", { staticClass: "avatar-lock-box" }, [
                _c("img", {
                  staticClass: "locked-icon",
                  attrs: {
                    src: require("../../../assets/imgs/common/private-close.png"),
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }