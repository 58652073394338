var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "trend-single-box" }, [
    _c("div", { staticClass: "trend-content" }, [
      _c(
        "div",
        { staticClass: "avatar-img-box" },
        [
          _vm.onlyComment || _vm.trendInfo.type == "3"
            ? _c("el-image", {
                staticClass: "avatar-img",
                attrs: { src: _vm.trendInfo.userAvatar },
              })
            : _vm._e(),
          _vm.isAddIcon
            ? _c("Icon", {
                staticClass: "trend-opera-icon",
                attrs: { "icon-class": "icon-add-round" },
              })
            : _vm._e(),
          _vm.isEditIcon
            ? _c("Icon", {
                staticClass: "trend-opera-icon",
                attrs: { "icon-class": "icon-edit-simple" },
              })
            : _vm._e(),
          _vm.isDelIcon
            ? _c("Icon", {
                staticClass: "trend-opera-icon",
                attrs: { "icon-class": "icon-delete-simple" },
              })
            : _vm._e(),
          _vm.isLikeIcon
            ? _c("Icon", {
                staticClass: "trend-opera-icon",
                attrs: { "icon-class": "icon-like-mini" },
              })
            : _vm._e(),
          _vm.isZrIcon
            ? _c("Icon", {
                staticClass: "trend-opera-icon",
                attrs: { "icon-class": "icon-zhuanrang" },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "single-trend" },
        [
          _c("div", { staticClass: "trend-top-info" }, [
            _c("div", { staticClass: "trend-item-name" }, [
              _c(
                "div",
                { staticClass: "user-name" },
                [
                  _c("wx-open-data", {
                    attrs: {
                      id: _vm.trendInfo.userName,
                      nameData: { name: _vm.trendInfo.userName },
                    },
                  }),
                ],
                1
              ),
              _vm.trendInfo.type == 3 || _vm.trendInfo.type == 0
                ? _c("div", { staticClass: "user-click-wrap" }, [
                    (_vm.trendInfo.fileCommon || "") !== "" &&
                    _vm.trendInfo.fileCommon !== null
                      ? _c(
                          "div",
                          {
                            staticClass: "download-click",
                            on: {
                              click: function ($event) {
                                return _vm.fileDownload(
                                  _vm.trendInfo.fileCommon
                                )
                              },
                            },
                          },
                          [_vm._v("下载附件")]
                        )
                      : _vm._e(),
                    _vm.userInfo.id == _vm.trendInfo.createUserid
                      ? _c(
                          "div",
                          {
                            staticClass: "comment-delete",
                            on: {
                              click: function ($event) {
                                return _vm.deleteComment(_vm.trendInfo.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "trend-item-time" }, [
              _vm._v(_vm._s(_vm.$utils.globalTime(_vm.trendInfo.createDate))),
            ]),
          ]),
          _c("div", {
            staticClass: "trend-main-txt",
            domProps: {
              innerHTML: _vm._s(_vm.$utils.changeToHtml(_vm.trendInfo.comment)),
            },
          }),
          _vm.trendInfo.fileCommon !== "" && _vm.trendInfo.fileCommon !== null
            ? _c("dynamic-file-list", {
                attrs: { "oss-file-entity-list": _vm.trendInfo.fileCommon },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm.trendInfo.children && _vm.trendInfo.children.length > 0
      ? _c(
          "div",
          _vm._l(_vm.trendInfo.children, function (item) {
            return _c(
              "div",
              { key: item.id, staticClass: "child-comment-box" },
              [
                _c("div", { staticClass: "avatar-img-box" }),
                _c(
                  "div",
                  { staticClass: "child-comment" },
                  [
                    _c("div", { staticClass: "child-top-info" }, [
                      _c("div", { staticClass: "child-left" }, [
                        _c(
                          "div",
                          { staticClass: "child-item-name" },
                          [
                            _c("wx-open-data", {
                              attrs: {
                                id: item.userName,
                                nameData: { name: item.userName },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.userInfo.id == item.createUserid
                          ? _c(
                              "div",
                              {
                                staticClass: "comment-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteComment(item.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "child-item-time" }, [
                        _vm._v(_vm._s(_vm.$utils.globalTime(item.createDate))),
                      ]),
                    ]),
                    _c("div", {
                      staticClass: "child-main-txt",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.changeToHtml(item.comment)
                        ),
                      },
                    }),
                    item.fileCommon !== "" && item.fileCommon !== null
                      ? _c("dynamic-file-list", {
                          attrs: { "oss-file-entity-list": item.fileCommon },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.trendInfo.type == "3",
            expression: "trendInfo.type == '3'",
          },
        ],
        staticClass: "reply-box",
      },
      [
        !_vm.replyView
          ? _c(
              "div",
              {
                staticClass: "trend-btn-reply",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.showReplyBox.apply(null, arguments)
                  },
                },
              },
              [_vm._v("回复...")]
            )
          : _vm._e(),
        _vm.replyView
          ? _c(
              "div",
              {
                staticClass: "trend-input-reply",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("comment-box", {
                  ref: "commentBox",
                  attrs: {
                    "file-upload-disabled": true,
                    placeholder: "输入回复的内容",
                    "blur-release": true,
                    "at-able": false,
                    height: "auto",
                    lineFeed: true,
                  },
                  on: {
                    release: _vm.addReplayComment,
                    hideComment: _vm.hideComment,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }