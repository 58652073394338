var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "add_align_ul" },
      [
        _c(
          "div",
          {
            staticClass: "add_align_ul_title clearfix",
            staticStyle: { position: "relative" },
          },
          [
            _vm.targetDetailsAlignState
              ? _c("div", {
                  staticStyle: {
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: "0px",
                    top: "0px",
                  },
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "add_align_ul_title_right clearfix flex-new" },
              [
                _vm._m(0),
                _c(
                  "div",
                  {
                    staticClass: "ul_title_right_text",
                    class: _vm.comment != "" ? "" : "ul_title_right_text-empty",
                  },
                  [_vm._v(_vm._s(_vm.comment))]
                ),
              ]
            ),
          ]
        ),
        _vm._l(_vm.keyresultsList, function (item, index) {
          return _c(
            "div",
            {
              key: "key" + index,
              staticClass: "add_align_li clearfix flex-new",
            },
            [
              _c("Img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: index != _vm.keyresultsList.length - 1,
                    expression: "index != keyresultsList.length - 1",
                  },
                ],
                staticClass: "add_align_li_bor3",
                attrs: { src: "/common/target_add_bor_icon1.png" },
              }),
              _c("Img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: index != _vm.keyresultsList.length - 1,
                    expression: "index != keyresultsList.length - 1",
                  },
                ],
                staticClass: "add_align_li_bor3",
                attrs: { src: "/common/target_add_bor_icon1.png" },
              }),
              _c("Img", {
                staticClass: "add_align_li_bor4",
                attrs: { src: "/common/target_add_bor_icon2.png" },
              }),
              _c("div", { staticClass: "add_align_li_text" }, [
                _vm._v("KR" + _vm._s(index + 1)),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "add_align_li_text1 flex-new flex-new-direction-column",
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "add-align-text",
                      class: item.title ? "" : "add-align-text-empty",
                    },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  item.title_ || _vm.pageBoolean
                    ? _c("p", { staticClass: "add-align-text-2" }, [
                        _vm._v(_vm._s(item.title_)),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ul_title_right_img" }, [
      _c("div", { staticClass: "ul_title_right_img_icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }