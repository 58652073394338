<template>
  <span class="user-list-content">
    <wx-open-data
      :type="2"
      :id="departName || '未设置'"
      :nameData="{name:departName || '未设置'}"
      v-if="!$isDD"
    />

    <el-tooltip placement="top" v-else>
      <div slot="content" v-html="`${departNameAllSplit()}`"></div>
      <span class="user-list-wrap">
        <span
          v-for="(departItem, index) in departUserList"
          :key="departItem.id"
          v-show="(departItem.departName || '') !== ''"
          class="span"
        >
          <wx-open-data
            :type="2"
            :id="departItem.departName"
            :nameData="{name:departItem.departName}"
          />
          {{index !== departUserList.length - 1 ? ';': ''}}
        </span>
        <span class="span" v-if="departUserList.length === 0">未设置</span>
      </span>
    </el-tooltip>
  </span>
</template>
<script>
export default {
  props: {
    departName: {
      default: ''
    },
    departUserList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  created() {
    // if (!this.$isDD) {
    //   this.departUserList = [{
    //     departName: this.departName
    //   }]
    // }
  },
  mounted() {},
  methods: {
    departNameAllSplit(departName) {
      var departNameAll = '未设置'
      if (this.departUserList.length > 0) {
        departNameAll = ''
        this.departUserList.forEach((item, index) => {
          if ((item.departName || '') !== '') {
            departNameAll += `${this.$isQw ? `<ww-open-data type="departmentName" openid="${item.departName}"></ww-open-data>`: `${item.departName}`} ${
              index < this.departUserList.length - 1 ? ';' : ''
            }`
          }
        })
      } else if (this.departName) {
        departNameAll = `${this.$isQw ? `<ww-open-data type="departmentName" openid="${this.departName}"></ww-open-data>` : `${this.departName}`}`
      }
      return departNameAll
    },
  }
}
</script>
<style scoped lang="less">
.user-list-content {
  height: 100%;
  width: 100%;
  .user-list-wrap {
    height: 100%;
    width: 100%;
    .flex-l();
    .text-n(1);
    .span {
      // display: block;
      height: 100%;
      overflow: hidden;
    }
  }
}
</style>