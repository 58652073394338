<template>
  <el-popover
    v-model="krProcessUpdateShow"
    :visible-arrow="false"
    placement="right-start"
    popper-class="kr-process-update"
    trigger="click"
    @show="initData"
    :disabled="disabled"
  >
    <div class="key_results_alert">
      <div class="key_results_alert_box">
        <div>
          <div class="alert_box_title" v-show="currentData.quantificationType !== 1">
            <div>
              <span>
                初始值{{ currentData.quantificationStart
                }}{{ currentData.quantificationUnit }}-目标值{{
                currentData.quantificationEnd
                }}{{ currentData.quantificationUnit }}
              </span>
            </div>
          </div>
          <div class="alert_box_input">
            <el-input
              v-show="currentData.quantificationType !== 1"
              v-model="from2.rate"
              type="text"
              size="mini"
              @blur="getNewKeywordInput"
            >
              <template slot="append">
                <div class="append-unit">{{ currentData.quantificationUnit }}</div>
              </template>
            </el-input>
            <div v-show="currentData.quantificationType === 1" class="status-select-box">
              <div class="select-item" @click="currentData.quantificationStatus = 0">
                <Icon
                  :icon-class="
                    currentData.quantificationStatus === 1
                      ? 'icon-weiwancheng'
                      : 'icon-finished'
                  "
                  class="select-icon"
                />未完成
              </div>
              <div class="select-item" @click="currentData.quantificationStatus = 1">
                <Icon
                  :icon-class="
                    currentData.quantificationStatus !== 1
                      ? 'icon-weiwancheng'
                      : 'icon-finished'
                  "
                  class="select-icon"
                />已完成
              </div>
            </div>
          </div>
          <!-- <div
            class="auto-progress-value"
            v-if="currentData.automationState && krAutoProgressList"
          >有「{{krAutoProgressList.autoAllNum}}{{krAutoProgressList.unit}}」已被设置为智能进度，可手动更新的进度只能在「{{krAutoProgressList.manualStartValue}}{{krAutoProgressList.unit}}-{{krAutoProgressList.manualEndValue}}{{krAutoProgressList.unit}}」之间哦</div>-->
          <div
            class="auto-progress-value"
            v-if="currentData.automationState && krAutoProgressList"
          >有「{{krAutoProgressList.autoAllNum}}{{krAutoProgressList.unit}}」已被设置为智能进度</div>
          <div
            class="alert_box_title"
            style="margin-top: 16px"
          >{{ currentData.quantificationType === 1 ? '完成情况说明' : '进度说明' }}</div>
          <div class="alert_box_textarea">
            <el-input
              v-model.lazy="from2.explain"
              type="textarea"
              placeholder="请输入进度反馈说明"
              @input="inputBack"
            />
          </div>
          <div class="alert_box_submit clearfix">
            <div class="alert_box_submit_button1" @click="getKeyResultsAlertClose">取消</div>
            <L-button
              :loading="loading"
              class="alert_box_submit_button2"
              @clickEvt="getKeyResultsAlertSubmit"
            >保存</L-button>
          </div>
        </div>
      </div>
    </div>
    <div slot="reference">
      <slot></slot>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: {
    objData: {
      type: Object,
      required: false
    },
    type: {
      default: 'okr'
    },
    okrType: {
      default: 'list'
    },
    disabled: {
      default: false
    }
  },
  data() {
    return {
      loading: false,
      from2: {
        keyId: '',
        explain: '',
        rate: 0
      },
      krProcessUpdateShow: false,
      krAutoProgressList: '',
      currentData: ''
    }
  },
  methods: {
    initData() {
      this.currentData = JSON.parse(JSON.stringify(this.objData))
      this.from2.explain = ''
      this.from2.keyId = this.currentData.id
      this.from2.rate = this.currentData.quantificationActualValue
      this.getAutoProgressValue()
    },
    getAutoProgressValue() {
      if (this.currentData.automationState) {
        const params = {
          krId: this.currentData.id
        }
        this.$apis.OKRKRPROGRESSVALUE(params).then((res) => {
          if (res.status === 200) {
            res.data.autoAllNum = (res.data.alignAtMeAutomationValue + res.data.taskAutomationValue)
            this.krAutoProgressList = res.data
          } else {
            this.$messsege.warning(res.message)
          }
        })
      }
    },
    // 字数超出显示提示
    inputBack() {
      const message_ = this.from2.explain.replace(/\s*/g, '') || ''
      if (message_.length > 500) {
        this.$message({
          msg: '你的宝贵意见最多可以输入 500 个字符哦!',
          type: 'warn'
        })
        this.from2.explain = message_.substring(0, 500)
      }
    },
    // 监听输入的内容
    getNewKeywordInput: function () {
      const content = this.from2.rate
      // this.from2.rate = content.replace(/\D/g, '')
      this.from2.rate = (Number(content) || 0).toFixed(2) // 保留两位小数
      // if (this.from2.rate < this.currentData.quantificationStart) {
      //   this.from2.rate = this.currentData.quantificationStart
      // }
      // if (this.from2.rate > this.currentData.quantificationEnd) {
      //   this.from2.rate = this.currentData.quantificationEnd
      // }
    },
    // 提交
    async getKeyResultsAlertSubmit() {
      this.loading = true
      if (
        this.currentData.quantificationType === 0 &&
        Number(this.currentData.quantificationStart) >=
          Number(this.currentData.quantificationEnd)
      ) {
        this.$message({
          msg: '目标值不能小于等于初始值！',
          type: 'warn'
        })
        this.loading = false
        return
      }
      if ((this.from2.rate || '') === '' && this.currentData.quantificationType === 0) {
        this.$message.warn('进度值不能为空哦！')
        this.loading = false
        return
      }
      // if (this.currentData.automationState && this.currentData.quantificationType === 0 && (this.from2.rate < this.krAutoProgressList.manualStartValue || this.from2.rate > this.krAutoProgressList.manualEndValue)) {
      //   this.$message.warn(`可手动更新的进度只能在「${this.krAutoProgressList.manualStartValue}${this.krAutoProgressList.unit}-${this.krAutoProgressList.manualEndValue}${this.krAutoProgressList.unit}」之间哦`)
      //   this.loading = false
      //   return
      // }
      // 企信pc迁移后新增
      //   埋点
      if (this.type === 'okr') {
        this.$mixpanelUtil.track('Update OKR Progress', {
          objid: this.currentData.id,
          view_type: this.okrType
        })
      }
      const obj = {
        id: this.currentData.id,
        explain: this.from2.explain,
        quantificationType: this.currentData.quantificationType,
        quantificationStatus: this.currentData.quantificationStatus,
        quantificationUnit: this.currentData.quantificationUnit,
        quantificationStart: this.currentData.quantificationStart,
        quantificationEnd: this.currentData.quantificationEnd,
        quantificationActualValue: this.from2.rate
      }
      this.$apis
        .OKRADDPROCESS(obj)
        .then((rest) => {
          if (rest.status === 200) {
            this.$message({
              msg: '修改成功',
              type: 'success'
            })
            this.bus(this).$emit('inquireStatus', {
              type: 1,
              name: 'OKR'
            })
            this.getKeyResultsAlertClose()
            this.$emit('getKeyResultsAlertClose')
          } else {
            this.$message.error(rest.message)
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },

    // 关闭
    getKeyResultsAlertClose() {
      this.krProcessUpdateShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-input-group__append,
  .el-input-group__prepend {
    border: none !important;
  }
  .el-textarea,
  .el-textarea__inner {
    border: none !important;
    resize: none !important;
    background: #f5f6fa !important;
  }
  .el-textarea__inner {
    &::placeholder {
      font-size: 14px;
    }
  }
}
.key_results_alert {
  min-height: 300px;
  .key_results_alert_box {
    width: 300px;
    .auto-progress-value {
      width: 252px;
      margin: 4px auto 0px auto;
      font-size: 12px;
      color: #a0a3bd;
    }
    .alert_box_title {
      width: 252px;
      height: 22px;
      margin: 24px auto 0px auto;
      font-size: 12px;
      font-weight: 700;
      color: #14142b;
      text-align: left;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
    }
    .alert-edit-button {
      margin-left: 8px;
      font-size: 12px;
      color: #3a78f6;
      cursor: pointer;
      &:hover {
        color: #005eff;
      }
    }
    .alert_box_input {
      width: 252px;
      height: 40px;
      margin: 8px auto 0px auto;
      display: flex;
      align-items: center;
      .append-unit {
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:before {
          content: '';
          display: block;
        }
      }
      .status-select-box {
        font-size: 16px;
        color: #a0a3bd;
        font-weight: 500;
        display: flex;
        .select-item {
          display: flex;
          align-items: center;
          cursor: pointer;
          .select-icon {
            font-size: 20px;
            height: 20px;
            width: 20px;
            margin-right: 8px;
          }
          &:first-of-type {
            margin-right: 16px;
          }
        }
      }
    }
    .alert_box_textarea {
      width: 252px;
      height: 100px;
      margin: 8px auto 0px auto;
    }

    .alert_box_submit {
      height: 40px;
      padding-right: 24px;
      margin: 40px 0px 23px 0px;
      display: flex;
      justify-content: flex-end;
      .alert_box_submit_button1 {
        width: 80px;
        height: 34px;
        border-radius: 13px;
        border: 1px solid #005eff;
        margin-left: 72px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #005eff;
        cursor: pointer;
        line-height: 34px;
      }
      .alert_box_submit_button2 {
        width: 80px;
        height: 34px;
        background: #005eff;
        border-radius: 13px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #f7f7fc;
        text-align: center;
        line-height: 34px;
        margin-left: 8px;
        cursor: pointer;
      }
    }
    .unit-edit-box {
      width: 252px;
      margin: 16px auto;
      .unit-edit-title {
        text-align: center;
        font-size: 16px;
        color: #14142b;
        font-weight: 700;
        position: relative;
        height: 24px;
        line-height: 24px;
        margin-bottom: 24px;
        .unit-back-icon {
          position: absolute;
          left: 0;
          width: 24px;
          height: 24px;
          cursor: pointer;
          &:hover {
            background: #f5f6fa;
          }
        }
      }
      .unit-edit-btn {
        width: 200px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
        border-radius: 16px;
        margin: 32px auto 24px;
        color: #fff;
        background: #3a78f6;
        &:hover {
          background: #005eff;
        }
      }
    }
  }
}
.key_results_alert .key_results_alert_box .alert_box_input ::v-deep {
  .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 8px;
  }
  .el-input-group {
    width: 150px;
  }
  .el-input__inner {
    text-align: center;
  }
}

::v-deep .el-textarea__inner {
  border-radius: 8px;
  height: 100px;
  background: #f5f6fa;
  border: 1px solid #f5f6fa;
  &:focus {
    background: #fff;
    border: 1px solid #3a78f6;
  }
}
.key_results_alert
  .key_results_alert_box
  .alert_box_textarea
  ::v-deep.el-textarea {
  height: 100%;
}
</style>
<style lang="scss">
.kr-process-update {
  padding: 0;
  background: #ffffff;
  border-radius: 8px;
}
</style>
