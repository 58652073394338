var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "comment-box",
      style: "height: " + _vm.height + "px",
      on: { drop: _vm.upLoadFile, mousedown: _vm.clickBox },
    },
    [
      _c(
        "div",
        { staticClass: "comment-input-box" },
        [
          _c("at-input", {
            ref: "atCommentBoxinput",
            attrs: {
              lineFeed: _vm.lineFeed,
              atDisable: !_vm.atAble,
              html: _vm.inputHtml,
              limit: 500,
              "enter-able": true,
              placeholder:
                _vm.$utils.tagFilter(_vm.inputHtml + "<div></div>") === ""
                  ? _vm.placeholder
                  : "",
              atAll: _vm.atAll,
              multipleChoice: _vm.multipleChoice,
            },
            on: {
              input: _vm.getComment,
              enter: _vm.enterInput,
              focus: _vm.focus,
              blur: _vm.blurInput,
              compositionstart: _vm.handleCompositionStart,
              compositionend: _vm.handleCompositionEnd,
              insertUser: _vm.insertUser,
              departInsertUser: _vm.departInsertUser,
              departVisibleChange: _vm.departVisibleChange,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "upload-comment-wrap" }, [
        !_vm.fileUploadDisabled
          ? _c(
              "div",
              { staticClass: "file-upload-wrap" },
              [
                _c("file-upload", {
                  attrs: { "input-drag-file-list": _vm.dragFile },
                  on: { fileFresh: _vm.fileFreshList },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "comment-release-btn" }, [
          _c(
            "div",
            { staticClass: "release-btn" },
            [
              _vm.inputHtml === "" && _vm.uploadFileList.length === 0
                ? _c(
                    "span",
                    {
                      staticClass: "disabled",
                      attrs: { loading: _vm.loading },
                    },
                    [_vm._v("发布")]
                  )
                : _c(
                    "span",
                    {
                      staticClass: "submit",
                      attrs: { loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.releaseComment.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("发布")]
                  ),
              _c("Icon", {
                staticClass: "release-icon",
                attrs: { "icon-class": "icon-arrow-down" },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }