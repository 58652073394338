var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          title: _vm.titleName[_vm.type + "Title"],
          visible: _vm.dialogVisible,
          width: "480px",
          "close-on-click-modal": false,
          "append-to-body": true,
          "before-close": _vm.handleClose,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "content-wrap" }, [
        _vm.step === 1
          ? _c(
              "div",
              { staticClass: "remind-wrap" },
              [
                _c("Icon", {
                  staticClass: "icon",
                  attrs: { "icon-class": "icon-shenpijinggao" },
                }),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.titleName[_vm.type + "Content"])),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.step === 2
          ? _c(
              "div",
              { staticClass: "input-wrap" },
              [
                _c("el-input", {
                  staticClass: "input-focus",
                  attrs: {
                    type: "textarea",
                    placeholder: _vm.titleName[_vm.type + "Placeholder"],
                    rows: 6,
                  },
                  model: {
                    value: _vm.content,
                    callback: function ($$v) {
                      _vm.content = $$v
                    },
                    expression: "content",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "bottom-step-buttons" },
          [
            _c(
              "el-button",
              { staticClass: "left-button", on: { click: _vm.handleClose } },
              [_vm._v("取消")]
            ),
            _vm.step === 1
              ? _c(
                  "el-button",
                  {
                    staticClass: "left-button",
                    on: {
                      click: function ($event) {
                        return _vm.nextStep(2)
                      },
                    },
                  },
                  [_vm._v("自定义要求")]
                )
              : _vm._e(),
            _vm.step === 1
              ? _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.nextStep(2)
                      },
                    },
                  },
                  [_vm._v("好的")]
                )
              : _vm._e(),
            _vm.step === 2
              ? _c(
                  "el-button",
                  {
                    staticClass: "system-button",
                    on: {
                      click: function ($event) {
                        return _vm.submit(1)
                      },
                    },
                  },
                  [_vm._v("系统生成")]
                )
              : _vm._e(),
            _vm.step === 2
              ? _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.submit(2)
                      },
                    },
                  },
                  [_vm._v("自定义生成")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }