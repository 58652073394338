// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/component-loading.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".u-loading-mask[data-v-3c6564f7] {\n  position: fixed;\n  z-index: 9999999;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  color: #ffffff;\n}\n.u-loading-mask .u-loading-spinner[data-v-3c6564f7] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 74px 74px;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}", ""]);
// Exports
module.exports = exports;
