var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _vm.type === "add"
            ? _c(
                "div",
                { key: "add" + Math.random() },
                [
                  _c("el-date-picker", {
                    staticClass: "date-box",
                    attrs: {
                      format: "yyyy-MM-dd HH:mm",
                      "value-format": "yyyy-MM-dd HH:mm",
                      type: "datetime",
                      placeholder: "开始日期",
                      "picker-options": _vm.pickerOptionsStart,
                      "popper-class": "addTimeClass",
                    },
                    on: { change: _vm.startDateChange },
                    model: {
                      value: _vm.form.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startDate", $$v)
                      },
                      expression: "form.startDate",
                    },
                  }),
                  _c("span", { staticClass: "m-l-r-10" }, [_vm._v("—")]),
                  _c("el-date-picker", {
                    staticClass: "date-box",
                    attrs: {
                      format: "yyyy-MM-dd HH:mm",
                      "value-format": "yyyy-MM-dd HH:mm",
                      type: "datetime",
                      placeholder: "截止日期",
                      "picker-options": _vm.pickerOptionsEnd,
                      "popper-class": "addTimeClass",
                    },
                    on: { change: _vm.endDateChange },
                    model: {
                      value: _vm.form.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endDate", $$v)
                      },
                      expression: "form.endDate",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { key: "else" + Math.random() },
                [
                  _c("date-time-picker", {
                    attrs: {
                      obj: _vm.form,
                      source: _vm.source,
                      fieldItem: _vm.fieldItem,
                      disabled: _vm.disabled,
                    },
                    on: { change: _vm.dateTimeChange },
                  }),
                ],
                1
              ),
          _vm.source != "stopdoing"
            ? _c("span", { staticClass: "line" })
            : _vm._e(),
          _vm.source != "stopdoing"
            ? _c(
                "el-tooltip",
                { attrs: { content: _vm.cycleDateFmt } },
                [
                  _c("task-cycle-detail", {
                    attrs: {
                      id:
                        _vm.row.cycleDate && _vm.row.cycleDate.id
                          ? _vm.row.cycleDate.id
                          : "",
                      "task-id": _vm.row.id,
                      row: _vm.row,
                      disabled: _vm.disabled,
                    },
                    on: { save: _vm.cycleSave },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.source != "stopdoing"
            ? _c("span", { staticClass: "line" })
            : _vm._e(),
          _vm.source != "stopdoing"
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content: _vm.remindDateFmt,
                    "open-delay": 300,
                    placement: "top",
                    effect: "light",
                  },
                },
                [
                  _vm.type_remind === 0 ||
                  !_vm.row.remindDate ||
                  !_vm.row.remindDate.id
                    ? _c("Icon", {
                        staticClass: "time-icon",
                        class: _vm.disabled ? "disabled" : "change-hover",
                        attrs: { "icon-class": "icon-remind" },
                        on: { click: _vm.remindTimeClick },
                      })
                    : _c("Icon", {
                        staticClass: "time-icon active-icon",
                        class: _vm.disabled ? "disabled" : "change-hover",
                        attrs: { "icon-class": "icon-remind" },
                        on: { click: _vm.remindTimeClick },
                      }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.source != "stopdoing"
        ? _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.remindTimeVisible
                ? _c("task-remind-time-detail", {
                    attrs: {
                      id:
                        _vm.row.remindDate && _vm.row.remindDate.id
                          ? _vm.row.remindDate.id
                          : "",
                      visible: _vm.remindTimeVisible,
                      "task-id": _vm.row.id,
                      row: _vm.row,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.remindTimeVisible = $event
                      },
                      save: _vm.remindTimeSave,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }