var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-tabs" }, [
    _c(
      "div",
      {
        staticClass: "box-select-li",
        class: _vm.activeTab == 1 ? "box-select-li-style" : "",
        on: {
          click: function ($event) {
            return _vm.getTargetSelect(1)
          },
        },
      },
      [
        _c("Icon", {
          staticClass: "kr-right-icon",
          attrs: { "icon-class": "icon-task" },
        }),
        _c("div", { staticClass: "box-select-li-text box-select-li-color" }, [
          _vm._v("任务"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "box-select-li clearfix",
        class: _vm.activeTab == 2 ? "box-select-li-style" : "",
        on: {
          click: function ($event) {
            return _vm.getTargetSelect(2)
          },
        },
      },
      [
        _c("Icon", {
          staticClass: "kr-right-icon",
          attrs: { "icon-class": "icon-comment" },
        }),
        _vm.commentUserAvatar
          ? _c("el-image", {
              staticClass: "kr-right-avatar border-radius-20",
              attrs: { fit: "cover", src: _vm.commentUserAvatar },
            })
          : _vm._e(),
        _vm.showCommentRedpoint
          ? _c("div", {
              staticClass: "common-red-point",
              class: _vm.commentUserAvatar ? "" : "no-avatar-point",
            })
          : _vm._e(),
        _c("div", { staticClass: "box-select-li-text box-select-li-color" }, [
          _vm._v("动态"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "box-select-li clearfix",
        class: _vm.activeTab == 3 ? "box-select-li-style" : "",
        on: {
          click: function ($event) {
            return _vm.getTargetSelect(3)
          },
        },
      },
      [
        _c("Icon", {
          staticClass: "kr-right-icon",
          attrs: { "icon-class": "icon-jindu1" },
        }),
        _c("div", { staticClass: "box-select-li-text box-select-li-color" }, [
          _vm._v("进度"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "box-select-li",
        class: _vm.activeTab == 4 ? "box-select-li-style" : "",
        on: {
          click: function ($event) {
            return _vm.getTargetSelect(4)
          },
        },
      },
      [
        _c("Icon", {
          staticClass: "kr-right-icon",
          attrs: { "icon-class": "icon-wenjian3" },
        }),
        _c("div", { staticClass: "box-select-li-text box-select-li-color" }, [
          _vm._v("文件"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }