<template>
  <div>
    <div class="key_results_li clearfix" :class="showBorder ? '' : 'key_results_li_no_border'">
      <div class="key_results_li_left_box">
        <div class="key_result_dragger_icon_box">
          <Icon
            v-show="targetObject.userRoleType === 0"
            icon-class="icon-targetkrdragger"
            class="key_result_dragger_icon"
          />
        </div>
        <slot name="slotLeft"></slot>
        <div class="li_key_results_ul_li_okr">
          KR{{ keyResultsLiNumber + 1 }}
          <privateOkr
            v-if="
              keyResultsLi.isVisible === 1 ||
              (keyResultsLi.objectivesPower &&
                keyResultsLi.objectivesPower.lookRulesType !== 0)
            "
            :content="
              privateOkrContent(
                targetObject.userRoleType === 0,
                keyResultsLi,
                1
              )
            "
            :createUser="targetObject.userRoleType === 0"
            :visible="
              keyResultsLi.isVisible === 1 ||
              (keyResultsLi.objectivesPower &&
                keyResultsLi.objectivesPower.lookRulesType !== 0)
            "
            :type="3"
          />
        </div>
      </div>
      <div class="key_results_li_right_box">
        <div class="li_key_results_ul_li_text">
          <!-- <transition name="bottom-slide" mode="out-in"> -->
          <div
            v-if="keyResultsLi.titleStates"
            class="li_key_results_ul_li_text1"
            :class="
              targetObject.userRoleType === 0
                ? 'cursor-text'
                : 'cursor-no'
            "
            @click="getKeyResultsListTitles()"
          >
            <el-tooltip
              placement="top"
              :open-delay="300"
              content="点击编辑"
              :disabled="targetObject.userRoleType !==0"
            >
              <!-- <span v-html="$utils.changeToHtml(keyResultsLi.title)"></span> -->
              <!-- :html="$utils.changeToHtml(keyResultsLi.title)" -->
              <advice-html
                :html="
                  !alignMember
                    ? $utils.changeToHtml(keyResultsLi.title)
                    : $utils.changeToHtmlUser(
                        $utils.changeToOkrtFormat(keyResultsLi.title),
                        keyResultsLi,
                        targetObject.childObjectivesAssociationUserList.concat(
                          targetObject.objectivesCollaboratorList
                        )
                      )
                "
                :show="targetObject.userRoleType === 0"
                :dict-info="keyResultsLi.objectivesRuleMatchDto"
                :listType="alignMember"
                :isOkr="pageType === 'okrList'"
                :businessType="1"
                :dataDetail="keyResultsLi"
                @ignore="changeObjectiveTitle('修改成功')"
                @okrDetail="getTopMembersAlignmentSelect"
                @toOKRList="getTopMembersFatherList"
              />
              <privateOkr
                v-if="keyResultsLi.isVisible === 1"
                :createUser="targetObject.userRoleType === 0"
                :visible="keyResultsLi.isVisible === 1"
              />
            </el-tooltip>
          </div>
          <!-- </transition>
          <transition name="bottom-slide" mode="out-in">-->
          <div v-if="!keyResultsLi.titleStates" class="li_key_results_ul_li_text2">
            <key-results-add
              :is-share="targetObject.isShare"
              :is-edit="true"
              :edit-info="keyResultsLi"
              :okrDataObj="targetObject"
              :fromType="fromType"
              :noRequest="pageType === 'addOkrAiList' ? true : false"
              :krIndex="keyResultsLiNumber"
              @changeObjectiveTitle="changeObjectiveTitle"
              @getkeyResultsAddRefresh="getkeyResultsAddRefresh"
              @saveContent="saveContent"
            />
          </div>
          <!-- </transition> -->
        </div>
        <div v-if="keyResultsLi.titleStates" class="key_results_li_ul key-results-popver clearfix">
          <autoProgressPopver
            :okrData="targetObject"
            :item="keyResultsLi"
            :disabled="keyResultsLi.automationState !== 1"
            @setAutoProcessClick="setAutoProcessClick"
          >
            <div
              v-if="keyResultsLi.quantificationType !== 1 && pageType!=='addOkrAiList'"
              :id="'keyResultsList' + keyResultsLi.id + 's'"
              class="key_results_li_ul_li key_results_li_ul_li3 clearfix"
              :class="
                userInfo.id === targetObject.createUserid
                  ? 'key_results_li_ul_li_style'
                  : ''
              "
              @click.stop="getHomeListMouseover(keyResultsLi.id)"
            >
              <mini-process
                type="circle"
                :automationState="keyResultsLi.automationState"
                :rate="keyResultsLi.progressRete"
              />
              <el-tooltip
                placement="top"
                :open-delay="300"
                :content="
                  targetObject.userRoleType === 0
                    ? 'KR进度'
                    : `初始值${keyResultsLi.quantificationStart}${keyResultsLi.quantificationUnit}-目标值${keyResultsLi.quantificationEnd}${keyResultsLi.quantificationUnit}`
                "
              >
                <div
                  class="key_results_li_ul_li_text"
                  :class="keyResultsAlertState ? 'active_text' : ''"
                >
                  {{ keyResultsLi.quantificationActualValue
                  }}{{ keyResultsLi.quantificationUnit }}
                </div>
              </el-tooltip>
            </div>
            <div
              v-else-if="pageType!=='addOkrAiList'"
              :id="'keyResultsList' + keyResultsLi.id + 's'"
              class="key_results_li_ul_li key_results_li_ul_li3 clearfix"
              :class="
                targetObject.userRoleType === 0
                  ? 'key_results_li_ul_li_style'
                  : ''
              "
              @click.stop="getHomeListMouseover(keyResultsLi.id)"
            >
              <el-tooltip
                placement="top"
                :open-delay="300"
                :content="
                  targetObject.userRoleType === 0
                    ? 'KR进度'
                    : '未完成-已完成'
                "
              >
                <span>
                  <div v-show="keyResultsLi.quantificationStatus !== 1" class="key-result-style">
                    <Icon icon-class="icon-not-finish" class="select-icon" />
                    <div
                      class="key_results_li_ul_li_text"
                      :class="keyResultsAlertState ? 'active_text' : ''"
                    >未完成</div>
                  </div>
                  <div v-show="keyResultsLi.quantificationStatus === 1" class="key-result-style">
                    <Icon icon-class="icon-finished" class="select-icon" />
                    <div
                      class="key_results_li_ul_li_text"
                      :class="keyResultsAlertState ? 'active_text' : ''"
                    >已完成</div>
                  </div>
                </span>
              </el-tooltip>
            </div>
          </autoProgressPopver>
          <el-tooltip placement="top" :open-delay="300" content="KR权重">
            <div class="key_results_li_ul_li2">
              <div
                v-if="keyResultsLi.weightStates"
                class="key_results_li_ul_li clearfix"
                style="width: 100%"
                :class="
                  targetObject.userRoleType === 0
                    ? 'key_results_li_ul_li_style'
                    : ''
                "
                @click="getKeyResultsListWeights(keyResultsLiNumber)"
              >
                <Icon icon-class="icon-weight" class="key_results_li_ul_li_icon" />
                <div class="key_results_li_ul_li_text">{{ keyResultsLi.weight }}%</div>
              </div>
              <el-input
                v-else
                :ref="'keyResultsListInput' + keyResultsLi.id"
                v-model="keyResultsLi.weight"
                type="text"
                autofocus
                class="input-focus"
                @blur="getKeyHandleChangeBlur(keyResultsLiNumber)"
                @input="getKeyHandleChange(keyResultsLiNumber)"
              />
            </div>
          </el-tooltip>
          <span style="width: 22%" v-if="pageType!=='addOkrAiList'">
            <guide-popover
              v-if="
                (pageType === 'okrList' &&
                  objIndex === 0 &&
                  keyResultsLiNumber === 0) ||
                (pageType === 'okrMap' && keyResultsLiNumber === 0)
              "
              ref="nextTargetPop"
              :pop-key="
                pageType === 'okrList'
                  ? 'okrGuideListPopover'
                  : 'okrGuideMapPopover'
              "
              title="KR任务"
              :hide-click-emit="true"
              :animate-state="true"
              inner-text="点击查看关联KR的任务"
              placement="bottom"
              @hideClick="showTaskList(keyResultsLi.id)"
            >
              <el-tooltip slot="guidepop" placement="top" :open-delay="300" content="KR任务">
                <div
                  class="key_results_li_ul_li key_results_li_ul_li3 key_results_li_ul_li_style"
                  :class="selectKrValue === keyResultsLi.id ? '' : 'active-li'"
                  @click.stop="showTaskList(keyResultsLi.id)"
                >
                  <Icon icon-class="icon-task" class="key_results_li_ul_li_icon" />
                  <div class="key_results_li_ul_li_text2">
                    {{ keyResultsLi.taskFinishCount || 0 }}/{{
                    keyResultsLi.taskCount || 0
                    }}
                  </div>
                </div>
              </el-tooltip>
            </guide-popover>
            <el-tooltip v-else placement="top" :open-delay="300" content="KR任务">
              <div
                class="key_results_li_ul_li key_results_li_ul_li3 key_results_li_ul_li_style"
                :class="selectKrValue === keyResultsLi.id ? '' : 'active-li'"
                @click.stop="showTaskList(keyResultsLi.id)"
              >
                <Icon icon-class="icon-task" class="key_results_li_ul_li_icon" />
                <div class="key_results_li_ul_li_text2">
                  {{ keyResultsLi.taskFinishCount || 0 }}/{{
                  keyResultsLi.taskCount || 0
                  }}
                </div>
              </div>
            </el-tooltip>
          </span>
          <el-tooltip
            v-if="targetObject.canBeScored === 1"
            placement="top"
            :open-delay="300"
            content="KR评分"
          >
            <div class="key_results_li_ul_li2">
              <div
                v-if="keyResultsLi.scoreStates"
                class="key_results_li_ul_li clearfix"
                style="width: 100%"
                :class="
                  targetObject.userRoleType === 0
                    ? 'key_results_li_ul_li_style'
                    : ''
                "
                @click="showKeyResultScore"
              >
                <Icon icon-class="icon-pingfen1" class="key_results_li_ul_li_icon" />
                <div
                  class="key_results_li_ul_li_text"
                  :class="
                    targetObject.userRoleType === 0
                      ? 'score-text'
                      : ''
                  "
                >
                  {{
                  keyResultsLi.score || keyResultsLi.score === 0
                  ? `${keyResultsLi.score}`
                  : '待评分'
                  }}
                </div>
              </div>
              <el-input
                v-else
                :ref="'keyResultsListScore' + keyResultsLi.id"
                v-model="keyResultsLi.score"
                autofocus
                placeholder="0-10"
                type="text"
                class="input-focus"
                @blur="getKeyScoreChangeBlur(keyResultsLiNumber)"
                @keyup.native.enter="getKeyScoreChangeBlur(keyResultsLiNumber)"
                @input="getKeyScoreChange(keyResultsLiNumber)"
              />
            </div>
          </el-tooltip>
        </div>
      </div>

      <div v-if="targetObject.userRoleType === 0" class="key_results_li_detele">
        <i class="iconfont ymbguanbianniu" @click="getTargetDetailsKeywordDetele(itemKeyresults)"></i>
      </div>
    </div>
  </div>
</template>
<script>
import miniProcess from './miniProcess' // 进度条
import keyResultsAdd from './keyResultsAdd'
import adviceHtml from '../AdviceHtml'
import privateOkr from '../PrivateOkr'
import autoProgressPopver from './autoProgressPopver'
export default {
  components: {
    miniProcess,
    keyResultsAdd,
    adviceHtml,
    privateOkr,
    autoProgressPopver
  },
  props: {
    keyResultsLi: {
      // 关键及结果数据
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    targetObject: {
      // 目标当前一条的数据
      type: Object,
      required: false,
      default: () => {
        return {
          createUserid: ''
        }
      }
    },
    keyResultsLiNumber: {
      // 关键结果循环数量
      type: Number,
      required: false,
      default: 0
    },
    showBorder: {
      // 是否展示下边框
      type: Boolean,
      default: true
    },
    pageType: {
      // 类型
      type: String,
      default: 'okrDetail'
    },
    objIndex: {
      // 目标列表下标
      type: Number,
      default: 1
    },
    alignMember: {
      // @人员经过显示弹窗
      type: Boolean,
      default: false
    },
    editState: {
      default: false // true: 可以编辑
    },
    fromType: {
      default: ''
    }
  },
  data() {
    return {
      atstate: true,
      num: 0,
      detailsId: '',
      homeX: 0,
      homeY: 0,
      keyResultsAlertObject: {},
      keyResultsAlertState: false,
      oldWeight: 0, // 保存原KR权重
      selectKrValue: '', // 当前选中KR查询任务
      oldScore: 0 // 原KR评分
    }
  },

  computed: {},
  created() {
    this.bus(this).$on('changeSelectKrTask', (val) => {
      this.selectKrValue = val
    })
    this.initData(this.targetObject)
  },
  watch: {
    targetObject: {
      handler(val) {
        this.initData(this.targetObject)
      }
    }
  },
  methods: {
    saveContent(data) {
      this.$emit('saveContent', data)
    },
    initData(data) {
      // 判断是否是创建人 后台把这个字段删除了 0:创建人 1:共享人 2:查看者
      if (this.fromType !== 'approvalDetail' && data.createUserid === this.userInfo.id && (data.objectivesProcessDataDto && data.objectivesProcessDataDto.businessType) !== 1) {
        data.userRoleType = 0
      } else {
        data.userRoleType = 2
      }
      if (this.editState === true) {
        data.userRoleType = 0
      }
      this.targetObject = data
    },
    getTopMembersAlignmentSelect(data) {
      this.$emit('okrDetail', data)
    },
    getTopMembersFatherList(data) {
      this.$emit('toOKRList', data)
    },
    setAutoProcessClick() {
      this.$emit('setAutoProcess')
    },
    privateOkrContent(create, item, type) {
      // type:1 带锁
      var content = ''
      if (item.objectivesPower && item.objectivesPower.lookRulesType === 1) {
        content = '目标所属人可见'
      } else if (
        item.objectivesPower &&
        item.objectivesPower.lookRulesType === 2
      ) {
        content = '部分人可见'
      }
      return content
    },
    // 获取关键字删除
    async getTargetDetailsKeywordDetele(item) {
      // if(this.userInfo.id === this.targetDetailsDataObjectives.createUserid){
      if (this.targetObject.keyresultsList.length > 1) {
        this.$confirm('是否删除该关键结果?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'confirmBox'
        })
          .then(() => {
            if (this.pageType === 'addOkrAiList') {
              this.$emit('getTargetDetailsKeywordDetele')
              return
            }
            if (this.targetObject.userRoleType === 0) {
              this.$apis
                .OKRDELETEKR(this.keyResultsLi.id)
                .then((rest) => {
                  if (rest.status === 200) {
                    this.$message({
                      msg: '删除关键结果成功',
                      type: 'success'
                    })

                    // this.getTopMembers();
                    this.bus.$emit(
                      'getTargetDetailsKeywordDetele',
                      this.keyResultsLi.id
                    )
                    this.$emit(
                      'getTargetDetailsKeywordDetele',
                      this.keyResultsLi.id
                    )
                    if (localStorage.getItem('approveRemindState') !== 'true' && this.targetObject.processState === 1 && this.$store.state.user.approvalCheckData.onOff === 0 && this.userInfo.id == this.targetObject.createUserid) {
                      this.$store.commit('okr/approveRemindObj', {
                        visible: true,
                        objDetail: {
                          id: this.targetObject.id,
                          ...this.targetObject
                        }
                      })
                    }
                  } else {
                    this.$message.error(rest.message)
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          })
          .catch(() => {
            // this.$message({
            //     type: 'info',
            //     msg: '已取消删除'
            // });
          })
      } else {
        this.$message({
          msg: '关键结果至少一个！',
          type: 'warn'
        })
      }
    },
    // 处理权重方法显示编辑
    getKeyResultsListWeights(index) {
      this.oldWeight = this.keyResultsLi.weight // 保存原KR权重
      if (this.targetObject.userRoleType === 0) {
        this.$emit('getKeyResultsListWeights', this.keyResultsLiNumber)
        if (
          this.targetObject.keyresultsList.length !==
          this.keyResultsLiNumber + 1
        ) {
          setTimeout(() => {
            if (this.$refs['keyResultsListInput' + this.keyResultsLi.id]) {
              this.$refs['keyResultsListInput' + this.keyResultsLi.id].focus()
            }
          }, 300)
        }
      }
    },
    // 处理权重上下级修改值
    getKeyHandleChange(index) {
      this.$emit('getKeyHandleChange', index)
    },
    getKeyScoreChange(index) {
      // 0-10 保留1位
      const content = this.keyResultsLi.score || ''
      if (content === '') return
      let inputVal = content
        .replace(/[^\d.]/g, '')
        .toString()
        .replace(/\.{2,}/g, '.')
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.') // 只能输入一个小数点
      if (
        inputVal.length > 1 &&
        inputVal.substr(0, 1) === '0' &&
        inputVal.substr(1, 1) !== '.'
      ) {
        inputVal = inputVal.substr(1, 1)
      }
      if (inputVal.substr(0, 1) === '.') {
        inputVal = ''
      }
      // 只能输入数字
      inputVal =
        inputVal.indexOf('.') > -1
          ? inputVal.slice(0, inputVal.indexOf('.') + 2)
          : inputVal
      inputVal = inputVal < 0 ? 0 : inputVal > 10 ? 10 : inputVal
      this.$emit('getKeyScoreChange', index, inputVal)
    },
    // 点击进度
    getHomeListMouseover: function (keyId) {
      this.$emit('showProcessList', keyId)
    },
    // 处理权重移出保存值
    async getKeyHandleChangeBlur(index) {
      if (this.keyResultsLi.weight === '') {
        this.$message.warn('权重值不能为空')
        this.keyResultsLi.weight = this.oldWeight
      }
      const changeState = this.oldWeight === this.keyResultsLi.weight
      this.$emit('getKeyHandleChangeBlur', index, changeState)
    },
    getKeyScoreChangeBlur(index) {
      if (this.keyResultsLi.score === '') {
        this.$message.warn('评分值不能为空')
        this.keyResultsLi.score = this.oldScore
      }
      const changeState = `${this.oldScore}` === `${this.keyResultsLi.score}`
      this.$emit('getKeyScoreChangeBlur', index, changeState)
    },
    // 处理编辑关键结果名称展现
    getKeyResultsListTitles() {
      const sel = this.$utils.getSelectedClick()
      if (sel) {
        return
      }
      if (this.targetObject.userRoleType !== 0) {
        if (this.targetObject.objectivesProcessDataDto && this.targetObject.objectivesProcessDataDto.businessType === 1) {
          this.$message.warn('OKR正在审批中不能编辑')
        } else {
          this.$message.warn('暂无权限哦！')
        }
        return
      }
      this.$emit('getKeyResultsListTitles', this.keyResultsLiNumber)
    },
    changeObjectiveTitle(type) {
      console.log('swager')
      this.$emit('changeObjectiveTitle', type)
    },
    getKeyResultsAlertClose() {
      this.keyResultsAlertState = false
      this.$emit('draggableSetStatus', true)
      this.$emit('changeObjectiveTitle', '修改成功')
    },
    // 点击展示关键结果下任务列表
    showTaskList(index) {
      this.$emit('showTaskByKey', index)
    },
    // 关键结果编辑刷新状态
    getkeyResultsAddRefresh(state) {
      if (state === '刷新') {
        this.$emit('changeObjectiveTitle', '修改成功')
      } else if (state === '不刷') {
        this.$emit('changeObjectiveTitle', '未修改')
      }
    },
    // 显示评分编辑框
    showKeyResultScore() {
      this.oldScore = this.keyResultsLi.score // 保存原KR权重
      if (
        this.userInfo.id === this.targetObject.createUserid &&
        this.targetObject.canBeScored === 1
      ) {
        this.$emit('showKeyResultScore', this.keyResultsLiNumber)
        setTimeout(() => {
          if (this.$refs['keyResultsListScore' + this.keyResultsLi.id]) {
            this.$refs['keyResultsListScore' + this.keyResultsLi.id].focus()
          }
        }, 300)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.key_results_li {
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  padding: 9px 32px 9px 4px;
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-top: -1px;
  border-radius: 8px;
  &::after {
    content: '';
    width: calc(100% - 30px);
    height: 1px;
    background: #f0f1f6;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &:hover {
    margin-top: -1px;
    background: #f5f6fa;
    &::after {
      opacity: 0;
    }
    .key_results_li_left_box {
      .key_result_dragger_icon_box {
        .key_result_dragger_icon {
          cursor: pointer;
          font-size: 20px;
          color: #6e7491;
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          left: 0px;
          top: 5px;
        }
      }
      .li_key_results_ul_li_okr {
        color: #6e7491;
      }
    }
    .key_results_li_detele {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      line-height: 16px;
      i {
        cursor: pointer;
        font-size: 16px;
        color: #a0a3bd;
        display: block;
      }
      i:hover {
        color: #005eff;
      }
    }
  }
  .key_results_li_left_box {
    display: flex;
    .key_result_dragger_icon_box {
      width: 28px;
      position: relative;
      .key_result_dragger_icon {
        display: none;
      }
    }
    .key_result_dragger_icon_box::before {
      content: '';
      position: absolute;
      // border-bottom: #fff 1px solid;
      width: 32px;
      left: -5px;
      height: calc(100% + 9px);
    }
    .li_key_results_ul_li_okr {
      width: 50px;
      font-size: 14px;
      line-height: 26px;
      margin: 6px 0px;
      font-weight: 500;
      color: #3a78f6;
      position: relative;
      border-radius: 13px;
      text-align: center;
      background: rgba(58, 120, 246, 0.08);
    }
  }
  .key_results_li_right_box {
    flex: 1;
    .li_key_results_ul_li_text {
      flex: 1;
      padding-right: 8px;
      .li_key_results_ul_li_text1 {
        width: 100%;
        padding: 8px 10px;
        text-align: left;
        line-height: 20px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box; // 作为弹性伸缩盒子模型显示。
        // -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式--从上到下垂直排列
        // -webkit-line-clamp: 2; // 显示的行
        word-break: break-word;
        font-size: 14px;
        font-weight: 400;
        color: #14142b;
        position: relative;
        min-height: 32px;
        .flur-wrap {
          right: 10px;
          left: 10px;
          width: calc(100% - 20px);
        }
      }
      .li_key_results_ul_li_text1:hover {
        color: #14142b;
      }
      .li_key_results_ul_li_text2 {
        margin-top: -3px;
        word-break: break-word;
        // padding: 6px 8px;
        width: 100%;
        // border: 1px solid #3a78F6;
        // box-shadow: 0px -3px 10px 0px rgba(50,150,250,0.14) inset;
        border-radius: 8px;
        ::v-deep {
          .editor {
            // min-height: 0;
            margin-top: -6px;
            // line-height: 20px;
            // padding: 0;
            // margin-right: 30px;
          }
        }
      }
    }
    .key_results_li_ul > div:first-child {
      min-width: 28% !important;
      .key_results_li_ul_li_text {
        margin-right: 4px;
      }
    }
    .key_results_li_ul {
      width: 365px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 16px 0px 0px 10px;
      ::v-deep span {
        display: block;
        width: 100%;
      }
      .key_results_li_ul_li3 {
        width: 100% !important;
      }
      .key_results_li_ul_li2 {
        width: 80px;
      }
      .key_results_li_ul_li {
        flex-shrink: 0;
        width: 80px;
        cursor: default;
        display: flex;
        align-items: center;
        .key-result-style {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .key_results_li_ul_li_icon {
          font-size: 18px;
          margin-right: 3px;
          color: #a0a3bd;
        }
        .key_results_li_ul_li_text {
          height: 100%;
          padding-left: 5px;
          font-size: 14px;
          text-align: left;
          color: #a0a3bd;
          line-height: 18px;
        }
        .key_results_li_ul_li_text2 {
          height: 100%;
          line-height: 18px;
          font-size: 14px;
          color: #a0a3bd;
          text-align: left;
          padding-left: 5px;
        }
        .score-text {
          color: #3296fa;
        }
        .active_text {
          color: #14142b;
          &:hover {
            color: #14142b !important;
          }
        }
        .select-icon {
          font-size: 16px;
          margin-top: 1px;
        }
      }
      .key_results_li_ul_li_style {
        cursor: pointer;
      }
      .active-li {
        color: #14142b !important;
      }
      .key_results_li_ul_li_style:hover {
        .key_results_li_ul_li_icon {
          color: #3a78f6;
        }
        .key_results_li_ul_li_text,
        .key_results_li_ul_li_text2 {
          color: #3a78f6 !important;
        }
        .active_text {
          color: #14142b !important;
        }
      }
    }
  }

  .key_results_li_detele {
    display: none;
  }
}

.key_results_li .li_key_results_ul_li_text ::v-deep .at-container .editor {
  margin-top: -10px;
  margin-top: -8px;
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #14142b;
  line-height: 1.5;
  // padding:0 8px;
  overflow-x: hidden;
}
.key_results_li
  .key_results_li_ul
  .key_results_li_ul_li2
  ::v-deep
  .el-input-number {
  height: 48px;
}
.key_results_li
  .key_results_li_ul
  .key_results_li_ul_li2
  ::v-deep
  .el-input-number__increase {
  height: 24px !important;
}
.key_results_li .key_results_li_ul .key_results_li_ul_li2 ::v-deep {
  .el-input__inner {
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    color: #3296fa;
    margin-top: -6px;
    margin-left: -6px;
  }
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #3296fa !important;
    outline: 0;
  }
}
.key_results_li
  .key_results_li_ul
  .key_results_li_ul_li2
  ::v-deep
  .el-input-number__decrease {
  height: 24px !important;
}
.key_results_li
  .key_results_li_ul
  .key_results_li_ul_li2
  ::v-deep
  .el-input-number {
  width: 80px;
  margin: 8px auto 0px auto;
}
.key_results_li_ul_li:hover {
  .key_results_li_ul_li_text,
  .key_results_li_ul_li_text2 {
    color: #a0a3bd;
  }
  .active_text {
    color: #14142b !important;
  }
}
::v-deep .key_results_li_no_border {
  display: flex;
  border-bottom: 1px solid #ffffff00;
  &::after {
    opacity: 0;
  }
  .key_results_li_right_box {
    display: flex;
    justify-content: space-between;
  }
  .key_results_li_ul {
    justify-content: flex-end !important;
    margin: 0px !important;
  }
}

.cursor-text {
  cursor: text;
}
.cursor-no {
  cursor: default;
}
</style>
