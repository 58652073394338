<template>
  <div class="input-number">
    <input
      :disabled="disabled"
      type="number"
      v-model="inputNumber"
      @blur="inputNumberChange"
      maxlength="2"
    />
    <div class="right-num-action">
      <div class="num-action" @click="numPlus">
        <Icon @click="hideOrShow" icon-class="icon-xialajiantou" class="up-down-icon" />
      </div>
      <div class="num-split"></div>
      <div class="num-action" @click="numDel">
        <Icon @click="hideOrShow" icon-class="icon-xialajiantou-1" class="up-down-icon" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'change'
  },

  watch: {
    inputValue: {
      handler(val) {
        this.inputNumber = val
      }
    }
  },
  props: {
    source: {
      type: String
    },
    point: {
      type: Number,
      default: 0
    },
    // 计数器步长
    step: {
      type: Number,
      default: 1
    },
    unit: {
      type: String
    },
    inputValue: {
      type: Number
    },
    disabled: {
      // 否禁用
      type: Boolean,
      default: false
    },
    positiveNumber: {
      default: true
    }

  },
  data() {
    return {
      inputNumber: this.inputValue
    }
  },
  methods: {
    inputNumberChange() {
      // 只能输入正整数
      if ((this.inputNumber < 0 && this.positiveNumber) || (this.inputNumber + '').trim() === '') {
        this.inputNumber = 0
      }
      if (this.inputNumber > 999999999) {
        if ((this.source = 'stopdoing')) {
          this.inputNumber = 999999999
        } else {
          this.inputNumber = 999999999
        }
      }
      this.$emit('change', Number(Number(this.inputNumber).toFixed(this.point)))
    },
    numPlus() {
      if (!this.disabled) {
        if (this.inputNumber === '') {
          this.inputNumber = 0
        } else {
          this.inputNumber += this.step
        }
        this.$emit(
          'change',
          Number(Number(this.inputNumber).toFixed(this.point))
        )
      }
    },
    numDel() {
      if (!this.disabled) {
        if (this.inputNumber === '') {
          this.inputNumber = 0
        } else if (this.inputNumber > 0) {
          this.inputNumber -= this.step
          if (this.source == 'stopdoing') {
            if (this.inputNumber < 0) {
              this.inputNumber = 0
            }
          }
        }
        this.$emit(
          'change',
          Number(Number(this.inputNumber).toFixed(this.point))
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.input-number {
  position: relative;
  height: 34px;
  border-radius: 8px;
  overflow: hidden;
  input {
    background: #fff;
    border: 1px solid #f5f6fa;
    height: 34px;
    border-radius: 8px;
    padding: 0 36px 0 12px;
    width: 100%;
    &:focus {
      border-color: #3a70ff;
      box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
    }
  }
  ::v-deep {
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
  .right-num-action {
    position: absolute;
    width: 30px;
    height: 32px;
    right: 1px;
    top: 1px;
    border-radius: 0 8px 8px 0;
    background: #f5f6fa;
    .num-action {
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .up-down-icon {
        height: 16px;
        width: 16px;
        color: #a0a3bd;
        &:hover {
          color: #6e7491;
        }
      }
    }
    .num-split {
      height: 1px;
      width: 14px;
      margin: 0 auto;
      background: #d9dbe9;
    }
  }
}
</style>
