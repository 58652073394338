var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        "popper-class": "task-type-pop",
        trigger: "manual",
        placement: "bottom-start",
      },
      model: {
        value: _vm.popShow,
        callback: function ($$v) {
          _vm.popShow = $$v
        },
        expression: "popShow",
      },
    },
    [
      _vm.type == "stopdoing"
        ? _c(
            "div",
            {
              staticClass: "stopdoing-box",
              style: _vm.disabled ? "cursor: default;" : "",
              attrs: { slot: "reference" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showPop.apply(null, arguments)
                },
              },
              slot: "reference",
            },
            [
              _c("Icon", {
                staticClass: "classify-icon",
                attrs: { "icon-class": "icon-biangengguishu" },
              }),
              _vm._v("变更所属 "),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "classify-box",
              style: _vm.disabled ? "cursor: default;" : "",
              attrs: { slot: "reference" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showPop.apply(null, arguments)
                },
              },
              slot: "reference",
            },
            [
              _c("Icon", {
                staticClass: "classify-icon",
                attrs: { "icon-class": _vm.activeObj.icon },
              }),
              _vm._v(" " + _vm._s(_vm.activeObj.name) + " "),
              !_vm.disabled
                ? _c("Icon", {
                    staticClass: "arrow-icon",
                    attrs: { "icon-class": "icon-xiangxia" },
                  })
                : _vm._e(),
            ],
            1
          ),
      !_vm.showProject
        ? _c(
            "div",
            { staticClass: "task-type-list" },
            [
              _c(
                "div",
                { staticClass: "pop-title" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.type == "stopdoing" ? "变更所属" : "任务分类"
                      ) +
                      " "
                  ),
                  _c("Icon", {
                    staticClass: "close-icon",
                    attrs: { "icon-class": "icon-guanbi2" },
                    on: { click: _vm.closePop },
                  }),
                ],
                1
              ),
              _c(
                "taskKr",
                {
                  ref: "taskKrPopRef",
                  attrs: {
                    source: _vm.type,
                    "custom-slot": true,
                    placement: "right",
                    "select-kr": _vm.selectKr,
                  },
                  on: { save: _vm.saveKr },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "task-type-item",
                      on: {
                        click: function ($event) {
                          return _vm.selectConfidence(_vm.krObj)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "classify-box classify-box-select" },
                        [
                          _c("Icon", {
                            staticClass: "classify-icon",
                            attrs: { "icon-class": _vm.krObj.icon },
                          }),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.type == "stopdoing"
                                    ? "变更为关键结果KR任务"
                                    : _vm.krObj.name
                                ) +
                                _vm._s() +
                                " "
                            ),
                          ]),
                          _vm.activeObj.val === _vm.krObj.val
                            ? _c("Icon", {
                                staticClass: "check-icon",
                                attrs: { "icon-class": "icon-duigou" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm.type == "stopdoing"
                ? _c(
                    "div",
                    { staticClass: "task-type-item" },
                    [
                      _c(
                        "el-popover",
                        {
                          ref: "taskPorjects",
                          attrs: { placement: "right", trigger: "click" },
                        },
                        [
                          _c("div", { staticClass: "pm-content-div" }, [
                            _c("div", { staticClass: "pm-title" }, [
                              _vm._v("变更为项目任务"),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "scroll-bottom",
                                    rawName: "v-scroll-bottom",
                                    value: _vm.loadMore,
                                    expression: "loadMore",
                                  },
                                ],
                                staticClass: "project-list",
                                staticStyle: { "margin-top": "12px" },
                                attrs: { "data-scroll-type": "projectList" },
                              },
                              [
                                _vm._l(_vm.projectList, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "project-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectProjectItem(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        style: "background:" + item.colour,
                                        attrs: { src: item.logo },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "project-name" },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _vm.selectProject.id === item.id
                                        ? _c("Icon", {
                                            staticClass: "check-icon",
                                            attrs: {
                                              "icon-class": "icon-duigou",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                _vm.projectList.length === 0
                                  ? _c(
                                      "div",
                                      { staticClass: "no-project" },
                                      [
                                        _c("Img", {
                                          attrs: {
                                            src: "/common/commentNull.png",
                                          },
                                        }),
                                        _vm._v("暂无数据 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "project-cont-footer" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "cancel-primary common_btn_next_add",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.cancelHidden.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("取 消")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "next-primary common_btn_next_add",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.saveProject.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("完 成")]
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "classify-box classify-box-select",
                              attrs: { slot: "reference" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectConfidence(_vm.projectObj)
                                },
                              },
                              slot: "reference",
                            },
                            [
                              _c("Icon", {
                                staticClass: "classify-icon",
                                attrs: { "icon-class": _vm.projectObj.icon },
                              }),
                              _c("span", [_vm._v("变更为项目任务")]),
                              _vm.activeObj.val === _vm.projectObj.val
                                ? _c("Icon", {
                                    staticClass: "check-icon",
                                    attrs: { "icon-class": "icon-duigou" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type != "stopdoing"
                ? _c(
                    "div",
                    {
                      staticClass: "task-type-item",
                      on: {
                        click: function ($event) {
                          return _vm.selectConfidence(_vm.projectObj)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "classify-box classify-box-select" },
                        [
                          _c("Icon", {
                            staticClass: "classify-icon",
                            attrs: { "icon-class": _vm.projectObj.icon },
                          }),
                          _c("span", [_vm._v(_vm._s(_vm.projectObj.name))]),
                          _vm.activeObj.val === _vm.projectObj.val
                            ? _c("Icon", {
                                staticClass: "check-icon",
                                attrs: { "icon-class": "icon-duigou" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.showProject
        ? _c(
            "div",
            { staticClass: "task-type-list" },
            [
              _c(
                "div",
                { staticClass: "pop-title" },
                [
                  _c("Icon", {
                    staticClass: "close-icon",
                    attrs: { "icon-class": "icon-back-arrow" },
                    on: { click: _vm.backMain },
                  }),
                  _vm._v("项目任务 "),
                  _c("Icon", {
                    staticClass: "close-icon",
                    attrs: { "icon-class": "icon-guanbi2" },
                    on: { click: _vm.closePop },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "project-select" },
                [
                  _vm._v(" 项目 "),
                  _c(
                    "el-popover",
                    {
                      ref: "taskPorjects",
                      attrs: {
                        trigger: "click",
                        "popper-class": "task-project-pop",
                        offset: 23,
                        placement: "bottom-end",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "select-item",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.selectProject.name || "请选择") +
                              " "
                          ),
                          _c("Icon", {
                            staticClass: "arrow-icon",
                            attrs: { "icon-class": "icon-xiangxia" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "scroll-bottom",
                              rawName: "v-scroll-bottom",
                              value: _vm.loadMore,
                              expression: "loadMore",
                            },
                          ],
                          staticClass: "project-list",
                          attrs: { "data-scroll-type": "projectList" },
                        },
                        [
                          _vm._l(_vm.projectList, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "project-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectProjectItem(item)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  style: "background:" + item.colour,
                                  attrs: { src: item.logo },
                                }),
                                _c("div", { staticClass: "project-name" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _vm.selectProject.id === item.id
                                  ? _c("Icon", {
                                      staticClass: "check-icon",
                                      attrs: { "icon-class": "icon-duigou" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          _vm.projectList.length === 0
                            ? _c(
                                "div",
                                { staticClass: "no-project" },
                                [
                                  _c("Img", {
                                    attrs: { src: "/common/commentNull.png" },
                                  }),
                                  _vm._v("暂无数据 "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "confirm-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.saveProject },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }