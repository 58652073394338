var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          title: "欢迎您使用源目标 AI 助手",
          visible: _vm.dialogVisible,
          width: "680px",
          "close-on-click-modal": false,
          "append-to-body": true,
          "before-close": _vm.handleClose,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "ai-set-wrap" },
        [
          _c(
            "el-form",
            {
              ref: "informationsForm",
              attrs: {
                slot: "cont",
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "0px",
              },
              slot: "cont",
            },
            [
              _c("ul", { staticClass: "base-info f-z-12" }, [
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "info-item m-b-30",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "m-r-15 setting_span setting_span_avatar",
                      },
                      [_vm._v("企业logo：")]
                    ),
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-uploader float_lf",
                        attrs: {
                          "show-file-list": false,
                          action: "",
                          accept: "image/png, image/jpg, image/jpeg",
                          "http-request": _vm.upLoadsCompress,
                          "on-success": _vm.handleAvatarSuccess,
                          "before-upload": _vm.beforeAvatarUpload,
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _vm.companyInfo.logo
                              ? _c("el-image", {
                                  staticClass: "avatar",
                                  attrs: {
                                    src: _vm.companyInfo.logo,
                                    fit: "cover",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      _vm.img_state = 1
                                    },
                                    mouseleave: function ($event) {
                                      _vm.img_state = 0
                                    },
                                  },
                                })
                              : _c("img", {
                                  staticClass: "m-r-10 avatar-img",
                                  attrs: {
                                    src: require("../../../../../assets/imgs/header/logo.png"),
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      _vm.img_state = 1
                                    },
                                    mouseleave: function ($event) {
                                      _vm.img_state = 0
                                    },
                                  },
                                }),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.img_state == 1,
                                  expression: "img_state == 1",
                                },
                              ],
                              staticClass: "avatar-img",
                              staticStyle: {
                                position: "absolute",
                                left: "0",
                                top: "0",
                              },
                              attrs: {
                                src: require("../../../../../assets/imgs/header/li_head_change_icon.png"),
                              },
                              on: {
                                mouseover: function ($event) {
                                  _vm.img_state = 1
                                },
                                mouseleave: function ($event) {
                                  _vm.img_state = 0
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "info-item m-b-30",
                  },
                  [
                    _c("span", { staticClass: "m-r-15 setting_span" }, [
                      _vm._v("主管理员："),
                    ]),
                    _vm.companyInfo_.belongerName
                      ? _c(
                          "p",
                          { staticClass: "setting_p" },
                          [
                            _c("wx-open-data", {
                              attrs: {
                                id: _vm.companyInfo_.belongerName,
                                nameData: {
                                  name: _vm.companyInfo_.belongerName,
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "info-item m-b-30",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "m-r-15 setting_span setting-self" },
                      [_vm._v("企业简称：")]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "float_lf float_lf_w480",
                        attrs: { label: "", prop: "enterpriseName" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inp",
                          attrs: {
                            "auto-complete": "off",
                            placeholder: "请输入企业名称",
                            disabled: _vm.$isQw || _vm.$isDD,
                          },
                          on: { input: _vm.nameInput },
                          model: {
                            value: _vm.form.enterpriseName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "enterpriseName", $$v)
                            },
                            expression: "form.enterpriseName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "info-item m-b-30",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "m-r-15 setting_span setting-self" },
                      [_vm._v("企业全称：")]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "float_lf",
                        attrs: { label: "", prop: "attestationCompanyName" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inp full-name",
                          attrs: {
                            "auto-complete": "off",
                            placeholder: "请输入企业全称",
                            disabled:
                              (_vm.form.attestationState || "") == 1 ||
                              _vm.$isQw ||
                              _vm.$isDD,
                          },
                          on: { input: _vm.nameInput },
                          model: {
                            value: _vm.form.attestationCompanyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "attestationCompanyName", $$v)
                            },
                            expression: "form.attestationCompanyName",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "attestation-state",
                            class:
                              (_vm.form.attestationState || "") == 1
                                ? "blue"
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                (_vm.form.attestationState || "") == 1
                                  ? "已认证"
                                  : "未认证"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "ai-infor-wrap" }, [
                  _c("div", { staticClass: "ai-title" }, [
                    _vm._v(
                      "源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR，同时我们将会帮助您生产指导性的工作任务"
                    ),
                  ]),
                  _c("div", { staticClass: "remind-title" }, [
                    _vm._v(
                      "在正式使用AI助写功能前，请填写以下内容，以便于AI助手更好的帮助您输出内容"
                    ),
                  ]),
                ]),
                _c(
                  "li",
                  { staticClass: "info-item m-b-30" },
                  [
                    _c(
                      "span",
                      { staticClass: "m-r-15 setting_span setting-self" },
                      [_vm._v("成立时间：")]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el_select_lf float_lf_w480",
                        attrs: { label: "", prop: "establishmentDate" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "float_lf_w480",
                            attrs: {
                              "poper-append-to-body": false,
                              placeholder: "请选择成立时间",
                            },
                            model: {
                              value: _vm.form.establishmentDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "establishmentDate", $$v)
                              },
                              expression: "form.establishmentDate",
                            },
                          },
                          _vm._l(_vm.createTimeList, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "info-item m-b-30" },
                  [
                    _c(
                      "span",
                      { staticClass: "m-r-15 setting_span setting-self" },
                      [_vm._v("企业规模：")]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el_select_lf float_lf_w480",
                        attrs: { label: "", prop: "enterpriseType" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "float_lf_w480",
                            attrs: {
                              "poper-append-to-body": false,
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.form.enterpriseType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "enterpriseType", $$v)
                              },
                              expression: "form.enterpriseType",
                            },
                          },
                          _vm._l(_vm.enterpriseType, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.value, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "info-item m-b-30" },
                  [
                    _c(
                      "span",
                      { staticClass: "m-r-15 setting_span setting-self" },
                      [_vm._v("所属行业：")]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el_select_lf float_lf_w240 m_lf_16",
                        attrs: { label: "", prop: "industryCategory" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              "poper-append-to-body": false,
                              clearable: "",
                              placeholder: "请选择",
                            },
                            on: { change: _vm.choseIndustryCategory },
                            model: {
                              value: _vm.form.industryCategory,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "industryCategory", $$v)
                              },
                              expression: "form.industryCategory",
                            },
                          },
                          _vm._l(_vm.industryCategory, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.value, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el_select_lf float_lf_w240",
                        attrs: { label: "" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              "poper-append-to-body": false,
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.form.industryCategory_1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "industryCategory_1", $$v)
                              },
                              expression: "form.industryCategory_1",
                            },
                          },
                          _vm._l(_vm.industryCategory_1, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.value, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "info-item m-b-30" },
                  [
                    _c("span", { staticClass: "m-r-15 setting_span" }, [
                      _vm._v("企业使命："),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "float_lf float_lf_w480",
                        attrs: { label: "", prop: "mission" },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              "open-delay": 300,
                              content: _vm.form.mission,
                              disabled: !_vm.form.mission,
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inp",
                              attrs: {
                                type: "textarea",
                                rows: 5,
                                "auto-complete": "off",
                                placeholder: "请输入企业使命",
                              },
                              on: { input: _vm.missionInput },
                              model: {
                                value: _vm.form.mission,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "mission", $$v)
                                },
                                expression: "form.mission",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "info-item m-b-30" },
                  [
                    _c("span", { staticClass: "m-r-15 setting_span" }, [
                      _vm._v("企业愿景："),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "float_lf float_lf_w480",
                        attrs: { label: "", prop: "vision" },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              "open-delay": 300,
                              content: _vm.form.vision,
                              disabled: !_vm.form.vision,
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inp",
                              attrs: {
                                type: "textarea",
                                rows: 5,
                                "auto-complete": "off",
                                placeholder: "请输入企业愿景",
                              },
                              on: { input: _vm.visionInput },
                              model: {
                                value: _vm.form.vision,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "vision", $$v)
                                },
                                expression: "form.vision",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "info-item m-b-30" },
                  [
                    _c("span", { staticClass: "m-r-15 setting_span" }, [
                      _vm._v("企业价值观："),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "float_lf float_lf_w480",
                        attrs: { label: "", prop: "corporateValues" },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              "open-delay": 300,
                              content: _vm.form.corporateValues,
                              disabled: !_vm.form.corporateValues,
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inp",
                              class: _vm.isTextareaInfo ? "textarea-info" : "",
                              attrs: {
                                type: "textarea",
                                rows: 5,
                                "auto-complete": "off",
                                placeholder: "请输入价值观",
                              },
                              on: {
                                input: _vm.senseOfWorthInput,
                                blur: _vm.senseOfWorthBlue,
                              },
                              model: {
                                value: _vm.form.corporateValues,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "corporateValues", $$v)
                                },
                                expression: "form.corporateValues",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "ai-count-wrap" }, [
                  _vm._v(" 剩余可调用次数： "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.companyInfo.aiUsageCount || 0)),
                  ]),
                  _vm._v(" 次 （更多订阅请咨询售前顾问） "),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "bottom-step-buttons" },
            [
              _c(
                "el-button",
                { staticClass: "left-button", on: { click: _vm.handleClose } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.saveBtn("informationsForm")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }