<template>
  <el-dialog
    title="编辑描述"
    :visible.sync="dialogVisible"
    width="780px"
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="handleClose"
    v-on="$listeners"
  >
    <div class="content-wrap">
      <div class="editor-wrap">
        <Editor v-model="description" :height="260" :width="`100%`" />
      </div>
      <div class="bottom-step-buttons">
        <el-button @click="handleClose" class="left-button">取消</el-button>
        <el-button @click="submit()">保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  components: {
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    content: {
      default: ''
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
        this.description = this.content
      },
      immediate: true
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      description: ''
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    submit(type) {
      this.$emit('submit', this.description)
      this.handleClose()
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
::v-deep {
  .el-dialog__body {
    padding: 24px 32px !important;
  }
}
.content-wrap {
  min-height: 300px;
}
.bottom-step-buttons {
  margin: 32px 0px 0px 0px;
  justify-content: center;
  .left-button,
  .el-button {
    height: 32px;
    border-radius: 8px;
  }
  .left-button,
  .system-button {
    margin-right: 8px;
  }
}
</style>
