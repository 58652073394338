var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      ref: "permissionSelectRef",
      staticClass: "permission-select",
      on: { change: _vm.changeValue },
      model: {
        value: _vm.optionValue,
        callback: function ($$v) {
          _vm.optionValue = $$v
        },
        expression: "optionValue",
      },
    },
    [
      _vm._l(_vm.options, function (item) {
        return _c(
          "el-option",
          {
            key: item.value,
            staticClass: "permission-select-option",
            attrs: { value: item.value, label: item.label },
          },
          [
            _c("div", { staticClass: "option-label" }, [
              _vm._v(_vm._s(item.label)),
            ]),
            _c("div", { staticClass: "option-describe" }, [
              _vm._v(_vm._s(item.describe)),
            ]),
          ]
        )
      }),
      _c("div", { staticClass: "permission-select-del-btn-box" }, [
        _c("div", { staticClass: "del-btn", on: { click: _vm.delItem } }, [
          _vm._v("删除"),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }