var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "append-to-body": true,
        center: "",
        size: 360,
        "modal-append-to-body": true,
        "before-close": _vm.dialogClose,
        "custom-class": "workhours-static-dialog",
        "show-close": true,
        title: "工时统计",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "workhours-static-main" }, [
        _c(
          "div",
          {
            staticClass: "look-detailed",
            on: {
              click: function ($event) {
                return _vm.lookDetailed()
              },
            },
          },
          [_vm._v("查看明细")]
        ),
        _c("div", { staticClass: "static-div" }, [
          _c("div", { staticClass: "static-left" }, [
            _c("div", { staticClass: "static-plan" }, [
              _c("div", { staticClass: "plan-title-div" }, [
                _c("div", { staticClass: "title" }, [_vm._v("计划工时")]),
                !_vm.disabled
                  ? _c(
                      "div",
                      {
                        staticClass: "operation",
                        on: {
                          click: function ($event) {
                            return _vm.openSetWorkHours("1")
                          },
                        },
                      },
                      [_vm._v("+ 预估")]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "plan-data-div" }, [
                _c("span", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.planHourTotal)),
                ]),
                _c("span", { staticClass: "unit" }, [_vm._v("小时")]),
              ]),
            ]),
            _c("div", { staticClass: "static-reality" }, [
              _c("div", { staticClass: "plan-title-div" }, [
                _c("div", { staticClass: "title" }, [_vm._v("实际工时")]),
                !_vm.disabled
                  ? _c(
                      "div",
                      {
                        staticClass: "operation",
                        on: {
                          click: function ($event) {
                            return _vm.openSetWorkHours("2")
                          },
                        },
                      },
                      [_vm._v("+ 登记")]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "plan-data-div" }, [
                _c("span", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.actualHourTotal)),
                ]),
                _c("span", { staticClass: "unit" }, [_vm._v("小时")]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "static-right" },
            [
              _c("div", { staticClass: "static-title" }, [_vm._v("工时")]),
              _c("Echart", { ref: "base" }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }