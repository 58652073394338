<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="key-result-add-content" v-show="!isAiAdd">
    <!-- <transition name="fade" mode="out-in"> -->
    <div class="keyResultsAdd">
      <div class="keyResultsAddText">KR</div>
      <div class="keyResultsAddInput">
        <AdviceInput
          v-model="titlePopShow"
          :html="$utils.changeToHtml(form.comment)"
          :type="2"
          :edit-ignore="isEdit && !noRequest"
          :dict-info="editInfo"
          :ignore-rule-id-list="form.ignoreRuleIdList"
          placement="top-start"
          :key="form"
          @addIgnore="addIgnoreRule"
        >
          <at-input
            :ref="`atCommentBoxinput${isEdit ? editInfo.id : ''}`"
            limit="150"
            :isBgColor="false"
            :html="$utils.changeToHtml(form.comment)"
            :editable="true"
            :enter-able="true"
            :placeholder="placeholder"
            @focus="objTitleFocus"
            @enter="changeObjectiveTitle($event)"
            @input="changeTitle($event)"
          />
        </AdviceInput>
      </div>
      <div class="keyResultsAddIcon">
        <at-user-dialog @selectPerson="atPerson">
          <Icon icon-class="icon-at" class="at-icon" />
        </at-user-dialog>
      </div>
    </div>
    <!-- </transition> -->
    <!-- <transition name="fade" mode="out-in"> -->
    <div class="kr-option-box">
      <quantify-kr
        :unit-form="unitForm"
        :is-okr-list="true"
        :disabled="automationState"
        key="unitForm"
      />
      <div class="action-btns">
        <div class="add-task-ai" @click="addKrkAi(true)" v-if="fromType !=='addOkrAiList'">
          <Icon icon-class="icon-ai" class="add-icon" />AI助写
        </div>
        <div class="bottoms-comm-dialog-btns cancel-btn btn-white" @click="cancel">取消</div>
        <l-button
          :loading="loading"
          class="bottoms-comm-dialog-btns save-btn btn-blue"
          @clickEvt="saveKrTitle"
        >保存</l-button>
      </div>
    </div>
    <!-- </transition> -->
    <add-kr-ai
      :objData="okrDataObj"
      :editInfo="editInfo"
      :kr-index="krIndex"
      :isEdit="isEdit"
      v-if="addKrAiVisible"
      v-model="addKrAiVisible"
      :fileList="fileList"
      :sId="sId"
      @refresh="addKrAisubmit"
      @close="addKrAiClose"
    />
  </div>
  <!-- </transition> -->
</template>
<script>
import addKrAi from '@/views/okr/components/singleOkrItem/addKrAi/index.vue'
import atUserDialog from './atUserDialog'
import quantifyKr from './quantifyKeyResult'
import AdviceInput from '../AdviceInput'
const targetAddTitle = require('../../dialog/addTarget/addTargetTitle.json')
export default {
  components: {
    atUserDialog,
    quantifyKr,
    AdviceInput,
    addKrAi
  },
  props: {
    objId: {
      type: Number,
      required: false
    },
    isShare: {
      type: Number,
      require: false
    },
    isEdit: {
      // 是否编辑
      type: Boolean,
      default: false
    },
    editInfo: {
      // 编辑信息
      type: Object
    },
    noRequest: {
      // 新增目标时使用，无请求
      type: Boolean
    },
    okrDataObj: {
      type: Object
    },
    fromType: {
      default: ''
    },
    krIndex: {
      default: 0
    },
    isAiAdd: {
      default: false
    },
    fileList: {
      default: () => {
        return []
      }
    },
    sId: {
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      form: {
        // 评论内容
        comment: '', // 评论
        userId: null, // at的人id
        ignoreRuleIdList: [] // 忽略的规则id
      },
      atstate: true,
      detailsId: '',
      periodMemberList: [], // 可@列表
      placeholder: '',
      unitForm: {
        quantificationType: 0, // 量化条件
        quantificationUnit: '', // 单位
        quantificationStart: 0,
        quantificationEnd: 100,
        quantificationStatus: 0,
        quantificationActualValue: 0
      },
      ignoreStateChange: false,
      automationState: false, // 单位是否禁用
      addKrAiVisible: false
    }
  },
  watch: {
    editInfo: {
      handler(val) {
        console.log('editInfo', this.editInfo)
        this.initData()
      },
      immediate: true
    }
  },
  computed: {},
  created() {
    this.detailsId = this.objId
    const indexz = Math.floor(Math.random() * 7 + 1)
    this.placeholder = targetAddTitle.objects2[indexz] + ' ↵'
    this.initData()
  },
  methods: {
    addKrAiClose() {
      if (this.isAiAdd) {
        this.cancel()
      }
    },
    initData() {
      if (this.isEdit) {
        // 编辑状态
        this.form.comment = this.$utils.changeToOkrtFormat(this.editInfo.comment)
        this.form.ignoreRuleIdList = this.editInfo.ignoreRuleIdList
          ? this.editInfo.ignoreRuleIdList
          : this.editInfo.ignoreObjectivesRuleMatchDtoList.map(
              (item) => item.writeRuleId
            ) || []
        this.unitForm.quantificationType = this.editInfo.quantificationType
        this.unitForm.quantificationUnit = this.editInfo.quantificationUnit
        this.unitForm.quantificationStart = this.editInfo.quantificationStart
        this.unitForm.quantificationEnd = this.editInfo.quantificationEnd
        this.unitForm.quantificationStatus = this.editInfo.quantificationStatus
        this.unitForm.quantificationActualValue =
          this.editInfo.quantificationActualValue
        if (this.editInfo.automationState === 1) {
          this.automationState = true
        }
      }
      if (this.isAiAdd) {
        this.addKrAiVisible = true
      }
    },
    addKrAisubmit(data) {
      this.form.comment = data.comment
      this.form.html = data.html
      this.form.atIds = data.atIds
      this.unitForm.quantificationType = data.quantificationType
      this.unitForm.quantificationUnit = data.quantificationUnit
      this.unitForm.quantificationStart = data.quantificationStart
      this.unitForm.quantificationEnd = data.quantificationEnd
      this.form = JSON.parse(JSON.stringify(this.form))
      this.unitForm = JSON.parse(JSON.stringify(this.unitForm))
      console.log('addKrAisubmit', data)
      this.changeObjectiveTitle(this.form)
      this.addKrkAi(false)
    },
    addKrkAi(state) {
      this.addKrAiVisible = state
    },
    addIgnoreRule(id) {
      this.ignoreStateChange = true
      this.form.ignoreRuleIdList.push(id)
    },
    getAddkeyList() {
      this.$emit('getAddkeyList', '按钮')
    },
    // 获取焦点事件
    objTitleFocus(obj, index) {
      console.log('obj')
      console.log('obj')
    },
    // 添加@人
    atPerson(addHtml, userInfo) {
      console.log(this.form.comment)
      this.$refs[
        `atCommentBoxinput${this.isEdit ? this.editInfo.id : ''}`
      ].addAtMember(addHtml)
    },
    saveKrTitle() {
      this.loading = true
      this.$emit('saveKrTitle')
      this.$refs[
        `atCommentBoxinput${this.isEdit ? this.editInfo.id : ''}`
      ].enterEvent()
    },
    cancel() {
      this.$emit('getkeyResultsAddRefresh', '不刷')
      this.$emit('cancel')
    },
    changeTitle(obj) {
      this.form.comment = obj.html
      this.form.userId = obj.atIds.toString()
    },
    // 修改关键结果内容
    changeObjectiveTitle(obj, index) {
      console.log('changeObjectiveTitle', obj)
      if (
        this.unitForm.quantificationType === 0 &&
        Number(this.unitForm.quantificationStart) >=
          Number(this.unitForm.quantificationEnd)
      ) {
        this.$message({
          msg: '目标值不能小于等于初始值！',
          type: 'warn'
        })
        this.loading = false
        return
      }
      if (this.noRequest) {
        this.loading = false
        console.log('saveContent11', {
          obj,
          unitForm: this.unitForm,
          ignoreRuleIdList: this.form.ignoreRuleIdList
        })
        this.$emit('saveContent', {
          obj,
          unitForm: this.unitForm,
          ignoreRuleIdList: this.form.ignoreRuleIdList
        })
        return
      }
      // if (this.isShare === 1 && obj.atIds.toString() !== '') {
      //   this.$message({
      //     msg: '您的目标已设为私密，不能@他人哦',
      //     type: 'warn'
      //   })
      //   this.$emit('getkeyResultsAddRefresh', '不刷')
      //   return
      // }
      this.form.comment = obj.html
      this.form.userId = obj.atIds.toString()
      if (this.form.comment.trim() !== '') {
        if (!this.isEdit) {
          this.addKeyResult()
          // this.$emit('getkeyResultsAddRefresh', '不刷')
        } else {
          this.updateKeyResult(obj)
        }
      } else {
        this.$message({
          msg: '关键结果不能为空！',
          type: 'warn'
        })
        this.loading = false
      }
    },
    async addKeyResult() {
      this.unitForm.quantificationActualValue =
        this.unitForm.quantificationStart
      const obj = Object.assign(this.unitForm, {
        collaboratorStr: this.form.userId, // @人员id
        objId: this.detailsId, // 目标id
        title: this.$utils.changeToOkrtFormat(this.form.comment), // 关键结果title
        titleSearch: this.$utils.tagFilter(this.form.comment), // 关键结果title，内容不包含标签
        ignoreRuleIdList: this.form.ignoreRuleIdList
      })
      this.$apis
        .OKRADDKR(obj)
        .then((rest) => {
          if (rest.status === 200) {
            this.$message({
              msg: '关键结果添加成功',
              type: 'success'
            })
            this.loading = false
            this.$emit('getkeyResultsAddRefresh', '刷新')
            if (localStorage.getItem('approveRemindState') !== 'true' && this.okrDataObj && this.okrDataObj.processState === 1 && this.$store.state.user.approvalCheckData.onOff === 0 && this.userInfo.id == this.okrDataObj.createUserid) {
              this.$store.commit('okr/approveRemindObj', {
                visible: true,
                objDetail: {
                  id: this.okrDataObj.id,
                  ...this.okrDataObj
                }
              })
            }
          } else {
            this.loading = false
            this.$message.error(rest.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 修改关键结果内容
    updateKeyResult(obj) {
      // return;
      let editState = false
      // 判断量化条件是否有修改
      if (
        this.unitForm.quantificationType !== this.editInfo.quantificationType
      ) {
        editState = true
      } else {
        if (
          this.unitForm.quantificationType === 0 &&
          (this.unitForm.quantificationUnit !==
            this.editInfo.quantificationUnit ||
            this.unitForm.quantificationStart !==
              this.editInfo.quantificationStart ||
            this.unitForm.quantificationEnd !== this.editInfo.quantificationEnd)
        ) {
          editState = true
        }
      }

      if (
        this.unitForm.quantificationActualValue <
        this.unitForm.quantificationStart
      ) {
        this.unitForm.quantificationActualValue =
          this.unitForm.quantificationStart
      }
      if (
        this.unitForm.quantificationActualValue >
        this.unitForm.quantificationEnd
      ) {
        this.unitForm.quantificationActualValue =
          this.unitForm.quantificationEnd
      }
      if (this.editInfo.comment2 !== obj.html) {
        this.updateKeyResultTitle(obj)
      } else if (editState) {
        this.updateQuantification()
      } else if (this.ignoreStateChange) {
        this.$emit('changeObjectiveTitle', '修改成功')
      } else {
        this.$emit('changeObjectiveTitle', '未修改')
      }
    },

    // 修改标题
    async updateKeyResultTitle(obj) {
      this.editInfo.comment = obj.html
      this.editInfo.userId = obj.atIds.toString()
      this.$apis
        .OKRUPDATEKRTITLE(
          Object.assign(this.unitForm, {
            collaboratorStr: this.editInfo.userId, // @人员id
            id: this.editInfo.id, // 目标id
            objId: this.editInfo.objId, // 关键结果id
            title: this.$utils.changeToOkrtFormat(this.editInfo.comment), // 关键结果title
            titleSearch: this.$utils.tagFilter(this.editInfo.comment) // 关键结果title，内容不包含标签
          })
        )
        .then((rest) => {
          if (rest.status === 200) {
            console.log('dfdfdf', this.okrDataObj)
            if (localStorage.getItem('approveRemindState') !== 'true' && this.okrDataObj && this.okrDataObj.processState === 1 && this.$store.state.user.approvalCheckData.onOff === 0 && this.userInfo.id == this.okrDataObj.createUserid) {
              this.$store.commit('okr/approveRemindObj', {
                visible: true,
                objDetail: {
                  id: this.okrDataObj.id,
                  ...this.okrDataObj
                }
              })
            }
            this.$emit('changeObjectiveTitle', '修改成功')
          } else if (rest.status === 62003) {
            this.$message({
              msg: '私有目标，不能@人',
              type: 'error'
            })
          } else {
            this.$message({
              msg: rest.message,
              type: 'error'
            })
          }
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // 修改量化条件
    async updateQuantification() {
      const obj = Object.assign(this.unitForm, {
        id: this.editInfo.id // 关键结果id
      })
      this.$apis
        .OKRUPDATEKRQUANTITY(obj)
        .then((rest) => {
          if (rest.status === 200) {
            this.$emit('changeObjectiveTitle', '修改成功')
            if (this.fromType !== 'approvalDetail' && localStorage.getItem('approveRemindState') !== 'true' && this.okrDataObj && this.okrDataObj.processState === 1 && this.$store.state.user.approvalCheckData.onOff === 0) {
              this.$store.commit('okr/approveRemindObj', {
                visible: true,
                objDetail: {
                  id: this.okrDataObj.id,
                  ...this.okrDataObj
                }
              })
            }
          } else {
            this.$message.error(rest.message)
          }
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.add-task-ai {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #005eff;
  margin-right: 8px;
  .add-icon {
    margin-right: 6px;
  }
  &:hover {
    opacity: 0.54;
  }
}
.key-result-add-content {
  .kr-option-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    min-height: 46px;
    .action-btns {
      display: flex;
      margin-left: auto;
      justify-content: flex-end;
      align-items: center;
      height: 46px;
    }
    .bottoms-comm-dialog-btns {
      height: 32px;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      border-radius: 8px;
      padding: 0px 12px;
    }
    .cancel-btn {
      color: #3a78f6;
      border: 1px solid #3a78f6;
      margin-right: 8px;
      &:hover {
        background: rgba(58, 120, 246, 0.06);
        border: 1px solid rgba(58, 120, 246, 0.87);
      }
    }
    .save-btn {
      background: #3a78f6;
      color: #fff;
      border: 1px solid #3a78f6;
      &:hover {
        background: rgba(58, 120, 246, 0.87);
        border: 1px solid rgba(58, 120, 246, 0.87);
      }
    }
  }
}
.keyResultsAdd {
  width: 100%;
  display: flex;
  min-height: 40px;
  box-sizing: border-box;
  padding: 2px 30px 2px 20px;
  background: #fff;
  border: 1px solid #3a78f6;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px -3px 10px 0px rgba(50, 150, 250, 0.14) inset;
  .keyResultsAddText {
    width: 24px;
    height: 36px;
    line-height: 36px;
    color: #3a78f6;
  }
  .keyResultsAddInput {
    flex: 1;
    color: #14142b;
    // height: 36px;
    line-height: 20px;
    height: 100%;
    // overflow-x: hidden;
  }
  .keyResultsAddIcon {
    height: 100%;
    position: absolute;
    right: 16px;
    top: 10px;
    .at-icon {
      cursor: pointer;
      font-size: 20px;
      color: #3a78f6;
      &:hover {
        color: #005eff;
      }
    }
  }
}
</style>
