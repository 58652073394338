var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kpi-verhicle-steps" },
    _vm._l(_vm.steps, function (item, index) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "step-item",
          class: Number(_vm.activeStep) < item.id ? "" : "active-step",
        },
        [
          _c("div", { staticClass: "step-item-title" }, [
            _vm._v(" " + _vm._s(item.name) + " "),
          ]),
          _c(
            "div",
            {
              staticClass: "step-line",
              class:
                Number(_vm.activeStep) === item.id ? "active-step-line" : "",
            },
            [
              _c("div", {
                staticClass: "step-split-line",
                class: index === 0 ? "no-show-line" : "",
              }),
              _c("div", {
                staticClass: "step-circle",
                on: {
                  click: function ($event) {
                    return _vm.clickStep(item)
                  },
                },
              }),
              _c("div", {
                staticClass: "step-split-line",
                class: index === _vm.steps.length - 1 ? "no-show-line" : "",
              }),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }