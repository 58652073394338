<template>
  <el-drawer
    :visible.sync="dialogVisible"
    :append-to-body="true"
    direction="rtl"
    :size="750"
    :modal-append-to-body="true"
    :before-close="dialogClose"
    class="task-detail"
    v-on="$listeners"
  >
    <div class="dialog-title-wrap" slot="title" v-if="!pageLoading">
      <div class="create-user" v-if="progressList && progressList.processDynamic">
        <wx-open-data
          :id="progressList.processDynamic.sponsorUserName"
          :nameData="{name: progressList.processDynamic.sponsorUserName}"
        />的OKR审批
      </div>
      <div
        v-if="progressList.process"
        class="state-button"
        :style="{
          background: stateList[progressList.process.approvalStatus || 0].backgroundColor,
          color: stateList[progressList.process.approvalStatus || 0].fontColor,
          borderColor: stateList[progressList.process.approvalStatus || 0].borderColor,
        }"
      >{{stateList[progressList.process.approvalStatus || 0].name}}</div>
    </div>
    <div
      class="main-content-wrap"
      ref="trendItem"
      v-scroll-bottom="loadMoreTrendArray"
      v-show="!pageLoading"
    >
      <div
        :class="`state-detail-wrap state-detail-type-${progressList.process && progressList.process.approvalStatus}`"
        v-if="progressList.process"
      >
        <div class="detail-content">
          <Icon
            :iconClass="progressList.process.approvalStatus === 3 ? 'icon-shenpitongguo' : progressList.process.approvalStatus === 2 ? 'icon-shenpiweitongguo' : 'icon-shenpijinggao'"
            class="remind"
          />
          <div class="content" v-if="progressList && progressList.processDynamic">
            <div class="state-text">{{progressList.processDynamic.actionContent}}</div>
            <div class="user" v-if="progressList.processDynamic.appointUserName">
              审批人：
              <wx-open-data
                :id="progressList.processDynamic.appointUserName"
                :nameData="{name: progressList.processDynamic.appointUserName}"
              />
              （{{progressList.processDynamic.appointRole}}）
            </div>
          </div>
        </div>
        <div
          class="date"
          v-if="progressList.processDynamic && progressList.processDynamic.sponsorDate"
        >{{progressList.processDynamic.sponsorDate}} 提交</div>
      </div>
      <div class="content-line"></div>
      <div class="obj-type-wrap">
        <div class="obj-type-detail">
          <div class="type-title">{{ showCycleTitle }}</div>
          <el-tooltip content="点击修改目标类型" :disabled="true" placement="top">
            <objective-label
              page-type="okrDetail"
              :okr-data="objectiveObj"
              :can-edit="false"
              :label-type="objectiveObj.labelType"
              :share-list="objectiveObj.objectivesSharedUserList"
              @refresh="queryObjectiveDetail(objectiveObj.id)"
            >
              <div class="type-title">{{ labelTypeObj[objectiveObj.labelType] }}目标</div>
            </objective-label>
          </el-tooltip>
        </div>
      </div>
      <div class="obj-cotent-wrap">
        <div class="obj-content">
          <div
            class="top-content"
            :class="objectiveObj.automationState === 1 ? 'progress-auto' : ''"
          >
            <top-members
              ref="topMember"
              :top-members-beans="objectiveObj"
              top-members-state="1"
              class="top-member"
              :details-id="objectiveObj.id"
              :editState="!editDisabled"
              :fromType="'approvalDetail'"
              @backData="backData"
              @delteAlignObj="queryObjectiveDetail(objectiveObj.id)"
              @updateObjTitle="queryObjectiveDetail(objectiveObj.id)"
              @showShareList="showShareList"
            />
            <top-progress :top-members-beans="objectiveObj" top-members-state="3">
              <div class="top-progress-auto">
                <autoProgressPopver
                  v-if="objectiveObj.automationState === 1"
                  :okr-data="objectiveObj"
                  :disabled="objectiveObj.automationState !== 1"
                  :type="1"
                >
                  <div class="top-progress-content blue">
                    <img src="../../../../assets/imgs/common/circle-progress.gif" class="icon" alt />
                  </div>
                </autoProgressPopver>
              </div>
            </top-progress>
            <div v-if="!editDisabled" class="add-keyresult">
              <transition name="bottom-slide" mode="out-in">
                <key-results
                  v-if="leftAddkeyState"
                  :top-members-bean="objectiveObj"
                  @getAddkeyList="getAddkeyList"
                />
              </transition>
              <transition name="bottom-slide" mode="out-in">
                <key-results-Add
                  v-if="!leftAddkeyState"
                  :is-share="objectiveObj.isShare"
                  :obj-id="objectiveObj.id"
                  :isAiAdd="krAddType"
                  @getkeyResultsAddRefresh="getkeyResultsAddRefresh"
                />
              </transition>
            </div>
          </div>
          <div class="okr-kr-list">
            <draggable
              v-model="keyResultsList"
              handle=".key_result_dragger_icon_box"
              :disabled="!(!editDisabled && draggableStatus)"
              @update="drop"
            >
              <div v-for="(item, index) in keyResultsList" :key="item.id" class="okr-kr-item-wrap">
                <div class="okr-kr-item">
                  <key-results-list
                    :key-results-li="item"
                    :target-object="objectiveObj"
                    :key-results-li-number="index"
                    :show-border="true"
                    :align-member="true"
                    :editState="!editDisabled"
                    :fromType="'approvalDetail'"
                    @draggableSetStatus="draggableSetStatus"
                    @getTargetDetailsKeywordDetele="getTargetDetailsKeywordDetele"
                    @getKeyResultsListTitles="
                          getKeyResultsListTitles(index)
                        "
                    @changeObjectiveTitle="changeObjectiveTitle"
                    @getKeyResultsListWeights="
                          getKeyResultsListWeights(index)
                        "
                    @getKeyHandleChange="getKeyHandleChange"
                    @getKeyHandleChangeBlur="getKeyHandleChangeBlur"
                    @showProcessList="showProcessList"
                    @setAutoProcess="keyResultsAutoClick(item)"
                    @showKeyResultScore="showKeyResultScore(index)"
                    @getKeyScoreChangeBlur="getKeyScoreChangeBlur"
                    @getKeyScoreChange="getKeyScoreChange"
                    @okrDetail="getTopMembersAlignmentSelect"
                    @toOKRList="getTopMembersFatherList"
                  />
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div class="shaodow-bg"></div>
      <div class="progress-wrap">
        <div class="progress-tab-wrap">
          <div
            class="tab-title"
            :class="selectTabValue === 1 ? 'active': ''"
            @click="selectTabValueChange(1)"
          >审批流程</div>
          <div
            class="tab-title"
            :class="selectTabValue === 2 ? 'active': ''"
            @click="selectTabValueChange(2)"
          >动态</div>
        </div>
        <div class="progress-content" v-if="selectTabValue === 1">
          <div
            :class="`progress-item ${item.approvalStatus === 1 && index === 0 ? 'dotted' : ''}`"
            v-for="(item , index) in progressList.processRecordArray"
            :key="item.content"
          >
            <div :class="`progess-line ${item.approvalStatus === 1 && index === 0 ? 'sign' : ''}`">
              <div class="circle"></div>
            </div>
            <div class="progess-detail">
              <div class="title-wrap">
                <div class="title">
                  {{item.actionRole}}:
                  <wx-open-data :id="item.actionUserName" :nameData="{name: item.actionUserName}" />
                </div>
                <div
                  class="blue-button"
                  @click="approvalRemind(item)"
                  v-if="item.approvalStatus === 1 && objectiveObj.createUserid == userInfo.id && index === 0"
                >催办</div>
              </div>
              <div class="content-wrap">
                <div class="content-text">
                  <div :class="`title type-${item.approvalStatus}`">
                    {{item.actionContent
                    }}
                    {{item.approvalStatus === 1 ? `审批中` : '' }}
                  </div>
                  <div class="content" v-if="item.content">说明:{{item.content}}</div>
                </div>
                <div class="date">{{$utils.globalTime(item.updateDate)}}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectTabValue === 2" v-vueloading="trendArrayState" class="tab-item">
          <div class="top-select-options">
            <div v-if="trendTotal > 10">
              <!-- 动态列表切换显示菜单 -->
              <div
                v-if="trendTotal - trendArray.length != 0 && !oldVisible"
                class="dynamic_menu"
                @click="showOld"
              >
                <Icon icon-class="icon-more-row" class="trend_change_icon" />
                <template>
                  显示较早
                  {{ trendTotal - trendArray.length }} 条
                </template>
              </div>
              <div v-else class="dynamic_menu" @click="showOld">
                <Icon icon-class="icon-more-row" class="trend_change_icon" />
                <template>隐藏较早的动态</template>
              </div>
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <div
              v-if="!trendArrayState && trendArray.length === 0"
              class="no-data-show-wrap no-data-show-wrap2"
            >
              <no-data />
            </div>
          </transition>
          <template v-vueloading="trendArrayState">
            <!-- ref="trendItem"
            v-scroll-bottom="loadMoreTrendArray"-->
            <transition-group
              id="transitionGroupTrend"
              name="fade"
              tag="div"
              data-scroll-type="trendItem"
              class="draggable-box trend_box"
            >
              <trend-item
                v-for="item in trendArray"
                :key="item.id"
                class="trend_item"
                :trend-info="item"
                :obj-id="detailsId"
                @refreshTrend="refreshTrend"
              />
            </transition-group>
          </template>
        </div>
      </div>
    </div>
    <div
      class="user-button-wrap"
      v-if="!pageloading && objectiveObj.objectivesProcessDataDto && ((progressList.process && progressList.process.approvalStatus === 1 && userInfo.id  == progressList.processDynamic.appointUserId) || ((progressList.process && progressList.process.approvalStatus === 0 || progressList.process && progressList.process.approvalStatus === 1) && userInfo.id  == objectiveObj.createUserid))"
    >
      <div class="approve-detail-wrap">
        <el-input
          v-model.lazy="approveRxplain"
          type="textarea"
          :placeholder="(progressList.process && progressList.process.approvalStatus === 1 && userInfo.id  == progressList.processDynamic.appointUserId) ? '请输入审批意见': '请描述提交审批的内容，例如修改的内容等'"
          :maxlength="500"
          :rows="3"
          v-if="(progressList.process && progressList.process.approvalStatus === 0 && userInfo.id  == objectiveObj.createUserid && $store.state.user.approvalCheckData.onOff === 0) || (progressList.process.approvalStatus === 1 && userInfo.id  == progressList.processDynamic.appointUserId)"
        />
        <div
          class="btn-list-wrap"
          v-if="(progressList.process && progressList.process.approvalStatus === 1 && userInfo.id  == progressList.processDynamic.appointUserId)"
        >
          <div class="title">如要驳回，请描述审批意见</div>
          <div class="btn-list">
            <div class="blue-button yellow-button" @click="approvePassTypeClick(2)">驳回</div>
            <div class="blue-button" @click="approvePassClick()">通过审批</div>
          </div>
        </div>
        <div class="btn-list-wrap flex-end">
          <div
            v-if="(progressList.process && progressList.process.approvalStatus === 0 && userInfo.id  == objectiveObj.createUserid && $store.state.user.approvalCheckData.onOff === 0)"
            class="blue-button"
            @click="approvalSubmitCancel(1)"
          >提交审批</div>
          <div
            v-if="(progressList.process && progressList.process.approvalStatus === 1 && userInfo.id  == objectiveObj.createUserid)"
            class="blue-button yellow-button"
            @click="approvalCancel(2)"
          >撤回审批</div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
/* eslint-disable */
import topMembers from '@/views/okr/components/singleOkrItem/topMembers.vue'
import keyResults from '@/views/okr/components/singleOkrItem/keyResults.vue'
import keyResultsAdd from '@/views/okr/components/singleOkrItem/keyResultsAdd.vue'
import keyResultsList from '@/views/okr/components/singleOkrItem/keyResultsList.vue'
import topProgress from '@/views/okr/components/singleOkrItem/topProgress.vue'
import autoProgressPopver from '@/views/okr/components/singleOkrItem/autoProgressPopver'
import draggable from 'vuedraggable'
import trendItem from '../../components/singleOkrItem/TrendItem.vue'
import CommentBox from '@/components/common/CommentBox.vue' // 回复评论框
export default {
  components: {
    topMembers,
    topProgress,
    keyResults,
    keyResultsAdd,
    keyResultsList,
    autoProgressPopver,
    draggable,
    trendItem,
    CommentBox
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    detailsId: {
    }
  },
  data() {
    return {
      pageLoading: true,
      dialogVisible: this.dialogShow,
      objectiveObj: {},
      showCycleTitle: '', // 目标周期名称
      cycleList: [], // 周期列表
      labelTypeObj: {
        400: '个人',
        200: '部门',
        100: '公司',
        300: '团队'
      },
      stateList: {
        0: {
          name: '待提交审批',
          fontColor: '#3A70FF',
          borderColor: 'rgba(58,112,255,0.5)',
          backgroundColor: 'rgba(58,112,255,0.08)',
        },
        1:{
          name: '审批中',
          fontColor: '#FF9900',
          borderColor: 'rgba(255,153,0,0.5)',
          backgroundColor: 'rgba(255,153,0,0.08)',
        },
        3:{
          name: '通过审批',
          fontColor: '#00BA88',
          borderColor: 'rgba(0,186,136,0.5)',
          backgroundColor: 'rgba(0,186,136,0.08)',
        },
        2:{
          name: '未通过审批',
          fontColor: '#FF572B',
          borderColor: 'rgba(255,87,43,0.5)',
          backgroundColor: 'rgba(255,87,43,0.08)',
        },
        4:{
          name: '已撤回',
          fontColor: '#A0A3BD',
          borderColor: 'rgba(160,163,189,0.5)',
          backgroundColor: 'rgba(160,163,189,0.08)',
        }
      },
      leftAddkeyState: true, // 关键结果新增状态
      keyResultsList: [], // 关键结果列表
      showCycleTitle: '', // 目标周期名称
      cycleList: [], // 周期列表
      selectTabValue: 1, // 选中的tab页
      leftAddkeyState: true, // 关键结果新增状态
      draggableStatus: true, // 是否能够拖拽
      trendOptionValue: 0, // 动态筛选值
      trendOption: {
        0: '所有动态',
        2: '仅评论'
        //   3: '仅附件',
      }, // 动态筛选项
      oldVisible: false, // 是否显示较早动态
      trendArray: [], // 动态列表
      trendArrayState: true,
      trendSize: 10, // 动态一页展示条数
      trendPage: 1, // 动态页数
      trendTotal: 0, // 动态总数
      writeRules: [],
      progressList: [],
      approveRxplain: '',
      editDisabled: false,
      krAddType:false
    }
  },
  computed: {},
  created() {
    this.cycleList = this.$store.state.common.okrCycleList.allCycleList
    this.queryObjectiveDetail()
    this.getApprovalProcessRecord()
    const rules = this.$store.state.okr.okrWriteRules
    if (!rules || rules.length === 0) {
      this.queryWriteRules()
    } else {
      this.writeRules = rules
    }
  },
  provide() {
    return {
      writeRules: () => this.writeRules
    }
  },
  methods: {
    approvalRemind(item) {
      this.$apis
        .APPROVALDATAREMIND({
          approvalProcessId: item.approvalProcessId,
          businessId: this.detailsId,
          appointUserId: this.progressList.processDynamic.appointUserId,
          approvalProcessNumber: item.spNo,
          businessType: 1
        })
        .then((rest) => {
          if (rest.status === 200) {
            this.$message.success('催办成功')
            this.getApprovalProcessRecord()
          } else {
            this.$message.error(rest.message)
          }
        })
    },
    approvePassClick() {
      if (this.progressList.processDynamic && this.progressList.processDynamic.action === 3) {
        this.$confirm('通过后将删除对方的OKR', '是否通过审批?', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          customClass: 'confirmBox'
        }).then(() => {
          this.approvePassTypeClick(3, 'delete')
        })
      } else {
        this.approvePassTypeClick(3)
      }
    },
    // 审批通过驳回
    approvePassTypeClick(operationStatus, deleteState) {
      if (operationStatus === 2 && this.approveRxplain.replace(/(\s*$)/g, "") === '') {
        this.$message.warn('驳回说明不能为空哦！')
        return
      }
      this.$apis
        .APPROVALPROCESSOPERATION({
          businessType: 1,
          businessId: this.detailsId,
          operationStatus,
          operationContent: operationStatus === 2 ? '驳回了审批' : '通过了审批 ，修改了OKR内容',
          content: this.approveRxplain,
          spNo: this.progressList.processDynamic.spNo
        })
        .then((rest) => {
          if (rest.status === 200) {
            this.$message.success('修改成功')
            if (deleteState === 'delete') {
              this.dialogClose()
            } else {
              this.queryObjectiveDetail()
              this.getApprovalProcessRecord()
            }
            this.bus(this).$emit('approveRemindRefresh')
          } else {
            this.$message.error(rest.message)
          }
        })
    },
    approvalCancel() {
      this.$confirm('已撤回后可修改OKR内容后再次提交审批', '是否要撤回审批？', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        customClass: 'confirmBox'
      }).then(() => {
        this.approvalSubmitCancel(2)
      })
    },
    // 审批提交撤回
    approvalSubmitCancel(submitterType) {
      this.$apis
        .APPROVALDATASUBMITTER({
          businessId: this.detailsId,
          businessData: '',
          businessActionType: this.progressList.processRecordArray.length > 1 ? 2 : 1,
          submitterType,
          appointRemark: this.approveRxplain,
          approvalProcessId: this.objectiveObj.objectivesProcessDataDto.approvalProcessId,
          approvalProcessNumber: this.objectiveObj.objectivesProcessDataDto.approvalProcessNumber,
        })
        .then((rest) => {
          if (rest.status === 200) {
            this.$message.success('修改成功')
            this.queryObjectiveDetail()
            this.getApprovalProcessRecord()
            this.bus(this).$emit('approveRemindRefresh')
          } else {
            this.$message.error(rest.message)
          }
        })
    },
    // 审批流详情
    getApprovalProcessRecord() {
      this.$apis
        .APPROVALPROCESSRECORD({
          businessId:this.detailsId,
          businessType: 1,
          businessExtId: ''
        })
        .then((rest) => {
          this.progressList = rest.data
        })
    },
    approveClick(type) {
      if (type === 1 && this.approveRxplain === '') {
        this.$message.warn('审批意见不能为空哦！')
        return
      }
      if (type === 2) {
        this.$confirm('通过后将删除对方的 OKR', '是否通过审批？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'confirmBox'
      }).then(() => {
      })
      }
    },
    getTopMembersAlignmentSelect(data) {
      this.$refs.topMember.getTopMembersAlignmentSelect(data)
    },
    getTopMembersFatherList(data) {
      this.$refs.topMember.getTopMembersFatherList(data)
    },
    trendOptionPermission() {
      if (this.objectiveObj.isVisible === 1) {
        return true
      }
      var isVisible = false
      this.objectiveObj.keyresultsList.forEach((item) => {
        if (item.isVisible === 1 && !isVisible) {
          isVisible = true
        }
      })
      return isVisible
    },
    closePop() {
      if (this.$refs.okrDetailMenu) {
        this.$refs.okrDetailMenu.closePopView()
      }
    },
    // 获取填写建议字段
    queryWriteRules() {
      const params = {
        businessType: -1
      }
      this.$apis.OKRWRITERULES(params).then((res) => {
        if (res && res.status === 200) {
          this.writeRules = res.data
          this.$store.commit('okr/okrWriteRules', this.writeRules)
        }
      })
    },
    // 顶部tab切换
    selectTabValueChange(val) {
      if (this.selectTabValue !== val) {
        this.selectTabValue = val
        if (val === 2) {
          this.getTrendList()
        }
      }
    },
    // 获取目标详情
    queryObjectiveDetail(objId) {
      this.$apis
        .OKROBJINFOSIMPLE(this.detailsId)
        .then((rest) => {
          if (rest.status === 200) {
            this.objDataHandle(rest.data)
          } else {
            this.$message.error(rest.message)
            this.dialogClose()
          }
          this.$nextTick(() => {
            this.pageLoading = false
          })
        })
    },
    objDataHandle(data) {
      this.objectiveObj = data || {}
      if (this.objectiveObj.processState === 1 && this.objectiveObj.objectivesProcessDataDto && this.progressList.process && this.progressList.process.approvalStatus === 1 && this.userInfo.id  == this.progressList.processDynamic.appointUserId) {
        this.editDisabled = false
      } else {
        this.editDisabled = true
      }
      if (data) {
        this.keyresultsListHandle(this.objectiveObj.keyresultsList)
      }
      this.cycleTitle(this.objectiveObj.cycleInfoId)
    },
    // 关键结果处理
    keyresultsListHandle(data) {
      this.keyResultsList = []
      data.forEach((item) => {
        const obj = Object.assign(item, {
          canBeScored: this.objectiveObj.canBeScored,
          cycleInfoId: this.objectiveObj.cycleInfoId,
          titleStates: true,
          weightStates: true,
          scoreStates: true,
          comment: item.title,
          comment2: item.title
        })
        this.keyResultsList.push(obj)
      })
    },
    // 获取周期名称
    cycleTitle(id) {
      this.cycleList.some((itemX) => {
        return itemX.cycleInfoList.some((itemY) => {
          if (itemY.id === id) {
            this.showCycleTitle = itemY.name
            return true
          }
        })
      })
    },
    // 点击新增关键结构按钮
    getAddkeyList(state, addType) {
      this.krAddType = addType
      if (state === '编辑') {
        this.leftAddkeyState = false
        for (let i = 0; i < this.keyResultsList.length; i++) {
          this.keyResultsList[i].titleStates = true
          this.keyResultsList[i].weightStates = true
          this.keyresultsList[i].scoreStates = true
        }
      } else {
        this.leftAddkeyState = true
      }
    },
    // 关键结果添加刷新状态
    getkeyResultsAddRefresh(state) {
      if (state === '刷新') {
        this.queryObjectiveDetail(this.objectiveObj.id)
        this.leftAddkeyState = true
      } else if (state === '不刷') {
        this.leftAddkeyState = true
      }
    },
    // 关闭kr编辑状态
    closeKrEditState() {
      const keyResultsList = this.keyResultsList
      this.keyResultsList = []
      keyResultsList.forEach((item) => {
        item.titleStates = true
        item.weightStates = true
        item.scoreStates = true
      })
      this.leftAddkeyState = true
      this.$nextTick(() => {
        this.keyResultsList = keyResultsList
      })
    },
    // 设置拖拽状态
    draggableSetStatus(state) {
      this.draggableStatus = state
    },
    // 处理编辑关键结果名称
    getKeyResultsListTitles(indexs) {
      if (this.editDisabled === false) {
        const lists = this.keyResultsList
        this.closeKrEditState()
        this.draggableStatus = false
        lists[indexs].titleStates = false
        this.keyResultsList = JSON.parse(JSON.stringify(lists))
      }
    },
    // 处理关键结果内容保存展现
    changeObjectiveTitle(state) {
      if (state === '修改成功') {
        this.queryObjectiveDetail(this.detailsId)
      } else {
        this.closeKrEditState()
      }
    },

    // 处理权重方法
    getKeyResultsListWeights(index) {
      if (this.keyResultsList.length - 1 !== index) {
        if (this.editDisabled === false) {
          const lists = this.keyResultsList
          let state = '暂无'
          for (let i = 0; i < lists.length; i++) {
            if (lists[i].titleStates === false) {
              state = '关键结果名称'
              break
            }
            if (lists[i].weightStates === false) {
              state = '关键结果权重'
              break
            }
          }
          if (state === '关键结果名称') {
            return
          }
          if (state === '关键结果权重') {
            return
          }
          this.draggableStatus = false
          lists[index].weightStates = false
          this.keyResultsList = JSON.parse(JSON.stringify(lists))
        }
      } else {
        this.$message({
          msg: '最后一个关键结果需要调整前面的进行编辑',
          type: 'warn'
        })
      }
    },

    // 处理权重上下级
    getKeyHandleChange(index) {
      const dataBeans = this.keyResultsList
      let numbers = 100
      // let sum =
      //   this.keyResultsList[index].weight +
      //   this.keyResultsList[this.keyResultsList.length - 1].weight
      for (let i = 0; i < dataBeans.length; i++) {
        if (i !== index && dataBeans.length - 1 !== i) {
          numbers -= dataBeans[i].weight
        }
      }
      const content = this.keyResultsList[index].weight
      this.keyResultsList[index].weight = content.replace(/\D/g, '')

      if (this.keyResultsList[index].weight > numbers) {
        this.$nextTick(() => {
          this.keyResultsList[index].weight = parseInt(numbers)
          this.keyResultsList[this.keyResultsList.length - 1].weight = 0
        })
      } else {
        this.$nextTick(() => {
          this.keyResultsList[this.keyResultsList.length - 1].weight =
            numbers - this.keyResultsList[index].weight
        })
      }
    },
    // 处理权重移除保存值
    async getKeyHandleChangeBlur(index, noChange) {
      if (noChange) {
        this.keyResultsList[index].weightStates = true
        return
      }
      const dataList = []
      if (this.keyResultsList[index].weight === '') {
        this.keyResultsList[index].weight = 0
      }
      for (let i = 0; i < this.keyResultsList.length; i++) {
        dataList.push({
          id: this.keyResultsList[i].id,
          objId: this.keyResultsList[i].objId,
          weight: this.keyResultsList[i].weight,
          progressRete: this.keyResultsList[i].progressRete
        })
      }
      this.$apis
        .OKRUPDATEWEIGHT(dataList)
        .then((rest) => {
          this.$mixpanelUtil.track('Update OKR Proportion', {
            objid: dataList[0].objId,
            view_type: 'map'
          })
          this.$message({
            msg: '修改成功',
            type: 'success'
          })
          this.bus.$emit('getKeyResultsAlertCloseBus')
          this.queryObjectiveDetail(this.detailsId)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    drop() {
      console.log('dropdropdropdrop', this.editDisabled)
      if (this.editDisabled === false) {
        this.getUpdataKeywordRank()
      }
    },

    // 关键字排序
    async getUpdataKeywordRank() {
      const beans = []
      for (let i = 0; i < this.keyResultsList.length; i++) {
        beans.push({
          id: this.keyResultsList[i].id,
          sort: i + 1
        })
      }
      this.$apis.OKRUPDATESORT(beans).then((res) => {
        if (res.status === 200) {
          this.$message({
            msg: '修改排序成功',
            type: 'success'
          })
          this.getTargetDetailsKeywordDetele()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // kr删除触发
    getTargetDetailsKeywordDetele() {
      // this.bus.$emit('getKeyResultsAlertCloseBus')
      this.queryObjectiveDetail(this.detailsId)
    },
    
    // 是否展示较早动态
    showOld() {
      this.oldVisible = !this.oldVisible
      if (this.oldVisible && this.trendTotal - this.trendArray.length !== 0) {
        this.getTrendList('loadMore')
      } else {
        this.getTrendList()
      }
    },
    // 获取动态列表
    getTrendList(type) {
      if (type === 'loadMore') {
        this.trendPage++
      } else {
        this.trendPage = 1
      }
      const beans = {
        pageSize: this.trendSize,
        pageNum: this.trendPage,
        objId: this.detailsId,
        type: this.trendOptionValue
      }
      this.$apis
        .OKRDYNAMICLIST(beans)
        .then((rest) => {
          const dataBean = rest.data.rows.map((item) => {
            return Object.assign(item, {
              comment: this.$utils.changeToHtml(item.comment)
            })
          })
          if (type === 'loadMore') {
            this.trendArray = this.trendArray.concat(dataBean || [])
          } else {
            this.trendArray = dataBean
          }
          this.trendTotal = parseInt(rest.data.total)
          if (this.trendTotal - this.trendArray.length === 0) {
            this.oldVisible = true
          }
          this.trendArrayState = false
        })
        .catch((err) => {
          console.log(err)
          this.trendArrayState = false
        })
    },

    // 动态中发布评论
    async addTrendComment(comment, userId, fileList) {
      if (comment.trim() === '' && fileList.length === 0) {
        this.$message({
          msg: '内容不能为空！',
          type: 'warn'
        })
        return
      }
      let commentValue = ''
      if ((comment || '') === '' && fileList.length > 0) {
        commentValue = '查看附件'
      } else {
        commentValue = this.$utils.changeToOkrtFormat(comment)
      }
      const dataBean = {
        collaboratorStr: userId, // @人员ID
        objComment: commentValue, // 发送内容
        objId: this.detailsId, // 目标id
        type: 0,
        ossFileEntityList: fileList.length > 0 ? fileList : null
      }
      let is_to_member = false
      if (dataBean.objComment.search('@') > -1) {
        is_to_member = true
      }

      this.$mixpanelUtil.track('OKR Comment', {
        is_to_member,
        objid: this.detailsId,
        view_type: 'map'
      })
      this.$apis
        .OKRADDDYNAMICCOMMENT(dataBean)
        .then((rest) => {
          // this.$message({
          //   msg: '发布成功',
          //   type: 'success'
          // })
          this.$refs.trendCommentBox.clearComment()
          // this.queryObjectiveDetail(this.detailsId)
          this.getTrendList()
          this.bus.$emit('clearFile')
          this.bus(this).$emit('inquireStatus', {
            type: 1,
            name: 'OKR'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    refreshTrend() {
      this.queryObjectiveDetail(this.detailsId)
      this.getTrendList()
    },
    // 加载更多
    loadMore({ type }) {
      var that = this
      const methodMap = {
        task: that.getTargetTaskList,
        trendItem: that.getTrendList
      }
      let page_end = 0
      let page = 1
      let loadMoreArr = []
      let loadTotal = 0
      // 滚动时判断是否加载更多
      if (type === 'trendItem') {
        page_end = Math.ceil(that.trendTotal / that.trendSize)
        page = that.trendPage
        loadMoreArr = that.trendArray
        loadTotal = that.trendTotal
      }
      // 满足条件加载更多
      if (loadMoreArr.length < loadTotal && page <= page_end) {
        methodMap[type]('loadMore')
      }
    },
    loadMoreTrendArray() {
      var that = this
      let page_end = 0
      let page = 1
      let loadMoreArr = []
      let loadTotal = 0
      page_end = Math.ceil(that.trendTotal / that.trendSize)
      page = that.trendPage
      loadMoreArr = that.trendArray
      loadTotal = that.trendTotal
      if (loadMoreArr.length < loadTotal && page <= page_end) {
        that.getTrendList('loadMore')
      }
    },
    dialogClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
  }
}
</script>
<style lang="less" scoped>
::v-deep {
  .el-drawer {
    border-radius: 16px 0px 0px 16px;
  }
  .el-drawer__header {
    padding: 0px 24px;
    height: 54px;
    line-height: 54px;
    border-bottom: 1px solid #f0f1f6;
    .dialog-title-wrap {
      .flex-l();
      .create-user {
        font-size: 18px;
        font-weight: bold;
        color: #14142b;
        margin-right: 8px;
        .text-n(1);
        max-width: 500px;
      }
      .state-button {
        font-size: 12px;
        height: 22px;
        line-height: 20px;
        border-radius: 11px;
        padding: 0px 12px;
        border-width: 1px;
        border-style: solid;
      }
    }
    .el-drawer__close-btn {
      line-height: 33px;
    }
  }
  .el-drawer__body {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 54px);
    .main-content-wrap {
      overflow-y: auto;
      flex: 1;
      margin-bottom: 24px;
      overflow-x: hidden;
      .state-detail-wrap {
        background: rgba(255, 165, 2, 0.08);
        border-radius: 12px 12px 12px 12px;
        padding: 12px;
        margin: 16px 24px 36px 24px;

        .flex-bt();
        align-items: flex-start;
        .detail-content {
          .flex-l();
          align-items: flex-start;
          .remind {
            font-size: 18px;
            margin-right: 8px;
          }
          .content {
            .state-text {
              font-size: 14px;
              font-weight: 500;
              color: #444558;
              line-height: 18px;
              margin-bottom: 8px;
            }
            .user {
              font-size: 12px;
              font-weight: 500;
              color: #6e7491;
              line-height: 16px;
            }
          }
        }
        .date {
          font-size: 14px;
          font-weight: 500;
          color: #a0a3bd;
          line-height: 18px;
        }
      }
      .state-detail-type-3 {
        background: rgba(0, 186, 136, 0.08);
      }
      .state-detail-type-2 {
        background: rgba(255, 87, 43, 0.08);
      }
      .content-line {
        border-top: 1px solid #f0f1f6;
        margin: 0px 24px;
      }
      .obj-type-wrap {
        .flex-l();
        margin-top: -14px;
        .obj-type-detail {
          .flex-l();
          margin-left: 24px;
          background: #fff;
          .type-title {
            height: 28px;
            line-height: 28px;
            background: rgba(50, 150, 250, 0.1);
            border-radius: 8px;
            font-size: 16px;
            font-weight: 500;
            color: #005eff;
            margin-right: 16px;
            padding: 0px 12px;
          }
        }
      }
      .obj-cotent-wrap {
        padding: 24px;
      }
      .shaodow-bg {
        height: 24px;
        background: linear-gradient(
          180deg,
          rgba(0, 94, 255, 0.06) 0%,
          rgba(160, 198, 255, 0) 100%
        );
      }
      .progress-wrap {
        padding: 0px 24px 24px 24px;
        .progress-tab-wrap {
          .flex-l();
          .tab-title {
            font-size: 14px;
            font-weight: 400;
            color: #a0a3bd;
            line-height: 20px;
            position: relative;
            margin-right: 24px;
            height: 28px;
            cursor: pointer;
          }
          .tab-title.active {
            color: #14142b;
            font-weight: 500;
          }
          .tab-title.active:after {
            content: '';
            display: flex;
            justify-content: center;
            width: 22px;
            height: 3px;
            background: #3068ff;
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            margin: 0px auto;
            text-align: center;
          }
        }
        .progress-content {
          margin: 24px 0px 0px 4px;
          .progress-item {
            .flex-l();
            align-items: flex-start;
            position: relative;
            border-left: 1px solid #3296fa;
            .progess-line {
              background: rgba(58, 120, 246, 0);
              width: 16px;
              height: 16px;
              border-radius: 8px;
              margin-left: -8px;
              .circle {
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background: #3296fa;
                position: absolute;
                left: -4px;
              }
            }

            .progess-detail {
              flex: 1;
              margin: -7px 0px 0px 18px;
              .title-wrap {
                .flex-l();
                .title {
                  font-size: 14px;
                  font-weight: 400;
                  color: #6e7491;
                  margin-right: 16px;
                  line-height: 22px;
                }
                .blue-button {
                  font-size: 12px;
                  height: 22px;
                  line-height: 20px;
                  background: #ffa502;
                  border: 1px solid #ffa502;
                  min-width: 60px;
                  max-width: 60px;
                  padding: 0px;
                  &:hover {
                    opacity: 0.85;
                  }
                }
              }
              .content-wrap {
                .flex-bt();
                flex-wrap: wrap;
                background: #f5f6fa;
                border-radius: 8px;
                padding: 8px 12px;
                margin: 8px 0px 24px 0px;
                align-items: flex-start;
                .content-text {
                  font-size: 12px;
                  font-weight: 500;
                  color: #444558;
                  margin-right: 16px;
                  line-height: 16px;
                  .content {
                    margin-top: 4px;
                  }
                }
                .type-1 {
                  color: #ff9900;
                }
                .type-2 {
                  color: #ff572b;
                }
                .type-3 {
                  color: #00ba88;
                }
                .date {
                  font-size: 12px;
                  font-weight: 500;
                  color: #a0a3bd;
                }
              }
            }
          }
          .progress-item.dotted {
            border-left: 1px dashed #3296fa;
            .progess-line {
              background: #d6eafe;
            }
            .circle {
              top: 4px;
            }
            .progess-detail {
              margin-top: -3px;
            }
          }
          .progress-item:last-child {
            border-left: 1px solid #fff;
          }
        }
      }
    }

    .user-button-wrap {
      .flex-l();
      justify-content: flex-end;
      padding: 24px;
      border-top: 1px solid #f0f1f6;
      width: 100%;
      .blue-button {
        font-size: 14px;
        padding: 0px 20px;
        border-radius: 8px;
        min-width: 99px;
      }
      .yellow-button {
        background: #ff9900;
        border: 1px solid #ff9900;
        &:hover {
          opacity: 0.85;
        }
      }
      .approve-detail-wrap {
        width: 100%;
        .el-textarea {
          border: none !important;
          background: none !important;
          margin-bottom: 24px;
          .el-textarea__inner {
            background: none !important;
            border: 1px solid #f0f1f6 !important;
          }
        }
        .btn-list-wrap {
          .flex-bt();
          .title {
            color: rgb(255, 87, 43);
            font-size: 12px;
          }
          .btn-list {
            .flex-l();
            justify-content: flex-end;
            .blue-button {
              margin-left: 16px;
            }
          }
        }
        .btn-list-wrap.flex-end {
          justify-content: flex-end;
        }
      }
    }
  }
  .add-keyresult {
    padding-top: 6px;
    margin-bottom: 14px;
  }
  .okr-kr-list {
    margin-left: -28px;
    .okr-kr-item-wrap {
      &:last-child {
        .key_results_li::after {
          height: 0px !important;
        }
      }
    }
  }
  .top_progress {
    height: 20px;
    margin-left: 64px;
    margin-bottom: 24px;
    width: 100%;
    max-width: 270px;
  }
  .dynamic_menu {
    font-size: 14px;
    color: #6e7491;
    line-height: 20px;
    .flex-r();
    cursor: pointer;
    .trend_change_icon {
      font-size: 20px;
      margin-right: 8px;
    }
    &:hover {
      color: #0d84eb;
    }
  }
}
</style>


