var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "default-page",
    },
    [_c("div", { class: ["default-img", _vm.type] })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }