var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isAiAdd,
          expression: "!isAiAdd",
        },
      ],
      staticClass: "key-result-add-content",
    },
    [
      _c("div", { staticClass: "keyResultsAdd" }, [
        _c("div", { staticClass: "keyResultsAddText" }, [_vm._v("KR")]),
        _c(
          "div",
          { staticClass: "keyResultsAddInput" },
          [
            _c(
              "AdviceInput",
              {
                key: _vm.form,
                attrs: {
                  html: _vm.$utils.changeToHtml(_vm.form.comment),
                  type: 2,
                  "edit-ignore": _vm.isEdit && !_vm.noRequest,
                  "dict-info": _vm.editInfo,
                  "ignore-rule-id-list": _vm.form.ignoreRuleIdList,
                  placement: "top-start",
                },
                on: { addIgnore: _vm.addIgnoreRule },
                model: {
                  value: _vm.titlePopShow,
                  callback: function ($$v) {
                    _vm.titlePopShow = $$v
                  },
                  expression: "titlePopShow",
                },
              },
              [
                _c("at-input", {
                  ref:
                    "atCommentBoxinput" + (_vm.isEdit ? _vm.editInfo.id : ""),
                  attrs: {
                    limit: "150",
                    isBgColor: false,
                    html: _vm.$utils.changeToHtml(_vm.form.comment),
                    editable: true,
                    "enter-able": true,
                    placeholder: _vm.placeholder,
                  },
                  on: {
                    focus: _vm.objTitleFocus,
                    enter: function ($event) {
                      return _vm.changeObjectiveTitle($event)
                    },
                    input: function ($event) {
                      return _vm.changeTitle($event)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "keyResultsAddIcon" },
          [
            _c(
              "at-user-dialog",
              { on: { selectPerson: _vm.atPerson } },
              [
                _c("Icon", {
                  staticClass: "at-icon",
                  attrs: { "icon-class": "icon-at" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "kr-option-box" },
        [
          _c("quantify-kr", {
            key: "unitForm",
            attrs: {
              "unit-form": _vm.unitForm,
              "is-okr-list": true,
              disabled: _vm.automationState,
            },
          }),
          _c(
            "div",
            { staticClass: "action-btns" },
            [
              _vm.fromType !== "addOkrAiList"
                ? _c(
                    "div",
                    {
                      staticClass: "add-task-ai",
                      on: {
                        click: function ($event) {
                          return _vm.addKrkAi(true)
                        },
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "add-icon",
                        attrs: { "icon-class": "icon-ai" },
                      }),
                      _vm._v("AI助写 "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "bottoms-comm-dialog-btns cancel-btn btn-white",
                  on: { click: _vm.cancel },
                },
                [_vm._v("取消")]
              ),
              _c(
                "l-button",
                {
                  staticClass: "bottoms-comm-dialog-btns save-btn btn-blue",
                  attrs: { loading: _vm.loading },
                  on: { clickEvt: _vm.saveKrTitle },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.addKrAiVisible
        ? _c("add-kr-ai", {
            attrs: {
              objData: _vm.okrDataObj,
              editInfo: _vm.editInfo,
              "kr-index": _vm.krIndex,
              isEdit: _vm.isEdit,
              fileList: _vm.fileList,
              sId: _vm.sId,
            },
            on: { refresh: _vm.addKrAisubmit, close: _vm.addKrAiClose },
            model: {
              value: _vm.addKrAiVisible,
              callback: function ($$v) {
                _vm.addKrAiVisible = $$v
              },
              expression: "addKrAiVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }