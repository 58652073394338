var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      {
        staticClass: "single-dialog",
        attrs: {
          visible: _vm.centerDialogVisibles,
          width: _vm.width,
          top: _vm.top,
          "append-to-body": _vm.appendToBody,
          "close-on-click-modal": true,
          "destroy-on-close": true,
          "show-close": _vm.showClo,
          "before-close": _vm.handleDialogClose,
        },
        on: {
          "update:visible": function ($event) {
            _vm.centerDialogVisibles = $event
          },
          "update:showClose": function ($event) {
            _vm.showClo = $event
          },
          "update:show-close": function ($event) {
            _vm.showClo = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          staticClass: "dialog-title-box",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("div", { staticClass: "flex-a-c-center" }, [
            _c("p", {
              staticClass: "title-txt",
              domProps: { textContent: _vm._s(_vm.title) },
            }),
          ]),
        ]
      ),
      _c("div", { staticClass: "guid_out" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.guidNext == 1,
                expression: "guidNext == 1",
              },
            ],
            staticClass: "guid_top",
          },
          [
            _c("ul", { staticClass: "inviter_ul" }, [
              _c("li", { staticClass: "inviter_li" }, [
                _c("p", { staticClass: "guid_top_title" }, [
                  _vm._v("选择加入的部门周期及权限"),
                ]),
                _c("div", { staticClass: "inviter_li_lf flex-new" }, [
                  _c("p", { staticClass: "inviter_li_lf_p" }, [
                    _vm._v("加入部门"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "add_person",
                      staticStyle: { position: "relative", flex: "1" },
                    },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.inviteFriendsStateShow,
                            expression: "inviteFriendsStateShow",
                          },
                        ],
                        staticClass: "add_person_shadow",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.closeShadow.apply(null, arguments)
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "add_person_out",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.selectDepts.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                                "white-space": "nowrap",
                                width: "226px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.select_value.name || "请选择"))]
                          ),
                          _c("i", {
                            staticClass:
                              "el-icon-arrow-right friends_content_list_name_right el-icon-arrow-right-name",
                            class:
                              _vm.inviteFriendsStateShow == true
                                ? "el-icon-router"
                                : "",
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.inviteFriendsStateShow,
                              expression: "inviteFriendsStateShow",
                            },
                          ],
                          staticClass: "add_person_div",
                        },
                        [
                          _c("div", { staticClass: "management_arrow" }),
                          _c("div", { staticClass: "add_person_div_inner" }, [
                            _vm.inviteFriendsList.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "invite_friends_title clearfix",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "friends_title_font",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.getInviteFriendsPage(
                                              "首页",
                                              _vm.index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.companyInfo.name))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "friends_title_font_text",
                                      },
                                      _vm._l(
                                        _vm.inviteFriendsList,
                                        function (item, index) {
                                          return _c(
                                            "span",
                                            {
                                              key: index,
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.getInviteFriendsPage(
                                                    "page",
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.label) + " "
                                              ),
                                              _vm.inviteFriendsList.length >
                                              index + 1
                                                ? _c("i", {
                                                    staticClass:
                                                      "el-icon-arrow-right friends_content_list_name_right",
                                                  })
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "invite_friends_content" },
                              [
                                _vm.inviteFriendsBeans.departs.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "friends_content_list" },
                                      _vm._l(
                                        _vm.inviteFriendsBeans.departs,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass:
                                                "friends_content_list_name clearfix",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.selectDepts_(item)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "friends_content_list_name_left",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.departmentTitle)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "friends_content_list_name_text",
                                                },
                                                [_vm._v(_vm._s(item.label))]
                                              ),
                                              item.id != "-1"
                                                ? _c("i", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          item.children
                                                            .length != 0,
                                                        expression:
                                                          "item.children.length != 0",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "el-icon-arrow-right friends_content_list_name_right",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.getInviteFriendsListSelect_(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "inviter_li_rg flex-new" },
                  [
                    _c("p", { staticClass: "inviter_li_lf_p" }, [
                      _vm._v("权限"),
                    ]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.selectDisabled,
                        },
                        model: {
                          value: _vm.jurisdiction,
                          callback: function ($$v) {
                            _vm.jurisdiction = $$v
                          },
                          expression: "jurisdiction",
                        },
                      },
                      _vm._l(_vm.jurisdictionList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("li", { staticClass: "inviter_li" }, [
                _c("div", { staticClass: "inviter_li_lfs flex-new" }, [
                  _c("p", { staticClass: "inviter_li_lf_p" }, [
                    _vm._v("加入周期"),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "inviter_li_lf_uls" },
                    [
                      _vm._l(_vm.cycleListPanel, function (item, index) {
                        return _c(
                          "li",
                          { key: index },
                          [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            _c("Icon", {
                              staticClass: "clear-img",
                              attrs: { "icon-class": "icon-close" },
                              on: {
                                click: function ($event) {
                                  return _vm.delCycle(item)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _c(
                        "li",
                        [
                          _c("Icon", {
                            staticClass: "inviter_guid_add",
                            attrs: {
                              "icon-class":
                                "icon-task_cooperation_add_yes_not_icon",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getInviteFriendsCycleShowState(
                                  "show"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _c("p", { staticClass: "guid_top_title" }, [
              _vm._v(" 填写手机号或邮箱 "),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.getInviteFriendsCodeShowState("show")
                    },
                  },
                },
                [_vm._v("通过链接或者二维码邀请")]
              ),
            ]),
            _c("ul", { staticClass: "guid_top_ul flex-new-two" }, [
              _c("li", [_c("span"), _vm._v("  姓名 ")]),
              _c("li", { staticClass: "guid_top_ul_li" }, [
                _c("span", [_vm._v("*")]),
                _vm._v("  手机号 "),
              ]),
              _c("li", [_c("span"), _vm._v("  邮箱 ")]),
            ]),
            _c("el-form", { ref: "ManageForm", attrs: { model: _vm.form } }, [
              _c(
                "ul",
                { staticClass: "guid_top_ul guid_top_ul_bottom" },
                _vm._l(_vm.form.dynamicItem, function (item, index) {
                  return _c(
                    "li",
                    { key: index },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            prop: "dynamicItem." + index + ".name",
                            rules: [
                              {
                                required: false,
                                message: "姓名不能为空",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入姓名" },
                            on: {
                              input: function ($event) {
                                return _vm.invitationName(item.name, index)
                              },
                            },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            id: "el-form-item",
                            label: "",
                            prop: "dynamicItem." + index + ".mobile",
                            rules: [
                              {
                                required: item.mobileRules,
                                message: "手机号不能为空",
                                trigger: "blur",
                              },
                              {
                                pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
                                message: "手机格式不正确",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入手机号" },
                            on: {
                              blur: function ($event) {
                                return _vm.mobileBlur(index)
                              },
                            },
                            model: {
                              value: item.mobile,
                              callback: function ($$v) {
                                _vm.$set(item, "mobile", $$v)
                              },
                              expression: "item.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            prop: "dynamicItem." + index + ".email",
                            rules: [
                              {
                                required: false,
                                message: "邮箱不能为空",
                                trigger: "blur",
                              },
                              {
                                pattern:
                                  /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/,
                                message: "邮箱格式有误",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入邮箱" },
                            on: {
                              blur: function ($event) {
                                return _vm.emailBlur(index)
                              },
                            },
                            model: {
                              value: item.email,
                              callback: function ($$v) {
                                _vm.$set(item, "email", $$v)
                              },
                              expression: "item.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("Icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: index == 0,
                            expression: "index == 0",
                          },
                        ],
                        staticClass: "guid_add list-icon-add inviter_guid_add",
                        attrs: {
                          "icon-class":
                            "icon-task_cooperation_add_yes_not_icon",
                        },
                        on: { click: _vm.addItem },
                      }),
                      _c("Icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: index != 0,
                            expression: "index != 0",
                          },
                        ],
                        staticClass: "guid_add list-icon-close",
                        attrs: { "icon-class": "icon-close" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item, index)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.emailChecked,
                  callback: function ($$v) {
                    _vm.emailChecked = $$v
                  },
                  expression: "emailChecked",
                },
              },
              [_vm._v("通过短信或邮件发送邀请")]
            ),
            _c("div", { staticClass: "guid_footer flex-new-four" }, [
              _c(
                "button",
                {
                  staticClass:
                    "login_next common_btn_next common_btn_next_able inviter-btns",
                  on: {
                    click: function ($event) {
                      return _vm.fishEnd("ManageForm")
                    },
                  },
                },
                [_vm._v("完成")]
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm.inviteFriendsCode
        ? _c("div", { staticClass: "invite_friends_code" }, [
            _c("div", { staticClass: "friends_code_box" }, [
              _c("div", { staticClass: "friends_box_top" }, [
                _vm._v(" 通过链接邀请 "),
                _c("i", {
                  staticClass: "el-icon-close friends_box_top_font",
                  on: {
                    click: function ($event) {
                      return _vm.getInviteFriendsCodeShowState("close")
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "friends_code_box_img" }, [
                _c("img", {
                  staticClass: "friends_code_box_img_icon",
                  attrs: { src: _vm.invitationBeans.shortUrlQrCode },
                }),
              ]),
              _c("div", { staticClass: "friends_code_box_text" }, [
                _c("div", { staticClass: "friends_code_box_text1" }, [
                  _vm._v(
                    "我和“" +
                      _vm._s(_vm.invitationBeans.companyName) +
                      "”的小伙伴都在源目标等你，"
                  ),
                ]),
                _c("div", { staticClass: "friends_code_box_text1" }, [
                  _vm._v("用这个专属链接加入我们吧！"),
                ]),
                _c("div", { staticClass: "friends_code_box_text1" }, [
                  _vm._v(_vm._s(_vm.invitationBeans.shortUrl)),
                ]),
              ]),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.invitationBeans.shortUrl,
                      expression: "invitationBeans.shortUrl",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error",
                    },
                  ],
                  staticClass: "friends_code_box_button",
                },
                [_vm._v("复制邀请链接")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.inviteFriendsCycle
        ? _c("div", { staticClass: "invite_friends_code" }, [
            _c("div", { staticClass: "friends_code_box_cycle" }, [
              _c("div", { staticClass: "friends_box_top" }, [
                _vm._v(" 选择周期 "),
                _c("i", {
                  staticClass: "el-icon-close friends_box_top_font",
                  on: {
                    click: function ($event) {
                      return _vm.getInviteFriendsCycleShowState("close")
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "friends_code_box_text_" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cycle_value,
                        expression: "cycle_value",
                      },
                    ],
                    staticClass: "friends_code_box_input",
                    attrs: { type: "text", placeholder: "请输入周期" },
                    domProps: { value: _vm.cycle_value },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.cycle_value = $event.target.value
                        },
                        _vm.getInviteFriendsCycleLists,
                      ],
                    },
                  }),
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass:
                        "friends_code_box_check flex-new-direction-column flex-new",
                      model: {
                        value: _vm.cycleList,
                        callback: function ($$v) {
                          _vm.cycleList = $$v
                        },
                        expression: "cycleList",
                      },
                    },
                    _vm._l(_vm.list, function (item) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.id,
                          class: item.checked == true ? "gray" : "",
                          attrs: {
                            label: item.id,
                            disabled: item.disabled,
                            checked: item.checked,
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "friends_code_box_button",
                  on: {
                    click: function ($event) {
                      return _vm.getInviteFriendsCycleShowState("close_sure")
                    },
                  },
                },
                [_vm._v("确认")]
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer-box",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_vm._t("footer")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }