<template>
  <el-dialog
    title=" AI 助写 KR 任务"
    :visible.sync="dialogVisible"
    width="1120px"
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="handleClose"
    v-on="$listeners"
    class="ai-task-dialog"
  >
    <div class="ai-count-wrap">
      剩余可调用次数：
      <span>{{companyInfo.aiUsageCount || 0}}</span> 次 （更多订阅请咨询售前顾问）
    </div>
    <div class="kr-content-wrap">
      <div class="kr-title">
        <div class="kr-index">KR{{ krIndex + 1}}</div>
        <div class="title" v-html="$utils.changeToHtml(objData.title)"></div>
      </div>
      <div class="btn-list-wrap" v-if="!listLoading">
        <div class="kr-infor">AI 助手已经自动生成围绕KR{{ krIndex + 1}}的工作任务，可以根据需求修改内容，并导入至系统中</div>
        <div class="bottom-step-buttons">
          <el-button
            v-preventReClick
            @click="getKrTaskArrayAgain()"
            :disabled="listLoading || submitLoading || (dataList.length > 0 && selectedItems.length === 0)"
          >重新生成</el-button>
        </div>
      </div>

      <div class="task-list-wrap" v-if="!listLoading">
        <el-table
          ref="table"
          :data="dataList"
          row-key="id"
          :tree-props="{ children: 'children' }"
          :row-style="{height: '54px'}"
          :key="dataList"
          @selection-change="changeSelectItems"
        >
          <template slot="empty">
            <div v-if="dataList.length === 0 && !listLoading" class="table-content-null">
              <no-data />
            </div>
            <div v-else></div>
          </template>
          <el-table-column type="selection" width="40" />
          <template v-for="(item, index) in  columns">
            <el-table-column
              :prop="item.prop"
              :label="item.label"
              :resizable="false"
              :min-width="item.minWidth"
              :width="item.minWidth"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="table-content" v-if="item.prop === 'delete'">
                  <div
                    class="operation-wrap"
                    v-if="!(scope.row.typeIdentifying === 0 || (indexType == 0 && scope.row.typeIdentifying === 4))"
                  >
                    <div class="delete" @click="delField(scope)">删除</div>
                  </div>
                </div>
                <!-- 负责人-->
                <div
                  class="cooperate-people"
                  v-else-if="item.prop === 'originatorName'"
                  @click.stop="chargeUserPickerVisibleClick(scope)"
                >
                  <user-picker @pick="chargeUserPicker($event, scope)" ref="userPicker">
                    <div class="cooperation-add">
                      <i class="head-icon border-radius-20" v-if="scope.row.originatorId === null"></i>
                      <span class="head-icon-label" v-if="scope.row.originatorId === null">待认领</span>
                      <el-image
                        class="avatar border-radius-20"
                        fit="cover"
                        :src="
                            scope.row.originatorAvatar ||
                            require('@/assets/imgs/common/avatar.png')
                          "
                        v-if="scope.row.originatorId !== null"
                      />
                      <p v-if="scope.row.originatorId !== null">
                        <wx-open-data
                          :id="scope.row[item.prop]"
                          :nameData="{name:scope.row[item.prop]}"
                        />
                      </p>
                    </div>
                  </user-picker>
                </div>
                <div class="table-content" v-else-if="item.prop === 'startDate'">
                  <el-date-picker
                    v-model="scope.row.startDate"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    type="datetime"
                    placeholder="开始时间"
                    class="date-box"
                    :picker-options="pickerOptionsStart(scope.row)"
                    popper-class="addTimeClass"
                    :clearable="false"
                    @change="startDateChange"
                  />
                </div>
                <div class="table-content" v-else-if="item.prop === 'endDate'">
                  <el-date-picker
                    v-model="scope.row.endDate"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    type="datetime"
                    placeholder="截止时间"
                    class="date-box"
                    :picker-options="pickerOptionsEnd(scope.row)"
                    popper-class="addTimeClass"
                    :clearable="false"
                    @change="endDateChange"
                  />
                </div>
                <div class="table-content" v-else-if="item.prop === 'title'">
                  <task-title-alert
                    :obj-data="scope.row"
                    @refresh="taskTitleRefresh($event, scope)"
                    slot="okrTableContent"
                  >
                    <div class="task-title">{{(scope.row[item.prop] || '-')}}</div>
                  </task-title-alert>
                </div>
                <div
                  class="table-content information"
                  v-else-if="item.prop === 'information'"
                  @click="descClick(scope)"
                  v-html="(scope.row[item.prop] || '-')"
                ></div>
                <template v-else>{{(scope.row[item.prop] || '-')}}</template>
                <!-- <div class="table-content" v-html="(scope.row[item.prop] || '-')" v-else></div> -->
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="task-list-wrap loading-wrap" v-if="listLoading">
        <!-- <no-data
          text="源目标AI助手生成任务中，请稍作等待哦！"
          :imgUrl="require('@/assets/imgs/common/page-loading.gif')"
        />-->
        <div class="step-list-wrap">
          <div
            :class="`step-list ${index > stepLoading}`"
            v-for="(item, index) in stepTitleList"
            :key="item"
            v-if="index <= stepLoading"
          >
            <i class="el-icon-loading icon" v-if="stepLoading === index"></i>
            <Icon
              icon-class="icon-a-daibanyiwancheng1"
              :class="`icon ${index > stepLoading}`"
              v-else
            />
            <div class="title">{{item}}</div>
          </div>
        </div>
      </div>
      <div class="bottom-step-buttons">
        <el-button @click="handleClose" class="left-button" :disabled="listLoading">取消</el-button>
        <el-button
          v-preventReClick
          :loading="loading"
          @click="submit()"
          :disabled="listLoading || dataList.length === 0 || submitLoading"
        >一键生成任务</el-button>
      </div>
    </div>
    <custom-dialog
      v-if="customDialogvisible"
      v-model="customDialogvisible"
      @submit="customDialogSubmit"
    />
    <desc-dialog
      v-if="descDialogVisible"
      v-model="descDialogVisible"
      :content="information"
      @submit="descDialogSubmit"
    />
  </el-dialog>
</template>
<script>
import taskTitleAlert from '@/views/okr/table/addTaskAi/taskTitleAlert'
import customDialog from '@/views/okr/table/addTaskAi/customDialog'
import descDialog from '@/views/okr/table/addTaskAi/descDialog'
export default {
  components: {
    taskTitleAlert,
    customDialog,
    descDialog
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    objData: {
      type: Object,
      required: false
    },
    krIndex: {
      default: 0
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
        if (val) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      dataList: [],
      columns: [
        {
          label: "标题",
          prop: "title"
        },
        {
          label: "负责人",
          prop: "originatorName",
          minWidth: 120
        },
        {
          label: "开始时间",
          prop: "startDate",
          minWidth: 170
        },
        {
          label: "截止时间",
          prop: "endDate",
          minWidth: 170
        },
        {
          label: "描述",
          prop: "information",
          minWidth: 220
        },
        {
          label: "操作",
          prop: "delete",
          minWidth: 80
        }
      ],
      listLoading: true,
      submitLoading: false,
      rebuild: 0,
      selectedItems: [],
      customDialogvisible: false,
      descDialogVisible: false,
      information: '',
      editIndex: 0,
      stepLoading: 0,
      stepTitleList: ['小源AI正在分析您的问题.....', 'AI模型正在匹配最佳信息.....', '您的任务正在生成中.....', '请稍候，您的任务即将出炉...']
    }
  },
  methods: {
    setLoadingInit() {
      this.stepLoading = 0
      setTimeout(() => {
        this.stepLoading = 1
      }, 2000)
      setTimeout(() => {
        this.stepLoading = 2
      }, 5000)
      setTimeout(() => {
        this.stepLoading = 3
      }, 8000)
    },
    descDialogSubmit(information) {
      this.dataList[this.editIndex].information = information
    },
    descClick(scope) {
      this.editIndex = scope.$index
      this.information = scope.row.information
      this.descDialogVisible = true
    },
    customDialogSubmit(requirement) {
      var selectedItems = JSON.parse(JSON.stringify(this.selectedItems))
      console.log('selectedItems', selectedItems)
      var list = []
      this.dataList.forEach((item, itemIndex) => {
        var state = false
        selectedItems.forEach((selectItem) => {
          if (item.id === selectItem.id) {
            state = true
          }
        })
        if (!state) {
          list.push(item)
        }
      })
      this.dataList = list
      console.log('dataList', this.dataList)
      this.selectedItems = []
      this.getKrTaskArray(selectedItems.length === 0 ? 5 : selectedItems.length, requirement)
    },
    changeSelectItems(data) {
      this.selectedItems = data
    },
    taskTitleRefresh(data, row) {
      this.dataList[row.$index].title = data.title
    },
    pickerOptionsStart(row) {
      const _this = this
      return {
        // 开始日期范围限制
        disabledDate(time) {
          let disabled
          if (row.endDate) {
            disabled = time.getTime() > _this.$moment(row.endDate).startOf('day').format('x')
          }
          return disabled
        }
      }
    },
    pickerOptionsEnd(row) {
      const _this = this
      return {
        // 结束日期范围限制
        disabledDate(time) {
          let disabled
          if (row.startDate) {
            disabled =
              time.getTime() <
              _this.$moment(row.startDate).startOf('day').format('x')
          }
          return disabled
        }
      }
    },
    chargeUserPickerVisibleClick() {
    },
    // 协作人选择
    chargeUserPicker(userData, row) {
      const { id, avatar, name } = userData
      this.dataList[row.$index].originatorId = id
      this.dataList[row.$index].originatorName = name
      this.dataList[row.$index].originatorAvatar = avatar
    },
    delField(scope) {
      this.dataList.splice(scope.$index, 1)
    },
    getKrTaskArrayAgain() {
      console.log('this.selectedItems', this.selectedItems)
      if (this.dataList.length > 0 && this.selectedItems.length === 0) {
        this.$message.warn('请先选择需要重新生成的任务哦！')
        return
      }
      this.customDialogvisible = true
      // this.getKrTaskArray(this.selectedItems.length === 0 ? 5 : this.selectedItems.length, '')
    },
    async getKrTaskArray(count = 5, requirement = '') {
      this.listLoading = true
      this.setLoadingInit()
      const beans = {
        companyId: this.companyInfo.id,
        content: this.objData.objContent,
        kr: this.objData.title,
        rebuild: this.rebuild,
        count,
        createDescription: 1,
        requirement
      }
      this.$apis.CREATETASKBYAI(beans)
        .then((rest) => {
          if (rest.status === 200) {
            rest.data.tasksList && rest.data.tasksList.forEach((item, index) => {
              item.originatorId = this.userInfo.id
              item.originatorName = this.userInfo.name
              item.originatorAvatar = this.userInfo.avatar
              item.fieldCategoriesId = ''
              item.objId = this.objData.objId
              item.keyId = this.objData.id
              item.cycleId = this.objData.cycleId
              item.businessType = 3
              item.id = Math.random()
              item.information = item.description
            })
            this.dataList = this.dataList.concat(rest.data.tasksList)
            this.companyInfo.aiUsageCount = rest.data.aiUsageCount
            this.$store.commit('user/setCompanyInfo', this.companyInfo)
          } else {
            this.$message.error(rest.message || '生成KR任务失败哦！')
          }
          this.listLoading = false
          this.rebuild = 1
        })
    },
    // 获取进度列表
    async getProcessList(val) {
      console.log('getProcessList')
      const beans = {
        objId: this.objData.objId,
        keyId: this.objData.id
      }
      this.$apis
        .OKRPROCESSLIST(beans)
        .then((rest) => {
          const dataBeans = rest.data
          this.processArray = dataBeans.map((item) => {
            item.krName = `KR${this.krIndex + 1}`
            return item
          })
          this.processArrayState = false
        })
        .catch((err) => {
          console.log(err)
          this.processArrayState = false
        })
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    initData() {
      this.objData = JSON.parse(JSON.stringify(this.objData))
      this.getKrTaskArray()
    },
    // 提交
    async submit() {
      this.submitLoading = true
      this.$apis
        .ADDBATCHTASK(this.dataList)
        .then((rest) => {
          if (rest.status === 200) {
            this.$message({
              msg: '新增成功',
              type: 'success'
            })
            this.$emit('refresh', this.dataList)
            this.handleClose()
          } else {
            this.$message.error(rest.message)
          }
          this.submitLoading = false
        })
        .catch((err) => {
          this.submitLoading = false
          console.log(err)
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.step-list-wrap {
  width: 260px;
  margin: 0px auto;
  text-align: center;
  .step-list {
    .flex-l();
    text-align: left;
    margin: 40px 0px;
    position: relative;
    color: #434458;
    font-size: 16px;
    .icon {
      color: #3a70ff;
      margin-right: 16px;
      font-size: 24px;
      width: 24px;
      height: 24px;
    }
    .icon.true {
      color: #a0a3bd;
    }
    &::before {
      content: '';
      width: 1px;
      height: 26px;
      background: #3a70ff;
      position: absolute;
      top: 32px;
      left: 12px;
      right: 0px;
    }
  }
  .step-list.true {
    color: #a0a3bd;
    &::before {
      background: #a0a3bd;
    }
  }
  .step-list:last-child {
    &::before {
      display: none;
    }
  }
}
.table-content.information,
.information p {
  .text-n(1);
  display: block;
  cursor: pointer;
  width: 100%;
  max-width: 220px;
  word-break: break-all;
  &:hover {
    color: #3a70ff;
  }
}
.ai-count-wrap {
  position: absolute;
  right: 64px;
  font-size: 12px;
  top: 34px;
  line-height: 28px;
  color: #a0a3bd;
  span {
    color: #ed2e7e;
  }
}
::v-deep {
  .el-table-column--selection.el-table__cell {
    text-align: center !important;
    .cell {
      padding: 0px;
    }
  }
  .no-data-show {
    .no-data-show-text {
      display: none;
    }
  }
  .loading-wrap .no-data-show .no-data-show-icon {
    width: 60px;
  }
  .loading-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .no-data-show-text {
    margin-top: 24px;
  }
}
.kr-content-wrap {
  .kr-title {
    .flex-l();
    margin: 24px 0px 32px 0px;
    align-items: flex-start;
    .kr-index {
      color: #3a70ff;
      margin-right: 8px;
      font-weight: bold;
    }
    .title {
      flex: 1;
    }
    font-size: 14px;
    color: #434458;
    line-height: 22px;
    padding: 12px 24px;
    background: rgba(58, 120, 246, 0.08);
    border-radius: 12px;
  }
  .btn-list-wrap {
    .flex-bt();
    align-items: center;
    margin-bottom: 16px;
    .bottom-step-buttons {
      margin: 0px;
    }
  }
  .kr-infor {
    color: #a0a3bd;
    font-size: 12px;
  }
  .task-list-wrap {
    min-height: 300px;
    .operation-wrap {
      .flex-l();
      cursor: pointer;
      .icon {
        margin-right: 8px;
        font-size: 18px;
      }

      .delete {
        color: #3a70ff;
        &:hover {
          color: #c0d4fc;
        }
      }
      &:hover {
        color: #444558;
      }
    }
    .cooperate-people {
      .cooperation-add {
        cursor: pointer;
        .flex-l();
        .avatar {
          .w-n(24);
          .h-n(24);
          .mg-r-n(8);
          border-radius: 100%;
          min-width: 24px;
        }
        p {
          max-width: 150px;
          .text-overOne();
          color: #6e7491;
        }
        .head-icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('~@/assets/imgs/pm/head-icon.png') no-repeat;
          background-size: 100% 100%;
        }
        .head-icon-label {
          margin-left: 8px;
          font-weight: 500;
          color: #a0a3bd;
        }
      }
    }
    .task-title {
      .text-n(1);
      display: block;
      cursor: pointer;
      &:hover {
        color: #3a70ff;
      }
    }
  }
}
.bottom-step-buttons {
  margin: 32px 0px 0px 0px;
  justify-content: center;
  .left-button,
  .el-button {
    height: 32px;
    border-radius: 8px;
  }
  .left-button {
    margin-right: 8px;
  }
}
::v-deep {
  .el-table__cell {
    padding: 8px 0;
  }
  .is-leaf.el-table__cell {
    padding: 12px 0;
  }
  .el-input__prefix {
    display: none;
  }
  .el-date-editor {
    width: 100% !important;
    .el-input__inner {
      padding: 6px 16px;
      height: 36px;
      line-height: 36px;
    }
  }
  .el-table__header-wrapper {
    .el-table__cell {
      background-color: #f8f8f9;
      color: #606266;
      font-size: 14px;
      .cell {
        padding: 0px 8px;
      }
    }
  }
}
</style>
