var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          title: "编辑描述",
          visible: _vm.dialogVisible,
          width: "780px",
          "close-on-click-modal": false,
          "append-to-body": true,
          "before-close": _vm.handleClose,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "content-wrap" }, [
        _c(
          "div",
          { staticClass: "editor-wrap" },
          [
            _c("Editor", {
              attrs: { height: 260, width: "100%" },
              model: {
                value: _vm.description,
                callback: function ($$v) {
                  _vm.description = $$v
                },
                expression: "description",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottom-step-buttons" },
          [
            _c(
              "el-button",
              { staticClass: "left-button", on: { click: _vm.handleClose } },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }