var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { key: Math.random(), staticClass: "no-data-show" }, [
      _vm.type === 1
        ? _c("img", {
            staticClass: "no-data-show-icon",
            attrs: { src: _vm.imgUrl },
          })
        : _c("img", {
            staticClass: "no-data-show-icon img-2",
            attrs: { src: require("@/assets/imgs/common/taskNull.png") },
          }),
      _vm.text
        ? _c(
            "div",
            {
              staticClass: "no-data-show-text",
              style: "color:" + _vm.textColor,
            },
            [_vm._v(" " + _vm._s(_vm.text) + " ")]
          )
        : _vm._e(),
      _vm.searchText
        ? _c("div", { staticClass: "search-text" }, [
            _vm._v(_vm._s(_vm.searchText)),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }