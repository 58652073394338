var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply-demo-wrap", on: { click: _vm.applyDemo } },
    [
      _c("Img", { attrs: { src: "/header/applyImg.gif" } }),
      _vm.applyDialogVisible
        ? _c("applyDialog", {
            model: {
              value: _vm.applyDialogVisible,
              callback: function ($$v) {
                _vm.applyDialogVisible = $$v
              },
              expression: "applyDialogVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }