<template>
  <div>
    <el-popover
      ref="moreMenuPopRef"
      v-model="visible"
      :placement="placement"
      trigger="click"
      popper-class="member-menu-popover"
      :visible-arrow="false"
      @show="show"
      @hide="hide"
    >
      <div class="member-card-pop" @click.stop>
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="item.text ? 'menu-item menu-item-special' : 'menu-item'"
          :style="{ color: item.color ? item.color : '#14142b' }"
          @click.stop="menuClick(item.func)"
        >
          <div class="menu-item-text-div">
            <div class="menu-item-text">
              <Icon v-if="(item.icon || '') !== ''" :icon-class="item.icon" class="menu-icon" />
              <!-- 规范公共基础组件使用，勿添加业务代码 -->
              {{ item.title }}
            </div>
            <p v-if="item.text" class="menu-text">{{ item.text }}</p>
          </div>
        </div>
      </div>
      <slot slot="reference"></slot>
    </el-popover>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  model: {
    prop: 'popVisible',
    event: 'change'
  },
  props: {
    items: {
      type: Array
    },
    popVisible: {
      type: Boolean
    },
    placement: {
      type: String,
      default: 'right'
    }
  },
  data() {
    return {
      visible: this.popVisible
    }
  },
  methods: {
    menuClick(func) {
      this.$emit('menuClick', func)
      this.visible = false
      this.$store.commit('common/setScrollBgVisible', false)
    },
    closePopView() {
      if (this.$refs.moreMenuPopRef) {
        this.$refs.moreMenuPopRef.doClose()
      }
    },
    show() {
      this.$store.commit('common/setScrollBgVisible', true)
    },
    hide() {
      this.$store.commit('common/setScrollBgVisible', false)
    }
  }
}
</script>
<style lang="less" scoped>
.member-card-pop {
  // padding: 8px 0;
  .menu-item:last-child {
    .menu-item-text-div {
      border-bottom: none;
    }
  }
  .menu-item {
    height: 40px;
    font-size: 14px;
    color: #14142b;
    line-height: 40px;
    padding: 0 8px;
    margin: 0 4px;
    cursor: pointer;
    .menu-item-text-div {
      display: flex;
      flex-direction: column;
    }
    .menu-item-text {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f5f6fa;
      .menu-icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }
    .menu-text {
      font-size: 10px;
      font-weight: 400;
      color: #a0a3bd;
      // padding-left: 24px;
    }
    &:hover {
      background: #f5f6fa;
      border-radius: 8px;
      .menu-item-text {
        border-bottom: 1px solid rgba(245, 246, 250, 0.54);
        border-radius: 12px;
      }
    }
    &:last-of-type {
      .menu-item-text {
        border-bottom: 0;
      }
    }
  }
  .menu-item-special {
    height: auto;
    line-height: 20px;
    padding: 0px 8px;
    .menu-item-text-div {
      padding: 8px 0px;
      border-bottom: 1px solid #f5f6fa;
    }
    .menu-item-text {
      border-bottom: none;
    }
  }
}
</style>
<style>
.member-menu-popover {
  border-radius: 16px;
  border: 0;
  padding: 0;
  overflow: hidden;
}
</style>
