var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-upload", class: "file-upload-" + _vm.formPage },
    [
      _c("upload-file-list", {
        attrs: {
          "form-page": _vm.formPage,
          preview: true,
          disabled: _vm.disabled,
          "oss-file-entity-list": _vm.uploadFileList,
        },
        on: { delete: _vm.fileDelete },
      }),
      _c(
        "el-upload",
        {
          ref: "commentUpload" + _vm.infoItem.id,
          staticClass: "upload-demo",
          attrs: {
            "show-file-list": false,
            action: "",
            "on-change": _vm.change,
            "http-request": _vm.uploadFileHttp,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "upload-btn-wrap",
              attrs: { slot: "trigger" },
              on: {
                click: function ($event) {
                  return _vm.resetFileCount()
                },
              },
              slot: "trigger",
            },
            [
              _c(
                "div",
                { staticClass: "upload-btn" },
                [
                  _c("Icon", {
                    class: _vm.disabled
                      ? "icon-upload-disabled"
                      : "icon-upload",
                    attrs: { "icon-class": "icon-tianjia-xuxian" },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }