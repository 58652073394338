<template>
  <el-dialog
    :title="titleName[`${type}Title`]"
    :visible.sync="dialogVisible"
    width="480px"
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="handleClose"
    v-on="$listeners"
  >
    <div class="content-wrap">
      <div class="remind-wrap" v-if="step === 1">
        <Icon icon-class="icon-shenpijinggao" class="icon" />
        <div class="title">{{titleName[`${type}Content`]}}</div>
      </div>
      <div class="input-wrap" v-if="step === 2">
        <el-input
          v-model.lazy="content"
          type="textarea"
          :placeholder="titleName[`${type}Placeholder`]"
          class="input-focus"
          :rows="6"
        />
      </div>
      <div class="bottom-step-buttons">
        <el-button @click="handleClose" class="left-button">取消</el-button>
        <el-button @click="nextStep(2)" class="left-button" v-if="step === 1">自定义要求</el-button>
        <el-button @click="nextStep(2)" v-if="step === 1">好的</el-button>
        <el-button @click="submit(1)" class="system-button" v-if="step === 2">系统生成</el-button>
        <el-button @click="submit(2)" v-if="step === 2">自定义生成</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  components: {
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    type: {
      default: 'task'
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      },
      immediate: true
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      titleName: {
        taskTitle: '重新生成 KR 任务',
        taskPlaceholder: '请描述您需要生成KR任务所需要的信息，例如：生成本周所需要的任务或针对某个关键动作的任务',
        krTitle: '重新生成关键结果KR',
        krPlaceholder: '请描述您需要生成KR所需要的信息，例如：针对销售过程或完成某项关键指标等',
        taskContent: '如果您觉得系统为您推荐生成的任务不是你想要的内容，可以点击【自定义要求】按钮来完成任务的生产',
        krContent: '如果您觉得系统为您推荐生成的关键结果不是你想要的内容，可以点击【自定义要求】按钮来完成KR的生产'
      },
      step: 1,
      content: ''
    }
  },
  methods: {
    nextStep(step) {
      this.step = step
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    submit(type) {
      if (type === 2 && this.content === '') {
        this.$message.warn('描述不能为空哦！')
        return
      }
      this.$emit('submit', this.content)
      this.handleClose()
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
::v-deep {
  .el-dialog__body {
    padding: 24px 32px !important;
  }
  .el-textarea {
    border: none !important;
  }
  .el-textarea,
  .el-textarea__inner {
    // border: none !important;
    // resize: none !important;
    background: none !important;
    height: 100%;
  }
  .el-textarea__inner {
    border: 1px solid #e7e8ef !important;
    border-radius: 8px;
    &::placeholder {
      font-size: 14px;
    }
  }
}
.content-wrap {
  .remind-wrap {
    .flex-l();
    padding: 16px;
    border-radius: 8px;
    background: rgba(255, 153, 0, 0.08);
    color: rgb(255, 153, 0);
    align-items: flex-start;
    .icon {
      font-size: 18px;
      margin-right: 8px;
      min-width: 18px;
    }
    .title {
      line-height: 1.5;
    }
  }
}
.bottom-step-buttons {
  margin: 32px 0px 0px 0px;
  justify-content: center;
  .left-button,
  .el-button {
    height: 32px;
    border-radius: 8px;
  }
  .left-button,
  .system-button {
    margin-right: 8px;
  }
}
</style>
