var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "set-task-time" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "自定义重复",
            visible: true,
            width: "500px",
            "append-to-body": true,
          },
          on: { close: _vm.$dialogClose },
        },
        [
          _c(
            "div",
            { staticClass: "m-b-20" },
            [
              _c("span", [_vm._v("重复：")]),
              _c(
                "el-select",
                {
                  staticClass: "m-r-20",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.customTypeChange },
                  model: {
                    value: _vm.customType,
                    callback: function ($$v) {
                      _vm.customType = $$v
                    },
                    expression: "customType",
                  },
                },
                _vm._l(_vm.customTypeDict, function (val, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: val, value: parseInt(key) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.isDaily
            ? [
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("间隔：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "m-r-20",
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.customInterval,
                          callback: function ($$v) {
                            _vm.customInterval = $$v
                          },
                          expression: "customInterval",
                        },
                      },
                      _vm._l(7, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                    _c("span", [_vm._v("天")]),
                  ],
                  1
                ),
                _c("p", { staticClass: "f-z-16 m-t-20" }, [
                  _vm._v("每" + _vm._s(_vm.dailyText) + "重复"),
                ]),
              ]
            : _vm._e(),
          _vm.isWeekly
            ? [
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("间隔：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "m-r-20",
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.customInterval,
                          callback: function ($$v) {
                            _vm.customInterval = $$v
                          },
                          expression: "customInterval",
                        },
                      },
                      _vm._l(7, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                    _c("span", [_vm._v("周")]),
                  ],
                  1
                ),
                _c(
                  "el-checkbox-group",
                  {
                    staticClass: "m-t-20",
                    on: { change: _vm.customCycleNumChange },
                    model: {
                      value: _vm.customCycleNum,
                      callback: function ($$v) {
                        _vm.customCycleNum = $$v
                      },
                      expression: "customCycleNum",
                    },
                  },
                  _vm._l(7, function (item) {
                    return _c(
                      "el-checkbox-button",
                      { key: item, attrs: { label: item } },
                      [_vm._v(_vm._s(_vm.weekToCn(item)))]
                    )
                  }),
                  1
                ),
                _c(
                  "p",
                  { staticClass: "f-z-16 m-t-20" },
                  [
                    _c("span", { staticClass: "p-r-5" }, [
                      _vm._v(" 每 "),
                      _vm.customInterval !== 1
                        ? _c("span", [_vm._v(_vm._s(_vm.customInterval))])
                        : _vm._e(),
                      _vm._v("周 "),
                    ]),
                    _vm._l(_vm.customCycleNum, function (item, index) {
                      return [
                        _c(
                          "span",
                          { key: item },
                          [
                            _vm._v(" 周" + _vm._s(_vm.weekToCn(item)) + " "),
                            _vm.isLastIndex(index) ? [_vm._v(",")] : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    }),
                    _c("span", { staticClass: "p-l-5" }, [_vm._v("重复")]),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _vm.isMonth
            ? [
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("间隔：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "m-r-20",
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.customInterval,
                          callback: function ($$v) {
                            _vm.customInterval = $$v
                          },
                          expression: "customInterval",
                        },
                      },
                      _vm._l(7, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                    _c("span", [_vm._v("月")]),
                  ],
                  1
                ),
                _c(
                  "ul",
                  { staticClass: "day-box" },
                  _vm._l(_vm.day, function (item) {
                    return _c(
                      "li",
                      {
                        key: item,
                        staticClass: "item",
                        class: _vm.dayActiveClass(item),
                        on: {
                          click: function ($event) {
                            return _vm.dayClick(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  0
                ),
                _c(
                  "p",
                  { staticClass: "f-z-16 m-t-20" },
                  [
                    _c("span", { staticClass: "p-r-5" }, [
                      _vm._v(" 每 "),
                      _vm.customInterval !== 1
                        ? _c("span", [_vm._v(_vm._s(_vm.customInterval))])
                        : _vm._e(),
                      _vm._v("月 "),
                    ]),
                    _vm._l(_vm.customCycleNum, function (item, index) {
                      return [
                        _c(
                          "span",
                          { key: item },
                          [
                            _vm._v(" " + _vm._s(item) + " "),
                            _vm.isLastIndex(index) ? [_vm._v(",")] : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    }),
                    _c("span", { staticClass: "p-l-5" }, [_vm._v("重复")]),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "flex-new justify-end footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancel-btns",
                  on: {
                    click: function ($event) {
                      return _vm.cancelDialog()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "button",
                {
                  staticClass: "common_btn_next common_btn_next_able",
                  attrs: { loading: _vm.loading },
                  on: { click: _vm.save },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }