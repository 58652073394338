var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.inviteFriendsShowStatez
      ? _c(
          "div",
          {
            staticClass: "invite_friends",
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.getInvitationClose.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "invite_friends_box" }, [
              _c("div", { staticClass: "friends_box_top" }, [
                _vm._v(" 邀请成员加入 "),
                _c("i", {
                  staticClass: "el-icon-close friends_box_top_font",
                  on: { click: _vm.getInvitationClose },
                }),
              ]),
              _c(
                "div",
                { staticClass: "invite_friends_link clearfix" },
                [
                  _c("Icon", {
                    staticClass: "invite_friends_link_icon",
                    attrs: { "icon-class": "icon-connect" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "invite_friends_link_name",
                      on: {
                        click: function ($event) {
                          return _vm.getInviteFriendsCodeShowState("show")
                        },
                      },
                    },
                    [_vm._v("邀请外部人员加入")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "invite_friends_search" },
                [
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: {
                      "prefix-icon": "el-icon-search",
                      placeholder: "输入关键词搜索成员",
                    },
                    on: { input: _vm.searchObjectiveOrTask },
                    model: {
                      value: _vm.inputValue,
                      callback: function ($$v) {
                        _vm.inputValue = $$v
                      },
                      expression: "inputValue",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "invite_friends_title clearfix" }, [
                _c(
                  "div",
                  {
                    staticClass: "friends_title_font",
                    on: {
                      click: function ($event) {
                        return _vm.getInviteFriendsPage("首页", _vm.index)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.companyInfo.name))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "friends_title_font_text",
                    attrs: { id: "friends_title_font_text" },
                  },
                  _vm._l(_vm.inviteFriendsList, function (item, index) {
                    return _c(
                      "span",
                      {
                        key: item.id,
                        on: {
                          click: function ($event) {
                            return _vm.getInviteFriendsPage("page", index)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item.name) + " "),
                        _vm.inviteFriendsList.length > index + 1
                          ? _c("i", {
                              staticClass:
                                "el-icon-arrow-right friends_content_list_name_right",
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "invite_friends_content",
                  attrs: { id: "invite_friends_content" },
                },
                [
                  _vm.inviteFriendsBeans.departs.length > 0 ||
                  _vm.inviteFriendsBeans.userInfos.length > 0
                    ? _c(
                        "div",
                        { staticClass: "friends_content_list" },
                        [
                          _vm._l(
                            _vm.inviteFriendsBeans.departs,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass:
                                    "friends_content_list_name clearfix",
                                  on: {
                                    click: function ($event) {
                                      return _vm.getInviteFriendsListSelect(
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "friends_content_list_name_left",
                                    },
                                    [_vm._v(_vm._s(item.departmentTitle))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "friends_content_list_name_text",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.name) +
                                          " * " +
                                          _vm._s(item.userNum)
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-right friends_content_list_name_right",
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "friends_content_list_name_button",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.getInviteFriendsAdd(
                                            item,
                                            "添加全部"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("添加全部")]
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm._l(
                            _vm.inviteFriendsBeans.userInfos,
                            function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass:
                                    "friends_content_list_name clearfix",
                                  on: { mouseover: _vm.getMouseOver },
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "friends_content_list_name_left border-radius-20",
                                    attrs: { src: item.avatar },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "friends_content_list_name_text",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  item.status === 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "friends_content_list_name_button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.getInviteFriendsAdd(
                                                item,
                                                "添加"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("添加")]
                                      )
                                    : _vm._e(),
                                  item.status === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "friends_content_list_name_button_font",
                                        },
                                        [_vm._v("已添加")]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _vm.inviteFriendsCode
              ? _c("div", { staticClass: "invite_friends_code" }, [
                  _c("div", { staticClass: "friends_code_box" }, [
                    _c("div", { staticClass: "friends_box_top" }, [
                      _vm._v(" 通过链接邀请 "),
                      _c("i", {
                        staticClass: "el-icon-close friends_box_top_font",
                        on: {
                          click: function ($event) {
                            return _vm.getInviteFriendsCodeShowState("close")
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "friends_code_box_img" }, [
                      _c("img", {
                        staticClass: "friends_code_box_img_icon",
                        attrs: { src: _vm.invitationBeans.shortUrlQrCode },
                      }),
                    ]),
                    _c("div", { staticClass: "friends_code_box_text" }, [
                      _c("div", { staticClass: "friends_code_box_text1" }, [
                        _vm._v(
                          "我和“" +
                            _vm._s(_vm.invitationBeans.companyName) +
                            "”的小伙伴都在源目标等你，"
                        ),
                      ]),
                      _c("div", { staticClass: "friends_code_box_text1" }, [
                        _vm._v("用这个专属链接加入我们吧！"),
                      ]),
                      _c("div", { staticClass: "friends_code_box_text1" }, [
                        _vm._v(_vm._s(_vm.invitationBeans.shortUrl)),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.invitationBeans.shortUrl,
                            expression: "invitationBeans.shortUrl",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                        staticClass: "friends_code_box_button",
                      },
                      [_vm._v("复制邀请链接")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.inviteByCodeVisible
              ? _c("invite-by-code", {
                  on: { closeInviterCode: _vm.closeInviterCode },
                  model: {
                    value: _vm.inviteByCodeVisible,
                    callback: function ($$v) {
                      _vm.inviteByCodeVisible = $$v
                    },
                    expression: "inviteByCodeVisible",
                  },
                })
              : _vm._e(),
            _vm.customerServiceVisible
              ? _c("customer-service", {
                  model: {
                    value: _vm.customerServiceVisible,
                    callback: function ($$v) {
                      _vm.customerServiceVisible = $$v
                    },
                    expression: "customerServiceVisible",
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }