var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "按「对齐我的OKR」完成情况自动更新",
        visible: _vm.dialogVisible,
        width: "880px",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.okrList.length > 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "vueloading",
                  rawName: "v-vueloading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "set-content",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "kr-infor-list" }, [
                _vm._v(" 我的KR量化（初始值: "),
                _c("span", [_vm._v(_vm._s(_vm.item.quantificationStart))]),
                _vm._v(" 目标值: "),
                _c("span", [_vm._v(_vm._s(_vm.item.quantificationEnd))]),
                _vm._v(" 单位: "),
                _c("span", [_vm._v(_vm._s(_vm.item.quantificationUnit))]),
                _vm._v(" ） "),
              ]),
              _c("div", { staticClass: "select-type" }, [
                _c("div", { staticClass: "push-from" }, [_vm._v("Ta完成")]),
                _c("div", { staticClass: "push-to" }, [_vm._v("推动我完成")]),
                _c("div", { staticClass: "push-rate" }, [
                  _vm._v("自动更新进度"),
                ]),
              ]),
              _vm._l(_vm.okrList, function (okrListItem, okrListIndex) {
                return _c(
                  "div",
                  { key: okrListIndex, staticClass: "okr-person-list" },
                  [
                    _c("div", { staticClass: "okr-person" }, [
                      okrListItem[0].userAvatar
                        ? _c("img", {
                            attrs: { src: okrListItem[0].userAvatar, alt: "" },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/imgs/common/avatar.png"),
                            },
                          }),
                      _c(
                        "p",
                        [
                          _c("wx-open-data", {
                            attrs: {
                              id: okrListItem[0].userName,
                              nameData: { name: okrListItem[0].userName },
                            },
                          }),
                          _vm._v(" " + _vm._s(okrListItem.length) + " "),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "okr-list-wrap" },
                      [
                        _c(
                          "el-collapse",
                          { attrs: { accordion: "" } },
                          _vm._l(okrListItem, function (okrItem, okrIndex) {
                            return _c(
                              "el-collapse-item",
                              { key: okrItem.id },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    { staticClass: "title-left" },
                                    [
                                      _c("el-checkbox", {
                                        attrs: { disabled: okrItem.disabled },
                                        on: {
                                          change: function ($event) {
                                            return _vm.okrCheckedChange(
                                              okrListIndex,
                                              okrIndex
                                            )
                                          },
                                        },
                                        model: {
                                          value: okrItem.checked,
                                          callback: function ($$v) {
                                            _vm.$set(okrItem, "checked", $$v)
                                          },
                                          expression: "okrItem.checked",
                                        },
                                      }),
                                      _c("div", { staticClass: "okr-num" }, [
                                        _vm._v("O" + _vm._s(okrIndex + 1)),
                                      ]),
                                      _c("div", {
                                        staticClass: "content",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.changeToHtml(
                                              okrItem.content
                                            )
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  okrItem.checked
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "title-right",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "push-from push" },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass: "input-focus",
                                                  attrs: {
                                                    placeholder: "0",
                                                    min: 0,
                                                    max: 100,
                                                  },
                                                  on: {
                                                    change: _vm.getSelectList,
                                                  },
                                                  model: {
                                                    value: okrItem.pushFrom,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        okrItem,
                                                        "pushFrom",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "okrItem.pushFrom",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [_vm._v("%")]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "push-to push" },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass: "input-focus",
                                                  attrs: { placeholder: "0" },
                                                  on: {
                                                    change: _vm.getSelectList,
                                                  },
                                                  model: {
                                                    value: okrItem.pushTo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        okrItem,
                                                        "pushTo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "okrItem.pushTo",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.item
                                                            .quantificationUnit
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "push-rate push" },
                                            [
                                              _vm._v(
                                                _vm._s(okrItem.pushRate) + "%"
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "key-list-wrap" },
                                  _vm._l(
                                    okrItem.keyresultsList,
                                    function (keyItem, keyIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: keyItem.id,
                                          staticClass: "key-list",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "key-list-left" },
                                            [
                                              _c("el-checkbox", {
                                                attrs: {
                                                  disabled:
                                                    (keyItem.disabled &&
                                                      okrItem.checkState !==
                                                        true) ||
                                                    keyItem.quantificationType ===
                                                      1,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.keyCheckedChange(
                                                      okrListIndex,
                                                      okrIndex,
                                                      keyIndex
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: keyItem.checked,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      keyItem,
                                                      "checked",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "keyItem.checked",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "key-num" },
                                                [
                                                  _vm._v(
                                                    "KR" + _vm._s(keyIndex + 1)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "content-list" },
                                                [
                                                  _c("div", {
                                                    staticClass: "content",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.$utils.changeToHtml(
                                                          keyItem.title
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                  keyItem.quantificationType ===
                                                  0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "infor",
                                                        },
                                                        [
                                                          _vm._v(" 初始值: "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                keyItem.quantificationStart
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v("目标值: "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                keyItem.quantificationEnd
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v("单位: "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                keyItem.quantificationUnit
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass: "infor",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "量化条件： 完成情况"
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          keyItem.checked &&
                                          okrItem.checked !== true
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "key-list-right",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "push-from push",
                                                    },
                                                    [
                                                      _c(
                                                        "el-input",
                                                        {
                                                          staticClass:
                                                            "input-focus",
                                                          attrs: {
                                                            placeholder: "0",
                                                            min: 0,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.getSelectList,
                                                          },
                                                          model: {
                                                            value:
                                                              keyItem.pushFrom,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                keyItem,
                                                                "pushFrom",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "keyItem.pushFrom",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  keyItem.quantificationUnit
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "push-to push",
                                                    },
                                                    [
                                                      _c(
                                                        "el-input",
                                                        {
                                                          staticClass:
                                                            "input-focus",
                                                          attrs: {
                                                            placeholder: "0",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.getSelectList,
                                                          },
                                                          model: {
                                                            value:
                                                              keyItem.pushTo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                keyItem,
                                                                "pushTo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "keyItem.pushTo",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.item
                                                                    .quantificationUnit
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "push-rate push",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          keyItem.pushRate
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              2
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.okrList.length === 0 && !_vm.loading
        ? _c(
            "div",
            { staticClass: "set-content" },
            [_c("noData", { attrs: { type: 2 } })],
            1
          )
        : _vm._e(),
      _vm.okrList.length > 0
        ? _c(
            "div",
            {
              staticClass: "bottom-step-buttons",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "percentage-text" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$utils.floatToFixedDown(_vm.selectPercentage)) +
                      "%"
                  ),
                ]),
                _vm._v("的进度由 "),
                _c("span", [_vm._v(_vm._s(_vm.selectKeyLen))]),
                _vm._v(" 个关键结果 "),
                _c("span", [_vm._v(_vm._s(_vm.selectOkrLen))]),
                _vm._v(" 个目标的完成情况自动更新 "),
              ]),
              _c(
                "div",
                { staticClass: "btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "left-button",
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.submit("提交")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }