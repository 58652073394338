var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "task-label",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.type == "default"
        ? [
            _vm.source == "stopdoing"
              ? _c(
                  "div",
                  {
                    class:
                      _vm.source == "stopdoing"
                        ? "label-item-stopdoing-div"
                        : "",
                  },
                  _vm._l(_vm.selectOptions, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "label-item",
                        style:
                          "color:" +
                          item.colour +
                          ";border:1px solid " +
                          item.colour,
                      },
                      [
                        _vm._v(" " + _vm._s(item.name) + " "),
                        !_vm.disabled
                          ? _c("i", {
                              staticClass: "el-icon-close del-btn",
                              style: _vm.overState
                                ? "color:" + item.colour + ";"
                                : "",
                              on: {
                                mouseenter: function ($event) {
                                  _vm.overState = true
                                },
                                mouseleave: function ($event) {
                                  _vm.overState = false
                                },
                                click: function ($event) {
                                  return _vm.delLabel(index, item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _vm._l(_vm.selectOptions, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "label-item",
                      style:
                        "color:" +
                        item.colour +
                        ";border:1px solid " +
                        item.colour,
                    },
                    [
                      _vm._v(" " + _vm._s(item.name) + " "),
                      !_vm.disabled
                        ? _c("i", {
                            staticClass: "el-icon-close del-btn",
                            style: _vm.overState
                              ? "color:" + item.colour + ";"
                              : "",
                            on: {
                              mouseenter: function ($event) {
                                _vm.overState = true
                              },
                              mouseleave: function ($event) {
                                _vm.overState = false
                              },
                              click: function ($event) {
                                return _vm.delLabel(index, item)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }),
          ]
        : _vm._e(),
      _c(
        "el-popover",
        {
          ref: "taskLabelRef",
          attrs: {
            teleported: false,
            placement: "bottom-start",
            trigger: "click",
            disabled: _vm.disabled,
            content: _vm.disabled
              ? "没有权限编辑"
              : "点击编辑" + _vm.fieldItem.name,
            "popper-class": "task-label-pop",
          },
          on: {
            show: function ($event) {
              _vm.type == "default" || _vm.type == "custom"
                ? _vm.popoverShow()
                : ""
            },
            hide: function ($event) {
              _vm.type == "default" || _vm.type == "custom" ? _vm.hide() : ""
            },
          },
        },
        [
          _vm.allLabels.length > 0
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "label-groups" },
                  _vm._l(_vm.allLabels, function (group, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "group-item" },
                      [
                        _c("div", { staticClass: "group-name" }, [
                          _vm._v(
                            _vm._s(
                              group.type === 0
                                ? "企业标签 *" + group.name
                                : "项目标签"
                            )
                          ),
                        ]),
                        _vm._l(group.labelList, function (item, lIndex) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "label-select",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.changeSelect(index, lIndex)
                                },
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "label-dot",
                                style: "background: " + item.colour,
                              }),
                              _c("div", { staticClass: "label-text" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              item.checked
                                ? _c("i", {
                                    staticClass: "el-icon-check checked-icon",
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    staticClass: "confirm-btn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.confirmSelect.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
              ])
            : _c("div", { staticClass: "no-data" }, [
                _c("img", {
                  staticClass: "kr-count-empty-img",
                  attrs: {
                    src: require("@/assets/imgs/common/commentNull.png"),
                  },
                }),
                _c("p", { staticClass: "kr-count-p" }, [_vm._v("暂无内容")]),
              ]),
          _vm.type == "default"
            ? _c(
                "span",
                {
                  staticClass: "reference-div",
                  class:
                    _vm.source == "stopdoing" && _vm.selectOptions.length > 0
                      ? "reference-stopdoing-div"
                      : "",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("Icon", {
                    class: _vm.disabled ? "add-btn-disabled" : "add-btn",
                    attrs: { "icon-class": "icon-tianjia-xuxian" },
                  }),
                  _vm.source == "stopdoing"
                    ? _c("span", { staticClass: "tag-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectOptions.length > 0 ? "" : "添加标签"
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._t("labelcontent", null, { slot: "reference" }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }