var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "file-preview",
          attrs: {
            title: _vm.fileObj.fileName,
            visible: _vm.isShow,
            "append-to-body": true,
            "modal-append-to-body": true,
            "destroy-on-close": true,
            "close-on-click-modal": true,
            "show-close": false,
            "before-close": _vm.closePreview,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "preview-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", { staticClass: "title-left" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fileObj.fileName,
                        expression: "fileObj.fileName",
                      },
                    ],
                    staticClass: "title",
                  },
                  [_vm._v(_vm._s(_vm.fileObj.fileName))]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fileObj.createName && _vm.fileObj.fileTime,
                        expression: "fileObj.createName && fileObj.fileTime",
                      },
                    ],
                    staticClass: "time",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.fileObj.createName) +
                        " 上传于 " +
                        _vm._s(_vm.fileObj.fileTime)
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "title-right" },
                [
                  _vm.isDownload
                    ? _c(
                        "el-button",
                        {
                          staticClass: "download-button",
                          attrs: { type: "primary" },
                          on: { click: _vm.fileDownload },
                        },
                        [
                          _c("Icon", {
                            staticClass: "file-svg",
                            attrs: { "icon-class": "icon-xiazai1" },
                          }),
                          _vm._v("下载 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.fileObj.fileUrl,
                          expression: "fileObj.fileUrl",
                          arg: "copy",
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.copyUrl,
                          expression: "copyUrl",
                          arg: "success",
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:error",
                          value: _vm.onError,
                          expression: "onError",
                          arg: "error",
                        },
                      ],
                      staticClass: "copy-button",
                      attrs: { type: "primary" },
                    },
                    [
                      _c("Icon", {
                        staticClass: "file-svg",
                        attrs: { "icon-class": "icon-fuzhilianjie1" },
                      }),
                      _vm._v("复制链接 "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "title-icon-div",
                      on: { click: _vm.closePreview },
                    },
                    [
                      _c("Icon", {
                        staticClass: "title-icon",
                        attrs: { "icon-class": "icon-guanbi1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "file-main" },
            [
              _vm.isShowView &&
              (_vm.fileObj.fileType === "png" ||
                _vm.fileObj.fileType == "bmp" ||
                _vm.fileObj.fileType === "jpg" ||
                _vm.fileObj.fileType === "jpeg" ||
                _vm.fileObj.fileType === "gif" ||
                _vm.fileObj.fileType === "svg" ||
                _vm.fileObj.fileType === "PNG" ||
                _vm.fileObj.fileType === "JPG" ||
                _vm.fileObj.fileType === "JPEG" ||
                _vm.fileObj.fileType === "GIT" ||
                _vm.fileObj.fileType === "SVG") &&
              _vm.isPreview
                ? [
                    _c(
                      "viewer",
                      {
                        ref: "viewer",
                        attrs: { options: _vm.options },
                        on: { inited: _vm.inited },
                      },
                      [_c("img", { attrs: { src: _vm.fileObj.fileUrl } })]
                    ),
                  ]
                : [
                    _c("iframe", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPreview && _vm.filePreviewMessage,
                          expression: "isPreview && filePreviewMessage",
                        },
                      ],
                      staticStyle: { border: "none !important" },
                      attrs: {
                        src: _vm.filePreviewUrl,
                        width: "100%",
                        height: "100%",
                        border: "0",
                        id: "iframe",
                      },
                    }),
                    !_vm.isPreview
                      ? _c(
                          "div",
                          { staticClass: "no-preview" },
                          [
                            _c(
                              "div",
                              { staticClass: "svg-div" },
                              [
                                _c("Icon", {
                                  staticClass: "file-svg",
                                  attrs: { "icon-class": "icon-fileCommon" },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.fileObj.fileName)),
                            ]),
                            !_vm.disabled
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "upload-button",
                                    attrs: { type: "primary" },
                                    on: { click: _vm.fileDownload },
                                  },
                                  [
                                    _vm._v(" 下载文件 "),
                                    _vm.fileObj.fileSize
                                      ? [
                                          _vm._v(
                                            "（ " +
                                              _vm._s(
                                                _vm.$utils.formatFileSize(
                                                  _vm.fileObj.fileSize
                                                )
                                              ) +
                                              " ）"
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.filePreviewPassword
                      ? _c("div", { staticClass: "preview-password-wrap" }, [
                          _vm._v(
                            "很抱歉，由于此文档使用不受支持的功能，所以无法在浏览器中打开;"
                          ),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }