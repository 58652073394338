<template>
  <div id="app" :class="`ymb-container ymb-container-${IsPC}`" data-app v-if="pageState">
    <router-view v-if="isRouterAlive" />
    <!-- 接口全局异常 -->
    <api-request-handle />
    <div v-if="accountPassword == true" class="account-password">
      <p>要保存账号密码吗？</p>
      <div class="account-password-wrap">
        <div class="account">
          <span>用户名:</span>
          <input v-model="telephone" class="account" type="text" readonly />
        </div>
        <div class="password">
          <span>密码:</span>
          <input v-model="password" class="password" type="password" readonly />
        </div>
      </div>
      <div class="footer-btns">
        <span class="account-save" @click="saveAccountPass">保存</span>
        <span class="account-cancel" @click="allNot">取消</span>
      </div>
    </div>
    <!-- 禁止操作 -->
    <div class="global-bg-style" v-show="$store.state.common.scrollBgVisible"></div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apiRequestHandle from '@/views/global/ApiRequestHandle' // 全局异常
import ddJs from '@/utils/mixins/ddJs'
import wxJs from '@/utils/mixins/wxJs'
export default {
  mixins: [ddJs,wxJs],
  provide() {
    // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  computed: {
        ...mapState('user', {
            routerChange: 'routerChange'
        })
    },
  data() {
    return {
      accountPassword: false, // 记住密码
      accountPasswords_: {},
      isRouterAlive: true, // 控制视图是否显示的变量
      pageState: false,
      isShowPreview: false, // 是否显示预览
      isPreview: true,
      fileObj: {
        fileName: '',
        fileUrl: '',
        fileType: '',
        fileTime: '',
        fileSize: '',
        createName: '',
        previewUrl: ''
      },
      isDownload: true // 是否可下载
    }
  },
  created() {
    this.pageLoadingState()
    var that = this
    window.addEventListener('beforeunload', that.destroyHandle)
    // 点击空白处关闭记住密码
    document.documentElement.addEventListener(
      'click',
      () => {
        that.accountPassword = false
      },
      false
    ),
      // 监听任务详情刷新事件
      that.$on('global:accountPassword', (obj) => {
        that.accountPasswords(obj)
      })
  },
  mounted() {
    const getChromeType = this.$utils.getChromeType()
    if ((getChromeType === 'pc') && (this.token || '') !== '' && window.location.href.indexOf('/login') === -1 && window.location.href.indexOf('/login?corpId=') === -1 && this.commonFromType === 'dingding' && (this.$utils.getQueryString('busicId') || '') === '') {
      this.getJsapiTicketAgent()
    }
    // 数据埋点 初始化
    this.$mixpanelUtil.init()
    // this.pageLoadingState()
    this.$store.commit('common/setScrollBgVisible', false)
    this.$store.commit('common/setVideoPopoverVisible', false)
    this.$store.commit('okr/approveRemindObj', {
      visible: false
    })
    this.$store.commit('task/taskApproveRemindObj', {
      visible: false
    })
  },
  components: {
    apiRequestHandle
  },
  watch: {
    $route(newRouter, oldRouter) {
      if (window.location.href.indexOf('type=signtrue') !== -1) {
        this.getJsapiTicketAgent()
      }
    }
    // routerChange: function (newVal, oldVal) {
    //   if (newVal) {
    //     this.getJsapiTicketAgent() // 微信签名
    //   }
    // }
  },
  created() {
    if ((this.$utils.getQueryStringHash('token') || '') !== '') {
      this.$store.commit('user/setToken', this.$utils.getQueryStringHash('token'))
      this.$store.commit('user/setUserInfo', JSON.parse(decodeURIComponent(this.$utils.getQueryStringHash('userInfo'))))
      this.$store.commit('user/setCompanyInfo', JSON.parse(decodeURIComponent(this.$utils.getQueryStringHash('companyInfo'))))
      this.$store.commit('common/apiRequestData', {
        visible: false,
        type: 0,
        companyId: this.userInfo.companyId,
        companyName: this.userInfo.companyName
      })
    }
    window.addEventListener("beforeunload", this.destroyHandle)
    // 数据埋点 初始化
    this.$mixpanelUtil.init()
    const isQyweixin = this.$utils.isQyweixin()
    this.$store.commit("user/setRedirectUrlState", false)
    // if ((isQyweixin !== 'app') && (this.token || '') !== '' && window.location.href.indexOf('.com/login') === -1 && window.location.href.indexOf('/?auth_code=') === -1) {
    //     this.getJsapiTicketAgent()
    // }
    if (((isQyweixin !== 'app') && (this.token || '') !== '' && window.location.href.indexOf('#/login') === -1)) {
      this.getJsapiTicketAgent()
    }
    this.pageLoadingState()
    this.$store.commit('common/setScrollBgVisible', false)
    this.$store.commit('common/setVideoPopoverVisible', false)
  },
  methods: {
    // 判断手机端还是pc端
    IsPC() {
      var userAgentInfo = navigator.userAgent
      var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod']
      var flag = true
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      if (window.screen.width >= 768) {
        flag = true
      }
      return flag
    },
    accountPasswords(obj) {
      this.accountPasswords_ = obj
      this.telephone = obj.telephone || ''
      this.password = obj.password || ''
      const boolsArr = obj.lists.filter((item) => {
        return item.telephone.indexOf(this.telephone) >= 0
      })
      if (boolsArr.length == 0 && isAllowSavePassword == true) {
        this.accountPassword = true
      }
    },
    saveAccountPass() {
      saveLoginInfo(
        this.accountPasswords_.telephone,
        this.accountPasswords_.password
      )
    },
    allNot() {
      this.accountPassword = false
    },
    destroyHandle() {
      this.pageState = false
      var dom = document.getElementById('global-page-loading')
      dom.style.display = 'block'
    },
    pageLoadingState() {
      var dom = document.getElementById('global-page-loading')
      dom.style.display = 'none'
      this.pageState = true
    },
    reload() {
      this.isRouterAlive = false // 先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true // 再打开
      })
    }
  }
}
</script>

<style lang="scss">
.tox-tinymce-aux {
  z-index: 3000 !important;
  .tox-dialog-wrap__backdrop {
    opacity: 0.5 !important;
    background: #000 !important;
  }
  .tox-dialog {
    border-radius: 16px !important;
    border: none !important;
    .tox-textarea {
      border-color: #e7e8ef;
      border-radius: 8px;
    }
    .tox-dialog__footer {
      border-top: 1px solid #e7e8ef;
      .tox-dialog__footer-end {
        .tox-button:last-child {
          background-color: #3a78f6 !important;
          border-color: #3a78f6 !important;
        }
      }
    }
  }
}
.global-bg-style {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
* {
  scrollbar-width: thin; //火狐浏览器
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    // background-color: #eef1f6;
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0);
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #d0cfd4;
    }
  }
  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
  //   border-radius: 5px;
  //   background-color: #f5f5f5;
  // }
}
#btn-tooltip-click {
  pointer-events: all;
}
#nprogress .bar {
  background: #3076fc !important; //自定义进度条颜色
}
#nprogress .spinner {
  display: none !important; // 隐藏右上角滚动条
}
body {
  padding-right: 0 !important;
}
.el-drawer.rtl {
  right: 0 !important;
}
</style>
<style lang="scss">
.account-password {
  position: fixed;
  right: 10px;
  top: 20px;
  padding: 20px;
  border: 1px solid #dedede;
  border-radius: 10px;
  z-index: 99999;
  background: #fff;
  p {
    font-size: 14px;
    color: #999;
    line-height: 20px;
  }
  input.account {
    border: 1px solid #dedede;
    line-height: 26px;
    padding-left: 4px;
  }
  input.password {
    border: 1px solid #dedede;
    line-height: 26px;
    padding-left: 4px;
  }
  .account-password-wrap {
    margin-top: 4px;
    div.account {
      margin-bottom: 6px;
      span {
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
    div.password {
      margin-bottom: 6px;
      span {
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }
  div.footer-btns {
    .account-save {
      margin-top: 8px;
      padding: 8px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      background: #3068ff;
      display: inline-block;
      border-radius: 6px;
    }
    .account-cancel {
      margin-top: 8px;
      margin-left: 8px;
      padding: 8px;
      font-size: 14px;
      color: #3068ff;
      cursor: pointer;
      display: inline-block;
      border-radius: 4px;
    }
    .account-cancel:hover {
      color: #fff;
      background: #3068ff;
    }
  }
}
</style>
