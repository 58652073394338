var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { "popper-class": "view-target-pop" },
      on: { show: _vm.showViewRecord },
    },
    [
      _c(
        "div",
        { attrs: { slot: "reference" }, slot: "reference" },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                placement: "top",
                "open-delay": 300,
                content:
                  _vm.objInfo.createUserid === _vm.userInfo.id
                    ? "谁看过我"
                    : "谁看过TA",
              },
            },
            [
              _c(
                "div",
                { staticClass: "kr-item" },
                [
                  _c("Icon", {
                    staticClass: "kr-right-icon",
                    attrs: { "icon-class": "icon-check-record" },
                  }),
                  _vm.localRecord &&
                  _vm.objInfo.createUserid !== _vm.userInfo.id
                    ? _c("img", {
                        staticClass: "kr-right-avatar border-radius-20",
                        attrs: {
                          id: _vm.objInfo.id + "animateAvatar",
                          src: _vm.userInfo.avatar,
                        },
                      })
                    : _vm.objInfo.objectivesLookRecordingDto
                    ? _c("img", {
                        staticClass: "kr-right-avatar border-radius-20",
                        attrs: {
                          src: _vm.objInfo.objectivesLookRecordingDto.userDto
                            .avatar,
                        },
                      })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(
                        Number(_vm.lookCount || 0) > 99
                          ? "99+"
                          : (JSON.stringify(_vm.localRecord) !== "{}" &&
                            _vm.objInfo.createUserid !== _vm.userInfo.id &&
                            !_vm.hasDayRecord
                              ? Number(_vm.lookCount || 0) + 1
                              : _vm.lookCount) || 0
                      ) +
                      " "
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.viewRecordList.length > 0
        ? _c(
            "div",
            { staticClass: "view-record-list" },
            [
              _c("div", { staticClass: "view-record-num" }, [
                _vm._v(
                  "近7天有 " + _vm._s(_vm.lastDayLookNum) + "人 查看过目标"
                ),
              ]),
              _c(
                "transition-group",
                {
                  directives: [
                    {
                      name: "scroll-bottom",
                      rawName: "v-scroll-bottom",
                      value: _vm.loadMore,
                      expression: "loadMore",
                    },
                  ],
                  ref: "task",
                  staticClass: "draggable-box view-record-lists",
                  attrs: {
                    id: "transitionGroup",
                    tag: "div",
                    "data-scroll-type": "task",
                  },
                },
                _vm._l(_vm.viewRecordList, function (record) {
                  return _c(
                    "div",
                    {
                      key: record.lastLookTime,
                      staticClass: "view-record-item",
                    },
                    [
                      _c("div", { staticClass: "view-record-time" }, [
                        _vm._v(_vm._s(record.lastLookTime)),
                      ]),
                      _vm._l(
                        record.objectivesLookRecordingDtoList,
                        function (person) {
                          return _c(
                            "div",
                            { key: person.id, staticClass: "view-person" },
                            [
                              person.userDto
                                ? _c("div", { staticClass: "person-info" }, [
                                    _c("img", {
                                      staticClass: "border-radius-20",
                                      attrs: {
                                        src:
                                          person.userDto.avatar ||
                                          require("@/assets/imgs/common/avatar.png"),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "person-name" },
                                      [
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: person.userDto.name,
                                            nameData: {
                                              name: person.userDto.name,
                                            },
                                          },
                                        }),
                                        _vm._v("  "),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "person-time" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$utils.formatRecentTime(
                                      person.lastLookTime || "",
                                      "detail"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  )
                }),
                0
              ),
            ],
            1
          )
        : _c("div", { staticClass: "no-data-show" }, [
            _c("div", { staticClass: "global-content-null-wrap" }, [
              _c("img", {
                staticClass: "img",
                attrs: { src: require("@/assets/imgs/common/commentNull.png") },
              }),
            ]),
            _c("div", { staticClass: "no-data-show-text" }, [
              _vm._v("暂无数据"),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }