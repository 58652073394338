var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "comm-check-form process-dialog-wrap",
      attrs: {
        visible: _vm.dialogVisible,
        width: "380px",
        modal: false,
        "append-to-body": true,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose,
        "close-on-click-modal": _vm.type == "upload" ? false : true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.type == "upload"
        ? _c(
            "div",
            { staticClass: "upload-content" },
            [
              _c("el-progress", {
                attrs: {
                  "text-inside": true,
                  "stroke-width": 16,
                  percentage: _vm.rate,
                },
              }),
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.dialogTxt)),
              ]),
            ],
            1
          )
        : _vm.type === "applyDemo"
        ? _c("div", { staticClass: "dialog-content" }, [
            _c("img", {
              attrs: { src: require("@/assets/imgs/okr/finishOperate.png") },
            }),
            _c("div", {
              staticClass: "dialog-text",
              domProps: { innerHTML: _vm._s(_vm.dialogTxt) },
            }),
            _c(
              "div",
              {
                staticClass: "bottom-btn",
                on: {
                  click: function ($event) {
                    return _vm.handleClose(true)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.btn))]
            ),
          ])
        : _vm.type !== "1"
        ? _c("div", { staticClass: "dialog-content" }, [
            _c("img", {
              attrs: { src: require("@/assets/imgs/okr/finishOperate.png") },
            }),
            _c("div", [_vm._v(_vm._s(_vm.dialogTxt) + "完成")]),
            _c(
              "div",
              { staticClass: "bottom-btn", on: { click: _vm.toFinishPage } },
              [_vm._v(_vm._s(_vm.btn))]
            ),
          ])
        : _c("div", { staticClass: "dialog-content" }, [
            _c("img", {
              attrs: { src: require("@/assets/imgs/okr/finishOperate.png") },
            }),
            _c("div", [_vm._v("项目复制成功")]),
            _c(
              "div",
              { staticClass: "step-close", on: { click: _vm.handleClose } },
              [
                _c("Icon", {
                  staticClass: "step-close-img",
                  attrs: { "icon-class": "icon-close" },
                  on: { click: _vm.addStepClose },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }