var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      ref: "memberCardPopRef",
      attrs: {
        placement: "right",
        trigger: "click",
        "popper-class": "member-card-popover",
        "visible-arrow": false,
        disabled: _vm.disabled,
      },
      on: { show: _vm.getUserInfo },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "member-card-pop" },
        [
          _c(
            "div",
            { staticClass: "member-avatar" },
            [
              _c("el-image", {
                attrs: {
                  fit: "cover",
                  src:
                    _vm.userInfoList.avatar ||
                    require("@/assets/imgs/common/avatar.png"),
                },
              }),
              _c("div", { staticClass: "avatar-info-box" }, [
                _c("div", { staticClass: "avatar-info" }, [
                  _c(
                    "span",
                    { staticClass: "avatar-name" },
                    [
                      _c("wx-open-data", {
                        attrs: {
                          id: _vm.userInfoList.username,
                          nameData: { name: _vm.userInfoList.name },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "avatar-job" }, [
                    _vm._v(_vm._s(_vm.userInfoList.jobTitle)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "member-info" }, [
            _vm.clickState === true
              ? _c(
                  "div",
                  { staticClass: "member-btns" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          placement: "top",
                          "open-delay": 300,
                          content: "分配任务",
                        },
                      },
                      [
                        _c("Icon", {
                          staticClass: "btn-icon",
                          attrs: { "icon-class": "icon-task_toadd" },
                          on: { click: _vm.addMemberTask },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "member-single-info" }, [
              _c("div", { staticClass: "info-title" }, [_vm._v("部门")]),
              _c(
                "div",
                { staticClass: "info-label" },
                [
                  _vm.userInfoList.departName || _vm.userInfoList.departUserList
                    ? _c("DepartUserList", {
                        attrs: {
                          departName: _vm.userInfoList.departName,
                          departUserList: _vm.userInfoList.departUserList,
                        },
                      })
                    : _c("span", [_vm._v("- -")]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "member-single-info" }, [
              _c("div", { staticClass: "info-title" }, [_vm._v("邮箱")]),
              _c("div", { staticClass: "info-label" }, [
                _vm._v(_vm._s(_vm.userInfoList.email || "未绑定")),
              ]),
            ]),
            _vm.isOkr && _vm.clickState === true
              ? _c(
                  "div",
                  { staticClass: "member-btn", on: { click: _vm.toOKRList } },
                  [_vm._v("查看周期内OKR")]
                )
              : _vm._e(),
          ]),
          _vm.addVisible
            ? _c("task-add", {
                attrs: {
                  keyId: _vm.addId,
                  status: _vm.status,
                  propsAddObj: _vm.propsAddObj,
                },
                model: {
                  value: _vm.addVisible,
                  callback: function ($$v) {
                    _vm.addVisible = $$v
                  },
                  expression: "addVisible",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._t("default", null, { slot: "reference" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }