var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          ref: "popover",
          attrs: {
            "popper-class": "progress-popover",
            trigger: "hover",
            "visible-arrow": false,
            width: 480,
            disabled: _vm.disabled,
            "open-delay": 300,
          },
          on: { show: _vm.getData },
        },
        [
          _c(
            "div",
            {
              staticClass: "progress-wrap",
              class: "progress-wrap-" + _vm.getDataState,
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "progress-title" }, [
                _c("div", { staticClass: "title-left" }, [_vm._v("智能推进")]),
                _c(
                  "div",
                  { staticClass: "title-right" },
                  [
                    _vm.type === 0 &&
                    _vm.userInfo.id === _vm.okrData.createUserid
                      ? _c("Icon", {
                          staticClass: "icon-edit",
                          attrs: { "icon-class": "icon-edit-simple" },
                          on: {
                            click: function ($event) {
                              return _vm.progressSetClick()
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm.data.advanceTo.length > 0
                ? _c("div", { staticClass: "progress-list-wrap" }, [
                    _c("div", { staticClass: "progress-type" }, [
                      _c(
                        "div",
                        { staticClass: "type-icon" },
                        [
                          _c("Icon", {
                            staticClass: "icon",
                            attrs: { "icon-class": "icon-xiangshangtuidong" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "name" },
                        [
                          _c("wx-open-data", {
                            attrs: {
                              id: _vm.okrData.userName,
                              nameData: { name: _vm.okrData.userName },
                            },
                          }),
                          _vm._v(
                            " 推进其他同事*" +
                              _vm._s(_vm.data.advanceTo.length) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "progress-list-content" }, [
                      _c("div", { staticClass: "progress-list" }, [
                        _c("div", {
                          staticClass: "infor-list",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.listData(4, _vm.data.advanceTo)
                            ),
                          },
                        }),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.data.advanceFrom.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "progress-list-wrap progress-list-span-none",
                    },
                    [
                      _c("div", { staticClass: "progress-type" }, [
                        _c(
                          "span",
                          { staticClass: "type-icon" },
                          [
                            _c("Icon", {
                              attrs: { "icon-class": "icon-xiangxiatuidong" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "name" },
                          [
                            _vm._v(" 同事推进 "),
                            _c("wx-open-data", {
                              attrs: {
                                id: _vm.okrData.userName,
                                nameData: { name: _vm.okrData.userName },
                              },
                            }),
                            _vm._v(
                              " * " + _vm._s(_vm.data.advanceFrom.length) + " "
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "progress-list-content" }, [
                        _c("div", { staticClass: "progress-list" }, [
                          _vm.data.advanceFrom
                            ? _c("div", {
                                staticClass: "infor-list",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.listData(1, _vm.data.advanceFrom)
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.data.advanceFromTask.length > 0 ||
              (_vm.data.taskMaxValue || 0) > 0
                ? _c(
                    "div",
                    {
                      staticClass: "progress-list-wrap progress-list-span-none",
                    },
                    [
                      _c("div", { staticClass: "progress-type" }, [
                        _c("span", { staticClass: "line" }),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "由任务推进 * " +
                              _vm._s(_vm.data.advanceFromTask.length)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "progress-list-content" }, [
                        _c("div", { staticClass: "progress-list" }, [
                          _vm.data.advanceFromTask.length > 0
                            ? _c("div", {
                                staticClass: "infor-list",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.listData(2, _vm.data.advanceFromTask)
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm.data.advanceFromTask.length === 0 &&
                          (_vm.data.taskMaxValue || 0) > 0
                            ? _c("div", {
                                staticClass: "infor-list",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.listData(3, _vm.data.advanceFromTask)
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.loading && _vm.dataNullState
                ? _c("noData")
                : _c(
                    "div",
                    {
                      staticClass: "detail-btn",
                      on: { click: _vm.autoDetail },
                    },
                    [_vm._v("查看详情")]
                  ),
            ],
            1
          ),
          _vm._t("default", null, { slot: "reference" }),
        ],
        2
      ),
      _vm.autoProgressDetailVisible
        ? _c("auto-progress-detail", {
            attrs: {
              okrData: _vm.okrData,
              item: _vm.item,
              type: _vm.type,
              data: _vm.data,
            },
            on: { edit: _vm.popoverVisible, refresh: _vm.getData },
            model: {
              value: _vm.autoProgressDetailVisible,
              callback: function ($$v) {
                _vm.autoProgressDetailVisible = $$v
              },
              expression: "autoProgressDetailVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }