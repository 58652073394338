var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _vm.type === "add"
            ? [
                _c(
                  "span",
                  [
                    _c(
                      "span",
                      {
                        staticClass: "time-text",
                        class:
                          _vm.startHover && _vm.form.startDate
                            ? "time-text-hover"
                            : "",
                        on: { click: _vm.startPicker },
                      },
                      [
                        _vm.form.startDate
                          ? _c("span", { staticClass: "time-text-yes" }, [
                              _vm._v(_vm._s(_vm.form.startDate)),
                            ])
                          : _c("span", { staticClass: "time-text-no" }, [
                              _vm._v("开始时间"),
                            ]),
                        _vm.form.startDate
                          ? _c("Icon", {
                              staticClass: "close",
                              attrs: { "icon-class": "icon-a-Group719-1" },
                              nativeOn: {
                                mouseenter: function ($event) {
                                  _vm.startHover = true
                                },
                                mouseleave: function ($event) {
                                  _vm.startHover = false
                                },
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.timeClear("start")
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    [_vm._v("-")],
                    _c(
                      "span",
                      {
                        staticClass: "time-text",
                        class:
                          _vm.endHover && _vm.form.endDate
                            ? "time-text-hover"
                            : "",
                        on: { click: _vm.endPicker },
                      },
                      [
                        _vm.form.endDate
                          ? _c("span", { staticClass: "time-text-yes" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.classifytype == "todo"
                                      ? _vm.form.endDate + " 截止"
                                      : _vm.form.endDate
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", { staticClass: "time-text-no" }, [
                              _vm._v("截止时间"),
                            ]),
                        _vm.form.endDate
                          ? _c("Icon", {
                              staticClass: "close",
                              attrs: { "icon-class": "icon-a-Group719-1" },
                              nativeOn: {
                                mouseenter: function ($event) {
                                  _vm.endHover = true
                                },
                                mouseleave: function ($event) {
                                  _vm.endHover = false
                                },
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.timeClear("end")
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("el-date-picker", {
                  ref: "startDateTask",
                  staticClass: "datetime hide-date-border",
                  attrs: {
                    format: "yyyy-MM-dd HH:mm",
                    "value-format": "yyyy-MM-dd HH:mm",
                    "default-time": _vm.defaultStart,
                    type: "datetime",
                    placeholder: "开始日期",
                    "picker-options": _vm.pickerOptionsStart,
                    "popper-class": "addTimeClass",
                  },
                  on: { change: _vm.startDateChange },
                  model: {
                    value: _vm.form.startDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "startDate", $$v)
                    },
                    expression: "form.startDate",
                  },
                }),
                _c(
                  "span",
                  { staticClass: "m-l-r-10", staticStyle: { display: "none" } },
                  [_vm._v("—")]
                ),
                _c("el-date-picker", {
                  ref: "endDateTask",
                  staticClass: "hide-date-border end-date",
                  attrs: {
                    format: "yyyy-MM-dd HH:mm",
                    "value-format": "yyyy-MM-dd HH:mm",
                    "default-time": _vm.defaultEnd,
                    type: "datetime",
                    placeholder: "截止日期",
                    "picker-options": _vm.pickerOptionsEnd,
                    "popper-class": "addTimeClass",
                  },
                  on: { change: _vm.endDateChange },
                  model: {
                    value: _vm.form.endDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "endDate", $$v)
                    },
                    expression: "form.endDate",
                  },
                }),
              ]
            : [
                _c("date-time-picker", {
                  attrs: { obj: _vm.form, disabled: _vm.disabled },
                  on: { change: _vm.dateTimeChange },
                }),
              ],
          _c("span", { staticClass: "line" }),
          !_vm.row.fatherTid
            ? _c(
                "el-tooltip",
                { attrs: { "open-delay": 300, content: _vm.cycleDateFmt } },
                [
                  _c("task-cycle", {
                    attrs: {
                      id: _vm.form.cycleDateId,
                      "task-id": _vm.form.id,
                      disabled: _vm.disabled,
                    },
                    on: { save: _vm.cycleSave },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("span", { staticClass: "line" }),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: _vm.remindDateFmt,
                "open-delay": 300,
                placement: "top",
                effect: "light",
              },
            },
            [
              _vm.type_remind === 0 || !_vm.form.remindDateId
                ? _c("Icon", {
                    staticClass: "time-icon",
                    class: {
                      "c-azure-radiance": _vm.form.remindDateId,
                      disabled: _vm.disabled,
                    },
                    attrs: { "icon-class": "icon-remind" },
                    on: { click: _vm.remindTimeClick },
                  })
                : _c("Icon", {
                    staticClass: "time-icon active-icon",
                    class: {
                      "c-azure-radiance": _vm.form.remindDateId,
                      disabled: _vm.disabled,
                    },
                    attrs: { "icon-class": "icon-remind" },
                    on: { click: _vm.remindTimeClick },
                  }),
            ],
            1
          ),
        ],
        2
      ),
      _vm.remindTimeVisible
        ? _c("task-remind-time", {
            attrs: {
              id: _vm.form.remindDateId,
              visible: _vm.remindTimeVisible,
              "task-id": _vm.form.id,
              type: _vm.type,
            },
            on: {
              "update:visible": function ($event) {
                _vm.remindTimeVisible = $event
              },
              save: _vm.remindTimeSave,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }