<template>
  <el-dialog
    title="自定义重复"
    width="500px"
    :before-close="handleClose"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="true"
    :destroy-on-close="true"
    class="single-dialog"
    @close="closeDialog"
  >
    <div>
      <!-- 重复类型 -->
      <div class="m-b-20">
        <span>重复：</span>
        <el-select
          v-model="form.customType"
          placeholder="请选择"
          class="m-r-20"
          @change="customTypeChange"
        >
          <el-option
            v-for="(val, key) in customTypeDict"
            :key="key"
            :label="val"
            :value="parseInt(key)"
          />
        </el-select>
      </div>
      <!-- 每天重复 -->
      <template v-if="isDaily">
        <div>
          <span>间隔：</span>
          <el-select v-model="form.customInterval" placeholder="请选择" class="m-r-20">
            <el-option v-for="item in 7" :key="item" :label="item" :value="item" />
          </el-select>
          <span>天</span>
        </div>
        <p class="f-z-16 m-t-20">每{{ dailyText }}重复</p>
      </template>
      <!-- 每周重复 -->
      <template v-if="isWeekly">
        <div>
          <span>间隔：</span>
          <el-select v-model="form.customInterval" placeholder="请选择" class="m-r-20">
            <el-option v-for="item in 7" :key="item" :label="item" :value="item" />
          </el-select>
          <span>周</span>
        </div>
        <!-- 每周重复显示周的选择 -->
        <el-checkbox-group
          v-model="form.customCycleNum"
          class="m-t-20"
          @change="customCycleNumChange"
        >
          <el-checkbox-button v-for="item in 7" :key="item" :label="item">
            {{
            noRequest ? scheWeekToCn(item) : weekToCn(item)
            }}
          </el-checkbox-button>
        </el-checkbox-group>
        <p class="f-z-16 m-t-20">
          <span class="p-r-5">
            每
            <span v-if="form.customInterval !== 1">
              {{
              form.customInterval
              }}
            </span>周
          </span>
          <template v-for="(item, index) in form.customCycleNum">
            <span :key="item">
              周{{ noRequest ? scheWeekToCn(item) : weekToCn(item) }}
              <!-- 最后一个元素不显示逗号 -->
              <template v-if="isLastIndex(index)">,</template>
            </span>
          </template>
          <span class="p-l-5">重复</span>
        </p>
      </template>
      <!-- 每月重复 -->
      <template v-if="isMonth">
        <div>
          <span>间隔：</span>
          <el-select v-model="form.customInterval" placeholder="请选择" class="m-r-20">
            <el-option v-for="item in 7" :key="item" :label="item" :value="item" />
          </el-select>
          <span>月</span>
        </div>
        <ul class="day-box">
          <li
            v-for="item in day"
            :key="item"
            :class="dayActiveClass(item)"
            class="item"
            @click="dayClick(item)"
          >{{ item }}</li>
        </ul>
        <p class="f-z-16 m-t-20">
          <span class="p-r-5">
            每
            <span v-if="form.customInterval !== 1">
              {{
              form.customInterval
              }}
            </span>月
          </span>
          <template v-for="(item, index) in form.customCycleNum">
            <span :key="item">
              {{ item }}
              <!-- 最后一个元素不显示逗号 -->
              <template v-if="isLastIndex(index)">,</template>
            </span>
          </template>
          <span class="p-l-5">重复</span>
        </p>
      </template>
    </div>
    <div slot="footer" class="flex-new justify-end footer">
      <el-button class="cancel-btns" @click="closeDialog">取 消</el-button>
      <button :loading="loading" class="common_btn_next common_btn_next_able" @click="save">确 定</button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import formMixin from '@/utils/mixins/form'
import repeatEnum from '@/utils/enums/repeat.js'
const customTypeEnum = repeatEnum.CYCLECUSTOMTYPE // 自定义重复类型
export default {
  mixins: [formMixin],
  props: {
    customCycleVisible: {
      type: Boolean,
      default: false
    },
    // 页面数据
    row: {
      type: Object,
      required: false
    },
    // 任务id
    taskId: {
      required: false
    },
    // 无需调接口---日程
    noRequest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false, // 弹框显隐
      customTypeDict: repeatEnum.CUSTOMTYPE, // 自定义重复类型
      form: {
        customType: customTypeEnum.weekly, // 重复类型(默认每周重复)
        customInterval: 1, // 间隔数(默认为1))
        customCycleNum: [] // 间隔天数后任务重复日期
      },
      day: 31 // 日期天数
      // taskAddObject: ''
    }
  },
  watch: {
    customCycleVisible(val) {
      this.dialogVisible = val
    }
  },
  created() {
    // 初始化
    // this.taskAddObject = this.$store.state.commom.taskAddObject
    this.init()
  },
  computed: {
    ...mapState('common', {
      taskAddObject: 'taskAddObject'
    }),
    // 是否每天重复
    isDaily() {
      return this.form.customType === customTypeEnum.daily
    },
    // 是否每周重复
    isWeekly() {
      return this.form.customType === customTypeEnum.weekly
    },
    // 是否每月重复
    isMonth() {
      return this.form.customType === customTypeEnum.month
    },
    // 每天重复的文字展示
    dailyText() {
      const customInterval = this.form.customInterval
      if (customInterval === 1 || !customInterval) {
        return '天'
      } else {
        return `${customInterval}天`
      }
    },
    // 每月重复的文字展示
    monthText() {
      const customInterval = this.form.customInterval
      if (customInterval === 1 || !customInterval) {
        return '月'
      } else {
        return `${customInterval}月`
      }
    }
  },
  methods: {
    // 初始化
    init() {
      if (this.row.id || this.noRequest) {
        this.form = this.$utils.clone(this.row)
        // 重复类型(默认每周重复)
        if (!this.form.customType) {
          this.form.customType = customTypeEnum.weekly
        }
        // 间隔数(默认为1))
        if (!this.form.customInterval) {
          this.form.customInterval = 1
        }
        // 间隔天数后任务重复日期(默认为空数组)
        let customCycleNum = this.form.customCycleNum
        if (customCycleNum) {
          customCycleNum = customCycleNum.split(',')
          this.form.customCycleNum = this.$utils.arrToInt(customCycleNum)
        } else {
          this.form.customCycleNum = []
        }
      }
      if (this.taskAddObject.default === '1') {
        this.form = this.taskAddObject.taskCustom
        let customCycleNum = this.taskAddObject.taskCustom.customCycleNum
        if (customCycleNum) {
          customCycleNum = customCycleNum.split(',')
          this.form.customCycleNum = this.$utils.arrToInt(customCycleNum)
        } else {
          this.form.customCycleNum = []
        }
      }
    },
    // 保存
    async save() {
      if (this.taskId) {
        this.form.taskId = this.taskId
      }
      if (this.isWeekly && this.form.customCycleNum.length === 0) {
        this.$message.warn('请选择每周重复的数据哦！')
        return
      }
      if (this.isMonth && this.form.customCycleNum.length === 0) {
        this.$message.warn('请选择每月重复的数据哦！')
        return
      }
      const path = this.id ? 'TASKREPEATUPDATE' : 'TASKREPEATADD'
      this.loading = true
      const form = this.$utils.clone(this.form)
      form.customCycleNum = form.customCycleNum.toString()
      form.type = repeatEnum.CYCLENUMTYPE.custom // 自定义重复
      if (!this.noRequest) {
        this.submitDetail(path, form)
      } else {
        this.$emit('save', form)
        this.closeDialog()
      }
    },
    closeDialog() {
      this.$emit('closeTaskCustomCycle')
    },
    submitDetail(path, _obj) {
      this.$apis[path](_obj).then((res) => {
        if (res.status === 200) {
          this.loading = false
          if (res.data) {
            this.id = res.data.id
            _obj.id = this.id
            this.$emit('save', _obj)
            this.taskAddObject.taskCustom = _obj
            this.taskAddObject.taskRemind = {}
            this.$store.commit('common/saveTaskAddObject', this.taskAddObject)
            this.closeDialog()
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 重复类型选择
    customTypeChange() {
      this.form.customInterval = 1
      this.form.customCycleNum = []
    },
    // 每周重复显示周的选择
    customCycleNumChange() {
      // 选择的数据正序
      this.customCycleNumSort()
    },
    // 每月重复的日期选择
    dayClick(val) {
      const customCycleNum = this.form.customCycleNum
      const exit = customCycleNum.includes(val)
      if (exit) {
        const index = customCycleNum.findIndex((item) => item === val)
        customCycleNum.splice(index, 1)
      } else {
        customCycleNum.push(val)
      }
      // 选择的数据正序
      this.customCycleNumSort()
    },
    // 选择的数据正序
    customCycleNumSort() {
      // 正序排列
      this.form.customCycleNum = this.form.customCycleNum.sort((a, b) => a - b)
    },
    // 是否是最后一个元素
    isLastIndex(index) {
      return index + 1 !== this.form.customCycleNum.length
    },
    // 每月重复的日期是否选中
    dayActiveClass(val) {
      const exit = this.form.customCycleNum.includes(val)
      return exit && 'active'
    },
    // 周转成汉字
    weekToCn(val) {
      const map = {
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '日'
      }
      return map[val]
    },
    // 周转成汉字---日程
    scheWeekToCn(val) {
      const map = {
        1: '日',
        2: '一',
        3: '二',
        4: '三',
        5: '四',
        6: '五',
        7: '六'
      }
      return map[val]
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/styles/mixins.scss';
.select-list {
  justify-content: space-between;
}
.day-box {
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 246px;
  .item {
    border-bottom: 1px solid #dedede;
    border-right: 1px solid #dedede;
    cursor: pointer;
    text-align: center;
    height: 35px;
    line-height: 35px;
    width: 35px;
  }
  .active {
    background-color: #0d84eb;
    color: #fff;
  }
}
.cancel-btns {
  height: 40px;
  line-height: 40px;
  padding: 0;
  width: 120px;
  border-radius: 18px;
  margin-right: 14px;
}
.common_btn_next {
  height: 40px;
  line-height: 40px;
  padding: 0;
  width: 120px;
  border-radius: 18px;
}
</style>
