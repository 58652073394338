var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "detail-list",
      class:
        "detail-list-" +
        (_vm.keyItem.handleType || _vm.keyItem.businessType === 0),
    },
    [
      _c("div", { staticClass: "detail-left" }, [
        _vm.itemType === "advanceFromTask"
          ? _c(
              "div",
              {
                staticClass: "type",
                class: "status-con status-con" + _vm.keyItem.taskState,
              },
              [_vm._v(_vm._s(_vm.statusDict[_vm.keyItem.taskState]))]
            )
          : _c(
              "div",
              {
                class:
                  "type type-" +
                  (_vm.keyItem.handleType || _vm.keyItem.businessType === 0),
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.keyItem.businessType === 0 || _vm.keyItem.handleType
                      ? "O" + (_vm.index + 1)
                      : "KR" + (_vm.index + 1)
                  )
                ),
              ]
            ),
        _c("div", {
          staticClass: "content",
          domProps: {
            innerHTML: _vm._s(
              _vm.keyItem.handleType
                ? _vm.$utils.changeToHtml(_vm.keyItem.groupObjectTitle)
                : _vm.$utils.changeToHtml(_vm.keyItem.title)
            ),
          },
        }),
      ]),
      _c("div", { staticClass: "detail-right" }, [
        _c("div", { staticClass: "push-to" }, [
          _vm._v(" 将推进 "),
          _vm.itemType === "advanceTo"
            ? _c(
                "div",
                { staticClass: "user-name" },
                [
                  _c("wx-open-data", {
                    attrs: {
                      id: _vm.keyItem.userName,
                      nameData: { name: _vm.keyItem.userName },
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "user-name" },
                [
                  _c("wx-open-data", {
                    attrs: {
                      id: _vm.okrData.userName,
                      nameData: { name: _vm.okrData.userName },
                    },
                  }),
                ],
                1
              ),
          _vm._v(
            " " + _vm._s(_vm.keyItem.pushTo) + _vm._s(_vm.keyItem.unit) + " "
          ),
        ]),
        !_vm.keyItem.handleType
          ? _c("div", { staticClass: "current-value" }, [
              _vm._v(
                "已推进" +
                  _vm._s(_vm.keyItem.currentValue) +
                  _vm._s(_vm.unit || _vm.item.quantificationUnit)
              ),
            ])
          : _vm._e(),
        _vm.keyItem.handleType || _vm.keyItem.businessType === 0
          ? _c("div", { staticClass: "cycle-name" }, [
              _vm._v(_vm._s(_vm.keyItem.cycleInfoName)),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }