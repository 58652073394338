var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "key-result-wrap" }, [
    _c(
      "div",
      {
        staticClass: "keyResults",
        on: {
          click: function ($event) {
            return _vm.getAddkeyList(false)
          },
        },
      },
      [
        _c("Icon", {
          staticClass: "keyResultsImg",
          attrs: { "icon-class": "icon-a-tianjia2" },
        }),
        _c("div", { staticClass: "keyResultsText" }, [
          _vm._v("手动添加关键结果"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "keyResults",
        on: {
          click: function ($event) {
            return _vm.getAddkeyList(true)
          },
        },
      },
      [
        _c("Icon", {
          staticClass: "keyResultsImg icon-ai",
          attrs: { "icon-class": "icon-ai" },
        }),
        _c("div", { staticClass: "keyResultsText" }, [_vm._v("添加关键结果")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }