var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "apply-demo-dialog",
      attrs: {
        title: "预约演示",
        width: "432px",
        modal: false,
        visible: _vm.dialogVisible,
        "append-to-body": true,
        "modal-append-to-body": true,
        "show-close": true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "apply-demo-wrap" },
        [_c("applyContent", { on: { close: _vm.handleClose } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }