var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.InitializeDates != null && _vm.state
    ? _c(
        "div",
        {
          staticClass: "top_progress clearfix",
          class:
            _vm.topMembersState === "2"
              ? "top_progress_style key-number-" + _vm.keyNumberVisible
              : "key-number-" + _vm.keyNumberVisible,
        },
        [
          _vm._t("default"),
          _vm.InitializeDates.progressRete > 0 &&
          _vm.InitializeDates.progressRete <= 100
            ? _c("el-progress", {
                attrs: {
                  percentage: _vm.InitializeDates.progressRete,
                  color:
                    (_vm.stateObj[_vm.InitializeDates.status] &&
                      _vm.stateObj[_vm.InitializeDates.status].color) ||
                    "#005EFF",
                  "text-inside": true,
                },
              })
            : _vm.InitializeDates.progressRete > 100
            ? _c(
                "div",
                { staticClass: "process-zero-bar-outer process-max-number" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "process-zero-bar-inner process-max-number-inner",
                    },
                    [_vm._v(_vm._s(_vm.InitializeDates.progressRete) + "%")]
                  ),
                ]
              )
            : _c("div", { staticClass: "process-zero-bar-outer" }, [
                _c("div", { staticClass: "process-zero-bar-inner" }, [
                  _vm._v(_vm._s(_vm.InitializeDates.progressRete) + "%"),
                ]),
              ]),
          _vm.keyNumberVisible
            ? _c("div", { staticClass: "top_progress_text" }, [
                _vm._v(" KR "),
                _c(
                  "div",
                  {
                    staticClass: "top_progress_text_radius",
                    class:
                      _vm.InitializeDates.status === 0
                        ? "top_progress_text_radius_color1"
                        : _vm.InitializeDates.status === 1
                        ? "top_progress_text_radius_color2"
                        : _vm.InitializeDates.status === 2
                        ? "top_progress_text_radius_color3"
                        : "top_progress_text_radius_color4",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.topMembersState === "4"
                            ? _vm.InitializeDates.keyCount
                            : _vm.InitializeDates.keyresultsList.length
                        ) +
                        " "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "li_box1_schedule_suspension" }, [
            _c(
              "div",
              {
                staticClass: "li_box1_schedule_suspension_box",
                class:
                  "li_box1_schedule_suspension_bng" +
                  _vm.InitializeDates.status,
              },
              [
                _c("Icon", {
                  staticClass: "tip-icon",
                  attrs: {
                    "icon-class": "icon-tip-box" + _vm.InitializeDates.status,
                  },
                }),
                _c("div", { staticClass: "tip-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.InitializeDates.status >= 0
                          ? _vm.stateObj[_vm.InitializeDates.status].txt
                          : "暂无说明"
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }