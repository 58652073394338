var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "left-slide", mode: "out-in" } }, [
    _vm.isShow && _vm.msg !== ""
      ? _c(
          "div",
          { key: "" + _vm.msg, ref: "message", staticClass: "msg-wrap" },
          [
            _c(
              "div",
              {
                class: [
                  "msg",
                  "global-msg",
                  _vm.type === "success"
                    ? "success"
                    : _vm.type === "error"
                    ? "error"
                    : "warn",
                ],
              },
              [
                _c("i", {
                  class: [
                    "iconfont",
                    _vm.type === "success" ? "ymbsuccess" : "ymbguanbianniu",
                  ],
                }),
                _c("div", { staticClass: "content" }, [
                  _vm.code != 200
                    ? _c("span", [_vm._v(_vm._s(_vm.code) + "：")])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.msg) + " "),
                ]),
                _c("i", {
                  staticClass: "close iconfont ymbclose",
                  on: { click: _vm.close },
                }),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }