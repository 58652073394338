var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-path" }, [
    _c(
      "div",
      { staticClass: "detail-path-return", on: { click: _vm.getTargetReturn } },
      [
        _c("Icon", {
          staticClass: "return-icon",
          attrs: { "icon-class": "icon-drawer_return" },
        }),
        _c("div", [_vm._v("返回上级")]),
      ],
      1
    ),
    _c("div", { staticClass: "split" }),
    _c("div", { staticClass: "path-okrs" }, [
      _c("div", {
        staticClass: "target_top_left4_return",
        domProps: { innerHTML: _vm._s(_vm.objPathData[1].content) },
        on: { click: _vm.getTargetReturn },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }