var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        {
          staticClass: "task-el-dropdown",
          attrs: { trigger: _vm.trigger },
          on: { command: _vm.command },
        },
        [
          _c("div", { staticClass: "pointer" }, [
            _c("span", [_vm._v(_vm._s(_vm.localText || "全部"))]),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _vm.all
                ? _c("el-dropdown-item", { attrs: { command: null } }, [
                    _vm._v("全部"),
                  ])
                : _vm._e(),
              _vm._l(_vm.options, function (val, key) {
                return _c(
                  "el-dropdown-item",
                  { key: val, attrs: { command: key } },
                  [_vm._v(" " + _vm._s(val) + " ")]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }