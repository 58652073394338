<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    size="580px"
    width="580px"
    :title="`欢迎您使用源目标 AI 助手`"
    direction="rtl"
  >
    <div class="add-content-wrap">
      <div class="ai-title">源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR，同时我们将会帮助您生产指导性的工作任务</div>
      <div class="remind-title">请填写以下内容，以便于AI助手更好的帮助您输出内容</div>
      <div class="content-input-wrap">
        <div class="list-wrap">
          <label>企业所属行业:</label>
          <el-select
            v-model="data.industryId"
            :poper-append-to-body="false"
            placeholder="请选择"
            @change="changeList($event, data.industryList, 'industryName', true)"
          >
            <el-option
              v-for="item in data.industryList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            />
          </el-select>
          <el-select
            v-model="data.industryChildrenId"
            :poper-append-to-body="false"
            placeholder="请选择"
            class="el-select-left"
            @change="changeList($event, industryListSon, 'industryChildrenName', false)"
          >
            <el-option
              v-for="item in industryListSon"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="list-wrap">
          <label>企业规模:</label>
          <el-select
            v-model="data.scopeId"
            :poper-append-to-body="false"
            placeholder="请选择"
            class="float_lf_w320"
            @change="changeList($event, data.userCountList, 'scopeName', false)"
          >
            <el-option
              v-for="item in data.userCountList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="list-wrap">
          <label>你的职位:</label>
          <el-input
            v-model="data.jobTitle"
            class="input-focus"
            auto-complete="off"
            placeholder="请输入职位"
          />
        </div>
        <div class="list-wrap">
          <label>部门人数:</label>
          <el-input
            v-model="data.departUserNum"
            auto-complete="off"
            placeholder="请输入部门人数"
            type="text"
            class="input-focus"
            @change="change($event)"
          />
        </div>
      </div>
      <div class="bottom-step-buttons">
        <button @click="handleClose" class="left-button">取消</button>
        <el-button v-preventReClick @click="saveBtn()">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  components: {
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean
    },
    data: {
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      setData: {},
      industryListSon: []
    }
  },
  computed: {},
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
        if (val) {
          this.setData = this.data
          this.changeList(this.setData.industryId, this.setData.industryList, 'industryName', false)
        }
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    changeList(id, list, name, refresh) {
      console.log('changeList', id, list, name, refresh)
      list.forEach((item) => {
        if (item.id === id) {
          this.setData[name] = item.value
          if (name === 'industryName') {
            if (refresh === true) {
              this.setData.industryChildrenId = item.children[0].id
              this.setData.industryChildrenName = item.children[0].value
            }
            this.industryListSon = item.children
            console.log('industryListSon', name, this.industryListSon, item.children, this.setData)
          }
        }
      })
    },
    change($event) {
      this.data.departUserNum = (Number($event) || 0).toFixed(0) // 保留两位小数
    },
    saveBtn() {
      this.$emit('submit', JSON.parse(JSON.stringify(this.setData)))
      this.handleClose()
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../../assets/styles/dialog.less';
::v-deep {
  .el-dialog__header {
    display: block !important;
    padding: 32px 32px 0 32px !important;
  }
  .el-dialog__body {
    padding: 0 32px 0px 32px !important;
  }
}
.add-content-wrap {
  .list-wrap {
    .flex-l();
    padding: 0px 0px 16px 0px;
    label {
      font-size: 14px;
      line-height: 40px;
      color: #14142b;
      font-weight: bold;
      min-width: 110px;
    }
    .el-select-left {
      margin-left: 16px;
    }
  }
  .ai-title {
    color: #a0a3bd;
    font-size: 12px;
    margin: 24px 0px 16px 0px;
    line-height: 1.5;
  }
  .remind-title {
    font-size: 12px;
    margin: 24px 0px 16px 0px;
    line-height: 1.5;
    color: #ed2e7e;
  }
  .bottom-step-buttons {
    margin: 24px 0px;
    padding: 0px 32px;
    .flex-l();
    justify-content: center;
    .left-button,
    .el-button {
      border-radius: 8px;
      height: 32px;
    }
    .left-button {
      background: none !important;
    }
  }
}
</style>
