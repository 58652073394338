var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          ref: "popoverOkrAlign",
          attrs: {
            width: "700",
            "popper-class": "popover-okr-align",
            placement: _vm.placement,
            disabled: _vm.disabled,
          },
          on: { hide: _vm.hide, show: _vm.getTargetAligningShow },
        },
        [
          _c(
            "div",
            {
              class:
                "popover-okr-wrap target_aligning target_aligning-" + _vm.type,
            },
            [
              _c("div", { staticClass: "target_aligning_search" }, [
                _c("div", { staticClass: "cycle-wrap" }, [
                  _c("div", [_vm._v("当前周期")]),
                  _vm.okrCycleList !== null
                    ? _c(
                        "div",
                        { staticClass: "cycle-infor" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.okrCycleList.largaeCycle.name) +
                              " "
                          ),
                          _vm.selectSmallCycle !== null
                            ? [
                                _vm._v(
                                  " " + _vm._s(_vm.selectSmallCycle.name) + " "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.selectKeyList.length === 0
                    ? _c(
                        "div",
                        { staticClass: "cycle-select" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "select-cycle",
                              attrs: { "popper-class": "popover-okr-align" },
                              model: {
                                value: _vm.smallCycleId,
                                callback: function ($$v) {
                                  _vm.smallCycleId = $$v
                                },
                                expression: "smallCycleId",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "select-cycle-wrap",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "cycle-large" },
                                    _vm._l(
                                      _vm.okrCycleList.allCycleList,
                                      function (val, key) {
                                        return _c(
                                          "div",
                                          {
                                            key: val.id,
                                            class:
                                              _vm.largeCycleId === val.id
                                                ? "select"
                                                : "",
                                            attrs: {
                                              label: val.name,
                                              value: val.id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.selectLargeCycleChange(
                                                  key
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(val.name))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "cycle-small" },
                                    _vm._l(
                                      _vm.okrCycleList.smallCycle,
                                      function (val, key) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: val.id,
                                            attrs: {
                                              label: val.name,
                                              value: val.id,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.selectCycleChange(
                                                  key
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(val.name))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              ),
                              _vm._t(
                                "default",
                                function () {
                                  return [_vm._v("更改")]
                                },
                                { slot: "prefix" }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.selectKeyList.length === 0
                  ? _c("div", { staticClass: "aligning_search_box clearfix" }, [
                      _c(
                        "div",
                        { staticClass: "aligning_search_box_input" },
                        [
                          _c("el-input", {
                            directives: [{ name: "focus", rawName: "v-focus" }],
                            ref: "nameFocus",
                            staticClass: "search-input input_shadow_inset",
                            attrs: {
                              "prefix-icon": "el-icon-search",
                              placeholder: "请输入姓名或标题搜索成员目标",
                              maxlength: "151",
                            },
                            on: { input: _vm.getTargetAligningInput },
                            model: {
                              value: _vm.searchContent,
                              callback: function ($$v) {
                                _vm.searchContent = $$v
                              },
                              expression: "searchContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.selectKeyList.length !== 0
                ? _c(
                    "div",
                    { staticClass: "back-wrap", on: { click: _vm.back } },
                    [
                      _c("Icon", {
                        staticClass: "back",
                        attrs: { "icon-class": "icon-left" },
                      }),
                      _vm._v("返回 "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectKeyList.length === 0
                ? _c(
                    "div",
                    [
                      _vm.targetAligningBeans.length > 0
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "target_aligning_list",
                                staticStyle: { "overflow-x": "hidden" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                  scroll: function ($event) {
                                    return _vm.listScroll()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "aligning_list_ul" },
                                  _vm._l(
                                    _vm.targetAligningBeans,
                                    function (item, userIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass:
                                            "aligning_list_li clearfix",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "person-infor-wrap",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "aligning_list_li_icon2 border-radius-20",
                                                attrs: {
                                                  src:
                                                    (item.chargeUser &&
                                                      item.chargeUser.avatar) ||
                                                    require("@/assets/imgs/common/avatar.png"),
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "aligning_list_li_name",
                                                },
                                                [
                                                  _c("wx-open-data", {
                                                    attrs: {
                                                      id:
                                                        item.chargeUser &&
                                                        item.chargeUser.name,
                                                      "name-data": {
                                                        name:
                                                          item.chargeUser &&
                                                          item.chargeUser.name,
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "job-title" },
                                                [
                                                  item.chargeUser
                                                    ? _c("DepartUserList", {
                                                        attrs: {
                                                          departName:
                                                            item.chargeUser
                                                              .departName,
                                                          departUserList:
                                                            item.chargeUser
                                                              .departUserList,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              item.chargeUser &&
                                              item.chargeUser.departmentCall
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        "user-type user-type-" +
                                                        (item.chargeUser &&
                                                          item.chargeUser
                                                            .departmentCall ===
                                                            "部门负责人"),
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.chargeUser &&
                                                              item.chargeUser
                                                                .departmentCall ===
                                                                "部门负责人"
                                                              ? "部门负责人"
                                                              : "直属上级"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._l(
                                            item.objectivesNewDtoList,
                                            function (objItem, objIndex) {
                                              return _c(
                                                "div",
                                                { key: objItem.id },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "cycle-list-wrap",
                                                      class: objItem.state
                                                        ? "cycle-list-select"
                                                        : "",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cycle-infor-left",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "obj-num",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "O" +
                                                                  _vm._s(
                                                                    objIndex + 1
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                "popper-class":
                                                                  "popover-okr-align-tooltip",
                                                                "open-delay": 300,
                                                                disabled:
                                                                  _vm.tooltipDisabled,
                                                                content:
                                                                  _vm.$utils.tagFilter(
                                                                    objItem.content
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "okr-list-content",
                                                                  class:
                                                                    "okr-list-content-" +
                                                                    (objItem.isVisible ===
                                                                      1),
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "okr-title",
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          objItem.content
                                                                        ),
                                                                    },
                                                                  }),
                                                                  _c(
                                                                    "privateOkr",
                                                                    {
                                                                      attrs: {
                                                                        "create-user":
                                                                          _vm
                                                                            .userInfo
                                                                            .id ===
                                                                          objItem.createUserid,
                                                                        visible:
                                                                          objItem.isVisible ===
                                                                          1,
                                                                        content:
                                                                          objItem.isVisible ===
                                                                          1
                                                                            ? "对方已设置为不可见"
                                                                            : "",
                                                                        type: 1,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cycle-infor-right",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "hover-block",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectKeyClick(
                                                                      objItem
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("查看KR")]
                                                          ),
                                                          objItem.state
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "align-state",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "hover-hidden",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "已对齐"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "hover-block",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.getTargetAligningSelect(
                                                                              userIndex,
                                                                              objIndex,
                                                                              objItem.state
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "取消对齐"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "align-o",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "hover-block",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.getTargetAligningSelect(
                                                                              userIndex,
                                                                              objIndex,
                                                                              objItem.state
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "选择对齐O"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.targetAligningBeans.length === 0 && !_vm.loading
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "global-content-null-wrap global-content-gray",
                              },
                              [
                                _c("img", {
                                  staticClass: "img",
                                  attrs: {
                                    src: require("@/assets/imgs/common/commentNull.png"),
                                  },
                                }),
                                _c("div", { staticClass: "null-text" }, [
                                  _vm._v("暂无数据"),
                                ]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "okr-key-list" },
                      _vm._l(_vm.selectKeyList, function (itemList, index) {
                        return _c(
                          "div",
                          {
                            key: itemList.id,
                            staticClass: "key-list-wrap",
                            class: itemList.state ? "cycle-list-select" : "",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "key-list" },
                              [
                                _c("div", { staticClass: "key-list-name" }, [
                                  _vm._v("KR" + _vm._s(index + 1)),
                                ]),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      "popper-class":
                                        "popover-okr-align-tooltip",
                                      placement: "top",
                                      "open-delay": 300,
                                      content: _vm.$utils.tagFilter(
                                        itemList.title
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "key-list-content" },
                                      [
                                        _c("div", {
                                          staticClass: "key-list-title",
                                          domProps: {
                                            innerHTML: _vm._s(itemList.title),
                                          },
                                        }),
                                        _c("privateOkr", {
                                          attrs: {
                                            "create-user":
                                              _vm.userInfo.id ===
                                              itemList.createUserid,
                                            visible: itemList.isVisible === 1,
                                            content:
                                              itemList.isVisible === 1
                                                ? "对方已设置为不可见"
                                                : "",
                                            type: 2,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "cycle-infor-right" }, [
                              itemList.state
                                ? _c("div", { staticClass: "align-state" }, [
                                    _c("div", { staticClass: "hover-hidden" }, [
                                      _vm._v("已对齐"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "hover-block",
                                        on: {
                                          click: function ($event) {
                                            return _vm.getKrAligningSelect(
                                              index,
                                              itemList.state
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("取消对齐")]
                                    ),
                                  ])
                                : _c("div", { staticClass: "align-o" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "hover-block",
                                        on: {
                                          click: function ($event) {
                                            return _vm.getKrAligningSelect(
                                              index,
                                              itemList.state
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("选择对齐KR")]
                                    ),
                                  ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
              _vm.type === "add"
                ? _c("div", { staticClass: "bottom-step-buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "left-button",
                        on: { click: _vm.getTargetAligningClose },
                      },
                      [_vm._v("取消")]
                    ),
                    _c("button", { on: { click: _vm.save } }, [_vm._v("完成")]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._t("guidepop", null, { slot: "reference" }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }