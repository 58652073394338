var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.disabled
        ? _c("Icon", {
            staticClass: "cycle-icon",
            class: { disabled: _vm.disabled },
            attrs: { "icon-class": "icon-zhongfu1" },
          })
        : _c(
            "el-dropdown",
            {
              attrs: { trigger: "click" },
              on: {
                "visible-change": _vm.dropdownVisibleChange,
                command: _vm.typeChange,
              },
            },
            [
              _c(
                "div",
                [
                  _c("Icon", {
                    staticClass: "cycle-icon",
                    class: { active: _vm.isActive },
                    style:
                      _vm.taskAddObject.type == 1 ||
                      (_vm.taskAddObject.type == 7 && !_vm.id)
                        ? "color:#a0a3bd"
                        : "",
                    attrs: { "icon-class": "icon-zhongfu1" },
                  }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "el-dropdown-menu-class-clock",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                _vm._l(_vm.cycleTypeDict, function (val, key) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: key,
                      class: {
                        active: parseInt(_vm.form.type) === parseInt(key),
                      },
                      attrs: { command: key },
                    },
                    [_vm._v(_vm._s(val))]
                  )
                }),
                1
              ),
            ],
            1
          ),
      _c("task-custom-cycle", {
        attrs: {
          "custom-cycle-visible": _vm.customCycleVisible,
          "task-id": _vm.taskId,
          row: _vm.form,
        },
        on: {
          closeTaskCustomCycle: _vm.closeTaskCustomCycle,
          save: _vm.customCycleSave,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }