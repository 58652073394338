<template>
  <el-dialog
    title=" AI 助写 KR"
    :visible.sync="dialogVisible"
    width="920px"
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="handleClose"
    v-on="$listeners"
  >
    <!-- <div class="set-btn" @click="aiSetClick(true)">设置</div> -->
    <div class="ai-count-wrap">
      剩余可调用次数：
      <span>{{companyInfo.aiUsageCount || 0}}</span> 次 （更多订阅请咨询售前顾问）
    </div>
    <div class="kr-content-wrap">
      <div class="kr-title" v-if="isEdit">
        <div class="kr-index">KR{{ krIndex + 1}}</div>
        <div class="title" v-html="$utils.changeToHtml(editInfo.title)"></div>
      </div>
      <div class="kr-title" v-else>
        <div class="kr-index">
          <Icon icon-class="icon-caidanlan-wodeOKR" class="icon" />
        </div>
        <div class="title" v-html="$utils.changeToHtml(objData.content)"></div>
      </div>
      <div class="kr-infor" v-if="!listLoading">AI 助手已经自动生成KR，可以根据需求修改内容，并导入至系统中</div>

      <div class="task-list-wrap" v-if="!listLoading">
        <key-results-edit
          :is-share="objData.isShare"
          :is-edit="isEdit"
          :edit-info="krContent"
          :okrDataObj="objData"
          :noRequest="true"
          :key="krContent"
          ref="keyResultsEdit"
          @saveContent="saveContent($event, index)"
        />
      </div>
      <div class="task-list-wrap loading-wrap" v-if="listLoading">
        <!-- <no-data
          text="源目标AI助手生成 KR 中，请稍作等待哦！"
          :imgUrl="require('@/assets/imgs/common/page-loading.gif')"
        />-->
        <div class="step-list-wrap">
          <div
            :class="`step-list ${index > stepLoading}`"
            v-for="(item, index) in stepTitleList"
            :key="item"
            v-if="index <= stepLoading"
          >
            <i class="el-icon-loading icon" v-if="stepLoading === index"></i>
            <Icon
              icon-class="icon-a-daibanyiwancheng1"
              :class="`icon ${index > stepLoading}`"
              v-else
            />
            <div class="title">{{item}}</div>
          </div>
        </div>
      </div>
      <div class="bottom-step-buttons">
        <el-button @click="handleClose" class="left-button" :disabled="listLoading">取消</el-button>
        <el-button
          v-preventReClick
          class="left-button"
          @click="submitRefresh()"
          :disabled="listLoading || submitLoading"
        >重新生成</el-button>
        <el-button
          v-preventReClick
          :loading="loading"
          @click="submit()"
          :disabled="listLoading || submitLoading"
        >确认</el-button>
      </div>
    </div>
    <ai-set
      v-if="aiSetvisible"
      v-model="aiSetvisible"
      @submit="submitSet"
      :data="JSON.parse(JSON.stringify(setData))"
    />
    <custom-dialog
      v-if="customDialogvisible"
      v-model="customDialogvisible"
      type="kr"
      @submit="getKrContent"
    />
  </el-dialog>
</template>
<script>
import aiSet from '@/views/okr/dialog/addTarget/addOkrAi/aiSet'
import keyResultsEdit from '@/views/okr/components/singleOkrItem/addKrAi/keyResultsEdit'
import customDialog from '@/views/okr/table/addTaskAi/customDialog'
export default {
  components: {
    aiSet,
    keyResultsEdit,
    customDialog
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    editInfo: {
      type: Object,
      required: false
    },
    objData: {
      type: Object,
      required: false
    },
    krIndex: {
      default: 0
    },
    isEdit: {
      default: false
    },
    fileList: {
      default: () => {
        return []
      }
    },
    sId: {
      default: 0
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
        if (val) {
          if (this.isEdit) {
            this.krContent = JSON.parse(JSON.stringify(this.editInfo))
          }
          var localData = localStorage.getItem('aiSetData')
          // if (localData) {
          //   this.setData = JSON.parse(localData)
          //   this.getKrContent()
          // } else {
          //   this.getAiSetData()
          // }
          this.getAiSetData()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      setData: '',
      listLoading: true,
      submitLoading: false,
      aiSetvisible: false,
      krContent: {
        quantificationEnd: 100,
        quantificationStart: 0,
        quantificationType: 0,
        quantificationUnit: '%',
        title: '',
        comment: ''
      },
      rebuild: 0,
      customDialogvisible: false,
      stepLoading: 0,
      stepTitleList: ['小源AI正在分析您的问题.....', 'AI模型正在匹配最佳信息.....', '您的KR正在生成中.....', '请稍候，您的KR即将出炉...']
    }
  },
  methods: {
    setLoadingInit() {
      this.stepLoading = 0
      setTimeout(() => {
        this.stepLoading = 1
      }, 1000)
      setTimeout(() => {
        this.stepLoading = 2
      }, 2000)
      setTimeout(() => {
        this.stepLoading = 3
      }, 4000)
    },
    submitRefresh() {
      this.customDialogvisible = true
    },
    saveContent(data, index) {
      console.log('saveContent00', data)
      this.krContent.comment = data.obj.html
      this.krContent.atIds = data.obj.atIds
      this.krContent.html = data.obj.html
      this.krContent.quantificationType = data.unitForm.quantificationType
      this.krContent.quantificationStart = data.unitForm.quantificationStart
      this.krContent.quantificationEnd = data.unitForm.quantificationEnd
      this.krContent.quantificationUnit = data.unitForm.quantificationUnit
      this.$emit('refresh', this.krContent)
    },
    aiSetClick(state) {
      this.aiSetvisible = state
    },
    submitSet(data) {
      this.setData = data
      localStorage.setItem('aiSetData', JSON.stringify(data))
      this.aiSetClick(false)
    },
    getAiSetData() {
      this.$apis
        .USERAIINFO()
        .then((rest) => {
          if (rest.status === 200) {
            rest.data.jobTitle = rest.data.jobTitle || ''
            this.setData = rest.data
            localStorage.setItem('aiSetData', JSON.stringify(rest.data))
            // this.aiSetClick(true)
            this.getKrContent()
          } else {
            this.$message.error('获取AI初始设置异常哦！')
          }
        })
    },
    handleClose() {
      this.$emit('close')
      this.dialogVisible = false
      this.$emit('change', false)
    },
    async getKrContent(requirement = '') {
      this.listLoading = true
      this.setLoadingInit()
      const beans = {
        companyId: this.companyInfo.id,
        userScale: this.setData.scopeName || '11-50人',
        industry: this.setData.industryChildrenName || '软件和企业服务',
        userRole: this.setData.jobTitle || '',
        departUserCount: this.setData.departUserNum || 0,
        content: this.objData && this.objData.content,
        kr: this.isEdit ? this.editInfo.title : '',
        rebuild: this.rebuild,
        count: 1,
        requirement,
        fileList: this.fileList,
        sId: this.sId
      }
      this.$apis.AICREATEKR(beans)
        .then((rest) => {
          if (rest.status === 200) {
            if (rest.data && rest.data.keyresultsList) {
              this.krContent.comment = rest.data.keyresultsList[0].title
              this.krContent.html = rest.data.keyresultsList[0].title
              this.krContent.atIds = []
              this.krContent.objectivesRuleMatchDto = []
              this.krContent.collaboratorStr = []
              this.krContent.ignoreObjectivesRuleMatchDtoList = []
              this.krContent.quantificationType = rest.data.keyresultsList[0].quantificationType
              this.krContent.quantificationStart = rest.data.keyresultsList[0].quantificationStart
              this.krContent.quantificationEnd = rest.data.keyresultsList[0].quantificationEnd
              this.krContent.quantificationUnit = rest.data.keyresultsList[0].quantificationUnit
              this.krContent = JSON.parse(JSON.stringify(this.krContent))
              console.log('krContent', this.krContent)
            }
          } else {
            this.$message.error(rest.message || '生成KR失败哦！')
          }
          this.listLoading = false
          this.rebuild = 1
          this.companyInfo.aiUsageCount = rest.data.aiUsageCount
          this.$store.commit('user/setCompanyInfo', this.companyInfo)
        })
    },
    // 提交
    async submit() {
      this.$refs.keyResultsEdit.saveKrTitle()
      // this.$emit('refresh', this.krContent)
      // this.handleClose()
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.step-list-wrap {
  width: 260px;
  margin: 0px auto;
  text-align: center;
  .step-list {
    .flex-l();
    text-align: left;
    margin: 40px 0px;
    position: relative;
    color: #434458;
    font-size: 16px;
    .icon {
      color: #3a70ff;
      margin-right: 16px;
      font-size: 24px;
      width: 24px;
      height: 24px;
    }
    .icon.true {
      color: #a0a3bd;
    }
    &::before {
      content: '';
      width: 1px;
      height: 28px;
      background: #3a70ff;
      position: absolute;
      top: 32px;
      left: 12px;
      right: 0px;
    }
  }
  .step-list.true {
    color: #a0a3bd;
    &::before {
      background: #a0a3bd;
    }
  }
  .step-list:last-child {
    &::before {
      display: none;
    }
  }
}
.ai-count-wrap {
  position: absolute;
  right: 64px;
  font-size: 12px;
  top: -54px;
  line-height: 28px;
  color: #a0a3bd;
  span {
    color: #ed2e7e;
  }
}
.set-btn {
  cursor: pointer;
  color: #005eff;
  position: absolute;
  top: -47px;
  z-index: 9999;
  right: 72px;
  &:hover {
    opacity: 0.54;
  }
}
::v-deep {
  .el-dialog__body {
    position: relative;
  }
  .action-btns {
    display: none !important;
  }
  .no-data-show {
    min-height: 300px;
  }
  .loading-wrap .no-data-show .no-data-show-icon {
    width: 60px;
  }
  .loading-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
  }
  .no-data-show-text {
    margin-top: 24px;
  }
}
.kr-content-wrap {
  position: relative;
  .kr-title {
    .flex-l();
    margin: 24px 0px 0px 0px;
    align-items: flex-start;
    .kr-index {
      color: #3a70ff;
      margin-right: 8px;
      font-weight: bold;
      .icon {
        font-size: 22px;
      }
    }
    .title {
      flex: 1;
    }
    font-size: 14px;
    color: #434458;
    line-height: 22px;
    padding: 12px 24px;
    background: rgba(58, 120, 246, 0.08);
    border-radius: 12px;
  }
  .kr-infor {
    color: #a0a3bd;
    font-size: 12px;
    margin-bottom: 16px;
    margin-top: 32px;
  }
}
.bottom-step-buttons {
  margin: 32px 0px 0px 0px;
  justify-content: center;
  .left-button,
  .el-button {
    height: 32px;
    border-radius: 8px;
  }
  .left-button {
    margin-right: 8px;
  }
}
</style>
