var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "comm-check-form",
          attrs: {
            title: "周期阶段设置",
            visible: _vm.dialogVisible,
            width: "630px",
            "append-to-body": true,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content comm-input" },
            [
              _c("stageForm", {
                attrs: {
                  "stage-date": _vm.stageSetInfo[0],
                  "date-types": _vm.monthDateType,
                  "cycle-info": _vm.cycleInfo,
                  type: "month",
                },
                on: {
                  changeData: function ($event) {
                    return _vm.changeStageData($event, 0)
                  },
                },
              }),
              _c("stageForm", {
                attrs: {
                  "stage-date": _vm.stageSetInfo[1],
                  "date-types": _vm.dateType,
                  "cycle-info": _vm.cycleInfo,
                  type: "year",
                },
                on: {
                  changeData: function ($event) {
                    return _vm.changeStageData($event, 1)
                  },
                },
              }),
              _c("div", { staticClass: "bottom-step-buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "left-button",
                    on: { click: _vm.handleClose },
                  },
                  [_vm._v("取消")]
                ),
                _c("button", { on: { click: _vm.save } }, [_vm._v("确定")]),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }