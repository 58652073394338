var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageState
    ? _c(
        "div",
        {
          class: "ymb-container ymb-container-" + _vm.IsPC,
          attrs: { id: "app", "data-app": "" },
        },
        [
          _vm.isRouterAlive ? _c("router-view") : _vm._e(),
          _c("api-request-handle"),
          _vm.accountPassword == true
            ? _c("div", { staticClass: "account-password" }, [
                _c("p", [_vm._v("要保存账号密码吗？")]),
                _c("div", { staticClass: "account-password-wrap" }, [
                  _c("div", { staticClass: "account" }, [
                    _c("span", [_vm._v("用户名:")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.telephone,
                          expression: "telephone",
                        },
                      ],
                      staticClass: "account",
                      attrs: { type: "text", readonly: "" },
                      domProps: { value: _vm.telephone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.telephone = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "password" }, [
                    _c("span", [_vm._v("密码:")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "password",
                      attrs: { type: "password", readonly: "" },
                      domProps: { value: _vm.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "footer-btns" }, [
                  _c(
                    "span",
                    {
                      staticClass: "account-save",
                      on: { click: _vm.saveAccountPass },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "account-cancel",
                      on: { click: _vm.allNot },
                    },
                    [_vm._v("取消")]
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.state.common.scrollBgVisible,
                expression: "$store.state.common.scrollBgVisible",
              },
            ],
            staticClass: "global-bg-style",
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }