var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        trigger: "hover",
        disabled: _vm.editState || _vm.adviceDisable || !_vm.adviceObj.id,
        "open-delay": 300,
        "popper-class": "advice-pop",
        placement: _vm.placement,
      },
      model: {
        value: _vm.popShow,
        callback: function ($$v) {
          _vm.popShow = $$v
        },
        expression: "popShow",
      },
    },
    [
      _c("div", { staticClass: "advice-content" }, [
        _c("div", { staticClass: "advice-head" }, [
          _c("div", { staticClass: "head-title" }, [_vm._v("填写建议")]),
          _c(
            "div",
            { staticClass: "ignore-btn", on: { click: _vm.ignoreAdvice } },
            [_vm._v("忽略")]
          ),
        ]),
        _c("div", { staticClass: "advice-item" }, [
          _vm._v(" " + _vm._s(_vm.adviceObj.text || "") + " "),
        ]),
      ]),
      _vm.editState
        ? _c(
            "div",
            {
              staticClass: "at-box",
              attrs: { slot: "reference" },
              on: { mouseenter: _vm.mouseEnter, mouseleave: _vm.mouseleave },
              slot: "reference",
            },
            [_vm._t("default")],
            2
          )
        : _c("span", {
            class: _vm.adviceDisable || !_vm.adviceObj.id ? "" : "content-html",
            attrs: { slot: "reference" },
            domProps: { innerHTML: _vm._s(_vm.html) },
            on: { click: _vm.clickEvent },
            slot: "reference",
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }