var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "按「我的任务」完成情况自动更新",
        visible: _vm.dialogVisible,
        width: "880px",
        "append-to-body": true,
        "before-close": _vm.handleClose,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.selectTaskList.length > 0 && !_vm.dataState
        ? _c(
            "div",
            {
              staticClass: "set-content",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "select-title" }, [
                _c(
                  "div",
                  { staticClass: "title-left" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.checkedAllChange },
                        model: {
                          value: _vm.checkedAll,
                          callback: function ($$v) {
                            _vm.checkedAll = $$v
                          },
                          expression: "checkedAll",
                        },
                      },
                      [_vm._v("全选")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "title-right" }, [
                  _c("p", [_vm._v("进度目标值")]),
                  _c("p", [
                    _vm._v(
                      "(剩余可设置" +
                        _vm._s(
                          _vm.$utils.floatToFixedDown(
                            _vm.maxPercentage - _vm.selectPercentage
                          )
                        ) +
                        _vm._s(_vm.item.quantificationUnit) +
                        ")"
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "select-list-wrap" },
                _vm._l(_vm.selectTaskList, function (taskItem, index) {
                  return _c(
                    "div",
                    {
                      key: taskItem.id + _vm.random,
                      staticClass: "select-list",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "list-left" },
                        [
                          _c("el-checkbox", {
                            on: {
                              change: function ($event) {
                                return _vm.checkedChange(taskItem, index)
                              },
                            },
                            model: {
                              value: taskItem.checked,
                              callback: function ($$v) {
                                _vm.$set(taskItem, "checked", $$v)
                              },
                              expression: "taskItem.checked",
                            },
                          }),
                          _c("img", {
                            staticClass: "border-radius-20",
                            attrs: {
                              src:
                                taskItem.originatorAvatar ||
                                require("@/assets/imgs/common/avatar.png"),
                              alt: "",
                            },
                          }),
                          _c("p", [_vm._v(_vm._s(taskItem.title))]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: taskItem.checked,
                              expression: "taskItem.checked",
                            },
                          ],
                          staticClass: "list-right",
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-focus",
                              attrs: { placeholder: "0", min: 0 },
                              on: { change: _vm.getSelectList },
                              model: {
                                value: taskItem.pushFrom,
                                callback: function ($$v) {
                                  _vm.$set(taskItem, "pushFrom", $$v)
                                },
                                expression: "taskItem.pushFrom",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v(_vm._s(_vm.item.quantificationUnit)),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _c(
            "div",
            { staticClass: "set-content set-content-null" },
            [_c("noData", { attrs: { type: 2 } })],
            1
          ),
      _vm.selectTaskList.length > 0 && !_vm.dataState
        ? _c("div", { staticClass: "bottom-step-buttons" }, [
            _c("div", { staticClass: "percentage-text" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$utils.floatToFixedDown(_vm.selectPercentage)) +
                    _vm._s(_vm.item.quantificationUnit)
                ),
              ]),
              _vm._v("的进度由 "),
              _c("span", [_vm._v(_vm._s(_vm.selectList.length))]),
              _vm._v("个任务完成后自动更新 "),
            ]),
            _c(
              "div",
              {
                staticClass: "btn-list",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "left-button",
                    on: { click: _vm.handleClose },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.submit("提交")
                      },
                    },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }