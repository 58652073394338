<template>
  <!-- <el-drawer
    :append-to-body="true"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    size="620px"
    :title="` AI 助写目标 OKR`"
    direction="rtl"
  >-->
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    size="1200px"
    width="1200px"
    :title="` ${aiStep === 2 || alignObj ? 'AI 助写关键结果KR' : 'AI 助写目标 O （我们将为你首先生成目标 O)'}`"
    direction="rtl"
    class="add-okr-dialog-wrap"
  >
    <div class="ai-count-wrap">
      剩余可调用次数：
      <span>{{companyInfo.aiUsageCount || 0}}</span> 次 （更多订阅请咨询售前顾问）
    </div>
    <div class="add-content-wrap">
      <!-- <div class="set-btn" @click="aiSetClick(true)">设置</div> -->
      <div class="add-content-left" v-show="aiStep !== 2 && !alignObj">
        <div class="ai-title">源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR，同时我们将会帮助您生产指导性的工作任务</div>
        <div class="content-input-wrap">
          <div class="list-wrap" v-show="!modeFile">
            <label>职位:</label>
            <el-input
              v-model="setData.jobTitle"
              class="input-focus"
              auto-complete="off"
              placeholder="请输入职位"
              :disabled="addOkrLoading"
            />
          </div>
          <div class="list-wrap" v-show="!modeFile">
            <label>目标周期:</label>
            <!-- <el-select
              v-model="setDataNew.cycle"
              :poper-append-to-body="false"
              placeholder="请选择目标周期"
              class="float_lf_w320"
              :disabled="addOkrLoading"
            >
              <el-option v-for="item in cycleList" :key="item" :label="item" :value="item" />
            </el-select>-->
            <Select
              :type="4"
              :minor-cycle="minorCycle"
              :major-cycle="majorCycle"
              @setMinorCycle="setMinorCycle"
            />
          </div>
          <div class="list-wrap" v-show="!modeFile">
            <label>类型:</label>
            <label-select v-model="setDataNew.type" class="label-select" />
          </div>
          <div class="upload-wrap" v-show="modeFile">
            <div class="file-title">
              <Icon icon-class="icon-shenpijinggao" class="icon" />
              <div class="title">例如与战略、营销、销售、财务等相关的公司或业务部门报告和分析。</div>
            </div>
            <el-upload
              ref="upload"
              :show-file-list="false"
              action
              class="upload-demo"
              :on-change="onChange"
              multiple
              :http-request="upLoadFile"
              :on-exceed="onExceed"
              :limit="4 - fileListUpload.length"
              drag
              accept=".xls, .xlsx, .csv, .txt, .doc, .docx, .pdf"
              :disabled="fileListUpload.length === 4"
            >
              <div slot="trigger" class="upload-btn-wrap">
                <Icon icon-class="icon-tianjiawenjian" class="icon" />
                <div class="bottom-step-buttons">
                  <el-button type="primary" :disabled="fileListUpload.length === 4">选择文件</el-button>
                </div>
                <div class="title">可以直接将文件拖拽到此处进行上传，最多上传4个文件，支持格式：xls、xlsx、csv、txt、doc、docx、pdf</div>
              </div>
            </el-upload>
            <upload-file-list
              :preview="true"
              :filePreview="false"
              :oss-file-entity-list="fileListUpload"
              @delete="fileDelete"
            />
          </div>
          <!-- <div class="list-wrap">
            <label>维度:</label>
            <el-radio-group v-model="setDataNew.dimension" :disabled="addOkrLoading">
              <el-radio v-for="item in dimensionList" :label="item" :key="item" />
            </el-radio-group>
          </div>-->
          <div class="content-input">
            <label>解决与突破的方向：</label>
            <el-input
              v-model.lazy="content"
              type="textarea"
              placeholder="请描述您的目标方向，可以是遇到的问题或是明确的目标 
例如： 
问题型：希望解决目前部门效率问题。 
目标型：完成业绩指标100万"
              class="input-focus"
              :rows="6"
              :disabled="addOkrLoading"
            />
          </div>
        </div>
        <div class="bottom-step-wrap">
          <div @click="modeClick" class="mode-wrap">
            <Icon
              class="icon"
              :icon-class="!modeFile ? 'icon-wenjian2' : 'icon-caidanlan-quanburenwu'"
            />
            {{!modeFile ? '文件模式' : '基础模式'}}
          </div>
          <div class="bottom-step-buttons">
            <button @click="handleClose" class="left-button">取消</button>
            <el-button
              :loading="addOkrLoading"
              :disabled="content === '' || addOkrLoading || submitKrLoading"
              v-preventReClick
              @click="saveBtn()"
            >生成目标O</el-button>
          </div>
        </div>
      </div>
      <div class="add-content-right">
        <div class="okr-list-wrap">
          <div class="preiew-title" v-if="objList.length === 0 && !alignObj">
            预览 OKR：
            <span>（可以编辑目标和关键结果后一键生成应用OKR）</span>
          </div>
          <div class="preiew-title-wrap" v-if="aiStep === 1 && objList.length > 0 && !alignObj">
            <div class="preiew-title">预览 OKR：</div>
            <div class="ai-title">可根据业务需求选择或调整目标 O的内容， 可点击下方生成关键结果KR 按钮OKR）</div>
          </div>
          <div class="preiew-title-wrap" v-if="aiStep === 2 || alignObj">
            <!-- <div class="preiew-title">AI 助写关键结果KR</div> -->
            <div class="ai-title">源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR，同时我们将会帮助您生产指导性的工作任务</div>
          </div>

          <div
            class="okr-content-detail"
            v-if="objList.length > 0 && !addOkrLoading && !submitKrLoading"
          >
            <div class="align-obj" v-if="alignObjectiveData !== {} && aiStep === 2">
              <objective-show
                :align-hide="true"
                :obj-data="alignObjectiveData.alignObjectiveData"
                :alignType="alignObjectiveData.alignType"
                type="align"
              />
            </div>
            <div
              v-for="(objectiveObj, objIndex) in objList"
              :key="`${JSON.stringify(objectiveObj)}`"
            >
              <div class="okr-title">
                <div class="okr-title-left">
                  <div class="okr-check-wrap" v-if="aiStep === 1">
                    <el-checkbox v-model="objectiveObj.check" />
                  </div>
                  <div class="okr-title-num">
                    目标 O{{objIndex + 1}}
                    <!-- <span v-if="aiStep === 1">({{setDataNewInit.dimension}}维度)</span> -->
                  </div>
                </div>
                <div class="okr-title-right" v-if="aiStep === 2">
                  <div
                    @click="objectiveObj.krCheckLength === 0 ? '' : sumbitCheckedKr(objIndex)"
                    :class="`kr-btn ${objectiveObj.krCheckLength === 0 ? 'disabled' : ''}`"
                  >
                    <Icon icon-class="icon-ai" class="icon" />重新生成KR
                  </div>
                  <div class="okr-delete" @click="okrDelete(objIndex)">
                    <Icon icon-class="icon-delete-icon" />
                  </div>
                </div>
              </div>
              <div class="okr-title-wrap">
                <el-tooltip placement="top" :open-delay="300" content="点击编辑">
                  <div
                    class="title"
                    v-if="objectiveObj.okrTitleEdit === false"
                    @click="okrTitleClick(true, objIndex)"
                    v-html="$utils.changeToHtml(objectiveObj.content)"
                  ></div>
                </el-tooltip>
                <div class="okr-title-edit" v-if="objectiveObj.okrTitleEdit !== false">
                  <at-input
                    ref="myatinput"
                    limit="150"
                    :isBgColor="false"
                    :html="$utils.changeToHtml(objectiveObj.content)"
                    :editable="true"
                    :enter-able="true"
                    @blur="changeObjTitle($event, objIndex)"
                  />
                </div>
              </div>
              <div
                class="kr-list-wrap"
                v-vueloading="aiStep === 2 && objectiveObj.loading"
                v-if="aiStep === 2"
              >
                <draggable
                  v-model="objectiveObj.keyresultsList"
                  handle=".key_result_dragger_icon_box"
                  @update="drop"
                >
                  <div v-for="(item, index) in objectiveObj.keyresultsList" :key="item.id">
                    <div class="okr-kr-item">
                      <!-- :align-member="true" -->
                      <key-results-list
                        :key-results-li="item"
                        :target-object="objectiveObj"
                        :key-results-li-number="index"
                        :show-border="true"
                        :align-member="true"
                        :page-type="`addOkrAiList`"
                        :fromType="`addOkrAiList`"
                        :editState="true"
                        @getTargetDetailsKeywordDetele="getTargetDetailsKeywordDetele(index, objIndex)"
                        @getKeyResultsListTitles="getKeyResultsListTitles(index, objIndex)"
                        @changeObjectiveTitle="changeObjectiveTitle(index, objIndex)"
                        @getKeyResultsListWeights="getKeyResultsListWeights(index, objIndex)"
                        @getKeyHandleChange="getKeyHandleChange($event, objIndex)"
                        @getKeyHandleChangeBlur="getKeyHandleChangeBlur($event, $event, objIndex)"
                        @saveContent="saveContent($event, index, objIndex)"
                      >
                        <div class="okr-check-wrap" slot="slotLeft">
                          <el-checkbox
                            v-model="item.check"
                            @change="getCheckedKr(objIndex, 'length')"
                          />
                        </div>
                      </key-results-list>
                    </div>
                  </div>
                </draggable>
                <div class="add-kr-wrap" v-if="aiStep === 2">
                  <key-results-add
                    v-if="objectiveObj.addkeyState === false"
                    :no-request="true"
                    :okrDataObj="objectiveObj"
                    :isAiAdd="krAddType"
                    :fromType="`addOkrAiList`"
                    :fileList="setDataNewInit.fileList"
                    :sId="sId"
                    @saveContent="saveContentAdd($event, objIndex)"
                    @cancel="cancel(objIndex)"
                  />
                  <div class="btn-list-wrap" @click="addKeyClick(objIndex)">
                    <key-results
                      v-if="objectiveObj.addkeyState && objectiveObj.keyresultsList.length < 10"
                      :top-members-bean="objectiveObj"
                      @getAddkeyList="getAddkeyList"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="okr-content-detail" v-if="objList.length === 0 && !addOkrLoading">
            <no-data
              text="源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR,快去试试吧！"
              :imgUrl="require('@/assets/imgs/common/kpiNull.png')"
            />
          </div>
          <div
            class="okr-content-detail add-loading-wrap"
            v-if="addOkrLoading || submitKrLoading || alignObj"
          >
            <!-- <no-data
              text="源目标AI助手生成信息中，请稍作等待哦！"
              :imgUrl="require('@/assets/imgs/common/page-loading.gif')"
            />-->
            <div class="step-list-wrap">
              <div
                :class="`step-list ${index > stepLoading}`"
                v-for="(item, index) in addOkrLoading ? stepTitleListO : stepTitleListKr"
                :key="`${item}`"
                v-if="index <= stepLoading"
              >
                <i class="el-icon-loading icon" v-if="stepLoading === index"></i>
                <Icon
                  icon-class="icon-a-daibanyiwancheng1"
                  :class="`icon ${index > stepLoading}`"
                  v-else
                />
                <div class="title">{{item}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-step-buttons">
          <el-button @click="handleClose" class="left-button" v-if="aiStep === 2">取消</el-button>
          <el-button
            :disabled="objList.length === 0 || submitKrLoading"
            v-preventReClick
            :loading="submitKrLoading"
            @click="saveBtnKr()"
            v-if="aiStep === 1 && objList.length > 0"
          >生成关键结果 KR</el-button>
          <el-button
            :disabled="objList.length === 0 || submitOkrLoading"
            v-preventReClick
            :loading="submitOkrLoading"
            @click="refreshObj()"
            class="left-button"
            v-if="aiStep === 2"
          >重新编辑目标 O</el-button>
          <el-button
            :disabled="objList.length === 0 || submitOkrLoading || submitKrLoading"
            v-preventReClick
            :loading="submitOkrLoading"
            @click="saveBtn(1)"
            v-if="aiStep === 2"
          >一键生成OKR</el-button>
        </div>
      </div>
    </div>
    <ai-set
      v-if="aiSetvisible"
      v-model="aiSetvisible"
      @submit="submitSet"
      :data="JSON.parse(JSON.stringify(setData))"
    />
    <custom-dialog
      v-if="customDialogvisible"
      v-model="customDialogvisible"
      type="kr"
      @submit="customDialogSubmit"
    />
  </el-dialog>
  <!-- </el-drawer> -->
</template>
<script>
import draggable from 'vuedraggable'
import keyResultsList from '@/views/okr/components/singleOkrItem/keyResultsList.vue'
import aiSet from '@/views/okr/dialog/addTarget/addOkrAi/aiSet'
import labelSelect from '@/views/okr/components/LabelTypeSelect'
import keyResults from '@/views/okr/components/singleOkrItem/keyResults.vue'
import keyResultsAdd from '@/views/okr/components/singleOkrItem/keyResultsAdd.vue'
import customDialog from '@/views/okr/table/addTaskAi/customDialog'
import objectiveShow from '@/views/okr/components/objectiveShow.vue'
import uploadFileList from '@/views/conclusion/components/FieldItem/File/uploadFileList.vue' // 评论动态展示
import debounce from 'lodash/debounce' // 局部引入防抖
export default {
  components: {
    draggable,
    keyResultsList,
    aiSet,
    labelSelect,
    keyResults,
    keyResultsAdd,
    customDialog,
    objectiveShow,
    uploadFileList
  },
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean
    },
    alignObjectiveData: {}
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      jobTitle: '',
      content: '',
      aiSetvisible: false,
      setData: '',
      addOkrLoading: false,
      submitOkrLoading: false,
      submitKrLoading: false,
      objList: [],
      objListInit: [],
      aiStep: 1,
      setDataNew: {
        userRole: '',
        cycle: '不限',
        type: 0, // 标签类型
        dimension: '不限',
        fileList: []
      },
      setDataNewInit: '',
      cycleList: ['年度','季度', '月度', '不限'],
      labelObj: {
        2: '公司',
        1: '部门',
        3: '团队',
        0: '个人'
      },
      dimensionList: ['不限', '财务', '客户', '管理'],
      objRebuild: 0,
      krRebuild: 0,
      alignObj: false,
      krAddType: false,
      editObjIndex: 0,
      majorCycle: '', // 大周期
      minorCycle: '', // 小周期
      customDialogvisible: false,
      stepLoading: 0,
      stepTitleListO: ['小源AI正在分析您的问题.....', 'AI模型正在匹配最佳信息.....', '您的OKR正在生成中.....', '请稍候，您的OKR即将出炉...'],
      stepTitleListKr: ['小源AI正在分析您的问题.....', 'AI模型正在匹配最佳信息.....', '您的KR正在生成中.....', '请稍候，您的KR即将出炉...'],
      modeFile: false,
      fileList: [],
      length: 0,
      fileTotalSize: 0, //文件总大小
      fileListUpload: [],
      sId: 0
    }
  },
  computed: {},
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
        if (val) {
          this.addOkrLoading = false
          this.submitOkrLoading = false
          this.content = ''
          this.objList = []
          // var localData = localStorage.getItem('aiSetData')
          // if (localData) {
          //   this.setData = JSON.parse(localData)
          // } else {
          //   this.getAiSetData()
          // }
          if (this.userInfo.isPrincipal === 1 || this.userInfo.isSuperAdmin === 1 || this.userInfo.isSuperior === 1) {
            this.setDataNew.type = 1
          }
          if (this.userInfo.jobTitle === 'CEO' || this.userInfo.jobTitle === '总经理') {
            this.setDataNew.type = 2
          }
          this.getAiSetData()
        }
      },
      immediate: true
    },
    alignObjectiveData: {
      handler(val) {
        if (JSON.stringify(this.alignObjectiveData) !== '{}') { // 邀请对齐
          this.alignObj = true
          this.setLoadingInit('O')
          var list = {
            content: this.alignObjectiveData.content
          }
          list.userRoleType = 0
          list.childObjectivesAssociationUserList = []
          list.objectivesCollaboratorList = []
          list.cycleId = this.majorCycle || this.$store.state.common.cycle.quarterId
          list.cycleInfoId = this.minorCycle || this.$store.state.common.cycle.periodId
          list.isShare = 0
          list.labelType = 400
          list.processState = 0
          list.keyresultsList = []
          list.okrTitleEdit = false
          list.check = true
          list.addkeyState = true
          list.dimension = this.setDataNew.dimension
          this.objList = [list]
          this.objListInit = JSON.parse(JSON.stringify([list]))
          this.submitKrLoading = true
          this.setLoadingInit('KR')
          this.objList.forEach((item, index) => {
            this.getKrContent(item, index)
          })
        }
      },
      immediate: true
    }
  },
  created() {
    this.minorCycle = this.$store.state.common.cycle.periodId
    this.majorCycle = this.$store.state.common.cycle.quarterId
  },
  methods: {
    onChange(file, fileList) {
      // 判断上传文件数量
      this.length = fileList.length
      this.fileList = fileList
      return false
    },
    fileDelete(list) {
      this.fileListUpload = list
    },
    onExceed() {
      this.$message.warn('文件总数不能超出4个哦！')
    },
    upLoadFile: debounce(function () {
      console.log('upLoadFile', this.fileList)
      let formData = new FormData()
      if (this.fileList.length > 0) {
        this.fileList.forEach((item) => {
          if (Number(item.size) > 4194304) {
            this.$message.warn('单个文件大小不能超出4M哦！')
            this.$refs.upload.clearFiles() //上传成功之后清除历史记录
            this.fileList = []
            return
          }
          this.fileTotalSize = Number(this.fileTotalSize) + Number(item.size)
        })
        console.log(
          '文件总大小---',
          this.fileList,
          this.fileTotalSize,
          this.$utils.formatFileSize(this.fileTotalSize)
        )
        // 超出50M
        if (this.fileTotalSize > 52428800) {
          this.$message.warn(`上传文件大小不能超出50M哦！`)
          this.$refs.upload.clearFiles() //上传成功之后清除历史记录
          this.fileList = []
          this.fileTotalSize = 0
          return
        }
        this.fileList.forEach((file) => {
          formData.append('file', file.raw)
        })
        if (
          formData.getAll('file').length == this.length &&
          this.length > 0
        ) {
          console.log('dddddd')
          this.$apis.AIUPLOADDOCUMENTS(formData).then((res) => {
            if (res.status === 200) {
              this.fileList = []
              this.$refs.upload.clearFiles() // 上传成功之后清除历史记录
              this.$message.success(res.message || '上传成功')
              this.fileListUpload = this.fileListUpload.concat(res.data.fileList)
              this.sId = res.data.sId
            } else {
              this.$message.error(res.message)
            }
          })
        }
      }
    }, 200),
    modeClick() {
      this.modeFile = !this.modeFile
      this.fileList = []
      this.fileListUpload = []
      this.sId = 0
    },
    setLoadingInit(type) {
      this.stepLoading = 0
      if (type === 'O') {
        setTimeout(() => {
          this.stepLoading = 1
        }, 1000)
        setTimeout(() => {
          this.stepLoading = 2
        }, 2000)
        setTimeout(() => {
          this.stepLoading = 3
        }, 4000)
      } else {
        setTimeout(() => {
          this.stepLoading = 1
        }, 2000)
        setTimeout(() => {
          this.stepLoading = 2
        }, 4000)
        setTimeout(() => {
          this.stepLoading = 3
        }, 6000)
      }
    },
    customDialogSubmit(requirement) {
      this.objList[this.editObjIndex].loading = true
      var list = []
      this.objList[this.editObjIndex].keyresultsList.forEach((item, index) => {
        if (!item.check) {
          list.push(item)
        }
      })
      this.objList[this.editObjIndex].keyresultsList = list
      this.getKrContent(this.objList[this.editObjIndex], this.editObjIndex, this.objList[this.editObjIndex].krCheckLength, requirement, 'refresh')
      this.objList[this.editObjIndex].krCheckLength = 0
    },
    sumbitCheckedKr(objIndex) {
      this.editObjIndex = objIndex
      this.customDialogvisible = true
    },
    getCheckedKr(objIndex, type) {
      var keyResultsList = this.objList[objIndex].keyresultsList || []
      var list = []
      keyResultsList.forEach((item, index) => {
        if (item.check) {
          list.push(list)
        }
      })
      this.objList[objIndex].krCheckLength = list.length
      return type === 'length' ? list.length : list
    },
    // 获取大小周期
    setMinorCycle(val) {
      this.minorCycle = val.minorCycle
      this.majorCycle = val.majorCycle
      this.setDataNew.cycle = val.minorCycleName
    },
    addKeyClick(objIndex) {
      console.log('addKeyClick', objIndex)
      this.editObjIndex = objIndex
    },
    // 点击新增关键结构按钮
    getAddkeyList(state, addType) {
      setTimeout(() => {
        this.krAddType = addType
        if (state === '编辑') {
          this.objList[this.editObjIndex].addkeyState = false
          for (let i = 0; i < this.objList[this.editObjIndex].keyResultsList.length; i++) {
            this.objList[this.editObjIndex].keyResultsList[i].titleStates = true
            this.objList[this.editObjIndex].keyResultsList[i].weightStates = true
            this.objList[this.editObjIndex].keyresultsList[i].scoreStates = true
          }
        } else {
          this.objList[this.editObjIndex].addkeyState = true
        }
        this.objList = JSON.parse(JSON.stringify(this.objList))
      }, 10)
    },
    // 保存关键结果
    saveContentAdd(data, objIndex) {
      if (data.obj.html.trim() === '') {
        this.$message({
          msg: '关键结果不能为空！',
          type: 'warn'
        })
        return
      }
      var list = {}
      list.title = data.obj.html
      list.collaboratorStr = data.obj.atIds
      list.quantificationType = data.unitForm.quantificationType
      list.quantificationStart = data.unitForm.quantificationStart
      list.quantificationEnd = data.unitForm.quantificationEnd
      list.quantificationUnit = data.unitForm.quantificationUnit
      list.weight = 0
      list.cycleInfoId = this.minorCycle,
      list.titleStates = true
      list.weightStates = true
      list.scoreStates = true
      list.comment = data.obj.html
      list.comment2 = data.obj.html
      list.objectivesRuleMatchDto = []
      list.collaboratorStr = []
      list.ignoreObjectivesRuleMatchDtoList = []
      this.objList[objIndex].keyresultsList.push(list)
      this.objList[objIndex].addkeyState = true
      this.objList = JSON.parse(JSON.stringify(this.objList))
    },
    cancel(objIndex) {
      this.getAddkeyList('', false)
    },
    okrDelete(index) {
      if (this.objList.length <= 1) {
        this.$message.warn('至少保留一个目标哦！')
        return
      }
      this.objList.splice(index, 1)
    },
    refreshObj() {
      this.objList = JSON.parse(JSON.stringify(this.objListInit))
      this.aiStep = 1
    },
    saveBtnKr() {
      var list = []
      this.objList.forEach((item) => {
        if (item.check) {
          list.push(item)
        }
      })
      if (list.length === 0) {
        this.$message.warn('请选择目标哦！')
        return
      } else {
        this.objList = list
        this.submitKrLoading = true
        this.setLoadingInit('KR')
        this.objList.forEach((item, index) => {
          this.getKrContent(item, index)
        })
      }
    },
    async getKrContent(item, index, count = 3, requirement = '', type) {
      const beans = {
        companyId: this.companyInfo.id,
        userScale: this.setData.scopeName || '11-50人',
        industry: this.setData.industryChildrenName || '软件和企业服务',
        userRole: this.setData.jobTitle || '',
        departUserCount: this.setData.departUserNum || 0,
        content: item.content,
        count,
        kr: '',
        rebuild: this.krRebuild,
        requirement,
        fileList: this.setDataNewInit.fileList,
        sId: this.sId
      }
      this.$apis.AICREATEKR(beans)
        .then((rest) => {
          if (rest.status === 200) {
            if (rest.data && rest.data.keyresultsList) {
              rest.data.keyresultsList.forEach((krContent) => {
                krContent.comment = rest.data.title
                krContent.html = rest.data.title
                krContent.atIds = []
                krContent.objectivesRuleMatchDto = []
                krContent.collaboratorStr = []
                krContent.ignoreObjectivesRuleMatchDtoList = []
                krContent.check = false
              })
              var weight = 100
              if (type === 'refresh') {
                this.objList[index].keyresultsList.forEach((item) => {
                  weight = weight - item.weight
                })
              }
              this.objList[index].keyresultsList = this.objList[index].keyresultsList.concat(this.keyresultsListHandle(rest.data.keyresultsList, weight))
            }
          } else {
            this.$message.error(rest.message || '生成KR失败哦！')
          }
          if (index === this.objList.length - 1) {
            this.submitKrLoading = false
            this.objList = JSON.parse(JSON.stringify(this.objList))
            this.aiStep = 2
            this.krRebuild = 1
          }
          if (this.companyInfo.aiUsageCount > rest.data.aiUsageCount) {
            this.companyInfo.aiUsageCount = rest.data.aiUsageCount
            this.$store.commit('user/setCompanyInfo', this.companyInfo)
          }
          this.alignObj = false
          this.objList[index].loading = false
        })
    },
    aiSetClick(state) {
      this.aiSetvisible = state
    },
    submitSet(data) {
      this.setData = data
      localStorage.setItem('aiSetData', JSON.stringify(data))
      this.aiSetClick(false)
    },
    getAiSetData() {
      this.$apis
        .USERAIINFO()
        .then((rest) => {
          if (rest.status === 200) {
            rest.data.jobTitle = rest.data.jobTitle || ''
            this.setData = rest.data
            localStorage.setItem('aiSetData', JSON.stringify(rest.data))
            // this.aiSetClick(true)
          } else {
            this.$message.error('获取AI初始设置异常哦！')
          }
        })
    },
    saveContent(data, index, objIndex) {
      this.objList[objIndex].keyresultsList[index].title = data.obj.html
      this.objList[objIndex].keyresultsList[index].collaboratorStr = data.obj.atIds
      this.objList[objIndex].keyresultsList[index].quantificationType = data.unitForm.quantificationType
      this.objList[objIndex].keyresultsList[index].quantificationStart = data.unitForm.quantificationStart
      this.objList[objIndex].keyresultsList[index].quantificationEnd = data.unitForm.quantificationEnd
      this.objList[objIndex].keyresultsList[index].quantificationUnit = data.unitForm.quantificationUnit
      this.changeObjectiveTitle(index, objIndex)
    },
    // 处理关键结果内容保存展现
    changeObjectiveTitle(index, objIndex) {
      this.objList[objIndex].keyresultsList[index].titleStates = true
      this.objList[objIndex].keyresultsList = JSON.parse(JSON.stringify(this.objList[objIndex].keyresultsList))
    },
    // 处理编辑关键结果名称
    getKeyResultsListTitles(indexs, objIndex) {
      const lists = this.objList[objIndex].keyresultsList
      const state = '暂无'
      for (let i = 0; i < lists.length; i++) {
        lists[i].titleStates = true
        lists[i].weightStates = true
        lists[i].scoreStates = true
        lists[i].addkeyState = true
      }
      if (state === '关键结果名称') {
        return
      }
      if (state === '关键结果权重') {
        return
      }
      this.draggableStatus = false
      lists[indexs].titleStates = false
      lists[indexs].comment = lists[indexs].title
      lists[indexs].comment2 = lists[indexs].title
      this.$nextTick(() => {
        this.objList[objIndex].keyresultsList = JSON.parse(JSON.stringify(lists))
      })
    },
    // 处理权重上下级
    getKeyHandleChange(index, objIndex) {
      const dataBeans = this.objList[objIndex].keyresultsList
      let numbers = 100
      for (let i = 0; i < dataBeans.length; i++) {
        if (i !== index && dataBeans.length - 1 !== i) {
          numbers -= dataBeans[i].weight
        }
      }
      const content = this.objList[objIndex].keyresultsList[index].weight
      this.objList[objIndex].keyresultsList[index].weight = content.replace(/\D/g, '')

      if (this.objList[objIndex].keyresultsList[index].weight > numbers) {
        this.$nextTick(() => {
          this.objList[objIndex].keyresultsList[index].weight = parseInt(numbers)
          this.objList[objIndex].keyresultsList[this.objList[objIndex].keyresultsList.length - 1].weight = 0
        })
      } else {
        this.$nextTick(() => {
          this.objList[objIndex].keyresultsList[this.objList[objIndex].keyresultsList.length - 1].weight =
            numbers - this.objList[objIndex].keyresultsList[index].weight
        })
      }
    },
    // 处理权重移除保存值
    async getKeyHandleChangeBlur(index, noChange, objIndex) {
      console.log('getKeyHandleChangeBlur', index, noChange, objIndex)
      this.objList[objIndex].keyresultsList[index].weightStates = true
      this.objList = JSON.parse(JSON.stringify(this.objList))
    },
    // 处理权重方法
    getKeyResultsListWeights(index, objIndex) {
      if (this.objList[objIndex].keyresultsList.length - 1 !== index) {
        const lists = this.objList[objIndex].keyresultsList
        let state = '暂无'
        for (let i = 0; i < lists.length; i++) {
          if (lists[i].titleStates === false) {
            state = '关键结果名称'
            break
          }
          if (lists[i].weightStates === false) {
            state = '关键结果权重'
            break
          }
        }
        if (state === '关键结果名称') {
          return
        }
        if (state === '关键结果权重') {
          return
        }
        lists[index].weightStates = false
        this.$nextTick(() => {
          this.objList[objIndex].keyresultsList = lists
          this.objList = JSON.parse(JSON.stringify(this.objList))
        })
      } else {
        this.$message({
          msg: '最后一个关键结果需要调整前面的进行编辑',
          type: 'warn'
        })
      }
    },
    getTargetDetailsKeywordDetele(index, objIndex) {
      var weight = parseInt(this.objList[objIndex].keyresultsList[index].weight)
      this.objList[objIndex].keyresultsList.splice(index, 1)
      this.objList[objIndex].keyresultsList[this.objList[objIndex].keyresultsList.length - 1].weight = parseInt(this.objList[objIndex].keyresultsList[this.objList[objIndex].keyresultsList.length - 1].weight) + weight
    },
    changeObjTitle($event, index) {
      this.objList[index].content = $event.html
      this.objList[index].collaboratorStr = $event.atIds.toString()
      this.okrTitleClick(false, index)
    },
    okrTitleClick(state, index) {
      this.objList[index].okrTitleEdit = state
    },
    // 关键结果处理
    keyresultsListHandle(data, initWeight) {
      var keyResultsList = []
      var weight = (initWeight / data.length).toFixed(0)
      var lastWeight = initWeight - weight * (data.length - 1)
      data.forEach((item, index) => {
        item.userRoleType = 0
        const obj = Object.assign(item, {
          cycleInfoId: this.minorCycle,
          titleStates: true,
          weightStates: true,
          scoreStates: true,
          comment: item.title,
          comment2: item.title,
          weight: index === data.length - 1 ? lastWeight : weight,
          objectivesRuleMatchDto: [],
          collaboratorStr: [],
          ignoreObjectivesRuleMatchDtoList: []
        })
        keyResultsList.push(obj)
      })
      return keyResultsList
    },
    saveBtn(type) {
      if (type === 1) {
        this.submitOkrLoading = true
        var objectivesAssociationList = []
        if (JSON.stringify(this.alignObjectiveData) !== '{}') {
          objectivesAssociationList.push({
            beAlignedObjId: this.alignObjectiveData.id,
            businessType: this.alignObjectiveData.alignType === 'O' ? 0 : 1,
            businessId: this.alignObjectiveData.businessId
          })
        }
        this.objList.forEach((objectiveObj, index) => {
          var dataBeans = objectiveObj
          dataBeans.content = this.$utils.changeToOkrtFormat(objectiveObj.content).replace(/&amp;/g, '&') // 目标内容
          dataBeans.contentSearch = this.$utils.tagFilter(objectiveObj.content) // 目标内容不包含标签
          dataBeans.keyresultsList.forEach((item) => {
            item.title = this.$utils.changeToOkrtFormat(item.title).replace(/&amp;/g, '&')
            item.titleSearch = this.$utils.tagFilter(item.title)
            item.collaboratorStr = item.collaboratorStr.toString()
          })
          dataBeans.objectivesAssociationList = objectivesAssociationList
          console.log('dataBeans', dataBeans)
          this.$apis.OKRADD(dataBeans).then((rest) => {
            if (rest.status === 200) {
              if (index === this.objList.length - 1) {
                this.$message({
                  msg: '添加目标成功',
                  type: 'success'
                })
              }
              this.$emit('submit', dataBeans)
            } else {
              this.$message({
                msg: rest.message,
                type: 'error'
              })
            }
            if (index === this.objList.length - 1) {
              this.submitOkrLoading = false
            }
          }).catch(() => {
            this.submitOkrLoading = false
          })
        })
      } else {
        if (this.content.trim() === '') {
          this.$message.warn('目标方向内容不能为空哦！')
          return
        }
        this.addOkrLoading = true
        this.setLoadingInit('O')
        this.objList = []
        this.objListInit = []
        var params = {
          companyId: this.companyInfo.id,
          userScale: this.setData.scopeName || '11-50人',
          industry: this.setData.industryChildrenName || '软件和企业服务',
          userRole: this.setData.jobTitle || '',
          departUserCount: this.setData.departUserNum || 0,
          companyAge: this.companyInfo.establishmentDate || '1年',
          dimension: this.setDataNew.dimension,
          direction: this.content,
          cycle: this.setDataNew.cycle,
          type: this.labelObj[this.setDataNew.type],
          // countStart: 3,
          // countEnd: 3,
          count: 3,
          content: this.content,
          rebuild: this.objRebuild,
          fileList: this.fileListUpload,
          sId: this.sId
        }
        this.$apis.AICREATEOBJ(params).then((rest) => {
          if (rest.status === 200) {
            if (rest.data.objectives) {
              var list = rest.data.objectives
              list.forEach((item) => {
                item.userRoleType = 0
                item.childObjectivesAssociationUserList = []
                item.objectivesCollaboratorList = []
                item.cycleId = this.majorCycle
                item.cycleInfoId = this.minorCycle
                item.isShare = 0
                item.labelType = this.setDataNew.type
                item.processState = 0
                item.keyresultsList = []
                item.okrTitleEdit = false
                item.check = false
                item.addkeyState = true
                item.krCheckLength = 0
              })
              this.objList = JSON.parse(JSON.stringify(list))
              this.objListInit = JSON.parse(JSON.stringify(list))
              this.setDataNewInit = JSON.parse(JSON.stringify(this.setDataNew))
              this.setDataNewInit.fileList = this.fileListUpload
            }
          } else {
            this.$message.error(rest.message)
          }
          this.addOkrLoading = false
          this.aiStep = 1
          this.objRebuild = 1
          this.companyInfo.aiUsageCount = rest.data.aiUsageCount
          this.$store.commit('user/setCompanyInfo', this.companyInfo)
        })
      }
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.step-list-wrap {
  width: 260px;
  margin: 0px auto;
  text-align: center;
  .step-list {
    .flex-l();
    text-align: left;
    margin: 40px 0px;
    position: relative;
    color: #434458;
    font-size: 16px;
    .icon {
      color: #3a70ff;
      margin-right: 16px;
      font-size: 24px;
      width: 24px;
      height: 24px;
    }
    .icon.true {
      color: #a0a3bd;
    }
    &::before {
      content: '';
      width: 1px;
      height: 28px;
      background: #3a70ff;
      position: absolute;
      top: 32px;
      left: 12px;
      right: 0px;
    }
  }
  .step-list.true {
    color: #a0a3bd;
    &::before {
      background: #a0a3bd;
    }
  }
  .step-list:last-child {
    &::before {
      display: none;
    }
  }
}
.okr-check-wrap {
  margin-right: 12px;
}
.key_results_li_left_box .okr-check-wrap {
  margin-top: 9px;
}
.kr-btn {
  color: #3296fa;
  cursor: pointer;
  .flex-l();
  // font-weight: bold;
  .icon {
    font-size: 20px;
    margin-right: 8px;
  }
  &:hover {
    color: #005eff;
  }
}
.kr-btn.disabled {
  color: #a0a3bd;
  cursor: not-allowed;
}
.btn-list-wrap {
  .flex-l();
}
.ai-count-wrap {
  position: absolute;
  right: 64px;
  font-size: 12px;
  top: 34px;
  line-height: 28px;
  color: #a0a3bd;
  span {
    color: #ed2e7e;
  }
}
::v-deep {
  .okr-type-list {
    margin-right: 16px;
    .icon-wrap {
      margin: 0px 6px 0px 0px !important;
    }
  }
  .select {
    flex: 1;
  }
  .cycle {
    .flex-l();
    margin-bottom: 0px !important;
    .label {
      display: none !important;
    }
    .one {
      width: calc(100%) !important;
      margin-left: 0px !important;
    }
    .one:nth-child(1) {
      width: 0px !important;
      visibility: hidden;
    }
    .el-select {
      width: 100%;
      height: 40px;
    }
  }
  .el-radio {
    margin-right: 16px !important;
  }
  .no-data-show-text {
    margin-top: 24px;
  }
  .key_results_li::after {
    opacity: 0;
  }
  .key_results_li_right_box {
    display: flex;
    justify-content: space-between;
  }
  .key_results_li_ul {
    width: 80px !important;
    margin: 0px !important;
  }
  .el-dialog__header {
    display: block !important;
    padding: 32px 32px 0 32px !important;
  }
  .add-okr-dialog-wrap .el-dialog__body {
    padding: 0 32px 0px 32px !important;
  }
  // .el-drawer {
  //   background-image: url('../../../../../assets/imgs/okr/bg.png');
  //   background-position: left center;
  //   background-repeat: no-repeat;
  //   background-size: 100% 100%;
  // }
  .el-textarea {
    border: none !important;
  }
  .el-textarea,
  .el-textarea__inner {
    // border: none !important;
    // resize: none !important;
    background: none !important;
    height: 100%;
  }
  .el-textarea__inner {
    border: 1px solid #e7e8ef !important;
    border-radius: 8px;
    &::placeholder {
      font-size: 14px;
    }
  }
}
.add-loading-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ::v-deep {
    .no-data-show .no-data-show-icon {
      width: 60px;
    }
  }
}
.add-content-wrap {
  position: relative;
  .flex-bt();
  height: calc(100vh - 200px);
  align-items: flex-start;
  .set-btn {
    cursor: pointer;
    color: #005eff;
    position: absolute;
    top: -23px;
    z-index: 9999;
    right: 40px;
    &:hover {
      opacity: 0.54;
    }
  }
  .add-content-left {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 420px;
    margin-right: 16px;
    label {
      font-size: 14px;
      line-height: 40px;
      color: #14142b;
      font-weight: bold;
      min-width: 65px;
      text-align: right;
      margin-right: 24px;
    }
    .list-wrap {
      .flex-l();
      padding: 0px 0px 16px 0px;
      .el-select-left {
        margin-left: 16px;
      }
      ::v-deep {
        .el-select {
          flex: 1;
        }
      }
    }
    .upload-demo {
      margin: 16px 0px;
      width: 100%;
      ::v-deep {
        .el-upload-dragger {
          width: 100%;
        }
      }
    }
    .upload-wrap {
      .file-title {
        .flex-l();
        padding: 16px 8px;
        border-radius: 8px;
        background: rgba(255, 153, 0, 0.08);
        color: rgb(255, 153, 0);
        align-items: flex-start;
        font-size: 12px;

        .icon {
          font-size: 16px;
          margin-right: 4px;
          min-width: 18px;
        }
        .title {
          line-height: 1.5;
        }
      }
      .upload-btn-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;
        .icon {
          font-size: 36px;
          color: #a0a3bd;
        }
        .title {
          color: #a0a3bd;
          font-size: 12px;
          line-height: 1.5;
          padding: 0px 16px;
          text-align: left;
        }
      }
      .bottom-step-buttons {
        margin: 16px 0px;
        display: block;
        button {
          padding: 0px 16px;
        }
      }
    }
    .bottom-step-wrap {
      .flex-bt();
      .mode-wrap {
        cursor: pointer;
        color: #005eff;
        margin-right: 8px;
        .flex-l();
        .icon {
          font-size: 16px;
          margin-right: 4px;
        }
        &:hover {
          opacity: 0.54;
        }
      }
      .bottom-step-buttons {
        flex: 1;
      }
    }
  }
  .add-content-right {
    flex: 1;

    padding: 24px 24px 0px 24px;
    background: rgba(245, 246, 250, 0.45);
    border-radius: 12px;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    .okr-list-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: calc(100% - 80px);
      .preiew-title {
        font-size: 24px;
        color: #14142b;
        font-weight: 700;
        display: flex;
        line-height: 30px;
        span {
          font-size: 12px;
          font-weight: normal;
          color: #a0a3bd;
        }
      }
      .okr-content-detail {
        flex: 1;
        overflow-y: auto;
        .okr-title {
          margin: 16px 0px 8px 0px;
          min-height: 40px;
          .flex-bt();
          // align-items: flex-start;

          .okr-title-num {
            // min-width: 32px;
            // height: 32px;
            // border-radius: 100% 100%;
            // background: #548af7;
            // color: #fff;
            // font-weight: bold;
            // line-height: 32px;
            // text-align: center;
            // margin-right: 16px;
            // margin-top: 4px;
            font-size: 18px;
            font-weight: 700;
            text-align: left;
            color: #14142b;
          }
          .okr-title-left {
            .flex-l();
          }
          .okr-title-right {
            .flex-l();
            justify-content: flex-end;
          }
          .okr-delete {
            color: #ed2e7e;
            cursor: pointer;
            font-size: 18px;
            margin-left: 16px;
            &:hover {
              opacity: 0.56;
            }
          }
        }
        .okr-title-wrap {
          flex: 1;
          position: relative;
          .title {
            padding: 8px;
            font-size: 16px;
            // font-weight: 700;
            text-align: left;
            line-height: 23px;
            border: 1px solid rgba(255, 255, 255, 0);
            color: #14142b;
          }
          ::v-deep .at-container {
            border: 1px solid #3a78f6;
            line-height: 23px;
            height: 100%;
            font-size: 16px;
            // font-weight: 700;
            color: #14142b;
            border-radius: 8px;
          }
        }
      }
    }
  }
  .ai-title {
    color: #a0a3bd;
    font-size: 12px;
    margin: 24px 0px 16px 0px;
    line-height: 1.5;
  }
  .preiew-title-wrap {
    .ai-title {
      margin: 4px 0px 16px 0px;
    }
  }
  .content-input-wrap {
    flex: 1;
    overflow-y: auto;
    // padding: 0px 24px;
    // box-shadow: 0px -5px 16px rgba(212, 213, 224, 0.2);
  }
  .bottom-step-buttons {
    margin: 24px 0px;
    padding: 0px 32px;
    .flex-l();
    justify-content: center;
    .left-button,
    .el-button {
      border-radius: 8px;
      height: 32px;
    }
    .left-button {
      background: none !important;
    }
  }
}
::v-deep {
  .el-drawer__header {
    border-bottom: 1px solid #fff;
  }
  .el-drawer {
    border-top-left-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
  }
}
</style>
