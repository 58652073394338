var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      ref: "popRef",
      attrs: {
        placement: _vm.placement,
        width: "280",
        trigger: "manual",
        "popper-class": _vm.animateState
          ? "guide-popover animate-style-" + _vm.placement
          : "guide-popover",
        disabled: _vm.disabled,
      },
    },
    [
      _c("div", { staticClass: "guide-pop-box" }, [
        _vm.animateState
          ? _c("p", { staticClass: "pop-box-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ])
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.innerText) + " "),
        _c(
          "div",
          { staticClass: "guide-pop-btn", on: { click: _vm.hidePopView } },
          [_vm._v("我知道了")]
        ),
      ]),
      _vm._t("guidepop", null, { slot: "reference" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }