var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dynamic-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "list-container",
      },
      [
        _vm.source == "stopdoing"
          ? _c("div", { staticClass: "dynamic-menu-wrap" }, [
              _c("div", { staticClass: "stopdoing-dynamic-title" }, [
                _vm._v("评论"),
              ]),
              _c("div", { staticClass: "stopdoing-dynamic-menu" }, [
                _vm.topList.length > 2 || _vm.nextCount > 0
                  ? _c("div", [
                      _vm.nextCount > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "dynamic-menu",
                              on: { click: _vm.showOld },
                            },
                            [
                              _c("Icon", {
                                staticClass: "menu-icon",
                                attrs: { "icon-class": "icon-more-row" },
                              }),
                              [
                                _vm._v(
                                  "显示较早的 " +
                                    _vm._s(_vm.nextCount) +
                                    " 条动态"
                                ),
                              ],
                            ],
                            2
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "dynamic-menu",
                              on: { click: _vm.showOld },
                            },
                            [
                              _c("Icon", {
                                staticClass: "menu-icon",
                                attrs: { "icon-class": "icon-more-row" },
                              }),
                              [_vm._v("隐藏较早的动态")],
                            ],
                            2
                          ),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _c("div", { staticClass: "dynamic-menu-wrap" }, [
              _c("div", { staticClass: "dynamic-type-wrap" }, [
                _c(
                  "div",
                  {
                    class:
                      "dynamic-type " + (_vm.tabType === 1 ? "select" : ""),
                    on: {
                      click: function ($event) {
                        return _vm.tabTypeClick(1)
                      },
                    },
                  },
                  [
                    _c("sing-dropdown", {
                      attrs: {
                        options: _vm.commentTypeEnum,
                        all: false,
                        text: _vm.commentTypeText,
                      },
                      on: { command: _vm.dynamicCommand },
                    }),
                  ],
                  1
                ),
                _vm.progressList &&
                _vm.progressList.processRecordArray &&
                _vm.progressList.processRecordArray.length > 0
                  ? _c(
                      "div",
                      {
                        class:
                          "dynamic-type " + (_vm.tabType === 2 ? "select" : ""),
                        on: {
                          click: function ($event) {
                            return _vm.tabTypeClick(2)
                          },
                        },
                      },
                      [_vm._v("审批记录")]
                    )
                  : _vm._e(),
              ]),
              (_vm.topList.length > 2 || _vm.nextCount > 0) && _vm.tabType === 1
                ? _c("div", [
                    _vm.nextCount > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "dynamic-menu",
                            on: { click: _vm.showOld },
                          },
                          [
                            _c("Icon", {
                              staticClass: "menu-icon",
                              attrs: { "icon-class": "icon-more-row" },
                            }),
                            [
                              _vm._v(
                                "显示较早的 " +
                                  _vm._s(_vm.nextCount) +
                                  " 条动态"
                              ),
                            ],
                          ],
                          2
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "dynamic-menu",
                            on: { click: _vm.showOld },
                          },
                          [
                            _c("Icon", {
                              staticClass: "menu-icon",
                              attrs: { "icon-class": "icon-more-row" },
                            }),
                            [_vm._v("隐藏较早的动态")],
                          ],
                          2
                        ),
                  ])
                : _vm._e(),
            ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabType === 1,
                expression: "tabType === 1",
              },
            ],
            staticClass: "dynamic-content-wrap",
          },
          [
            _vm._l(_vm.topList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "list-box rel",
                  class:
                    item.type == 1 ? "list-box-comment" : "list-box-dynamic",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      item.type === 1
                        ? _c("el-image", {
                            staticClass: "avatar m-t-5 border-radius-20",
                            attrs: {
                              fit: "cover",
                              src:
                                item.createUserAvatar ||
                                require("@/assets/imgs/common/advar.png"),
                            },
                          })
                        : _c("Icon", {
                            staticClass: "m-r-5 m-t-5 dynamic-icon",
                            attrs: {
                              "icon-class": _vm.dynamicEnum[item.type].icon,
                            },
                          }),
                      _c("div", { staticClass: "dynamic-action" }, [
                        _c(
                          "span",
                          { staticClass: "name m-r-5" },
                          [
                            _c("wx-open-data", {
                              attrs: {
                                id: item.createUserName,
                                nameData: { name: item.createUserName },
                              },
                            }),
                            item.type === 5
                              ? [
                                  _vm._v(" 添加了「 "),
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: item.comment,
                                      nameData: { name: item.comment },
                                    },
                                  }),
                                  _vm._v("」为负责人 "),
                                ]
                              : item.type === 11
                              ? [
                                  _vm._v(" 删除了协作人「 "),
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: item.comment,
                                      nameData: { name: item.comment },
                                    },
                                  }),
                                  _vm._v("」 "),
                                ]
                              : item.type === 12
                              ? [
                                  _vm._v(" 添加了「 "),
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: item.comment,
                                      nameData: { name: item.comment },
                                    },
                                  }),
                                  _vm._v("」为协作人 "),
                                ]
                              : [_vm._v(_vm._s(_vm.getAction(item)))],
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                  item.type === 1 || item.type === 19 || item.type === 20
                    ? _c("div", {
                        staticClass: "describe m-l-40 m-t-5",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.changeToHtml(item.comment)
                          ),
                        },
                      })
                    : item.type !== 13 && _vm.getContent(item) !== ""
                    ? _c("div", { staticClass: "describe m-l-40 m-t-5" }, [
                        _vm._v(_vm._s(_vm.getContent(item))),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "time abs f-z-12" }, [
                    _vm._v(_vm._s(item.createDate.substring(0, 16))),
                  ]),
                  item.ossFileEntityList !== null &&
                  item.ossFileEntityList !== ""
                    ? _c("dynamic-file-list", {
                        attrs: {
                          "oss-file-entity-list": item.ossFileEntityList,
                        },
                      })
                    : _vm._e(),
                  item.type === 1
                    ? _c("div", { staticClass: "trend-btn-reply-operation" }, [
                        _c("div", { staticClass: "operation-left" }, [
                          item.ossFileEntityList !== "" &&
                          item.ossFileEntityList !== null &&
                          !_vm.$utils.isWxMac()
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.fileDownload(
                                        item.ossFileEntityList
                                      )
                                    },
                                  },
                                },
                                [_vm._v("下载附件")]
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "operation-right" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "点击评论",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "operation-icon-span",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.showReplyBox(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "operation-icon",
                                      attrs: { "icon-class": "icon-pinglun" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm.userInfo.id === item.createUserid
                              ? _c("span", { staticClass: "line" })
                              : _vm._e(),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "点击删除评论",
                                  placement: "top-start",
                                },
                              },
                              [
                                _vm.userInfo.id === item.createUserid
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "operation-icon-span",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteComment(item.id)
                                          },
                                        },
                                      },
                                      [
                                        _c("Icon", {
                                          staticClass: "operation-icon",
                                          attrs: {
                                            "icon-class":
                                              "icon-shanchu-renwuxiangqing",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  item.type == 1 &&
                  item.sonCommentArray &&
                  item.sonCommentArray.length > 0
                    ? _c(
                        "div",
                        { staticClass: "task-sonCommentArray" },
                        _vm._l(
                          item.sonCommentArray,
                          function (sonItem, sonIndex) {
                            return _c(
                              "div",
                              { key: sonIndex, staticClass: "son-task-list" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "son-task-name" },
                                  [
                                    _c("el-image", {
                                      staticClass: "avatar",
                                      attrs: {
                                        src:
                                          sonItem.createUserAvatar ||
                                          require("@/assets/imgs/common/advar.png"),
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "son-name" },
                                      [
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: sonItem.createUserName,
                                            nameData: {
                                              name: sonItem.createUserName,
                                            },
                                          },
                                        }),
                                        _vm._v("回复 "),
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: item.createUserName,
                                            nameData: {
                                              name: item.createUserName,
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "son-task-time" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            sonItem.createDate.substring(0, 16)
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "son-task-row" }, [
                                  _c("div", {
                                    staticClass: "son-task-title",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.changeToHtml(sonItem.comment)
                                      ),
                                    },
                                  }),
                                ]),
                                sonItem.type == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "trend-btn-reply-operation",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "operation-left",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "operation-right" },
                                          [
                                            _vm.userInfo.id ===
                                            sonItem.createUserid
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "operation-icon-span",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteComment(
                                                          sonItem.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("Icon", {
                                                      staticClass:
                                                        "operation-icon",
                                                      attrs: {
                                                        "icon-class":
                                                          "icon-shanchu-renwuxiangqing",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "reply-box" },
                    [
                      item.type === 1
                        ? [
                            _vm.replyView && _vm.isActive == item.id
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "trend-input-reply",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _c("comment-box", {
                                      ref: "commentBox",
                                      refInFor: true,
                                      attrs: {
                                        "file-upload-disabled": true,
                                        placeholder: "输入回复的内容",
                                        "blur-release": true,
                                        "at-able": false,
                                        height: "auto",
                                        lineFeed: true,
                                      },
                                      on: {
                                        release: _vm.addReplayComment,
                                        hideComment: _vm.hideComment,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            _vm.list.length > 0
              ? _vm._l(_vm.list, function (item) {
                  return _c(
                    "div",
                    {
                      key: item,
                      staticClass: "list-box rel",
                      class:
                        item.type == 1
                          ? "list-box-comment"
                          : "list-box-dynamic",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          item.type === 1
                            ? _c("el-image", {
                                staticClass: "avatar",
                                attrs: {
                                  src:
                                    item.createUserAvatar ||
                                    require("@/assets/imgs/common/advar.png"),
                                },
                              })
                            : _c("Icon", {
                                staticClass: "m-r-5 m-t-5 dynamic-icon",
                                attrs: {
                                  "icon-class": _vm.dynamicEnum[item.type].icon,
                                },
                              }),
                          _c(
                            "div",
                            { staticClass: "dynamic-action flex-a-c-center" },
                            [
                              _c(
                                "span",
                                { staticClass: "name m-r-5" },
                                [
                                  _c("wx-open-data", {
                                    attrs: {
                                      id: item.createUserName,
                                      nameData: { name: item.createUserName },
                                    },
                                  }),
                                  item.type === 5
                                    ? [
                                        _vm._v(" 添加了「 "),
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: item.comment,
                                            nameData: { name: item.comment },
                                          },
                                        }),
                                        _vm._v("」为负责人 "),
                                      ]
                                    : item.type === 11
                                    ? [
                                        _vm._v(" 删除了协作人「 "),
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: item.comment,
                                            nameData: { name: item.comment },
                                          },
                                        }),
                                        _vm._v("」 "),
                                      ]
                                    : item.type === 12
                                    ? [
                                        _vm._v(" 添加了「 "),
                                        _c("wx-open-data", {
                                          attrs: {
                                            id: item.comment,
                                            nameData: { name: item.comment },
                                          },
                                        }),
                                        _vm._v("」为协作人 "),
                                      ]
                                    : [_vm._v(_vm._s(_vm.getAction(item)))],
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      item.type === 1 || item.type === 19 || item.type === 20
                        ? _c("div", {
                            staticClass: "describe m-l-40 m-t-5",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$utils.changeToHtml(item.comment)
                              ),
                            },
                          })
                        : item.type !== 13 && _vm.getContent(item) !== ""
                        ? _c("div", { staticClass: "describe m-l-40 m-t-5" }, [
                            _vm._v(_vm._s(_vm.getContent(item))),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "time abs f-z-12" }, [
                        _vm._v(_vm._s(item.createDate.substring(0, 16))),
                      ]),
                      item.ossFileEntityList !== null &&
                      item.ossFileEntityList !== ""
                        ? _c("dynamic-file-list", {
                            attrs: {
                              "oss-file-entity-list": item.ossFileEntityList,
                            },
                          })
                        : _vm._e(),
                      item.type === 1
                        ? _c(
                            "div",
                            { staticClass: "trend-btn-reply-operation" },
                            [
                              _c("div", { staticClass: "operation-left" }, [
                                item.ossFileEntityList !== "" &&
                                item.ossFileEntityList !== null &&
                                !_vm.$utils.isWxMac()
                                  ? _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.fileDownload(
                                              item.ossFileEntityList
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("下载附件")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "operation-right" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: "点击评论",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "operation-icon-span",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.showReplyBox(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("Icon", {
                                            staticClass: "operation-icon",
                                            attrs: {
                                              "icon-class": "icon-pinglun",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.userInfo.id === item.createUserid
                                    ? _c("span", { staticClass: "line" })
                                    : _vm._e(),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: "点击删除评论",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _vm.userInfo.id === item.createUserid
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "operation-icon-span",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteComment(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("Icon", {
                                                staticClass: "operation-icon",
                                                attrs: {
                                                  "icon-class":
                                                    "icon-shanchu-renwuxiangqing",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      item.type == 1 &&
                      item.sonCommentArray &&
                      item.sonCommentArray.length > 0
                        ? _c(
                            "div",
                            { staticClass: "task-sonCommentArray" },
                            _vm._l(
                              item.sonCommentArray,
                              function (sonItem, sonIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: sonIndex,
                                    staticClass: "son-task-list",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "son-task-name" },
                                      [
                                        _c("el-image", {
                                          staticClass: "avatar",
                                          attrs: {
                                            src:
                                              sonItem.createUserAvatar ||
                                              require("@/assets/imgs/common/advar.png"),
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "son-name" },
                                          [
                                            _c("wx-open-data", {
                                              attrs: {
                                                id: sonItem.createUserName,
                                                nameData: {
                                                  name: sonItem.createUserName,
                                                },
                                              },
                                            }),
                                            _vm._v("回复 "),
                                            _c("wx-open-data", {
                                              attrs: {
                                                id: item.createUserName,
                                                nameData: {
                                                  name: item.createUserName,
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "son-task-time" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                sonItem.createDate.substring(
                                                  0,
                                                  16
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "son-task-row" }, [
                                      _c("div", {
                                        staticClass: "son-task-title",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.changeToHtml(
                                              sonItem.comment
                                            )
                                          ),
                                        },
                                      }),
                                    ]),
                                    sonItem.type == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "trend-btn-reply-operation",
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "operation-left",
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "operation-right",
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "点击删除评论",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.userInfo.id ===
                                                    sonItem.createUserid
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "operation-icon-span",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteComment(
                                                                  sonItem.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("Icon", {
                                                              staticClass:
                                                                "operation-icon",
                                                              attrs: {
                                                                "icon-class":
                                                                  "icon-shanchu-renwuxiangqing",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "reply-box" },
                        [
                          item.type === 1
                            ? [
                                _vm.replyView && _vm.isActive == item.id
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "trend-input-reply",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      [
                                        _c("comment-box", {
                                          ref: "commentBox",
                                          refInFor: true,
                                          attrs: {
                                            "file-upload-disabled": true,
                                            placeholder: "输入回复的内容",
                                            "blur-release": true,
                                            "at-able": false,
                                            height: "auto",
                                            lineFeed: true,
                                          },
                                          on: {
                                            release: _vm.addReplayComment,
                                            hideComment: _vm.hideComment,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                })
              : _vm._e(),
            _vm.topList.length == 0 && !_vm.loading
              ? _c("div", { staticClass: "empty-box rel" }, [_vm._m(0)])
              : _vm._e(),
          ],
          2
        ),
        _vm.tabType === 2
          ? _c(
              "div",
              { staticClass: "approve-content-wrap" },
              _vm._l(
                _vm.progressList.processRecordArray,
                function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.content,
                      class:
                        "progress-item " +
                        (item.approvalStatus === 1 && index === 0
                          ? "dotted"
                          : ""),
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            "progess-line " +
                            (item.approvalStatus === 1 && index === 0
                              ? "sign"
                              : ""),
                        },
                        [_c("div", { staticClass: "circle" })]
                      ),
                      _c("div", { staticClass: "progess-detail" }, [
                        _c("div", { staticClass: "title-wrap" }, [
                          _c(
                            "div",
                            { staticClass: "title" },
                            [
                              _vm._v(" " + _vm._s(item.actionRole) + ": "),
                              _c("wx-open-data", {
                                attrs: {
                                  id: item.actionUserName,
                                  nameData: { name: item.actionUserName },
                                },
                              }),
                            ],
                            1
                          ),
                          item.approvalStatus === 1 &&
                          _vm.progressList.processDynamic.sponsorUserId ==
                            _vm.userInfo.id &&
                          index === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "blue-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.approvalRemind(item)
                                    },
                                  },
                                },
                                [_vm._v("催办")]
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "content-wrap" }, [
                          _c("div", { staticClass: "content-text" }, [
                            _c(
                              "div",
                              { class: "title type-" + item.approvalStatus },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.actionContent) +
                                    " " +
                                    _vm._s(
                                      item.approvalStatus === 1 ? "审批中" : ""
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            item.content
                              ? _c("div", { staticClass: "content" }, [
                                  _vm._v("说明:" + _vm._s(item.content)),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(_vm.$utils.globalTime(item.updateDate))
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }
              ),
              0
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gravity-center" }, [
      _c("div", { staticClass: "global-content-null-wrap" }, [
        _c("img", {
          staticClass: "img",
          attrs: { src: require("@/assets/imgs/common/stopdoingNull.png") },
        }),
      ]),
      _c("p", { staticClass: "null-text" }, [
        _vm._v("还没有评论，快来抢占第一个沙发～"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }