var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "set-task-time" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置提醒时间",
            visible: true,
            width: "35%",
            "append-to-body": true,
            "close-on-click-modal": true,
            top: "30vh",
          },
          on: { close: _vm.$dialogClose },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading",
                },
              ],
              staticClass: "select-list",
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "m-r-20",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.dateTypeChange },
                  model: {
                    value: _vm.form.dateType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "dateType", $$v)
                    },
                    expression: "form.dateType",
                  },
                },
                _vm._l(_vm.dateTypeDict, function (val, key) {
                  return _c(
                    "el-option",
                    { key: key, attrs: { label: val, value: parseInt(key) } },
                    [
                      _vm.isCustomDate(key)
                        ? _c("div", { staticClass: "select-decorate" })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(val))]),
                    ]
                  )
                }),
                1
              ),
              _vm.isShowTime
                ? [
                    _vm.customTimeVisible
                      ? _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            format: "yyyy-MM-dd HH:mm",
                            "value-format": "yyyy-MM-dd HH:mm",
                            placeholder: "选择日期",
                            "popper-class": "addTimeClass",
                          },
                          model: {
                            value: _vm.form.customDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "customDate", $$v)
                            },
                            expression: "form.customDate",
                          },
                        })
                      : [
                          _c(
                            "el-select",
                            {
                              staticClass: "m-r-20",
                              model: {
                                value: _vm.form.number,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "number", $$v)
                                },
                                expression: "form.number",
                              },
                            },
                            _vm._l(_vm.numberDict, function (val, key) {
                              return _c("el-option", {
                                key: key,
                                attrs: { label: val, value: parseInt(key) },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.form.numberType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "numberType", $$v)
                                },
                                expression: "form.numberType",
                              },
                            },
                            _vm._l(_vm.numberTypeDict, function (val, key) {
                              return _c("el-option", {
                                key: key,
                                attrs: { label: val, value: parseInt(key) },
                              })
                            }),
                            1
                          ),
                        ],
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "flex-new justify-end footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { staticClass: "cancel-btn", on: { click: _vm.$dialogClose } },
                [_vm._v("取 消")]
              ),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "common_btn_next common_btn_next_able",
                  on: { click: _vm.save },
                },
                [_vm._v(" 添 加 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }