// okr模块方法库

import apis from '../apis_moudles/index'
import req from '../request'

// 获取某人目标列表
const OKRLISTBYPERSON = (appendUrl, params) => {
  return req.get(`${apis.okrListByPerson}${appendUrl}`, {
    params
  })
}
// 要对齐的目标列表
const OKRTOALIGNLIST = (appendUrl, params) => {
  return req.get(`${apis.okrToAlignList}${appendUrl}`, {
    params
  })
}

// 更新邀请对齐已读状态
const OKRUPDATEALIGNSTATE = (params) => {
  return req.post(apis.okrUpdateAlignState, params)
}

// 删除邀请对齐
const OKRDELETEALIGN = (appendUrl, params) => {
  return req.get(`${apis.okrDeleteAlign}${appendUrl}`, {
    params
  })
}

// 对齐的目标信息
const OKRALIGNOBJINFO = (params) => {
  return req.get(`${apis.okrAlignObjInfo}`, {
    params
  })
}

// 单个目标信息
const OKROBJINFO = (appendUrl, params) => {
  return req.get(`${apis.okrObjInfo}${appendUrl}`, {
    params
  })
}

// 对齐此目标的目标列表-目标子级 向下
const OKRALIGNOBJLISTING = (appendUrl, params) => {
  return req.get(`${apis.okrAlignObjListing}${appendUrl}/align`, {
    params
  })
}

// 添加对齐
const OKRADDFATHERALIGN = (params) => {
  return req.post(apis.okrAddFatherAlign, params)
}

// 取消对齐
const OKRDELETEFATHERALIGN = (params) => {
  return req.post(`${apis.okrDeleteFatherAlign}`, params)
}
// 删除对齐-地图
const OKRDELETEFATHERALIGNMAP = (appendUrl, params) => {
  return req.get(`${apis.okrDeleteFatherAlign}${appendUrl}`, {
    params
  })
}

// 删除OKR
const OKRDELETE = (appendUrl, params) => {
  return req.get(`${apis.okrDelete}${appendUrl}`, {
    params
  })
}
// 添加OKR
const OKRADD = (params) => {
  return req.post(apis.okrAdd, params)
}
// 更改OKR标题
const OKRUPDATETITLE = (params) => {
  return req.post(apis.okrUpdateTitle, params)
}

// 更改OKR信心值
const OKRCONFIDENCEUPDATE = (params) => {
  return req.post(apis.okrConfidenceUpdate, params)
}

// 修改OKR公开状态
const OKRPUBLICSTATECHANGE = (params) => {
  return req.post(apis.okrPublicStateChange, params)
}

// 根据关键结果查询任务
const OKRQUERYTASKBYKEYID = (params) => {
  return req.get(apis.okrQueryTaskBykeyId, {
    params
  })
}
// 根据关键结果查询任务（新）
const OKRQUERYTASKBYKEYIDNEW = (params) => {
  return req.get(apis.okrQueryTaskBykeyIdNew, {
    params
  })
}
// 根据关键结果查询任务（新）
const OKRQUERYTASKBYKEYIDNEWV2 = (params) => {
  return req.get(apis.okrQueryTaskBykeyIdNewV2, {
    params
  })
}

// 点赞OKR
const OKRADDLAUD = (params) => {
  return req.post(apis.okrAddLaud, params)
}

// 取消点赞
const OKRDELETELAUD = (appendUrl, params) => {
  return req.get(`${apis.okrDeleteLaud}${appendUrl}`, {
    params
  })
}

// 获取OKR动态列表
const OKRDYNAMICLIST = (params) => {
  return req.get(apis.okrDynamicList, {
    params
  })
}

// 获取OKR进度列表
const OKRPROCESSLIST = (params) => {
  return req.get(apis.okrProcessList, {
    params
  })
}

// 更新OKR进度
const OKRADDPROCESS = (params) => {
  return req.put(apis.okrAddProcess, params)
}

// OKR进度中发布评论
const OKRADDPROCESSCOMMENT = (params) => {
  return req.post(apis.okrAddProcessComment, params)
}

// OKR动态中发布评论
const OKRADDDYNAMICCOMMENT = (params) => {
  return req.post(apis.okrAddDynamicComment, params)
}

// 删除OKR动态评论
const OKRDELDYNAMICCOMMENT = (appendUrl, params) => {
  return req.delete(`${apis.okrDelDynamicComment}${appendUrl}`, params)
}

// OKR动态回复评论
const OKRADDDYNAMICREPLY = (params) => {
  return req.post(apis.okrAddDynamicReply, params)
}

// 添加Kr
const OKRADDKR = (params) => {
  return req.post(apis.okrAddKr, params)
}
// 删除Kr
const OKRDELETEKR = (appendUrl, params) => {
  return req.get(`${apis.okrDeleteKr}${appendUrl}`, {
    params
  })
}

// 更新KR权重
const OKRUPDATEWEIGHT = (params) => {
  return req.post(apis.okrUpdateWeight, params)
}

// 更新KR排序
const OKRUPDATESORT = (params) => {
  return req.post(apis.okrUpdateSort, params)
}

// 更新KR标题
const OKRUPDATEKRTITLE = (params) => {
  return req.post(apis.okrUpdateKrTitle, params)
}

// 更改KR量化条件
const OKRUPDATEKRQUANTITY = (params) => {
  return req.put(apis.okrUpdateKrQuantity, params)
}

// 获取kr量化单位列表
const OKRQUANTITYUNITS = (params) => {
  return req.get(apis.okrQuantityUnits, {
    params
  })
}

// 获取OKR指引目标
const OKRGUIDETEMPLETES = (appendUrl, params) => {
  return req.get(`${apis.okrGuideTempletes}${appendUrl}`, {
    params
  })
}

// 查询目标查看记录
const OKRVIEWRECORD = (params) => {
  return req.get(apis.okrViewRecord, {
    params
  })
}

// 查询最近使用的kr
const OKRRECENTKRLIST = (params) => {
  return req.get(apis.okrRecentKrList, {
    params
  })
}

// 查询最近使用的kr
const OKRRECOMMENDKRLIST = (params) => {
  return req.get(apis.okrRecommendKrList, {
    params
  })
}
// 获取单个目标的KR列表
const OKRKRLISTBYOBJID = (appendUrl, params) => {
  return req.get(`${apis.okrKrListByObjId}${appendUrl}`, {
    params
  })
}
// 查询当前登陆人所在的公司的目标列表【当前所有进行的周期、当前周期的上一个周期、下一个周期-总结中使用】
const OKRCONCLUSIONOKRLIST = (params) => {
  return req.get(apis.okrConclusionOkrList, {
    params
  })
}
// 根据关键结果查询进度列表
const OKRPROCESSLISTBYKR = (params) => {
  return req.get(apis.okrProcessListBykr, {
    params
  })
}
// 地图页目标列表
const OKRMAPPAGELIST = (params) => {
  return req.get(`${apis.okrMapPageList}`, {
    params
  })
}
//  地图-根据菜单条件，查询目标列表
const OBJECTIVESMAPCONDITIONMENU = (params) => {
  return req.get(`${apis.objectivesMapConditionMenu}`, {
    params
  })
}
// 查询目标列表-地图-v3
const OKROBJECTIVESLIST = (params) => {
  return req.get(`${apis.okrObjectivesList}`, {
    params
  })
}
// 查询向下对齐目标信息和邀请未对齐信息-v3
const OKROBJECTIVESLISTCHILD = (params) => {
  return req.get(`${apis.okrObjectivesListChild}`, {
    params
  })
}
// 获取目标子集列表-地图页
const OKRCHILDALIGNLIST = (appendUrl, params) => {
  return req.get(`${apis.okrChildAlignList}${appendUrl}`, {
    params
  })
}
// 获取对齐目标
const GETOKRALIGNLIST = (params) => {
  return req.get(`${apis.getOkrAlignList}`, {
    params
  })
}
// 地图评论删除
const OKRMAPCOMMENTDEL = (id, params) => {
  return req.delete(`${apis.okrMapCommentDel}${id}`, {
    params
  })
}
// 地图评论删除
const OKRMAPCOMMENTLIST = (params) => {
  return req.get(`${apis.okrMapCommentList}`, {
    params
  })
}
// 提醒他
const OKRMAPREMIND = (params) => {
  return req.put(`${apis.okrMapRemind}`, params)
}
// 复制目标
const OKRCOPY = (params) => {
  return req.put(`${apis.okrCopy}`, params)
}
// 移动目标
const OKRMOVE = (params) => {
  return req.put(`${apis.okrMove}`, params)
}
// 转让目标
const OKRTRANSFER = (params) => {
  return req.post(`${apis.okrTransfer}`, params)
}
// 获取Kr已关联的项目列表
const KRATTACHEDPROJECTS = (params) => {
  return req.get(`${apis.krAttachedProjects}`, {
    params
  })
}
// 获取Kr可关联的项目列表
const KRATTACHALLPROJECTS = (params) => {
  return req.get(`${apis.krAttachAllProjects}`, {
    params
  })
}
// kr关联项目
const KRADDATTACH = (params) => {
  return req.post(`${apis.krAddAttach}`, params)
}
// 校验当前成员是否在项目成员里
const PROJECTCHECK = (id, params) => {
  return req.get(`${apis.projectCheck}${id}`, {
    params
  })
}
// kr取消关联项目
const KRDELATTACH = (id, params) => {
  return req.delete(`${apis.krDelAttach}${id}`, {
    params
  })
}
// 左侧评分按钮弹框是否显示
const TARGETCYCLEDYNAMIC = (params) => {
  return req.get(`${apis.targetCycledynamic}`, {
    params
  })
}
// 查询企业内OKR(缺省页)
const OKRCOMPANYDEFAULTLIST = (params) => {
  return req.get(`${apis.okrCompanyDefaultList}`, {
    params
  })
}
// 提交查看记录
const OKRVIEWRECORDSAVE = (params) => {
  return req.post(`${apis.okrViewRecordSave}`, params)
}
// 批量提交查看记录
const OKRVIEWRECORDSAVEBATCH = (params) => {
  return req.post(`${apis.okrViewRecordSaveBatch}`, params)
}
// 获取目标建议字典
const OKRWRITERULES = (params) => {
  return req.get(`${apis.okrWriteRules}`, {
    params
  })
}
// 忽略目标建议
const OKRIGNOREWRITERULE = (params) => {
  return req.post(`${apis.okrIgnoreWriteRule}`, params)
}
// 目标权限修改
const OKRPOWERUPDATE = (params) => {
  return req.put(`${apis.okrPowerUpdate}`, params)
}
// 查询目标查看权限
const OKRPOWERDETAIL = (id, params) => {
  return req.get(`${apis.okrPowerDetail}${id}`, {
    params
  })
}

// okr 文件列表
const OKRFILELIST = (params) => {
  return req.get(`${apis.okrFileList}`, {
    params
  })
}
// okr文件置顶/取消置顶
const OKRFILETOP = (params) => {
  return req.put(`${apis.okrFileTop}`, params)
}
// okr文件状态设置
const OKRFILESTATUS = (params) => {
  return req.put(`${apis.okrFileStatus}`, params)
}
// okr文件删除(单个or批量)
const DELETEOKRFILE = (objId, params) => {
  return req.put(`${apis.deleteOkrFile}${objId}`, params)
}
// okr文件删除(单个or批量)
const DELETEOKRFILEONE = (params) => {
  return req.post(`${apis.deleteOkrFileOne}`, params)
}
// okr文件保存
const OKRFILESSAVE = (params) => {
  return req.post(`${apis.okrFilesSave}`, params)
}
// okr自动化推进设置回显
const OKRGETAUTOPROGRESS = (params) => {
  return req.get(`${apis.okrGetAutoProgress}`, {
    params
  })
}
// OKR自动化推进设置保存
const OKRAUTOPROGRESSSAVE = (params) => {
  return req.post(`${apis.okrtAutoProgressSave}`, params)
}
// OKR自动化任务列表
const OKRAUTOTASKLIST = (params) => {
  return req.get(`${apis.okrtAutoTaskList}`, {
    params
  })
}
// OKR自动化目标列表
const OKRAUTOOKRLIST = (id, params) => {
  return req.get(`${apis.okrAutoOkrList}${id}/automation/align`, {
    params
  })
}
// 关联自动化推进显示（小图标显示）
const OKRAUTOPROGRESSLIST = (params) => {
  return req.get(`${apis.okrAutoProgressList}`, {
    params
  })
}
// 添加OKR评分
const UPDATEKRSCORE = (params) => {
  return req.put(`${apis.updateKrScore}`, params)
}
// okr共享（添加/修改共享人）
const CHANGEOKRSHARE = (params) => {
  return req.post(`${apis.changeOkrShare}`, params)
}
// okr共享列表
const OKRSHARELIST = (objId, params) => {
  return req.get(`${apis.okrShareList}${objId}/list`, {
    params
  })
}
// okr共享人动态
const OKRSHAREDYNAMIC = (params) => {
  return req.get(`${apis.okrShareDynamic}`, {
    params
  })
}
// 修改目标标签
const OKRUPDATELABEL = (params) => {
  return req.put(`${apis.okrUpdateLabel}`, params)
}
// 根据目标id,获取关键结果列表
const OKRIDKEYLIST = (params) => {
  return req.get(`${apis.okrIdKeyList}`, {
    params
  })
}
// 目标地图向上
const OKRALIGNOBJLISTINGUP = (params) => {
  return req.get(`${apis.okrAlignObjListingUp}`, {
    params
  })
}
// 关键结果获取目标详情
const KEYOKRALIGNOBJDETAIL = (id, params) => {
  return req.get(`${apis.okrObjInfo}${id}/kr`, {
    params
  })
}
// kr智能推进分配额度提醒
const OKRKRPROGRESSVALUE = (params) => {
  return req.get(`${apis.okrKrProgressValue}`, {
    params
  })
}
// 根据周期id,查询创建人和共享人是自己的目标列表
const OKRHAVESHAREDLIST = (params) => {
  return req.get(apis.okrHaveSharedList, {
    params
  })
}
// 查询菜单列表
const CONDITIONMENULIST = (params) => {
  return req.get(apis.conditionMenuList, {
    params
  })
}
// 添加关注
const COMPANYUSERCARESAVE = (params) => {
  return req.post(apis.companyUserCareSave, params)
}
// 取消关注
const COMPANYUSERCAREDELETE = (params) => {
  return req.delete(apis.companyUserCareDelete, {
    data: params
  })
}
// 查询关注
const COMPANYUSERCARELIST = (params) => {
  return req.get(apis.companyUserCareList, params)
}
// 查询全部菜单列表
const CONDITIONMENULISTALL = (params) => {
  return req.get(apis.conditionMenuListAll, params)
}
// 批量修改菜单
const CONDITIONMENUUPDATEBATCH = (params) => {
  return req.post(apis.conditionMenuUpdateBatch, params)
}
// 查询默认菜单列表
const CONDITIONMENULISTDEFAULT = (params) => {
  return req.get(apis.conditionMenuListDefault, params)
}
// 列表-根据菜单条件，查询目标列表
const OBJECTIVESLISTCONDITIONMENU = (params) => {
  return req.get(apis.objectivesListConditionMenu, {
    params
  })
}
// 删除OKR动态评论
const KRPROGRESSCOMMENTDELETE = (id, params) => {
  return req.delete(`${apis.krProgressCommentDelete}${id}`, params)
}
// 列表-根据菜单条件,查询目标列表-导出
const EXPORTCONTIDIONMENU = (params) => {
  return req.get(apis.exportConditionMenu, {
    params,
    responseType: 'arraybuffer'
  })
}
// 关注目标
const OKRCARECONFIRM = (params) => {
  return req.post(apis.okrCareConfirm, params)
}
// 取消关注目标
const OKRCARECANCEL = (params) => {
  return req.post(apis.okrCareCancel, params)
}
// 查询目标列表 表头
const OKRTABLETITLE = (params) => {
  return req.get(apis.okrTableTitle, {
    params
  })
}
// 列表-根据菜单条件，查询目标列表-表格
const OKRTABLEMENULIST = (params) => {
  return req.get(apis.okrTableMenuList, {
    params
  })
}
// 修改目标列表表头是否展示
const OKRTABLEMENUTITLESHOW = (params) => {
  return req.post(apis.okrTableMenuTitleShow, params)
}
// 修改目标列表表头是否展示
const OKRTABLEMENUTITLEORDER = (params) => {
  return req.post(apis.okrTableMenuTitleOrder, params)
}
// 根据人员id,查询向上对齐关系的目标列表
const GETFATHERALIGNOBJLIST = (params) => {
  return req.get(`${apis.getFatherAlignObjList}`, {
    params
  })
}
// 根据人员id,查询向下对齐关系的目标列表
const GETCHILDALIGNOBJLIST = (params) => {
  return req.get(`${apis.getChildAlignObjList}`, {
    params
  })
}
// 根据kr或者目标标题 搜索 目标
const OKRSEACHTITLELIST = (params) => {
  return req.get(`${apis.okrSearchTitleList}`, {
    params
  })
}
// 审批设置详情
const APPROVALDETAIL = (params) => {
  return req.get(`${apis.approvalDetail}`, {
    params
  })
}
// 审批设置保存或者修改
const APPROVALUPDATE = (params) => {
  return req.post(apis.approvalUpdate, params)
}
// 审批流详情
const APPROVALPROCESSRECORD = (params) => {
  return req.get(`${apis.approvalProcessRecord}`, {
    params
  })
}
// 分页查询 审批数据列表
const APPROVALPAGELIST = (params) => {
  return req.get(`${apis.approvalPageList}`, {
    params
  })
}
// 查询我的审批红点数量
const APPROVALREDCOUNT = (params) => {
  return req.get(`${apis.approvalRedCount}`, {
    params
  })
}
// 催办
const APPROVALDATAREMIND = (params) => {
  return req.post(apis.approvalDataRemind, params)
}
// 提交撤回审批
const APPROVALDATASUBMITTER = (params) => {
  return req.post(apis.approvalDataSubmitter, params)
}
// 审批流程操作 审批通过/驳回
const APPROVALPROCESSOPERATION = (params) => {
  return req.post(apis.approvalProcessOperation, params)
}
// 检测当前用户是否需要审批
const APPROVALCHECK = (params) => {
  return req.get(`${apis.approvalCheck}`, {
    params
  })
}
// 单个目标信息
const OKROBJINFOSIMPLE = (appendUrl, params) => {
  return req.get(`${apis.okrObjInfoSimple}${appendUrl}`, {
    params
  })
}
// 设置默认周期
const SETCYCLEDEFSTATE = (params) => {
  return req.post(apis.setCycleDefState, params)
}
const OBJECTIVESIMPORTEXCEL = (params) => {
  return req.post(apis.objectivesImportExcel, params, {
    timeout: 120000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
//  根据周期id,人员等分页查询kr列表
const OBJECTIVESKEYRESULTS = (params) => {
  return req.get(apis.objectivesKeyresults, {
    params
  })
}
// 根据用户输入的目标内容生成OKR
const AICREATEOKR = (params) => {
  return req.post(apis.aiCreateOKR, params)
}
const USERAIINFO = (params) => {
  return req.get(apis.userAiInfo, {
    params
  })
}
const CREATETASKBYAI = (params) => {
  return req.post(apis.createTaskByAi, params)
}
// 批量添加任务
const ADDBATCHTASK = (params) => {
  return req.post(apis.addBatchTask, params)
}
// AI 助写-生成或优化改写KR
const AICREATEKR = (params) => {
  return req.post(apis.aiCreateKr, params)
}
// 根据用户输入的目标内容生成O
const AICREATEOBJ = (params) => {
  return req.post(apis.aiCreateObj, params)
}
// ai文件
const AIUPLOADDOCUMENTS = (params) => {
  return req.post(apis.aiUploadDocuments, params, {
    timeout: 120000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export default [
  {
  AIUPLOADDOCUMENTS
},
  {
  AICREATEOBJ
},
{
  AICREATEKR
},
{
  ADDBATCHTASK
},
{
  CREATETASKBYAI
},
{
  USERAIINFO
},
{
  AICREATEOKR
},
{
  OBJECTIVESKEYRESULTS
},
{
  OBJECTIVESIMPORTEXCEL
},
{
  SETCYCLEDEFSTATE
},
{
  OKROBJINFOSIMPLE
},
{
  APPROVALCHECK
},
{
  APPROVALPROCESSOPERATION
},
{
  APPROVALDATASUBMITTER
},
{
  APPROVALDATAREMIND
},
{
  APPROVALREDCOUNT
},
{
  APPROVALPAGELIST
},
{
  APPROVALPROCESSRECORD
},
{
  APPROVALUPDATE
},
{
  APPROVALDETAIL
},
{
  OKRSEACHTITLELIST
},
{
  GETCHILDALIGNOBJLIST
},
{
  GETFATHERALIGNOBJLIST
},
{
  OKRTABLEMENUTITLEORDER
},
{
  OKRTABLEMENUTITLESHOW
},
{
  OKRTABLEMENULIST
},
{
  OKRTABLETITLE
},
{
  OKRCARECANCEL
},
{
  OKRCARECONFIRM
},
{
  EXPORTCONTIDIONMENU
},
{
  KRPROGRESSCOMMENTDELETE
},
{
  OBJECTIVESLISTCONDITIONMENU
},
{
  CONDITIONMENULISTDEFAULT
},
{
  CONDITIONMENUUPDATEBATCH
},
{
  CONDITIONMENULISTALL
},
{
  COMPANYUSERCAREDELETE
},
{
  COMPANYUSERCARELIST
},
{
  COMPANYUSERCARESAVE
},
{
  OKRHAVESHAREDLIST
},
{
  CONDITIONMENULIST
},
{
  OKRKRPROGRESSVALUE
},
{
  OKRALIGNOBJLISTINGUP
},
{
  OKRIDKEYLIST
},
{
  OKRUPDATELABEL
},
{
  OKRSHAREDYNAMIC
},
{
  OKRSHARELIST
},
{
  CHANGEOKRSHARE
},
{
  UPDATEKRSCORE
},
{
  OKRAUTOPROGRESSLIST
},
{
  OKRAUTOOKRLIST
},
{
  OKRAUTOTASKLIST
},
{
  OKRAUTOPROGRESSSAVE
},
{
  OKRGETAUTOPROGRESS
},
{
  OKRPOWERDETAIL
},
{
  OKRPOWERUPDATE
},
{
  OKRFILESSAVE
},
{
  DELETEOKRFILE
},
{
  OKRFILESTATUS
},
{
  OKRFILETOP
},
{
  OKRFILELIST
},
{
  OKRCOPY
},
{
  OKRMOVE
},

{
  OKRMAPREMIND
},
{
  OKRMAPCOMMENTLIST
},
{
  OKRMAPCOMMENTDEL
},
{
  OKRUPDATEALIGNSTATE
},
{
  OKRDELETEALIGN
},
{
  OKRALIGNOBJINFO
},
{
  OKROBJINFO
},
{
  OKRADDFATHERALIGN
},
{
  OKRDELETEFATHERALIGN
},
{
  OKRDELETEFATHERALIGNMAP
},
{
  OKRDELETE
},
{
  OKRADD
},
{
  OKRUPDATETITLE
},
{
  OKRCONFIDENCEUPDATE
},
{
  OKRPUBLICSTATECHANGE
},
{
  OKRQUERYTASKBYKEYID
},
{
  OKRQUERYTASKBYKEYIDNEW
},
{
  OKRADDLAUD
},
{
  OKRDELETELAUD
},
{
  OKRDYNAMICLIST
},
{
  OKRPROCESSLIST
},
{
  OKRADDPROCESS
},
{
  OKRADDPROCESSCOMMENT
},
{
  OKRADDDYNAMICCOMMENT
},
{
  OKRDELDYNAMICCOMMENT
},
{
  OKRADDDYNAMICREPLY
},
{
  OKRADDKR
},
{
  OKRDELETEKR
},
{
  OKRUPDATEWEIGHT
},
{
  OKRUPDATESORT
},
{
  OKRUPDATEKRTITLE
},
{
  OKRUPDATEKRQUANTITY
},
{
  OKRQUANTITYUNITS
},
{
  OKRGUIDETEMPLETES
},
{
  OKRVIEWRECORD
},
{
  OKRRECENTKRLIST
},
{
  OKRRECOMMENDKRLIST
},
{
  OKRKRLISTBYOBJID
},
{
  OKRCONCLUSIONOKRLIST
},
{
  OKRPROCESSLISTBYKR
},
{
  OKRMAPPAGELIST
},
{
  OBJECTIVESMAPCONDITIONMENU
},
{
  OKRCHILDALIGNLIST
},
{
  KRATTACHEDPROJECTS
},
{
  KRATTACHALLPROJECTS
},
{
  KRADDATTACH
},
{
  KRDELATTACH
},
{
  PROJECTCHECK
},
{
  TARGETCYCLEDYNAMIC
},
{
  OKRCOMPANYDEFAULTLIST
},
{
  OKRVIEWRECORDSAVE
},
{
  OKRVIEWRECORDSAVEBATCH
},
{
  OKRWRITERULES
},
{
  OKRIGNOREWRITERULE
},
{
  DELETEOKRFILEONE
},
{
  OKRPOWERDETAIL
},
{
  OKRPOWERUPDATE
},
{
  OKRFILESSAVE
},
{
  DELETEOKRFILE
},
{
  OKRFILESTATUS
},
{
  OKRFILETOP
},
{
  OKRFILELIST
},
{
  OKRCOPY
},
{
  OKRMOVE
},
{
  OKRTRANSFER
},
{
  OKRMAPREMIND
},
{
  OKRMAPCOMMENTLIST
},
{
  OKRMAPCOMMENTDEL
},
{
  GETOKRALIGNLIST
},
{
  OKRLISTBYPERSON
},
{
  OKRTOALIGNLIST
},
{
  OKRUPDATEALIGNSTATE
},
{
  OKRDELETEALIGN
},
{
  OKRALIGNOBJINFO
},
{
  OKROBJINFO
},
{
  OKRALIGNOBJLISTING
},
{
  OKRADDFATHERALIGN
},
{
  OKRDELETEFATHERALIGN
},
{
  OKRDELETEFATHERALIGNMAP
},
{
  OKRDELETE
},
{
  OKRADD
},
{
  OKRUPDATETITLE
},
{
  OKRCONFIDENCEUPDATE
},
{
  OKRPUBLICSTATECHANGE
},
{
  OKRQUERYTASKBYKEYID
},
{
  OKRQUERYTASKBYKEYIDNEW
},
{
  OKRQUERYTASKBYKEYIDNEWV2
},
{
  OKRADDLAUD
},
{
  OKRDELETELAUD
},
{
  OKRDYNAMICLIST
},
{
  OKRPROCESSLIST
},
{
  OKRADDPROCESS
},
{
  OKRADDPROCESSCOMMENT
},
{
  OKRADDDYNAMICCOMMENT
},
{
  OKRDELDYNAMICCOMMENT
},
{
  OKRADDDYNAMICREPLY
},
{
  OKRADDKR
},
{
  OKRDELETEKR
},
{
  OKRUPDATEWEIGHT
},
{
  OKRUPDATESORT
},
{
  OKRUPDATEKRTITLE
},
{
  OKRUPDATEKRQUANTITY
},
{
  OKRQUANTITYUNITS
},
{
  OKRGUIDETEMPLETES
},
{
  OKRVIEWRECORD
},
{
  OKRRECENTKRLIST
},
{
  OKRRECOMMENDKRLIST
},
{
  OKRCONCLUSIONOKRLIST
},
{
  OKRPROCESSLISTBYKR
},
{
  OKRMAPPAGELIST
},
{
  OKROBJECTIVESLIST
},
{
  OKROBJECTIVESLISTCHILD
},
{
  KEYOKRALIGNOBJDETAIL
}
]
