var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "task-project" },
    [
      _vm.canSelect && !_vm.disabled
        ? _c("div")
        : _c(
            "div",
            { staticClass: "project-item" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom",
                    width: "200",
                    trigger: "click",
                    disabled: _vm.disabled,
                  },
                  on: { hide: _vm.closeProject },
                  model: {
                    value: _vm.projectVisible,
                    callback: function ($$v) {
                      _vm.projectVisible = $$v
                    },
                    expression: "projectVisible",
                  },
                },
                [
                  _vm.projectList.length > 0
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "scroll-bottom",
                              rawName: "v-scroll-bottom",
                              value: _vm.loadMoreGetProjectList,
                              expression: "loadMoreGetProjectList",
                            },
                          ],
                          staticClass: "project-div",
                        },
                        _vm._l(_vm.projectList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "project-row",
                              on: {
                                click: function ($event) {
                                  return _vm.checkedProject(item, index)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "project-img",
                                  style: "background: " + item.colour,
                                },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src:
                                        item.logo ||
                                        require("@/assets/imgs/common/avatar.png"),
                                      fit: "cover",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "project-name" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _c("div", { staticClass: "project-checked" }, [
                                _vm.activeProject == item.id
                                  ? _c("i", {
                                      staticClass: "el-icon-check checked-icon",
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "no-data" }, [
                        _c("img", {
                          staticClass: "kr-count-empty-img",
                          attrs: {
                            src: require("@/assets/imgs/common/commentNull.png"),
                          },
                        }),
                        _c("p", { staticClass: "kr-count-p" }, [
                          _vm._v("暂无内容"),
                        ]),
                      ]),
                  _c(
                    "div",
                    {
                      staticClass: "project-show",
                      style: _vm.disabled
                        ? "cursor: not-allowed; !important"
                        : "cursor: pointer; !important",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [
                      !_vm.projectObj.id
                        ? _c("div", { staticClass: "add-project" }, [
                            _vm._v("+选择任务归属于"),
                          ])
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "project-img",
                                style: "background: " + _vm.projectObj.colour,
                              },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src:
                                      _vm.projectObj.logo ||
                                      require("@/assets/imgs/common/avatar.png"),
                                    fit: "cover",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "project-name" }, [
                              _vm._v(" " + _vm._s(_vm.projectObj.name) + " "),
                              !_vm.projectDisabled && !_vm.disabled
                                ? _c("i", {
                                    class: _vm.projectVisible
                                      ? "el-icon-arrow-up"
                                      : "el-icon-arrow-down",
                                  })
                                : _vm._e(),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.flowId && _vm.isFlowSelect && _vm.project.id,
              expression: "flowId && isFlowSelect && project.id",
            },
          ],
        },
        [_vm._v("/")]
      ),
      _c(
        "el-select",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.flowId &&
                _vm.isFlowSelect &&
                _vm.isShowFlow &&
                _vm.project.id,
              expression: "flowId && isFlowSelect && isShowFlow && project.id",
            },
          ],
          staticClass: "flow-select",
          attrs: { disabled: _vm.projectDisabled },
          on: { change: _vm.changeVal },
          model: {
            value: _vm.flowId,
            callback: function ($$v) {
              _vm.flowId = $$v
            },
            expression: "flowId",
          },
        },
        _vm._l(_vm.projectFlows, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.id },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }