var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      {
        staticClass: "ai-task-dialog",
        attrs: {
          title: " AI 助写 KR 任务",
          visible: _vm.dialogVisible,
          width: "1120px",
          "close-on-click-modal": false,
          "append-to-body": true,
          "before-close": _vm.handleClose,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "ai-count-wrap" }, [
        _vm._v(" 剩余可调用次数： "),
        _c("span", [_vm._v(_vm._s(_vm.companyInfo.aiUsageCount || 0))]),
        _vm._v(" 次 （更多订阅请咨询售前顾问） "),
      ]),
      _c("div", { staticClass: "kr-content-wrap" }, [
        _c("div", { staticClass: "kr-title" }, [
          _c("div", { staticClass: "kr-index" }, [
            _vm._v("KR" + _vm._s(_vm.krIndex + 1)),
          ]),
          _c("div", {
            staticClass: "title",
            domProps: {
              innerHTML: _vm._s(_vm.$utils.changeToHtml(_vm.objData.title)),
            },
          }),
        ]),
        !_vm.listLoading
          ? _c("div", { staticClass: "btn-list-wrap" }, [
              _c("div", { staticClass: "kr-infor" }, [
                _vm._v(
                  "AI 助手已经自动生成围绕KR" +
                    _vm._s(_vm.krIndex + 1) +
                    "的工作任务，可以根据需求修改内容，并导入至系统中"
                ),
              ]),
              _c(
                "div",
                { staticClass: "bottom-step-buttons" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      attrs: {
                        disabled:
                          _vm.listLoading ||
                          _vm.submitLoading ||
                          (_vm.dataList.length > 0 &&
                            _vm.selectedItems.length === 0),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getKrTaskArrayAgain()
                        },
                      },
                    },
                    [_vm._v("重新生成")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.listLoading
          ? _c(
              "div",
              { staticClass: "task-list-wrap" },
              [
                _c(
                  "el-table",
                  {
                    key: _vm.dataList,
                    ref: "table",
                    attrs: {
                      data: _vm.dataList,
                      "row-key": "id",
                      "tree-props": { children: "children" },
                      "row-style": { height: "54px" },
                    },
                    on: { "selection-change": _vm.changeSelectItems },
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _vm.dataList.length === 0 && !_vm.listLoading
                        ? _c(
                            "div",
                            { staticClass: "table-content-null" },
                            [_c("no-data")],
                            1
                          )
                        : _c("div"),
                    ]),
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "40" },
                    }),
                    _vm._l(_vm.columns, function (item, index) {
                      return [
                        _c("el-table-column", {
                          attrs: {
                            prop: item.prop,
                            label: item.label,
                            resizable: false,
                            "min-width": item.minWidth,
                            width: item.minWidth,
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    item.prop === "delete"
                                      ? _c(
                                          "div",
                                          { staticClass: "table-content" },
                                          [
                                            !(
                                              scope.row.typeIdentifying === 0 ||
                                              (_vm.indexType == 0 &&
                                                scope.row.typeIdentifying === 4)
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "operation-wrap",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "delete",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delField(
                                                              scope
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : item.prop === "originatorName"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "cooperate-people",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.chargeUserPickerVisibleClick(
                                                  scope
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "user-picker",
                                              {
                                                ref: "userPicker",
                                                refInFor: true,
                                                on: {
                                                  pick: function ($event) {
                                                    return _vm.chargeUserPicker(
                                                      $event,
                                                      scope
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "cooperation-add",
                                                  },
                                                  [
                                                    scope.row.originatorId ===
                                                    null
                                                      ? _c("i", {
                                                          staticClass:
                                                            "head-icon border-radius-20",
                                                        })
                                                      : _vm._e(),
                                                    scope.row.originatorId ===
                                                    null
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "head-icon-label",
                                                          },
                                                          [_vm._v("待认领")]
                                                        )
                                                      : _vm._e(),
                                                    scope.row.originatorId !==
                                                    null
                                                      ? _c("el-image", {
                                                          staticClass:
                                                            "avatar border-radius-20",
                                                          attrs: {
                                                            fit: "cover",
                                                            src:
                                                              scope.row
                                                                .originatorAvatar ||
                                                              require("@/assets/imgs/common/avatar.png"),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    scope.row.originatorId !==
                                                    null
                                                      ? _c(
                                                          "p",
                                                          [
                                                            _c("wx-open-data", {
                                                              attrs: {
                                                                id: scope.row[
                                                                  item.prop
                                                                ],
                                                                nameData: {
                                                                  name: scope
                                                                    .row[
                                                                    item.prop
                                                                  ],
                                                                },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : item.prop === "startDate"
                                      ? _c(
                                          "div",
                                          { staticClass: "table-content" },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "date-box",
                                              attrs: {
                                                format: "yyyy-MM-dd HH:mm",
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm",
                                                type: "datetime",
                                                placeholder: "开始时间",
                                                "picker-options":
                                                  _vm.pickerOptionsStart(
                                                    scope.row
                                                  ),
                                                "popper-class": "addTimeClass",
                                                clearable: false,
                                              },
                                              on: {
                                                change: _vm.startDateChange,
                                              },
                                              model: {
                                                value: scope.row.startDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "startDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.startDate",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : item.prop === "endDate"
                                      ? _c(
                                          "div",
                                          { staticClass: "table-content" },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "date-box",
                                              attrs: {
                                                format: "yyyy-MM-dd HH:mm",
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm",
                                                type: "datetime",
                                                placeholder: "截止时间",
                                                "picker-options":
                                                  _vm.pickerOptionsEnd(
                                                    scope.row
                                                  ),
                                                "popper-class": "addTimeClass",
                                                clearable: false,
                                              },
                                              on: { change: _vm.endDateChange },
                                              model: {
                                                value: scope.row.endDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "endDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.endDate",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : item.prop === "title"
                                      ? _c(
                                          "div",
                                          { staticClass: "table-content" },
                                          [
                                            _c(
                                              "task-title-alert",
                                              {
                                                attrs: {
                                                  slot: "okrTableContent",
                                                  "obj-data": scope.row,
                                                },
                                                on: {
                                                  refresh: function ($event) {
                                                    return _vm.taskTitleRefresh(
                                                      $event,
                                                      scope
                                                    )
                                                  },
                                                },
                                                slot: "okrTableContent",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "task-title" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row[item.prop] ||
                                                          "-"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : item.prop === "information"
                                      ? _c("div", {
                                          staticClass:
                                            "table-content information",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              scope.row[item.prop] || "-"
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.descClick(scope)
                                            },
                                          },
                                        })
                                      : [
                                          _vm._v(
                                            _vm._s(scope.row[item.prop] || "-")
                                          ),
                                        ],
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.listLoading
          ? _c("div", { staticClass: "task-list-wrap loading-wrap" }, [
              _c(
                "div",
                { staticClass: "step-list-wrap" },
                _vm._l(_vm.stepTitleList, function (item, index) {
                  return index <= _vm.stepLoading
                    ? _c(
                        "div",
                        {
                          key: item,
                          class: "step-list " + (index > _vm.stepLoading),
                        },
                        [
                          _vm.stepLoading === index
                            ? _c("i", { staticClass: "el-icon-loading icon" })
                            : _c("Icon", {
                                class: "icon " + (index > _vm.stepLoading),
                                attrs: {
                                  "icon-class": "icon-a-daibanyiwancheng1",
                                },
                              }),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(item)),
                          ]),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                0
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "bottom-step-buttons" },
          [
            _c(
              "el-button",
              {
                staticClass: "left-button",
                attrs: { disabled: _vm.listLoading },
                on: { click: _vm.handleClose },
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                attrs: {
                  loading: _vm.loading,
                  disabled:
                    _vm.listLoading ||
                    _vm.dataList.length === 0 ||
                    _vm.submitLoading,
                },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v("一键生成任务")]
            ),
          ],
          1
        ),
      ]),
      _vm.customDialogvisible
        ? _c("custom-dialog", {
            on: { submit: _vm.customDialogSubmit },
            model: {
              value: _vm.customDialogvisible,
              callback: function ($$v) {
                _vm.customDialogvisible = $$v
              },
              expression: "customDialogvisible",
            },
          })
        : _vm._e(),
      _vm.descDialogVisible
        ? _c("desc-dialog", {
            attrs: { content: _vm.information },
            on: { submit: _vm.descDialogSubmit },
            model: {
              value: _vm.descDialogVisible,
              callback: function ($$v) {
                _vm.descDialogVisible = $$v
              },
              expression: "descDialogVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }