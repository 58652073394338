var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "okr-type" },
    _vm._l(_vm.labelObj, function (item) {
      return _c(
        "div",
        {
          key: item.value,
          class: "okr-type-list",
          on: {
            click: function ($event) {
              return _vm.selectActiveLabel(item)
            },
          },
        },
        [
          _c(
            "div",
            { class: "icon-wrap", style: { "--color": item.color } },
            [
              _c("Icon", {
                class: "icon icon-" + (_vm.labelTypeVal === item.value),
                attrs: { "icon-class": "icon-draw_checked" },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              class: "name name-" + (_vm.labelTypeVal === item.value),
              style: { "--color": item.color },
            },
            [_vm._v(_vm._s(item.label))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }