<template>
  <el-dialog
    title="欢迎您使用源目标 AI 助手"
    :visible.sync="dialogVisible"
    width="680px"
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="handleClose"
    v-on="$listeners"
  >
    <div class="ai-set-wrap">
      <el-form ref="informationsForm" slot="cont" :rules="rules" :model="form" label-width="0px">
        <ul class="base-info f-z-12">
          <li class="info-item m-b-30" v-show="false">
            <span class="m-r-15 setting_span setting_span_avatar">企业logo：</span>
            <el-upload
              class="avatar-uploader float_lf"
              :show-file-list="false"
              action
              accept="image/png, image/jpg, image/jpeg"
              :http-request="upLoadsCompress"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <div>
                <!-- <img
                  v-if="companyInfo.logo"
                  :src="companyInfo.logo"
                  class="avatar"
                  @mouseover="img_state = 1"
                  @mouseleave="img_state = 0"
                />-->
                <el-image
                  v-if="companyInfo.logo"
                  :src="companyInfo.logo"
                  class="avatar"
                  @mouseover="img_state = 1"
                  @mouseleave="img_state = 0"
                  fit="cover"
                />
                <img
                  v-else
                  src="../../../../../assets/imgs/header/logo.png"
                  class="m-r-10 avatar-img"
                  @mouseover="img_state = 1"
                  @mouseleave="img_state = 0"
                />
                <img
                  v-show="img_state == 1"
                  src="../../../../../assets/imgs/header/li_head_change_icon.png"
                  class="avatar-img"
                  style="position: absolute; left: 0; top: 0"
                  @mouseover="img_state = 1"
                  @mouseleave="img_state = 0"
                />
              </div>
            </el-upload>
          </li>
          <li class="info-item m-b-30" v-show="false">
            <span class="m-r-15 setting_span">主管理员：</span>
            <p v-if="companyInfo_.belongerName" class="setting_p">
              <wx-open-data
                :id="companyInfo_.belongerName"
                :nameData="{name:companyInfo_.belongerName}"
              />
            </p>
          </li>
          <li class="info-item m-b-30" v-show="false">
            <span class="m-r-15 setting_span setting-self">企业简称：</span>
            <el-form-item label prop="enterpriseName" class="float_lf float_lf_w480">
              <el-input
                v-model="form.enterpriseName"
                class="inp"
                auto-complete="off"
                placeholder="请输入企业名称"
                :disabled="$isQw || $isDD"
                @input="nameInput"
              />
            </el-form-item>
          </li>
          <li class="info-item m-b-30" v-show="false">
            <span class="m-r-15 setting_span setting-self">企业全称：</span>
            <el-form-item label prop="attestationCompanyName" class="float_lf">
              <el-input
                v-model="form.attestationCompanyName"
                class="inp full-name"
                auto-complete="off"
                placeholder="请输入企业全称"
                :disabled="(form.attestationState || '') ==1 || $isQw || $isDD"
                @input="nameInput"
              />
              <div
                class="attestation-state"
                :class="(form.attestationState || '') ==1 ? 'blue': ''"
              >{{(form.attestationState || '') == 1 ? '已认证' : '未认证'}}</div>
            </el-form-item>
          </li>
          <div class="ai-infor-wrap">
            <!-- <div class="title">欢迎您使用源目标 AI 助手</div> -->
            <div class="ai-title">源目标AI助手将帮助您便捷的快速编写目标 O 与关键结果 KR，同时我们将会帮助您生产指导性的工作任务</div>
            <div class="remind-title">在正式使用AI助写功能前，请填写以下内容，以便于AI助手更好的帮助您输出内容</div>
          </div>
          <li class="info-item m-b-30">
            <span class="m-r-15 setting_span setting-self">成立时间：</span>
            <el-form-item label prop="establishmentDate" class="el_select_lf float_lf_w480">
              <el-select
                v-model="form.establishmentDate"
                :poper-append-to-body="false"
                placeholder="请选择成立时间"
                class="float_lf_w480"
              >
                <el-option v-for="item in createTimeList" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
          </li>
          <li class="info-item m-b-30">
            <span class="m-r-15 setting_span setting-self">企业规模：</span>
            <el-form-item label prop="enterpriseType" class="el_select_lf float_lf_w480">
              <el-select
                v-model="form.enterpriseType"
                :poper-append-to-body="false"
                clearable
                placeholder="请选择"
                class="float_lf_w480"
              >
                <el-option
                  v-for="item in enterpriseType"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </li>
          <li class="info-item m-b-30">
            <span class="m-r-15 setting_span setting-self">所属行业：</span>
            <el-form-item label prop="industryCategory" class="el_select_lf float_lf_w240 m_lf_16">
              <el-select
                v-model="form.industryCategory"
                :poper-append-to-body="false"
                clearable
                placeholder="请选择"
                @change="choseIndustryCategory"
              >
                <el-option
                  v-for="item in industryCategory"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label class="el_select_lf float_lf_w240">
              <el-select
                v-model="form.industryCategory_1"
                :poper-append-to-body="false"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in industryCategory_1"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </li>
          <li class="info-item m-b-30">
            <span class="m-r-15 setting_span">企业使命：</span>
            <el-form-item label prop="mission" class="float_lf float_lf_w480">
              <el-tooltip
                placement="top"
                :open-delay="300"
                :content="form.mission"
                :disabled="!form.mission"
              >
                <el-input
                  type="textarea"
                  :rows="5"
                  v-model="form.mission"
                  class="inp"
                  auto-complete="off"
                  placeholder="请输入企业使命"
                  @input="missionInput"
                />
              </el-tooltip>
            </el-form-item>
          </li>
          <li class="info-item m-b-30">
            <span class="m-r-15 setting_span">企业愿景：</span>
            <el-form-item label prop="vision" class="float_lf float_lf_w480">
              <el-tooltip
                placement="top"
                :open-delay="300"
                :content="form.vision"
                :disabled="!form.vision"
              >
                <el-input
                  type="textarea"
                  :rows="5"
                  v-model="form.vision"
                  class="inp"
                  auto-complete="off"
                  placeholder="请输入企业愿景"
                  @input="visionInput"
                />
              </el-tooltip>
            </el-form-item>
          </li>
          <li class="info-item m-b-30">
            <span class="m-r-15 setting_span">企业价值观：</span>
            <el-form-item label prop="corporateValues" class="float_lf float_lf_w480">
              <el-tooltip
                placement="top"
                :open-delay="300"
                :content="form.corporateValues"
                :disabled="!form.corporateValues"
              >
                <el-input
                  type="textarea"
                  :rows="5"
                  v-model="form.corporateValues"
                  class="inp"
                  :class="isTextareaInfo?'textarea-info':''"
                  auto-complete="off"
                  placeholder="请输入价值观"
                  @input="senseOfWorthInput"
                  @blur="senseOfWorthBlue"
                />
              </el-tooltip>
            </el-form-item>
          </li>
          <div class="ai-count-wrap">
            剩余可调用次数：
            <span>{{companyInfo.aiUsageCount || 0}}</span> 次 （更多订阅请咨询售前顾问）
          </div>
        </ul>
      </el-form>
      <div class="bottom-step-buttons">
        <el-button class="left-button" @click="handleClose">取消</el-button>
        <el-button @click="saveBtn('informationsForm')">保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
/* eslint-disable */
export default {
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    dialogShow: {
      type: Boolean
    }
  },
  data() {
    var re = new RegExp(
      /^[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/
    )
    var reg = /^[\da-z]+$/i
    var validatedomain = (rule, value, callback) => {
      callback()
    }
    return {
      dialogVisible: this.dialogShow,
      img_state: 0,
      companyInfo_: {}, // 企业信息
      grantDate: '', // 授权日期
      expireDate: '', // 授权到期
      ascriptionPerson: '',
      industryCategory_1: '', //行业数组
      form: {
        mission: '', //企业使命
        vision: '', //企业愿景
        corporateValues:'', //企业价值观
        enterpriseName: '', //企业简称
        attestationCompanyName: '', //企业全称
        domainName: '', //域名
        enterpriseType: '', //公司员工数量
        industryCategory: '', //行业
        industryCategory_1: '',
        establishmentDate: ''
      },
      isTextareaInfo:false,
      // 校验规则
      rules: {
        enterpriseName: [
          { required: true, message: '企业简称不能为空', trigger: 'blur' }
        ],
        attestationCompanyName: [
          { required: false, message: '企业全称不能为空', trigger: 'blur' }
        ],
        // mission: [
        //   { required: true, message: '企业使命不能为空', trigger: 'blur' }
        // ],
        // vision: [
        //   { required: true, message: '企业愿景不能为空', trigger: 'blur' }
        // ],
        // corporateValues: [
        //   { required: true, message: '企业价值观不能为空', trigger: 'blur' }
        // ],
        domainName: [
          { required: true, validator: validatedomain, trigger: 'blur' }
        ],
        enterpriseType: [
          { required: false, message: '公司规模不能为空', trigger: ['blur'] }
        ],
        industryCategory: [
          { required: false, message: '行业类型不能为空', trigger: ['blur'] }
        ],
        establishmentDate: [
          { required: false, message: '成立时间不能为空', trigger: ['blur'] }
        ]
      },
      enterpriseType: [],
      industryCategory: [],
      createTimeList: ['1年','2年','3年','4年','5年','6年','7年','8年','9年','10年','11~15年','15~20年','20年以上']
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      },
      immediate: true
    }
  },
  created() {
    this.getUserInfos()
  },
  mounted() {
    var that = this
    that.$on('global:backInformations', () => {
      that.getUserInfos()
    })
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.$emit('change', false)
    },
    //上传成功
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    //上传格式限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    senseOfWorthInput(){
      let _obj = {}
      _obj.corporateValues = this.form.corporateValues.replace(/\s*/g, '') //企业价值观;
      if (_obj.corporateValues.replace(/\s*/g, '').length > 500) {
        this.$message({
          msg: '企业价值观最多可以输入 500 个字符哦!',
          type: 'warn'
        })
        this.form.corporateValues = _obj.corporateValues.substring(0, 500)
      }
    },
    senseOfWorthBlue(){
      if (this.form.corporateValues.length ===0) {
        this.isTextareaInfo=true
      } else {
        this.isTextareaInfo=false
      }
    },
    visionInput() {
      let _obj = {}
      _obj.vision = this.form.vision.replace(/\s*/g, '') //企业愿景;
      if (_obj.vision.replace(/\s*/g, '').length > 200) {
        this.$message({
          msg: '企业愿景最多可以输入 200 个字符哦!',
          type: 'warn'
        })
        this.form.vision = _obj.vision.substring(0, 200)
      }
    },
    missionInput() {
      let _obj = {}
      _obj.mission = this.form.mission.replace(/\s*/g, '') //企业使命
      if (_obj.mission.replace(/\s*/g, '').length > 200) {
        this.$message({
          msg: '企业使命最多可以输入 200 个字符哦!',
          type: 'warn'
        })
        this.form.mission = _obj.mission.substring(0, 200)
      }
    },
    nameInput() {
      let _obj = {}
      _obj.name = this.form.enterpriseName.replace(/\s*/g, '') //公司名称
      if (_obj.name.replace(/\s*/g, '').length > 30) {
        this.$message({
          msg: '公司名称最多可以输入 30 个字符哦!',
          type: 'warn'
        })
        this.form.enterpriseName = _obj.name.substring(0, 30)
      }
    },
    //保存
    saveBtn(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //调用后台接口传输数据======================================================================
          let _obj = {}
          _obj.domain = this.form.domainName //域名
          _obj.vision = (this.form.vision || '').replace(/\s*/g, '') //企业愿景;
          _obj.corporateValues = (this.form.corporateValues || '').replace(/\s*/g, '') //企业价值观;
          _obj.mission = (this.form.mission || '').replace(/\s*/g, '') //企业使命
          _obj.name = this.form.enterpriseName.replace(/\s*/g, '') //公司简称
          _obj.attestationCompanyName = this.form.attestationCompanyName && this.form.attestationCompanyName.replace(/\s*/g, '') //公司全称
          _obj.scope = this.form.enterpriseType //公司规模
          _obj.industryId = this.form.industryCategory //公司行业id_1
          _obj.industryChildrenId = this.form.industryCategory_1 //公司行业id_2
          _obj.logo = this.companyInfo.logo //公司行业id_2
          _obj.id = this.companyInfo.id //公司行业id_2
          _obj.establishmentDate = this.form.establishmentDate
          // if (!_obj.vision || _obj.vision.replace(/\s*/g, '').length == 0) {
          //   that.$message({ msg: '企业愿景不能为空!', type: 'warn' })
          // } else 
          // else if(_obj.corporateValues.replace(/\s*/g, '').length > 500){
          //   that.$message({
          //     msg: '企业价值观最多可以输入 500 个字符哦!',
          //     type: 'warn'
          //   })
          // } else if (
          //   !_obj.mission ||
          //   _obj.mission.replace(/\s*/g, '').length == 0
          // ) {
          //   that.$message({ msg: '企业使命不能为空!', type: 'warn' })
          // }
          if (_obj.vision.replace(/\s*/g, '').length > 200) {
            that.$message({
              msg: '企业愿景最多可以输入 200 个字符哦!',
              type: 'warn'
            })
          } else if (_obj.mission.replace(/\s*/g, '').length > 200) {
            that.$message({
              msg: '企业使命最多可以输入 200 个字符哦!',
              type: 'warn'
            })
          } else if (!_obj.name || _obj.name.replace(/\s*/g, '').length == 0) {
            that.$message({ msg: '公司名称不能为空!', type: 'warn' })
          } else if (_obj.name.replace(/\s*/g, '').length > 30) {
            that.$message({
              msg: '公司名称最多可以输入 30 个字符哦!',
              type: 'warn'
            })
          }
          //  else if (!this.$isQw && !this.isDD && (!_obj.attestationCompanyName || _obj.attestationCompanyName.replace(/\s*/g, '').length == 0)) {
          //   that.$message.warn('公司全称不能为空!')
          // }
           else if ((_obj.establishmentDate || '').replace(/\s*/g, '').length == 0) {
            that.$message({ msg: '请选择成立时间哦!', type: 'warn' })
          }  else if ((_obj.scope || '').replace(/\s*/g, '').length == 0) {
            that.$message({ msg: '请选择企业规模哦!', type: 'warn' })
          } else if (
            this.form.industryCategory.length == 0 ||
            this.form.industryCategory_1.length == 0
          ) {
            that.$message({ msg: '请选择行业哦!', type: 'warn' })
          } else {
            that.saveInfos(_obj)
          }
        } else {
          return false
        }
      })
    },
    //==============================   API ===================
    //保存企业信息
    saveInfos(param) {
      //接口待定======================================
      this.$apis.ADMINGETCOMPANYUPDATEINFO(param).then((res) => {
        if (res.status == 200) {
          //更新本地公司信息
          res.data.aiUsageCount = this.companyInfo.aiUsageCount
          this.$store.commit('user/setCompanyInfo', res.data)
          this.$message.success('修改成功!')
          this.handleClose()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 加载行业，二级
    getEnterpriseType() {
      var that = this
      this.$apis.ADMINDICTIONARYUSERCOUNT().then((res) => {
        if (res.status == '200') {
          that.enterpriseType = res.data
        } else {
          that.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 加载行业，二级
    gethangyeData() {
      var that = this
      this.$apis.ADMINDICTIONARYLISTTWO().then((res) => {
        if (res.status == '200') {
          that.industryCategory = res.data
          //后台数据回显
          if (that.form.industryCategory && that.form.industryCategory_1) {
            for (var index2 in that.industryCategory) {
              if (
                that.form.industryCategory == that.industryCategory[index2].id
              ) {
                that.industryCategory_1 =
                  that.industryCategory[index2].children || []
              }
            }
          }
        } else {
          that.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 选行业
    choseIndustryCategory: function (e) {
      let hasChild = false
      for (var index2 in this.industryCategory) {
        if (e === this.industryCategory[index2].id) {
          hasChild = true
          this.industryCategory_1 = this.industryCategory[index2].children || []
          if (this.industryCategory[index2].children.length) {
            this.form.industryCategory_1 =
              this.industryCategory[index2].children[0].id
          } else {
            this.form.industryCategory_1 = ''
          }
        }
      }
      if (!hasChild) {
        this.industryCategory_1 = []
        this.form.industryCategory_1 = ''
      }
    },
    //获取行业信息
    getUserInfos() {
      let param = {}
      param.username = this.companyInfo.id
      this.$apis.ADMINDICTIONARYLISTONE(param.username).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            this.companyInfo_ = res.data
            this.form.mission = res.data.mission //企业使命
            this.form.vision = res.data.vision //企业愿景
            this.form.corporateValues = res.data.corporateValues?res.data.corporateValues:"" //企业价值观
            this.form.domainName = res.data.domain //域名
            this.form.enterpriseName = res.data.name //企业名称
            this.form.attestationCompanyName  = res.data.attestationCompanyName //企业名称
            this.form.attestationState  = res.data.attestationState
            this.form.establishmentDate  = res.data.establishmentDate
            if (
              res.data.scope == null ||
              res.data.scope == 0 ||
              res.data.scope == undefined
            ) {
              this.form.enterpriseType = '' //企业规模
            } else {
              this.form.enterpriseType = res.data.scope //企业规模
            }
            this.form.industryCategory = res.data.industryId || '25' //行业1
            this.form.industryCategory_1 = res.data.industryChildrenId || '44' //行业2
            //请求字典
            this.getEnterpriseType()
            this.gethangyeData()
          }
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    upLoadsCompress(file) {
      var that = this
      // if (file.size / 1024 > 1025) {
        // 文件大于1M（根据需求更改），进行压缩上传
      this.photoCompress(
        file.file,
        {
          // 调用压缩图片方法
          quality: 0.5,
        },
        function (base64Codes) {
          // console.log("压缩后：" + base.length / 1024 + " " + base);
          let bl = that.base64UrlToBlob(base64Codes);
          // file.append('file', bl, 'file_' + Date.parse(new Date()) + '.jpg') // 文件对象
          that.upLoads(bl); // 请求图片上传接口
        }
      )
      return
    },
    /**
     * base64 转 Blob 格式 和file格式
     */
    base64UrlToBlob(urlData) {
      let arr = urlData.split(","),
        mime = arr[0].match(/:(.*?);/)[1], // 去掉url的头，并转化为byte
        bstr = atob(arr[1]), // 处理异常,将ascii码小于0的转换为大于0
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // 转blob
      // return new Blob([u8arr], {type: mime})
      let filename = Date.parse(new Date()) + ".jpg";
      // 转file
      return new File([u8arr], filename, { type: mime });
    },
    /*
      压缩图片
      file：文件(类型是图片格式)，
      obj：文件压缩后对象width， height， quality(0-1)
      callback：容器或者回调函数
    */
    photoCompress(file, obj, callback) {
      let that = this;
      let ready = new FileReader();
      /* 开始读取指定File对象中的内容. 读取操作完成时,返回一个URL格式的字符串. */
      ready.readAsDataURL(file);
      ready.onload = function () {
        let re = this.result;
        that.canvasDataURL(re, obj, callback); // 开始压缩
      };
    },
    /* 利用canvas数据化图片进行压缩 */
    /* 图片转base64 */
    canvasDataURL(path, obj, callback) {
      let img = new Image();
      img.src = path;
      img.onload = function () {
        let that = this; // 指到img
        // 默认按比例压缩
        let w = that.width,
          h = that.height,
          scale = w / h;
        w = obj.width || w;
        h = obj.height || w / scale;
        let quality = 0.5; // 默认图片质量为0.7
        // 生成canvas
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        // 创建属性节点
        let anw = document.createAttribute("width");
        anw.nodeValue = w;
        let anh = document.createAttribute("height");
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        ctx.drawImage(that, 0, 0, w, h);
        // 图像质量
        if (obj.quality && obj.quality >= 1 && obj.quality < 0) {
          quality = obj.quality;
        }
        // quality值越小，所绘制出的图像越模糊
        let base64 = canvas.toDataURL("image/jpeg", quality);
        // 回调函数返回base64的值
        callback(base64);
      };
    },
    //上传图片
    upLoads(file) {
      const formData = new FormData()
      formData.append('file', file)
      let _obj = {}
      this.$apis.ADMINGETCOMPANYUPDATELOGO(formData).then((res) => {
        if (res.status == '200') {
          this.companyInfo_.logo = res.data.url
          this.companyInfo.logo = res.data.url // 请求成功之后赋给头像的URL
          this.$store.commit('user/setCompanyInfo', this.companyInfo_)
          this.$message.success('图片上传成功')
        } else {
          this.$message('图片上传失败')
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.ai-count-wrap {
  line-height: 28px;
  color: #a0a3bd;
  font-size: 12px;
  margin-bottom: 24px;
  span {
    color: #ed2e7e;
  }
}
.bottom-step-buttons {
  margin: 24px 0px 0px 0px;
  padding: 0px 32px;
  .flex-l();
  justify-content: center;
  .left-button,
  .el-button {
    border-radius: 8px;
    height: 32px;
  }
  .left-button {
    background: none !important;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
@import '../../../../../assets/styles/variable.scss';
.ai-infor-wrap {
  .title {
    font-size: 18px;
    color: #14142b;
    font-weight: 700;
  }
  .ai-title {
    color: #a0a3bd;
    font-size: 12px;
    margin: 4px 0px 16px 0px;
  }
  .remind-title {
    margin: 0px 0px 16px 0px;
    font-size: 12px;
    color: #ed2e7e;
  }
}
::v-deep {
  .el-textarea {
    border: none !important;
  }
  .el-textarea__inner {
    border-radius: 12px;
    border: 1px solid #f5f6fa !important;
    font-size: 14px;
    background: #fff !important;
    cursor: pointer;
    &:hover {
      color: #3a78f6 !important;
    }
  }
}
// .textarea-info {
//   box-shadow: inset 0px -3px 12px 0px rgba(255, 87, 43, 0.12) !important;
//   border: 1px solid #ff572b !important;
// }
::v-deep .el-form-item__content {
  display: flex;
  .el-input.full-name {
    flex: 1;
    min-width: 320px;
  }
  .attestation-state {
    color: #a0a3bd;
    margin-left: 24px;
  }
  .blue {
    color: #3a78f6;
  }
}
.el-icon-plus {
  display: none !important;
}
.el-form-item {
  margin: 0 !important;
}
.base-info {
  height: calc(100vh - 190px);
  @include scroll-beautify;
  .setting_span {
    color: #14142b;
    font-weight: bold;
    font-size: 14px;
    width: 100px;
    text-align: right;
    float: left;
    line-height: 40px;
    min-height: 1px;
  }
  .setting_span_avatar {
    line-height: 80px;
  }
  .el_select_lf {
    float: left;
  }
  .setting_p {
    color: $mine-shaft;
    font-size: 14px;
    line-height: 34px;
  }
  .float_lf {
    float: left;
    // width: 160px;
  }
  .avatar-uploader {
    position: relative;
  }
  .float_lf_w480 {
    width: 480px !important;
  }
  .float_lf_w240 {
    width: 232px !important;
  }
  .m_lf_16 {
    margin-right: 16px !important;
  }
  .avatar-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
  }
  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
  }
  .m-b-50 {
    margin-bottom: 50px;
  }
  .info-item:after {
    content: '';
    display: block;
    clear: both;
    overflow: hidden;
  }
  .save_btns {
    width: 330px;
    height: 36px;
    line-height: 36px;
    border: 0;
    font-size: 14px;
    color: #fff;
  }
}
</style>
