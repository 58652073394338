var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "comm-check-form",
      attrs: {
        title: "复制目标",
        visible: _vm.dialogVisible,
        width: "520px",
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c(
          "div",
          { staticClass: "form-title" },
          [
            _vm._v(" 复制内容 "),
            _c(
              "el-tooltip",
              {
                attrs: {
                  "open-delay": 300,
                  placement: "top-start",
                  offset: 3,
                  content:
                    "将原有目标重新复制一份到所选周期内，不包含目标对齐关系、目标进度、KR进度、目标动态及点赞评论等。勾选KR任务及子任务后，将会把KR下的任务和子任务一起复制一份。",
                },
              },
              [_c("i", { staticClass: "el-icon-warning-outline info-icon" })]
            ),
          ],
          1
        ),
        _c("p", { staticClass: "info" }, [
          _vm._v("复制的目标不包含目标对齐关系"),
        ]),
        _c(
          "div",
          { staticClass: "comm-check" },
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.hasTask,
                  callback: function ($$v) {
                    _vm.hasTask = $$v
                  },
                  expression: "hasTask",
                },
              },
              [_vm._v("KR任务及子任务")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-title" },
          [
            _vm._v(" 复制到周期 "),
            _c("el-cascader", {
              staticClass: "cycle-select",
              attrs: {
                options: _vm.cycleList,
                props: {
                  expandTrigger: "hover",
                  value: "id",
                  label: "name",
                  children: "cycleInfoList",
                },
              },
              model: {
                value: _vm.cycleId,
                callback: function ($$v) {
                  _vm.cycleId = $$v
                },
                expression: "cycleId",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "bottom-step-buttons" }, [
          _c(
            "button",
            { staticClass: "left-button", on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
          _c("button", { on: { click: _vm.save } }, [_vm._v("确定")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }