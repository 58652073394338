var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        "popper-class": "kr-process-update",
        trigger: "click",
        disabled: _vm.disabled,
        width: 320,
      },
      on: { show: _vm.initData },
      model: {
        value: _vm.krProcessUpdateShow,
        callback: function ($$v) {
          _vm.krProcessUpdateShow = $$v
        },
        expression: "krProcessUpdateShow",
      },
    },
    [
      _c("div", { staticClass: "obj-title-alert" }, [
        _c(
          "div",
          { staticClass: "at-content-wrap" },
          [
            _c("el-input", {
              staticClass: "input-focus",
              attrs: {
                type: "textarea",
                placeholder: "+ 添加任务 例如：完成产品说明手册 ↵",
                rows: "6",
              },
              model: {
                value: _vm.dataObj.title,
                callback: function ($$v) {
                  _vm.$set(_vm.dataObj, "title", $$v)
                },
                expression: "dataObj.title",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottom-step-buttons" },
          [
            _c(
              "button",
              { staticClass: "left-button", on: { click: _vm.alertClose } },
              [_vm._v("取消")]
            ),
            _c(
              "L-button",
              {
                staticClass: "alert_box_submit_button2",
                on: {
                  clickEvt: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { attrs: { slot: "reference" }, slot: "reference" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }