var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          title: " AI 助写 KR",
          visible: _vm.dialogVisible,
          width: "920px",
          "close-on-click-modal": false,
          "append-to-body": true,
          "before-close": _vm.handleClose,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "ai-count-wrap" }, [
        _vm._v(" 剩余可调用次数： "),
        _c("span", [_vm._v(_vm._s(_vm.companyInfo.aiUsageCount || 0))]),
        _vm._v(" 次 （更多订阅请咨询售前顾问） "),
      ]),
      _c("div", { staticClass: "kr-content-wrap" }, [
        _vm.isEdit
          ? _c("div", { staticClass: "kr-title" }, [
              _c("div", { staticClass: "kr-index" }, [
                _vm._v("KR" + _vm._s(_vm.krIndex + 1)),
              ]),
              _c("div", {
                staticClass: "title",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$utils.changeToHtml(_vm.editInfo.title)
                  ),
                },
              }),
            ])
          : _c("div", { staticClass: "kr-title" }, [
              _c(
                "div",
                { staticClass: "kr-index" },
                [
                  _c("Icon", {
                    staticClass: "icon",
                    attrs: { "icon-class": "icon-caidanlan-wodeOKR" },
                  }),
                ],
                1
              ),
              _c("div", {
                staticClass: "title",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$utils.changeToHtml(_vm.objData.content)
                  ),
                },
              }),
            ]),
        !_vm.listLoading
          ? _c("div", { staticClass: "kr-infor" }, [
              _vm._v(
                "AI 助手已经自动生成KR，可以根据需求修改内容，并导入至系统中"
              ),
            ])
          : _vm._e(),
        !_vm.listLoading
          ? _c(
              "div",
              { staticClass: "task-list-wrap" },
              [
                _c("key-results-edit", {
                  key: _vm.krContent,
                  ref: "keyResultsEdit",
                  attrs: {
                    "is-share": _vm.objData.isShare,
                    "is-edit": _vm.isEdit,
                    "edit-info": _vm.krContent,
                    okrDataObj: _vm.objData,
                    noRequest: true,
                  },
                  on: {
                    saveContent: function ($event) {
                      return _vm.saveContent($event, _vm.index)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.listLoading
          ? _c("div", { staticClass: "task-list-wrap loading-wrap" }, [
              _c(
                "div",
                { staticClass: "step-list-wrap" },
                _vm._l(_vm.stepTitleList, function (item, index) {
                  return index <= _vm.stepLoading
                    ? _c(
                        "div",
                        {
                          key: item,
                          class: "step-list " + (index > _vm.stepLoading),
                        },
                        [
                          _vm.stepLoading === index
                            ? _c("i", { staticClass: "el-icon-loading icon" })
                            : _c("Icon", {
                                class: "icon " + (index > _vm.stepLoading),
                                attrs: {
                                  "icon-class": "icon-a-daibanyiwancheng1",
                                },
                              }),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(item)),
                          ]),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                0
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "bottom-step-buttons" },
          [
            _c(
              "el-button",
              {
                staticClass: "left-button",
                attrs: { disabled: _vm.listLoading },
                on: { click: _vm.handleClose },
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                staticClass: "left-button",
                attrs: { disabled: _vm.listLoading || _vm.submitLoading },
                on: {
                  click: function ($event) {
                    return _vm.submitRefresh()
                  },
                },
              },
              [_vm._v("重新生成")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                attrs: {
                  loading: _vm.loading,
                  disabled: _vm.listLoading || _vm.submitLoading,
                },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ]),
      _vm.aiSetvisible
        ? _c("ai-set", {
            attrs: { data: JSON.parse(JSON.stringify(_vm.setData)) },
            on: { submit: _vm.submitSet },
            model: {
              value: _vm.aiSetvisible,
              callback: function ($$v) {
                _vm.aiSetvisible = $$v
              },
              expression: "aiSetvisible",
            },
          })
        : _vm._e(),
      _vm.customDialogvisible
        ? _c("custom-dialog", {
            attrs: { type: "kr" },
            on: { submit: _vm.getKrContent },
            model: {
              value: _vm.customDialogvisible,
              callback: function ($$v) {
                _vm.customDialogvisible = $$v
              },
              expression: "customDialogvisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }