var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "avatar-box",
      class: _vm.canDelete
        ? "del-avatar avatar-box-" + _vm.type
        : "avatar-box-" + _vm.type,
    },
    [
      _vm.type == 1
        ? _c("img", {
            staticClass: "border-radius-20",
            attrs: {
              src:
                _vm.avatarInfo.avatar ||
                require("@/assets/imgs/common/avatar.png"),
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "avatar-name" },
        [
          _c("wx-open-data", {
            attrs: {
              type: _vm.type,
              id: _vm.avatarInfo.name || _vm.avatarInfo.username,
              nameData: {
                name: _vm.avatarInfo.name || _vm.avatarInfo.username,
              },
            },
          }),
        ],
        1
      ),
      _vm.canDelete
        ? _c("Icon", {
            staticClass: "icon-close",
            attrs: { "icon-class": "icon-close" },
            on: { click: _vm.deleteAvatar },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }