var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "state-detail-wrap state-detail-type-3" },
    [
      _c("div", { staticClass: "detail-content" }, [
        _c(
          "div",
          { staticClass: "approve-type" },
          [
            _c("Icon", {
              staticClass: "remind",
              attrs: {
                iconClass:
                  _vm.progressList.process.approvalStatus === 3
                    ? "icon-shenpitongguo"
                    : _vm.progressList.process.approvalStatus === 2
                    ? "icon-shenpiweitongguo"
                    : "icon-shenpijinggao",
              },
            }),
            _c("div", { staticClass: "approve-type-content" }, [
              _vm._v(_vm._s(_vm.progressList.processDynamic.actionContent)),
            ]),
            _c("approve-type-label", {
              attrs: {
                objDetails: _vm.taskDetail,
                businessType: _vm.progressList.process.approvalStatus,
                disabled: true,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "approve-user" }, [
          _c("div", { staticClass: "state-text" }, [
            _c(
              "span",
              { staticClass: "name" },
              [
                _c("wx-open-data", {
                  attrs: {
                    id: _vm.progressList.processDynamic.sponsorUserName,
                    nameData: {
                      name: _vm.progressList.processDynamic.sponsorUserName,
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(
              " 提交了" +
                _vm._s(_vm.progressList.processDynamic.actionContent) +
                " "
            ),
          ]),
          _vm.progressList.processDynamic.appointUserName
            ? _c("div", { staticClass: "user" }, [
                _vm._v(" 审批人： "),
                _c(
                  "span",
                  { staticClass: "name" },
                  [
                    _c("wx-open-data", {
                      attrs: {
                        id: _vm.progressList.processDynamic.appointUserName,
                        nameData: {
                          name: _vm.progressList.processDynamic.appointUserName,
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "approve-btn" },
        [
          _vm.progressList.process &&
          _vm.progressList.process.approvalStatus === 1 &&
          _vm.userInfo.id == _vm.progressList.processDynamic.sponsorUserId
            ? _c(
                "div",
                {
                  staticClass: "blue-button yellow-button",
                  on: {
                    click: function ($event) {
                      return _vm.approvalCancel(2)
                    },
                  },
                },
                [_vm._v("撤回审批")]
              )
            : _vm._e(),
          _vm.progressList.process &&
          _vm.progressList.process.approvalStatus === 1 &&
          _vm.userInfo.id == _vm.progressList.processDynamic.appointUserId
            ? [
                _c(
                  "div",
                  {
                    staticClass: "blue-button refuse-button",
                    on: {
                      click: function ($event) {
                        return _vm.approvePassTypeClick(2)
                      },
                    },
                  },
                  [_vm._v("驳回")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "blue-button",
                    on: {
                      click: function ($event) {
                        return _vm.approvePassTypeClick(1)
                      },
                    },
                  },
                  [_vm._v("通过审批")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.approveRefuseVisible
        ? _c("approve-refuse", {
            attrs: {
              progressList: _vm.progressList,
              taskDetail: _vm.taskDetail,
            },
            on: { approvalRefresh: _vm.approvalRefresh },
            model: {
              value: _vm.approveRefuseVisible,
              callback: function ($$v) {
                _vm.approveRefuseVisible = $$v
              },
              expression: "approveRefuseVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }