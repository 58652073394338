var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "转化为子任务",
        visible: _vm.isShow,
        "before-close": _vm.close,
        "append-to-body": "",
        width: "760px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
        open: _vm.init,
      },
    },
    [
      _c(
        "div",
        { staticClass: "search-wrap", attrs: { slot: "title" }, slot: "title" },
        [
          _vm.fromType === "pmDetail"
            ? _c(
                "div",
                { staticClass: "select-left" },
                [
                  _c("select-head", {
                    attrs: { fromType: "copyTask" },
                    on: { change: _vm.refresh },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "search-right" },
            [
              _vm._v(" 复制到： "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px", "margin-right": "16px" },
                  attrs: { placeholder: "请选择项目" },
                  on: { change: _vm.getProjectProcessList },
                  model: {
                    value: _vm.projectId,
                    callback: function ($$v) {
                      _vm.projectId = $$v
                    },
                    expression: "projectId",
                  },
                },
                _vm._l(_vm.allProjectList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { placeholder: "请选择工作流" },
                  model: {
                    value: _vm.processId,
                    callback: function ($$v) {
                      _vm.processId = $$v
                    },
                    expression: "processId",
                  },
                },
                _vm._l(_vm.projectProcessList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { class: "main-wrap " + (_vm.fromType === "pmDetail") }, [
        _vm.fromType === "pmDetail"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "scroll-bottom",
                    rawName: "v-scroll-bottom",
                    value: _vm.loadMoreList,
                    expression: "loadMoreList",
                  },
                ],
                staticClass: "task-list-wrap",
              },
              [
                _vm._l(_vm.taskList, function (item, index) {
                  return _c("task-item", {
                    key: item.id + item.checked,
                    attrs: { index: index, item: item },
                    on: { selectClick: _vm.clickChange },
                  })
                }),
                _vm.taskList.length === 0 && !_vm.loading
                  ? _c("div", { staticClass: "no-content" }, [_c("noData")], 1)
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "bottom-step-buttons" }, [
          _c(
            "button",
            { staticClass: "left-button", on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
          _c("button", { on: { click: _vm.save } }, [_vm._v("完成")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }