var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "single_process_content",
      class: _vm.processShowType === "1" ? "no_hover" : "",
    },
    [
      _c("div", { staticClass: "process_top_info" }, [
        _c("div", {
          staticClass: "process_kr_name",
          domProps: { innerHTML: _vm._s(_vm.processInfo.krName) },
        }),
        _vm.processInfo.quantificationType !== 1
          ? _c("div", { staticClass: "process_num" }, [
              _c("span", { staticClass: "process_old_num" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.processInfo.originalQuantificationActualValue === null
                        ? 0
                        : _vm.processInfo.originalQuantificationActualValue
                    ) +
                    _vm._s(_vm.processInfo.quantificationUnit) +
                    " - "
                ),
              ]),
              _c(
                "span",
                {
                  staticClass: "process_changed_num",
                  class:
                    _vm.processInfo.type == "2"
                      ? "down"
                      : _vm.processInfo.type == "3"
                      ? "nochange"
                      : "",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.processInfo.quantificationActualValue) +
                      _vm._s(_vm.processInfo.quantificationUnit) +
                      " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm.processInfo.quantificationType !== 1
          ? _c(
              "div",
              [
                Number(_vm.processInfo.originalQuantificationActualValue) <
                Number(_vm.processInfo.quantificationActualValue)
                  ? _c("Icon", {
                      staticClass: "process_icon up-icon",
                      attrs: { "icon-class": "icon-arrow-up-line" },
                    })
                  : _vm._e(),
                Number(_vm.processInfo.originalQuantificationActualValue) >
                Number(_vm.processInfo.quantificationActualValue)
                  ? _c("Icon", {
                      staticClass: "process_icon down-icon",
                      attrs: { "icon-class": "icon-arroe-down-line" },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.processInfo.quantificationType === 1
          ? _c("div", { staticClass: "process-status" }, [
              _vm.processInfo.quantificationStatus === 0
                ? _c(
                    "div",
                    { staticClass: "unselect-label" },
                    [
                      _c("Icon", {
                        staticClass: "select-icon",
                        attrs: { "icon-class": "icon-not-finish" },
                      }),
                      _vm._v("未完成 "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.processInfo.quantificationStatus === 1
                ? _c(
                    "div",
                    [
                      _c("Icon", {
                        staticClass: "select-icon",
                        attrs: { "icon-class": "icon-finished" },
                      }),
                      _vm._v("已完成 "),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _c("div", { staticClass: "process_change_time" }, [
          _vm._v(_vm._s(_vm.processInfo.createDate)),
        ]),
      ]),
      _vm.processInfo.explain !== ""
        ? _c("div", { staticClass: "process_title" }, [
            _c(
              "div",
              { staticClass: "process_title_icon_div" },
              [
                _c("Icon", {
                  staticClass: "process_title_icon",
                  attrs: { "icon-class": "icon-process" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { domProps: { innerHTML: _vm._s(_vm.processInfo.explain) } },
              [_vm._v(_vm._s(_vm.processInfo.explain))]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "process_comment_list" },
        _vm._l(_vm.processInfo.commentList, function (item) {
          return _c("trend-item", {
            key: item.id,
            staticClass: "trend_item",
            attrs: { "trend-info": item, "only-comment": true },
            on: { refreshTrend: _vm.refreshComment },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "process_reply" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            !_vm.replyView
              ? _c(
                  "div",
                  {
                    staticClass: "process_btn_reply",
                    on: { click: _vm.showReplyBox },
                  },
                  [_vm._v("回复...")]
                )
              : _vm._e(),
            _vm.replyView
              ? _c(
                  "div",
                  { staticClass: "process_input_reply" },
                  [
                    _c("comment-box", {
                      ref: "commentBox",
                      attrs: {
                        "at-able": false,
                        height: "auto",
                        placeholder: "填写对进度的评论  ↵",
                        "blur-release": true,
                        "file-upload-disabled": true,
                        lineFeed: true,
                      },
                      on: {
                        hideComment: _vm.hideComment,
                        release: _vm.addProcessComment,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }