var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      ref: "taskKrRef",
      attrs: {
        trigger: "click",
        disabled: _vm.disabled,
        "popper-class": "task-kr-pop",
        placement: _vm.placement,
      },
      on: { show: _vm.showHandle },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { staticClass: "kr-list" }, [
        _c("span", { staticClass: "list-title" }, [
          _vm._v(
            _vm._s(
              _vm.source == "stopdoing" ? "变更为关键结果KR任务" : "关键结果"
            )
          ),
        ]),
        !_vm.isOkrShortcut
          ? _c("div", { staticClass: "filter-div" }, [
              _c(
                "div",
                { staticClass: "filter-okr" },
                [
                  _vm.smallCycleId !== ""
                    ? _c(
                        "el-select",
                        {
                          staticClass: "select-cycle",
                          attrs: { "popper-class": "popover-okr-align" },
                          on: { "visible-change": _vm.cycleVisibleChange },
                          model: {
                            value: _vm.smallCycleId,
                            callback: function ($$v) {
                              _vm.smallCycleId = $$v
                            },
                            expression: "smallCycleId",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "select-cycle-wrap",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "cycle-large" },
                                _vm._l(
                                  _vm.okrCycleList.allCycleList,
                                  function (val, key) {
                                    return _c(
                                      "div",
                                      {
                                        key: val.id,
                                        class:
                                          _vm.largeCycleId === val.id
                                            ? "select"
                                            : "",
                                        attrs: {
                                          label: val.name,
                                          value: val.id,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.selectLargeCycleChange(
                                              key
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(val.name) + " ")]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "div",
                                { staticClass: "cycle-small" },
                                _vm._l(
                                  _vm.okrCycleList.smallCycle,
                                  function (val, key) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: val.id,
                                        attrs: {
                                          id: "smallCycleIdOkr" + val.id,
                                          label: "周期：" + val.name,
                                          value: val.id,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.selectCycleChange(key)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(val.name))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-search" },
                [
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: {
                      placeholder: "搜索目标或KR名称",
                      "prefix-icon": "el-icon-search",
                    },
                    on: { input: _vm.changeSearch },
                    model: {
                      value: _vm.searchVal,
                      callback: function ($$v) {
                        _vm.searchVal = $$v
                      },
                      expression: "searchVal",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.okrToKeyList.length > 0 && _vm.isShow
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "kr-list-content",
              },
              _vm._l(_vm.okrToKeyList, function (oItem, oIndex) {
                return _c(
                  "div",
                  { key: oIndex, staticClass: "okr-list-div" },
                  [
                    _c("div", { staticClass: "okr-title-o" }, [
                      _c("div", { staticClass: "okr-title-icon-div" }, [
                        _c("img", {
                          staticClass: "okr-title-icon",
                          attrs: {
                            src: require("@/assets/imgs/okr/objective.png"),
                          },
                        }),
                      ]),
                      _c("div", {
                        staticClass: "title-content",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.changeToHtml(oItem.content)
                          ),
                        },
                      }),
                    ]),
                    _vm._l(oItem.keyresultsList, function (keyItem, keyIndex) {
                      return _c(
                        "div",
                        {
                          key: keyIndex,
                          staticClass: "key-list-div",
                          on: {
                            click: function ($event) {
                              return _vm.checkedKey(keyItem)
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "line-icon",
                            attrs: { "icon-class": "icon-zhixiangxian" },
                          }),
                          _c(
                            "div",
                            { staticClass: "key-content" },
                            [
                              _c("div", {
                                staticClass: "content",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.changeToHtml(keyItem.title)
                                  ),
                                },
                              }),
                              _vm.selectKrId == keyItem.id
                                ? _c("Icon", {
                                    staticClass: "checked-icon",
                                    attrs: { "icon-class": "icon-duigou" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            )
          : _c("div", { staticClass: "no-data" }, [
              _c("img", {
                staticClass: "kr-count-empty-img",
                attrs: { src: require("@/assets/imgs/common/commentNull.png") },
              }),
              _c("p", { staticClass: "kr-count-p" }, [_vm._v("暂无内容")]),
            ]),
        _c("div", { staticClass: "kr-cont-footer" }, [
          _c(
            "button",
            {
              staticClass: "cancel-primary common_btn_next_add",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.cancelHidden.apply(null, arguments)
                },
              },
            },
            [_vm._v(" 取 消 ")]
          ),
          _c(
            "button",
            {
              staticClass: "next-primary common_btn_next_add",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.saveKr.apply(null, arguments)
                },
              },
            },
            [_vm._v(" 完 成 ")]
          ),
        ]),
      ]),
      !_vm.customSlot
        ? _c(
            "div",
            {
              staticClass: "task-reference",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _vm.selectKr.title
                ? _c(
                    "el-tooltip",
                    {
                      class: _vm.disabled ? "task-kr-disabled" : "",
                      attrs: {
                        "open-delay": 300,
                        content: _vm.disabled ? "禁止编辑" : "点击编辑",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "top-item-right-box",
                          class: _vm.closeBoolean
                            ? "top-item-right-box-txt-blue"
                            : "",
                        },
                        [
                          _c("p", { staticClass: "top-item-right-box-txt" }, [
                            !_vm.selectOkrOrProject
                              ? _c("span", { staticClass: "top-item-span" }, [
                                  _vm._v("KR"),
                                ])
                              : _vm._e(),
                            _vm.selectOkrOrProject
                              ? _c("span", { staticClass: "top-item-span" }, [
                                  _vm._v("任务"),
                                ])
                              : _vm._e(),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$isQw
                                    ? _vm.selectKr.title
                                    : _vm.$utils.changeToHtml(
                                        _vm.selectKrObj.title
                                      )
                                ),
                              },
                            }),
                          ]),
                          _vm.type != "details"
                            ? _c(
                                "div",
                                {
                                  staticClass: "task-cen-icon",
                                  on: {
                                    mouseenter: _vm.cancelenter,
                                    mouseleave: _vm.blueLeave,
                                  },
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "close-icon",
                                    attrs: { "icon-class": "icon-guanbianniu" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.TaskDetailMiaoshuDelete()
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "top-item-right-box-kr" },
                    [
                      !_vm.todoAdd
                        ? _c(
                            "guide-popover",
                            {
                              attrs: {
                                title: "关联KR",
                                "pop-key": "keyResult",
                                "inner-text":
                                  "这里可以将任务和KR关联起来，试试看～",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "guidepop" },
                                  slot: "guidepop",
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        "open-delay": 300,
                                        content: _vm.disabled
                                          ? "禁止编辑"
                                          : "点击编辑",
                                      },
                                    },
                                    [_c("span", [_vm._v("+选择关联KR")])]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          )
        : _c(
            "div",
            { attrs: { slot: "reference" }, slot: "reference" },
            [_vm._t("default")],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }