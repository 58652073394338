<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :append-to-body="true"
    center
    :size="360"
    :modal-append-to-body="true"
    :before-close="dialogClose"
    custom-class="workhours-detailed-dialog"
    :show-close="true"
    title="工时明细"
  >
    <div class="workhours-detailed-main">
      <div class="workhours-detailed-tab">
        <div
          v-for="(tItem, tIndex) in tabList"
          :key="tIndex"
          class="detailed-tab-list"
          :class="hourType == tItem.id ? 'detailed-tab-list-active' : ''"
          @click="handleTab(tItem, tIndex)"
        >
          <div class="detailed-tab-top">
            {{ tItem.name }}
            <span class="total">{{ tItem.total }}</span>
          </div>
          <div v-show="hourType == tItem.id" class="line"></div>
        </div>
      </div>
      <div class="workhours-detailed-content">
        <vxe-table
          v-if="showTable"
          border
          round
          show-header-overflow
          show-overflow
          max-height="300"
          :align="allAlign"
          :row-config="{ isHover: true }"
          :data="tableData"
          empty-text="暂无内容"
        >
          <vxe-column width="120" field="hourNum" :title="hourType == 1 ? '预计工时' : '实际工时'" />
          <vxe-column width="120" field="executor" title="负责人">
            <template #default="{ row }">
              <div class="executor-div">
                <img class="executor-avatar border-radius-20" :src="row.userDto.avatar" alt />
                <div class="executor-name">
                  <wx-open-data
                    :id="row.userDto.username"
                    :name-data="{ name: row.userDto.username }"
                  />
                </div>
              </div>
            </template>
          </vxe-column>
          <vxe-column v-if="hourType == 2" width="300" field="hourDate" title="工作时间" />
          <vxe-column field="hourDescribe" title="工作描述">
            <template #default="{ row }">
              <span v-if="row.hourDescribe" class="desc-text">
                {{
                row.hourDescribe
                }}
              </span>
              <span v-else class="desc-placeholder">暂无描述</span>
            </template>
          </vxe-column>
          <vxe-column width="180" field="createDate" title="添加时间">
            <template #default="{ row }">
              {{
              row.createDate.substring(0, 16)
              }}
            </template>
          </vxe-column>
          <vxe-column width="100" fixed="right" title="操作" show-overflow>
            <template #default="{ row }">
              <el-button
                class="edit-button"
                type="text"
                :disabled="disabled"
                @click="editEvent(row)"
              >
                <Icon icon-class="icon-a-xiangmulunkuohuamiaobian-1" class="task-operate" />
              </el-button>
              <el-button
                class="remove-button"
                type="text"
                :disabled="disabled"
                @click="removeEvent(row)"
              >
                <Icon icon-class="icon-a-xiangmulunkuohuamiaobian" class="task-operate" />
              </el-button>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
// import Vue from 'vue'
// import 'xe-utils'
// import VXETable from 'vxe-table'
// import 'vxe-table/lib/style.css'
// Vue.use(VXETable)
export default {
  computed: {
    ...mapState('user', {
      userInfo: 'userInfo' // 用户信息
    })
  },
  components: {},
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    source: {
      type: String,
      default: 'task'
    },
    dialogShow: {
      type: Boolean
    },
    taskId: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: this.dialogShow,
      tabList: [
        {
          id: 1,
          name: '预计工时',
          total: 0
        },
        {
          id: 2,
          name: '实际工时',
          total: 0
        }
      ],
      hourType: 1,
      allAlign: 'left',
      tableData: [],
      showTable: true,
      taskId: '',
      totalPlanHour: 0,
      totalActualHour: 0,
      hourTypeData: 1,
      isRefresh: false
    }
  },
  mounted() {
    console.log('用户信息--', this.userInfo)
    this.getWorkHourDetaileTotal()
  },
  methods: {
    removeEvent(row) {
      this.$confirm('确认删除工时？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          id: row.id
        }
        this.$apis.DELETETASKWORKHOURS(params).then((res) => {
          if (res.status == 200) {
            this.$message({
              msg: res.message,
              type: 'success'
            })
            this.getWorkHourDetaileTotal()
            this.taskId = res.data.taskId
            this.hourTypeData = res.data.hourType
            this.totalPlanHour = res.data.totalPlanHour
            this.totalActualHour = res.data.totalActualHour
            this.isRefresh = true
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
        })
      })
    },
    editEvent(row) {
      console.log('编辑---', row)
      const title = this.hourType == 1 ? '计划工时' : '实际工时'
      this.bus(this).$emit('setWorkHours', title, 'edit', row)
    },
    getWorkHourDetaileTotal() {
      const params = {
        taskId: this.taskId
      }
      this.$apis.TASKWORKHOURSINFOLISTTOTAL(params).then((res) => {
        if (res.status == 200) {
          this.tabList = [
            {
              id: 1,
              name: '预计工时',
              total:
                res.data && res.data.planHourTotal ? res.data.planHourTotal : 0
            },
            {
              id: 2,
              name: '实际工时',
              total:
                res.data && res.data.actualHourTotal
                  ? res.data.actualHourTotal
                  : 0
            }
          ]
          this.getWorkHourDetailedList()
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    getWorkHourDetailedList() {
      const params = {
        taskId: this.taskId,
        hourType: this.hourType
      }
      this.$apis.TASKWORKHOURSINFOLIST(params).then((res) => {
        if (res.status == 200) {
          this.tableData = []
          if (res.data && res.data.length > 0) {
            res.data.forEach((item) => {
              this.tableData.push({
                totalPlanHour: item.totalPlanHour,
                totalActualHour: item.totalActualHour,
                hourDate:
                  item.hourStartDate && item.hourEndDate
                    ? item.hourStartDate.substring(0, 16) +
                      '-' +
                      item.hourEndDate.substring(0, 16)
                    : '',
                hourStartDate: item.hourStartDate,
                hourEndDate: item.hourEndDate,
                hourDescribe: item.hourDescribe,
                createDate: item.createDate,
                id: item.id,
                userDto: item.userDto,
                executor: item.userDto.name,
                hourNum: item.hourNum
              })
            })
          }
          this.showTable = false
          this.$nextTick(() => {
            this.showTable = true
          })
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    handleTab(tItem, tIndex) {
      this.hourType = tItem.id
      this.getWorkHourDetailedList()
    },
    // 弹窗关闭
    dialogClose() {
      if (this.isRefresh) {
        this.bus.$emit(
          'global:setWorkHoursRefresh',
          this.source,
          this.taskId,
          this.hourTypeData,
          this.totalPlanHour ? this.totalPlanHour : this.totalActualHour
        )
      }
      this.dialogVisible = false
      this.$emit('change', false)
      this.isRefresh = false
      this.bus(this).$emit('closeWorkHoursDetailed')
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .vxe-body--column {
    padding: 0px 10px !important;
    .vxe-cell {
      padding: 0px !important;
    }
  }
  .desc-text {
    font-size: 14px;
    font-weight: 400;
    color: #444558;
  }
  .desc-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #a0a3bd;
  }
  .executor-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    .executor-avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .executor-name {
      font-size: 14px;
      font-weight: 400;
      color: #444558;
      line-height: 16px;
    }
  }
  .el-button.edit-button {
    border: none;
    .task-operate {
      color: #a0a3bd;
      font-size: 18px;
    }
    &:hover {
      border: none;
      background: none;
      .task-operate {
        color: #3a70ff;
      }
    }
  }
  .el-button.remove-button {
    border: none;
    .task-operate {
      color: #a0a3bd;
      font-size: 18px;
    }
    &:hover {
      border: none;
      background: none;
      .task-operate {
        color: #3a70ff;
      }
    }
  }

  .el-dialog.workhours-detailed-dialog {
    width: 1000px !important;
    .el-dialog__header {
      padding: 24px 24px 0 24px !important;
      text-align: left;
      .el-dialog__title {
        font-size: 20px;
        font-weight: bold;
        color: #14142b;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      position: absolute;
      top: -10px;
      right: 0px;
      z-index: 9;
    }
    .el-dialog__body {
      padding: 0 24px 24px 24px !important;
    }
  }
}
.workhours-detailed-main {
  margin-top: 24px;
  min-height: 260px;
  .workhours-detailed-tab {
    display: flex;
    flex-direction: row;
    .detailed-tab-list {
      height: 14px;
      padding: 0 24px 0 0px;
      line-height: 14px;
      border-right: 1px solid #d9dbe9;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      position: relative;
      .detailed-tab-top {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        font-weight: 400;
        color: #6e7491;
        .total {
          display: inline-block;
          margin-left: 6px;
        }
      }
      .line {
        position: absolute;
        width: 28px;
        height: 2px;
        background: #3a70ff;
        border-radius: 19px;
        margin-top: 4px;
        left: 30px;
        bottom: -10px;
      }
    }
    .detailed-tab-list-active {
      .detailed-tab-top {
        color: #3a70ff;
      }
    }
  }
  .workhours-detailed-tab .detailed-tab-list:nth-last-child(1) {
    border-right: none;
    padding-left: 24px;
    .line {
      left: 50px;
    }
  }
  .workhours-detailed-content {
    margin-top: 28px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .vxe-header--column {
    font-weight: 400 !important;
  }
  .vxe-table--render-default.is--round.is--footer:not(.is--header)
    .vxe-table--body-wrapper.body--wrapper,
  .vxe-table--render-default.is--round.is--header
    .vxe-table--header-wrapper.body--wrapper {
    border-radius: 16px 16px 0 0 !important;
  }
  .vxe-table--render-default.is--round.is--footer
    .vxe-table--footer-wrapper.body--wrapper,
  .vxe-table--render-default.is--round.is--header:not(.is--footer)
    .vxe-table--body-wrapper.body--wrapper {
    border-radius: 0 0 16px 16px !important;
  }
  .vxe-table--render-default.is--round .vxe-table--border-line,
  .vxe-table--render-default.is--round .vxe-table--render-default.is--round,
  .vxe-table--render-default.is--round:not(.is--header):not(.is--footer)
    .vxe-table--body-wrapper.body--wrapper {
    border-radius: 16px !important;
  }
  .vxe-table--render-default.border--default .vxe-table--header-wrapper,
  .vxe-table--render-default.border--full .vxe-table--header-wrapper,
  .vxe-table--render-default.border--outer .vxe-table--header-wrapper {
    background: #fff !important;
  }
  .vxe-header--column.col--ellipsis > .vxe-cell .vxe-cell--title {
    display: inline-block;
    width: 100%;
  }
  .vxe-table .vxe-table--header-wrapper {
    color: #a0a3bd;
  }
  .fixed-right--wrapper .vxe-header--column {
    background-image: none !important;
  }
}
</style>
