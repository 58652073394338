var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "680px",
        "append-to-body": true,
        title: "",
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c("Icon", {
            staticClass: "icon-close",
            attrs: { "icon-class": "icon-guanbi2" },
            on: { click: _vm.closeDialog },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/imgs/header/schoolImg.png"),
              alt: "",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }