var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.tabState == true ? "list-item-list" : "list-item-detail" },
    [
      _c(
        "div",
        {
          staticClass: "list-item",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.detail.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "left-box",
              class: _vm.bgColorstate,
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("state-picker", {
                attrs: { status: _vm.row.status, disabled: _vm.isDisabledEdit },
                on: { changeStatus: _vm.updateStatus },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "right-box",
              class: [
                { "right-box-todo": _vm.tabState == true },
                { "grey-item": _vm.row.status == "3" },
              ],
            },
            [
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "task-title" }, [
                  _c(
                    "div",
                    { staticClass: "task-title-box" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: { "open-delay": 300, content: _vm.row.title },
                        },
                        [
                          _c("div", { staticClass: "ellipsis-task-1" }, [
                            _vm._v(_vm._s(_vm.row.title)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.tabState,
                          expression: "!tabState",
                        },
                      ],
                      staticClass: "avatar-img-box border-radius-20",
                    },
                    [
                      _vm.row.originatorId === null
                        ? _c("i", { staticClass: "head-icon" })
                        : _c("avatar-locked", {
                            staticClass: "avatar-img",
                            attrs: {
                              avatar: _vm.row.originatorAvatar,
                              size: 24,
                              locked: _vm.row.isShare === 1,
                            },
                          }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "bottom-info flex-new" }, [
                  _c(
                    "div",
                    { staticClass: "flex-new" },
                    [
                      !_vm.isFinishOrStale
                        ? [
                            _vm.isSetTime || _vm.showProgress
                              ? _c(
                                  "div",
                                  { staticClass: "date-box minHeight" },
                                  [
                                    _vm.row.startDate || _vm.row.endDate
                                      ? _c("div", { staticClass: "flex-1" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "date-txt",
                                              class:
                                                _vm.row.status != 3
                                                  ? "dateColor" +
                                                    _vm.$utils.getDateBgColor(
                                                      _vm.row.startDate,
                                                      _vm.row.endDate
                                                    )
                                                  : "",
                                            },
                                            [
                                              !_vm.row.startDate &&
                                              _vm.row.endDate
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$utils.formatRecentTime(
                                                            _vm.row.endDate,
                                                            "page",
                                                            1
                                                          )
                                                        ) +
                                                        " 截止 "
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.row.startDate &&
                                              _vm.row.endDate
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$utils.formatRecentTime(
                                                            _vm.row.startDate,
                                                            "page",
                                                            1
                                                          )
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm.$utils.formatRecentTime(
                                                            _vm.row.endDate,
                                                            "page",
                                                            1
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm.row.startDate &&
                                              !_vm.row.endDate
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$utils.formatRecentTime(
                                                            _vm.row.startDate,
                                                            "page",
                                                            1
                                                          )
                                                        ) +
                                                        " 开始 "
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm.isCycle || _vm.isRemindTime || !_vm.row.keyTitle
                              ? _c(
                                  "div",
                                  { staticClass: "remind-box minHeight" },
                                  [
                                    _vm.isCycle
                                      ? _c("div", { staticClass: "cycle" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.cycleFmt(_vm.row.cycleDate)
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "flex-new" }, [
                    _vm.isRemindTime
                      ? _c(
                          "div",
                          { staticClass: "remind-time-box" },
                          [
                            _vm.isRemindTime
                              ? _c("Icon", {
                                  staticClass: "remind-time-icon",
                                  attrs: { "icon-class": "icon-remind" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "kr-counts-right" }, [
                      _vm.row.taskCollaboratorList &&
                      _vm.row.taskCollaboratorList.length > 0 &&
                      _vm.row.status != 3
                        ? _c(
                            "div",
                            { staticClass: "task-num task-num-read" },
                            [
                              _c(
                                "el-popover",
                                {
                                  ref: "popover-" + _vm.row.id,
                                  attrs: {
                                    placement: "bottom-start",
                                    trigger: "hover",
                                    "popper-class": "read-popover",
                                    "visible-arrow": false,
                                    "open-delay": 300,
                                  },
                                },
                                [
                                  _c("ReadList", {
                                    ref: "readList",
                                    staticClass: "read-list-div",
                                    attrs: {
                                      "read-arr": _vm.row.taskCollaboratorList,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "task-num-read-left",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _c("el-progress", {
                                        staticClass: "read-progress",
                                        attrs: {
                                          type: "circle",
                                          percentage: _vm.percentage(_vm.row),
                                          "show-text": false,
                                          "stroke-width": 30,
                                        },
                                      }),
                                      _vm.unReadCount(_vm.row) != 0
                                        ? _c(
                                            "span",
                                            { staticClass: "unread-span" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.unReadCount(_vm.row)
                                                ) + "人未读"
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "unread-span" },
                                            [_vm._v("全部已读")]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      parseInt(_vm.row.allSonCount) > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "task-num",
                              staticStyle: {
                                "margin-left": "20px",
                                display: "flex",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "task-num-img" },
                                [
                                  _c("Icon", {
                                    staticClass: "remind-work-icon set-svg",
                                    attrs: { "icon-class": "icon-tasksubicon" },
                                  }),
                                ],
                                1
                              ),
                              parseInt(_vm.row.allSonCount) > 99
                                ? _c("div", { staticClass: "task-num-value" }, [
                                    _vm._v("99+"),
                                  ])
                                : _c("div", { staticClass: "task-num-value" }, [
                                    _vm._v(_vm._s(_vm.row.allSonCount)),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tabState,
                            expression: "tabState",
                          },
                        ],
                        staticClass: "avatar-img-box border-radius-20",
                      },
                      [
                        _c("avatar-locked", {
                          staticClass: "avatar-img",
                          attrs: {
                            avatar: _vm.row.originatorAvatar,
                            size: 34,
                            locked: _vm.row.isShare === 1,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm.priorityVisible
            ? _c("div", {
                staticClass: "priority-box",
                style: _vm.priorityStyle,
              })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }