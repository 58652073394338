var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "radial-indicator-container",
      style: { width: _vm.size + "px", height: _vm.size + "px" },
    },
    [
      _c("el-progress", {
        staticStyle: { position: "absolute", left: "0px", top: "0px" },
        attrs: {
          type: "circle",
          width: _vm.size,
          "stroke-width": 2,
          "show-text": false,
          color: _vm.color,
          percentage: _vm.percentNum,
        },
      }),
      _c("img", {
        staticClass: "content-img",
        attrs: { src: _vm.icon, alt: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }