<template>
  <transition name="fade" mode="out-in">
    <div v-if="!loading">
      <div class="quantity-kr" :class="direction === 'col' ? 'col-quantity-kr' : ''">
        <div class="kr-unit-item">
          <div class="unit-label">量化条件</div>
          <div
            class="unit-select"
            :style="`${unitForm.quantificationType === 0 ? 'width:78px;' : ''}`"
          >
            <el-select v-model="unitForm.quantificationType">
              <el-option
                v-for="item in typeItems"
                :key="item.value"
                :label="item.text"
                :value="item.value"
                :disabled="disabled"
              />
            </el-select>
          </div>
        </div>
        <div v-show="unitForm.quantificationType === 0" class="kr-unit-item">
          <div class="unit-label">单位</div>
          <div class="unit-select-unit">
            <el-select v-model="unitSelectValue" @change="changeUnit">
              <el-option
                v-for="item in units"
                :key="item.value"
                :label="item.text"
                :value="item.value"
                :disabled="disabled"
              />
            </el-select>
          </div>
        </div>
        <div v-show="unitForm.quantificationType === 0" class="kr-unit-item">
          <div class="unit-label">初始值</div>
          <div class="unit-value">
            <input-number
              v-model="unitForm.quantificationStart"
              :point="2"
              :disabled="disabled"
              :positiveNumber="false"
            />
          </div>
        </div>
        <div v-show="unitForm.quantificationType === 0" class="kr-unit-item">
          <div class="unit-label">目标值</div>
          <div class="unit-value" style="margin-right: 0">
            <input-number
              v-model="unitForm.quantificationEnd"
              :point="2"
              :disabled="disabled"
              :positiveNumber="false"
            />
          </div>
        </div>

        <div v-show="unitForm.quantificationType === 1" class="finish-state">
          <div class="unselect-label select-label">
            <Icon icon-class="icon-not-finish" class="select-icon" />未完成
          </div>
          <div class="select-label">
            <Icon icon-class="icon-finished" class="select-icon" />已完成
          </div>
        </div>
      </div>

      <el-dialog
        :visible.sync="unitDialog"
        width="300px"
        :before-close="cancelEdit"
        :append-to-body="true"
        class="comm-check-form"
      >
        <div class="kr-unit-item unit-dialog">
          <div class="unit-label">单位</div>
          <div class="unit-value">
            <el-input v-model="unitValue" :maxlength="3" placeholder="请输入" />
          </div>
        </div>
        <div class="unit-btns">
          <div class="bottoms-comm-dialog-btns cancel-btn" @click="cancelEdit">取消</div>
          <div class="bottoms-comm-dialog-btns save-btn" @click="saveUnit">保存</div>
        </div>
      </el-dialog>
    </div>
  </transition>
</template>
<script>
import InputNumber from './InputNumber.vue'
export default {
  components: { InputNumber },
  props: {
    direction: {
      // row: 横向 col: 竖向
      type: String,
      default: 'row'
    },
    unitForm: {
      type: Object,
      default: () => {
        return {
          quantificationType: 0, // 量化条件
          quantificationUnit: '', // 单位
          quantificationStart: 0,
          quantificationEnd: 100
        }
      }
    },
    isOkrList: {
      // okr列表中会向下偏移，暂不清楚原因
      type: Boolean,
      default: false
    },
    disabled: { // 否禁用
      type: Boolean,
      default: false
    }
  },
  computed: {},
  data() {
    return {
      random: Math.random(),
      loading: true,
      units: [],
      typeItems: [
        { text: '进度', value: 0 },
        { text: '完成情况', value: 1 }
      ],
      unitDialog: false,
      unitValue: '%', // 自定义单位
      unitSelectValue: '',

      originValue: ''
    }
  },
  created() {
    this.queryUnits()
  },
  methods: {
    async queryUnits() {
      this.$apis
        .OKRQUANTITYUNITS()
        .then((rest) => {
          let unitExit = false
          this.units = rest.data.map((unit) => {
            if (unit.value === this.unitForm.quantificationUnit) {
              // 是否是已存在单位
              unitExit = true
            }
            return {
              text: unit.value,
              value: unit.value
            }
          })
          this.unitSelectValue = this.unitForm.quantificationUnit
          this.originValue = this.unitForm.quantificationUnit
          if (this.unitForm.quantificationUnit !== '' && !unitExit) {
            // 存在自定义单位
            this.units.push({
              text: this.unitForm.quantificationUnit,
              value: this.unitForm.quantificationUnit,
              exit: true
            })
            this.unitValue = this.unitForm.quantificationUnit
          } else if (this.unitForm.quantificationUnit === '') {
            this.unitForm.quantificationUnit = this.units[0].value
            this.unitSelectValue = this.units[0].value
          }
          // 自定义
          this.units.push({
            text: '自定义',
            value: '自定义'
          })
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    changeUnit(val) {
      console.log(val)
      if (val === '自定义') {
        // 打开自定义单位
        this.unitDialog = true
      } else {
        this.originValue = val
        this.unitForm.quantificationUnit = val
        this.unitValue = val
      }
    },
    cancelEdit() {
      this.unitDialog = false
      this.unitSelectValue = this.originValue
    },
    saveUnit() {
      // 保存自定义单位
      if (this.unitValue.trim() === '') {
        this.$message.warn('单位不能为空！')
        return
      }
      let isPersonUnit = false
      let isPersonUnitIndex = 0
      let exit = false
      this.units.forEach((unit, index) => {
        if (unit.exit) {
          isPersonUnit = unit.exit
          isPersonUnitIndex = index
        }
        if (unit.value === this.unitValue) {
          exit = true
        }
      })
      if (exit) {
        this.$message.warn('已存在该单位！')
        return
      }
      if (!isPersonUnit) {
        this.units.unshift({
          text: this.unitValue,
          value: this.unitValue
        })
      } else {
        this.units[isPersonUnitIndex].text = this.unitValue
        this.units[isPersonUnitIndex].value = this.unitValue
      }
      this.unitSelectValue = this.unitValue
      this.unitForm.quantificationUnit = this.unitValue
      this.unitDialog = false
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
.kr-unit-item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: 4px 0;
  font-size: 14px;
  .unit-label {
    margin-right: 8px;
    color: #a0a3bd;
  }
  .unit-select {
    margin-right: 16px;
    width: 100px;
  }
  .unit-select-unit {
    margin-right: 16px;
    width: 85px;
  }
  .unit-value {
    margin-right: 16px;
    // width: 80px;
    width: 130px;
  }
}
.unit-dialog {
  padding: 40px 24px 32px;
  justify-content: center;
  font-size: 16px;
  line-height: 40px;
}
.unit-btns {
  display: flex;
  justify-content: center;
  .bottoms-comm-dialog-btns {
    width: 64px;
    height: 34px;
    font-size: 14px;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
    border-radius: 10px;
  }
  .cancel-btn {
    color: #3a78f6;
    border: 1px solid #3a78f6;
    margin-right: 8px;
    &:hover {
      color: #005eff;
      border: 1px solid #005eff;
    }
  }
  .save-btn {
    background: #3a78f6;
    color: #fff;
    border: 1px solid #3a78f6;
    &:hover {
      background: #005eff;
      border: 1px solid #005eff;
    }
  }
}
.quantity-kr {
  display: flex;
  flex-wrap: wrap;
  .finish-state {
    margin: 4px 0;
    width: 208px;
    height: 35px;
    border-radius: 8px;
    background: #f6fafe;
    color: #a0a3bd;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
    .unselect-label {
      color: #ff76af;
    }
    .select-label {
      display: flex;
      align-items: center;
    }
    .select-icon {
      font-size: 20px;
      margin-right: 8px;
    }
  }
}
::v-deep {
  .el-input__inner {
    border: 1px solid #f5f6fa;
    border-radius: 8px;
    height: 34px;
    line-height: 34px;
  }
  .el-input-number {
    width: 80px;
  }
  .el-input__icon {
    line-height: 34px;
    width: 20px;
  }
  .el-dialog__header {
    display: none;
  }
  .el-input-number.is-controls-right .el-input__inner {
    padding-left: 8px;
    padding-right: 30px;
  }
  .el-input--suffix .el-input__inner {
    padding-right: 25px;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    width: 30px;
  }
  .el-input-number.is-controls-right .el-input-number__decrease,
  .el-input-number.is-controls-right .el-input-number__increase {
    line-height: 17px;
  }
  .el-input-number.is-controls-right .el-input-number__decrease {
    bottom: 3px;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    top: 3px;
  }
}
.col-quantity-kr {
  flex-direction: column;
  .kr-unit-item {
    margin-bottom: 12px;
    .unit-label {
      width: 80px;
      text-align: right;
      margin-right: 16px;
    }
    .unit-select {
      margin-right: 0;
    }
    .unit-select-unit {
      margin-right: 0;
    }
    .unit-value {
      margin-right: 0;
    }
  }
  .finish-state {
    flex-direction: column;
    width: 228px;
    height: 120px;
    margin-bottom: 32px;
    padding: 30px 0;
    .select-icon {
      margin-top: -2px;
    }
  }
}
</style>
