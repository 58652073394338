<template>
  <!-- 企信pc迁移后新增 -->
  <el-dialog
    title="添加工作任务"
    :visible.sync="dialogVisible"
    width="640px"
    :show-close="false"
    custom-class="add-task-dialog"
    :append-to-body="true"
    :close-on-press-escape="false"
    @close="cancle"
  >
    <div slot="title" class="task-title">
      <div class="task-title-left">
        <span class="title">
          {{
          propsAddObj && propsAddObj.taskType
          ? '添加' + propsAddObj.taskType
          : '创建'
          }}
        </span>
        <ClassifySelect
          v-model="classifytype"
          :is-son-task="
            propsAddObj.taskType && propsAddObj.taskType == '子任务'
              ? true
              : false
          "
          :source="
            propsAddObj.formPage == 'pm'
              ? 'pm'
              : this.taskAddObject.type == 1
              ? 'kr'
              : 'group'
          "
          @change="queryTaskParams($event, false)"
        />
      </div>
      <div class="adver-wrap">
        <advertisement type="addTask" :style-type="2" />
      </div>
    </div>
    <!-- 任务标题 -->
    <div class="add-task-bd-textarea">
      <el-input
        v-model.lazy="form.title"
        :autosize="{ minRows: 4, maxRows: 5 }"
        type="textarea"
        size="medium"
        placeholder="请输入具体的工作任务(例：每天拜访50个客户/每周邀约面试30人)"
        @keydown.enter.native="enterToBlur"
        @input="judge(false)"
      />
    </div>
    <ul class="task-info">
      <!-- 设置负责人 -->
      <li class="task-info-item">
        <div class="describe" :class="moreParamShow ? '' : 'simple-descirbe'">
          <Icon slot="title" icon-class="icon-fuzeren1" class="m-r-10 svg-icons" />
          <span v-if="moreParamShow" class="pointer">负责人</span>
          <span
            v-if="
              sysParamInfo['executor'] &&
              sysParamInfo['executor'].required === 0
            "
            class="required"
          >*</span>
        </div>
        <div
          class="originator-box"
          :class="originatorHover ? 'originator-box-hover' : ''"
          @mouseover="isColorCloseIcon = false"
          @mouseout="isColorCloseIcon = true"
        >
          <user-picker @pick="toriginatorPicker">
            <div class="charge-person">
              <div v-if="originatorName === null">
                <span class="head-icon-label">
                  <Icon
                    v-if="moreParamShow"
                    class="avatar-icon border-radius-20"
                    icon-class="icon-kong-touxiang"
                  />待认领
                </span>
              </div>
              <div v-else>
                <el-image
                  :src="
                    originatorAvatar ||
                    require('@/assets/imgs/common/advar.png')
                  "
                  class="charge-avatar"
                />
                <span class="p-l-5">
                  <wx-open-data :id="originatorName" :name-data="{ name: originatorName }" />
                </span>
              </div>
            </div>
          </user-picker>
          <Icon
            v-if="originatorName !== null"
            :icon-class="isColorCloseIcon ? '' : 'icon-a-Group719-1'"
            class="close-icon"
            @mouseenter.native="originatorHover = true"
            @mouseleave.native="originatorHover = false"
            @click.native.stop="
              clearToriginatorPicker(sysParamInfo['executor'])
            "
          />
        </div>
      </li>
      <!-- 协作人 moreParamShow && -->
      <li v-if="classifytype !== 'todo'" class="task-info-item task-info-item-between">
        <!-- 协作人 -->
        <div class="charge-user-box">
          <div class="describe involves-describe">
            <Icon icon-class="icon-xiezuoren1" class="svg-icons" />
            <div>协作人 ({{ chargeUserListInit.length || 0 }})</div>
            <span
              v-if="
                sysParamInfo['involves'] &&
                sysParamInfo['involves'].required === 0
              "
              class="required"
            >*</span>
          </div>
          <div class="flex-a-c-center flex-new-wrap">
            <div
              v-for="(item, index) in chargeUserListInit"
              :key="item.id"
              class="charge-avatar-box"
              :class="chargeUserId == item.id ? 'charge-avatar-box-hover' : ''"
            >
              <el-image fit="cover" :src="item.avatar" class="charge-avatar border-radius-20" />
              <Icon
                icon-class="icon-a-Group719-1"
                class="close-icon"
                @mouseenter.native="chargeUserId = item.id"
                @mouseleave.native="chargeUserId = ''"
                @click.native.stop="chargeUserDelete(index)"
              />
            </div>
            <user-picker-checked
              :select-people-list="chargeUserList"
              :length="40"
              @pick="chargeUserPicker"
              @click.native.stop
            >
              <Icon icon-class="icon-tianjia-xuxian" class="cooperation-add" />
            </user-picker-checked>
          </div>
        </div>
      </li>
      <!-- 开始日期和结束日期 v-if="moreParamShow" -->
      <li class="task-info-item">
        <div
          :class="`describe ${
            moreParamShow ? 'time-describe' : 'simple-descirbe'
          }`"
        >
          <Icon icon-class="icon-rili" class="m-r-10 svg-icons" />
          <span v-if="moreParamShow" class="pointer m-r-10">时间</span>
          <span
            v-if="
              sysParamInfo['startDate'] &&
              sysParamInfo['startDate'].required === 0
            "
            class="required"
          >*</span>
        </div>
        <set-date-time
          v-if="setDateTimeVisible"
          :key="setDateTimeVisible"
          class="set-date-time"
          type="add"
          :classifytype="classifytype"
          :row="form"
        />
      </li>
      <!-- 开始日期 -->
      <!-- <li v-else class="task-info-item date-picker">
        <div class="describe simple-descirbe">
          <Icon icon-class="icon-rili" class="m-r-10 svg-icons" />
          <span
            v-if="
              sysParamInfo['endDate'] && sysParamInfo['endDate'].required === 0
            "
            class="required"
            >*</span
          >
        </div>
        <div
          class="more-btn select-start"
          :class="endHover && form.endDate ? 'select-hover-start' : ''"
          @click="selectEndTime"
        >
          <span v-if="form.endDate" class="time-text-yes">
            {{ form.endDate + ' 截止' }}
          </span>
          <span v-else class="time-text-no">选择截止时间</span>
          <Icon
            v-if="form.endDate"
            icon-class="icon-guanbianniu"
            class="close"
            @mouseenter.native="endHover = true"
            @mouseleave.native="endHover = false"
            @click.native.stop="timeClear()"
          />
        </div>
        <el-date-picker
          ref="startTimeRef"
          v-model="form.endDate"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          :default-time="defaultEnd"
          type="datetime"
          placeholder="设置截止日期"
          class="datetime hide-date-border"
          popper-class="addTimeClass"
          @change="endDateChange"
        />
      </li>-->
      <!-- 关联kr 有气泡-->
      <li v-if="classifytype === 'kr' && propsAddObj.taskType != '子任务'" class="task-info-item">
        <div class="describe kr-describe" :class="moreParamShow ? '' : 'simple-descirbe'">
          <Icon icon-class="icon-guishuyu" class="m-r-10 svg-icons" />
          <span v-if="moreParamShow">任务所属</span>
          <span class="required">*</span>
        </div>
        <task-kr
          style="margin-left: 0px"
          :select-kr="copyProps"
          :todo-add="false"
          :is-okr-shortcut="isOkrShortcut"
          @save="krSave"
          @userPickerHidden="userPickerHidden"
        />
      </li>
      <!-- 关联项目 -->
      <li v-if="classifytype === 'project' && propsAddObj.taskType != '子任务'" class="task-info-item">
        <div class="describe project-describe" :class="moreParamShow ? '' : 'simple-descirbe'">
          <Icon icon-class="icon-guishuyu" class="m-r-10 svg-icons" />
          <span v-if="moreParamShow">任务归属</span>
          <span class="required">*</span>
        </div>
        <TaskProject
          v-if="copyProps.formPage === 'pm'"
          :project="projectInfo"
          :process-id="copyProps.processId"
          :is-pm-shortcut="isPmShortcut"
          :disabled="isPmShortcut ? false : true"
          @change="changeProcess"
          @changeProject="changeProject"
        />
        <TaskProject
          v-else
          source="add"
          :project="projectInfo"
          :disabled="false"
          @change="changeProcess"
          @changeProject="changeProject"
        />
      </li>
      <template v-if="moreParamShow">
        <li
          v-for="param in paramList"
          v-if="param.fieldType != 'workingHours'"
          :key="param.id"
          class="task-info-item"
        >
          <div class="flex-new">
            <div
              class="describe"
              :class="`${
                param.fieldType == 'customField'
                  ? param.fieldAttribute
                  : param.fieldType
              }-describe`"
            >
              <Icon
                :icon-class="
                  paramDict[
                    param.fieldType !== 'customField'
                      ? param.fieldType
                      : param.fieldAttribute
                  ]
                "
                class="m-r-10 svg-icons"
              />
              <el-tooltip :content="param.name" :open-delay="300">
                <span class="param-name-text">{{ param.name }}</span>
              </el-tooltip>
              <span v-if="param.required === 0" class="required">*</span>
            </div>

            <!-- 优先级 -->
            <template v-if="param.fieldType === 'priority'">
              <el-select
                v-model="form.priorityId"
                class="priority-box"
                :style="`color: ${priorityInfo.colour} !important`"
                placeholder="请选择"
                @change="selectClick"
              >
                <template v-for="item in priorityDict">
                  <el-option
                    :key="item.id"
                    :style="`color: ${item.colour}`"
                    :label="item.value"
                    :value="item.id"
                  />
                </template>
              </el-select>
            </template>
            <!-- 标签 -->
            <template v-else-if="param.fieldType === 'tag'">
              <TaskLabel
                :label-options="tagList"
                :select-options="selectTag"
                @save="saveLabels"
                @update="saveLabels"
              />
            </template>
            <!-- 自定义 -->
            <template v-else>
              <CustomField
                v-model="param.value"
                :field-type="param.fieldAttribute"
                :field-info="param"
                :value="param.value"
                @change="changeParamVal($event, param)"
              />
            </template>
          </div>
        </li>
      </template>

      <!-- 更多 -->
      <li v-if="!moreParamShow" class="task-info-item">
        <div class="describe more-btn" @click="moreParamShow = true">
          <Icon icon-class="icon-more-row" class="m-r-10 svg-icons" />
          <span>更多</span>
        </div>
      </li>
    </ul>
    <div slot="footer" class="footer flex-new">
      <div>
        <el-tooltip
          :open-delay="300"
          :content="
            switchValue
              ? '全员可见'
              : classifytype == 'todo'
              ? '仅自己可见'
              : '仅参与人可见'
          "
        >
          <div class="flex-new footer-box" @click.stop="switchValue = !switchValue">
            <Icon v-if="switchValue" class="task-add-img" icon-class="icon-chakan" />
            <Icon v-if="!switchValue" class="task-not-img" icon-class="icon-yincang" />
            <p class="footer-lf">
              {{
              switchValue
              ? '全员可见'
              : classifytype == 'todo'
              ? '仅自己可见'
              : '仅参与人可见'
              }}
            </p>
          </div>
        </el-tooltip>
      </div>
      <div class="align-center">
        <!-- <el-button @click.stop="cancle"  :loading="loading" type="cancel" class="cancel-btns">取 消</el-button> -->
        <button
          v-if="loading || nextLoading"
          class="next-primary common_btn_next_add common_btn_next_add_unable"
        >取 消</button>
        <button
          v-else
          v-preventReClick
          class="next-primary common_btn_next_add common_btn_next_add_able"
          @click.stop="cancle"
        >取 消</button>
        <!-- <el-button @click="next" :disabled="disabledSubmit" :loading="loading" class="next-btn" :class="!disabledSubmit?'next-btn-orange':''">
            完成并创建下一个
        </el-button>-->
        <button
          v-if="disabledSubmit || loading"
          class="next-btns common_btn_next_add common_btn_next_add_unable"
        >完成并创建下一个</button>
        <L-button
          v-else
          :loading="nextLoading"
          color="#3068ff"
          class="next-btns common_btn_next_add common_btn_next_add_able"
          @clickEvt="save('next')"
        >完成并创建下一个</L-button>
        <button
          v-if="disabledSubmit || nextLoading"
          class="next-primary common_btn_next_add common_btn_next_add_unable"
        >完 成</button>
        <L-button
          v-else
          :loading="loading"
          class="next-primary common_btn_next_add common_btn_next_add_able_blue"
          @clickEvt="save"
        >完成</L-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import formMixin from '@/utils/mixins/form'
import ClassifySelect from '../../components/ClassifySelect'
import SetDateTime from '@/views/todo/components/TaskTimeSet/SetDateTime.vue' // 设置时间
import TaskKr from '../../components/TaskKr/index' // 关联kr
import TaskProject from '../../components/TaskProject' // 所属项目
import TaskLabel from '../../components/Label' // 任务标签
import CustomField from '../../components/CustomField/index.vue'
import advertisement from '@/views/global/components/advertisement'
import taskEnums from '@/utils/enums/task.js'
export default {
  components: {
    SetDateTime,
    TaskKr,
    ClassifySelect,
    TaskProject,
    TaskLabel,
    CustomField,
    advertisement
  },
  mixins: [formMixin],
  model: {
    prop: 'dialogShow',
    event: 'change'
  },
  props: {
    isOriginatorEmpty: {
      type: Boolean,
      default: true
    },
    dialogShow: {
      type: Boolean,
      default: true
    },
    // kr id
    keyId: {
      type: String,
      required: false,
      default: ''
    },
    // 任务状态
    status: {
      type: Number,
      required: false,
      default: 1
    },
    startCalDate: {
      type: String,
      default: ''
    },
    endCalDate: {
      type: String,
      default: ''
    },
    propsAddObj: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    source: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('task', {
      taskTodoOrKrDict: 'taskTodoOrKrDict' // 任务字典
    }),
    ...mapState('user', {
      userInfo: 'userInfo'
    }),
    ...mapState('common', {
      companyCalendarInfo: 'companyCalendarInfo'
    })
  },
  data() {
    return {
      loading: false,
      nextLoading: false,
      isColorCloseIcon: true,
      form: {
        startDate: this.startCalDate,
        endDate: this.endCalDate
      },
      dialogVisible: this.dialogShow,
      originatorAvatar: null, // 负责人头像
      originatorName: null, // 负责人姓名
      chargeUserList: [], // 协作人列表
      chargeUserListInit: [],
      setDateTimeVisible: true, // 设置时间是否显示
      switchValue: true, // 开关任务设置
      taskAddForm: {},
      krVisible: false,
      addcooperationBoolean: false,
      defaultEnd: '',
      typeObj: {
        working: 'todo',
        quadrants: 'todo',
        pm: 'project',
        memberCard: 'todo',
        kr: 'kr'
      },
      classifytype: 'todo', // todo: 待办 kr：目标 project：项目
      moreParamShow: false,
      paramDict: {
        note: 'icon-miaoshu',
        tag: 'icon-biaoqian1',
        priority: 'icon-youxianji1',
        text: 'icon-wenben', // 文本
        singleChoice: 'icon-danxuan', // 单选
        multipleChoice: 'icon-duoxuan', // 多选
        number: 'icon-shuzi', // 数字
        date: 'icon-kaishishijian', // 日期
        file: 'icon-wenjian', // 文件
        workingHours: 'icon-gongshi' // 工时
      },
      paramList: [],
      tagList: [], // 标签字典
      selectTag: [], // 选中字典
      priorityDict: [], // 优先级字典
      priorityInfo: {},
      fieldCategoriesId: '', // 字段分类id
      projectInfo: {}, // 项目信息
      customFieldForm: {}, // 自定义字段表单
      sysParamInfo: {}, // 系统字段信息
      copyProps: {},
      endHover: false,
      statusDict: taskEnums.TASKSTATUS, // 状态字典
      originatorHover: false,
      chargeUserId: '',
      isShowPm: true,
      isOkrShortcut: false,
      isSharer: false
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.dialogVisible = val
      }
    },
    propsAddObj: {
      handler(val) {
        this.copyProps = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // 点击空白处添加人员列表关闭
    document.documentElement.addEventListener(
      'click',
      () => {
        this.krVisible = false
      },
      false
    )
    // 点击遮罩层  进行关闭弹框存储到本地进行数据回填效果
    this.bus(this).$on('global:dialogBeforeClose', function () {
      this.cancle()
    })
    // 关闭前看下有没有已经写得数据，如果有给提示
    this.bus(this).$on('global:beforeClose', async function () {
      this.cancle()
    })
  },
  created() {
    // 初始化
    this.taskAddObject = this.$store.state.common.taskAddObject
    this.cooperateVuexList = this.$store.state.common.cooperateVuexList
    this.init()
    // 获取kr信息
    this.keyId && this.getKrById()
    // 数据埋点c

    this.$mixpanelUtil.track('View AddTask')
    if (this.source === 'okrTable') {
      this.krSave(this.copyProps.krObj)
    }
  },
  methods: {
    getOkrShareList(objId) {
      this.$apis.OKRSHARELIST(objId).then((res) => {
        if (res.status === 200) {
          console.log('共享人列表-', res)
          this.isSharer = res.data.some(
            (item) => item.user.id === this.userInfo.id
          )
          if (this.isSharer) {
            this.isOkrShortcut = true
          } else {
            this.isOkrShortcut = false
          }
          console.log('是否是共享人-', this.isSharer)
        } else {
          this.$message.warn(res.message)
        }
      })
    },
    // 新增项目
    changeProject(projectId, projectObj, processId) {
      console.log(
        '新增项目---',
        projectId,
        projectObj,
        processId,
        this.projectInfo
      )
      this.projectInfo = projectObj
      this.projectInfo['processId'] = processId
      this.form.projectId = this.projectInfo.id
      this.form.processId = processId
      this.queryTaskParams(projectId, true)
    },
    timeClear() {
      this.endHover = false
      this.form.endDate = ''
    },
    endDateChange(val) {
      console.log('时间--', val)
    },
    //  清除负责人
    clearToriginatorPicker(fieldItem) {
      this.originatorHover = false
      // 判断必填项
      if (fieldItem.required == 0) {
        this.$message({
          msg: fieldItem.name + '字段为必填项！',
          type: 'warn'
        })
        return
      }
      this.originatorName = null
    },
    // 初始化
    init(type) {
      // this.copyProps = JSON.parse(JSON.stringify(this.propsAddObj))
      console.log('创建任务字段--', this.copyProps)
      if (this.companyCalendarInfo.pmEndTime) {
        this.defaultEnd =
          this.companyCalendarInfo.pmEndTime.substring(11, 19) || ''
      }
      if (this.copyProps.classifytype == 'kr') {
        this.classifytype = 'kr'
        this.form.businessType == 3
        if (this.copyProps.businessId) {
          this.getOkrShareList(this.copyProps.businessId)
        }
      } else if (this.copyProps.classifytype == 'project') {
        this.classifytype = 'project'
        this.form.businessType == 2
        console.log('项目捷径---', this.copyProps)
        if (this.copyProps.businessId) {
          this.isPmShortcut = true
        }
      } else {
        this.classifytype = 'todo'
        this.form.businessType == 1
      }

      // 分组 kr
      if (
        this.copyProps.identification == 'type' &&
        this.copyProps.key == '3'
      ) {
        this.classifytype = 'kr'
      }
      // 分组 项目
      if (
        this.copyProps.identification == 'type' &&
        this.copyProps.key == '2'
      ) {
        this.classifytype = 'project'
      }
      if (this.propsAddObj.formPage) {
        this.classifytype = this.typeObj[this.propsAddObj.formPage]
      }
      // 分组 优先级
      if (
        this.copyProps.identification == 'priority' &&
        this.copyProps.businessId
      ) {
        this.form.priorityId = this.copyProps.businessId
        console.log('优先级---', this.copyProps, this.form.priorityId)
      }
      // 分组 状态
      if (this.copyProps.identification == 'status' && this.copyProps.key) {
        this.status = this.copyProps.key
      }
      // 分组截止时间(今天)
      if (
        this.copyProps.identification == 'endDate' &&
        this.copyProps.key == 'today'
      ) {
        const today = this.$moment(new Date()).format('YYYY-MM-DD')
        this.form.endDate =
          today + ' ' + this.companyCalendarInfo.pmEndTime.substring(11, 19)
      }
      // 分组截止时间(明天)
      if (
        this.copyProps.identification == 'endDate' &&
        this.copyProps.key == 'tomorrow'
      ) {
        const tomorrow = this.$moment()
          .subtract(-1, 'days')
          .format('YYYY-MM-DD')
        this.form.endDate =
          tomorrow + ' ' + this.companyCalendarInfo.pmEndTime.substring(11, 19)
      }
      if (this.copyProps.formPage === 'pm') {
        if (this.isPmShortcut) {
          this.projectInfo = {
            id: this.copyProps.businessId
          }
          this.queryTaskParams(this.copyProps.businessId, true)
        } else {
          console.log(
            '项目---',
            this.propsAddObj.projectInfo.selectProjectData.list
          )
          this.projectInfo = this.propsAddObj.projectInfo.selectProjectData.list
          this.form.businessType == 2
          this.queryTaskParams(this.propsAddObj.projectId, true)
        }
      } else {
        this.form.businessType = this.copyProps.businessType
        this.queryTaskParams(this.classifytype, false)
      }
      // 默认负责人
      this.setDefaulToriginator()

      // 设置任务状态
      if (this.status) {
        this.form.status = this.status
      }
      if (type === 'next' && this.startCalDate) {
        this.form.startDate = this.startCalDate
        this.form.endDate = this.endCalDate
      }
      this.selectTag = []
    },
    clearForm() {
      this.selectTag = []
      // 清空协作人
      this.chargeUserList = []
      this.chargeUserListInit = []
      this.priorityInfo = {}
    },
    // 获取任务字段字典
    queryTaskParams(id, isProject) {
      this.clearForm()
      if (this.copyProps.formPage != 'pm') {
        this.form.processId = ''
        this.form.keyId = ''
        this.form.objId = null // 目标id
        this.form.keyId = null
        this.form.cycle_id = null
        this.copyProps.keyId = ''
        this.copyProps.objId = null // 目标id
        this.copyProps.keyId = null
        this.copyProps.cycle_id = null
        this.copyProps.title = ''
        this.copyProps.projectId = null
      }
      if (isProject) {
        // 项目取字典
        this.$apis.TASKADDPARAMPROJECT(id).then((res) => {
          if (res.status === 200) {
            this.fieldCategoriesId = res.data.fileCategories.id
            this.paramsHandle(res.data)
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        // 非项目从store中取字典
        const data =
          id === 'todo' ? this.taskTodoOrKrDict.todo : this.taskTodoOrKrDict.OKR
        data.labelDictionaryList = this.taskTodoOrKrDict.labelDictionaryList
        data.priorityDictionaryList =
          this.taskTodoOrKrDict.priorityDictionaryList
        this.fieldCategoriesId = data.id
        this.paramsHandle(data)
      }
    },
    paramsHandle(data) {
      this.sysParamInfo = {}
      this.paramList = data.fieldDictionaryList.filter((item) => {
        item.value = null
        if (item.base !== 1 && item.fieldType !== 'note') {
          this.sysParamInfo[item.fieldType] = item
        }
        // 单选，多选必填的话默认第一个选项
        if (item.fieldAttribute === 'singleChoice' && item.required === 0) {
          item.value = item.fieldsCustomfieldsChoices[0].value
          this.customFieldForm[item.id] = item.value
        }
        if (item.fieldAttribute === 'multipleChoice' && item.required === 0) {
          item.value = [item.fieldsCustomfieldsChoices[0].value]
          this.customFieldForm[item.id] = item.value
        }
        return item.base !== 0 && item.fieldType !== 'note'
      })
      this.paramList.sort((a, b) => Number(a.sort) - Number(b.sort))
      this.tagList = JSON.parse(JSON.stringify(data.labelDictionaryList))
      this.priorityDict = JSON.parse(
        JSON.stringify(data.priorityDictionaryList)
      )
      this.form.priorityId = this.priorityDict[0].id
      if (
        this.copyProps.identification == 'priority' &&
        this.copyProps.businessId
      ) {
        this.form.priorityId = this.copyProps.businessId
      }
      this.priorityInfo = this.priorityDict[0]
      this.$forceUpdate()
    },
    // 修改工作流id
    changeProcess(id) {
      this.form.processId = id
    },
    // 设置默认负责人信息
    setDefaulToriginator() {
      console.log('设置默认负责人信息', this.userInfo)
      if (this.isOriginatorEmpty) {
        this.form.originatorId = this.userInfo.id
        this.originatorAvatar = this.userInfo.avatar
        this.originatorName = this.userInfo.name
      } else {
        this.originatorName = null
      }

      this.taskAddForm.originatorId = this.userInfo.id
      this.taskAddForm.originatorAvatar = this.userInfo.avatar
      this.taskAddForm.originatorName = this.userInfo.name

      // kr  组件使用
      if (
        `${this.taskAddObject.default}` === '1' &&
        (this.propsAddObj.formPage === 'quadrants' ||
          this.propsAddObj.formPage === 'working')
      ) {
        this.taskAddForm = this.taskAddObject
        this.form.originatorId = this.taskAddObject.originatorId || ''
        this.originatorAvatar = this.taskAddObject.originatorAvatar || ''
        this.originatorName = this.taskAddObject.originatorName || ''
        this.chargeUserList = this.taskAddObject.chargeUserList || []
        // 时间设置
        // this.form.startDate = this.taskAddObject.startDate || null
        this.form.endDate = this.taskAddObject.endDate || ''
        this.form.type = this.taskAddObject.type || null
        if (this.taskAddObject.title) {
          this.$set(this.form, 'title', this.taskAddObject.title)
          this.judge(false)
        }
        this.priorityInfo = this.taskAddObject.priority || null
        this.switchValue = this.taskAddObject.switchValue
        this.form.cycleDateId = this.taskAddObject.cycleDateId || null
        this.form.remindDateId = this.taskAddObject.remindDateId || null

        // kr 提交试用
        if (this.taskAddObject.type === 1) {
          this.form.objId = this.taskAddObject.objId || null // 目标id
          this.form.keyId = this.taskAddObject.keyId || null // krId
          this.form.cycleId = this.taskAddObject.cycleId || null // 周期id
          this.form.keyTitle = this.taskAddObject.keyTitle
          // this.form.type = this.taskAddObject.type
          this.form.projectId = null
        }
        this.copyProps.type = this.taskAddObject.type || null // 目标id
        this.copyProps.objId = this.taskAddObject.objId || null // 目标id
        this.copyProps.keyId = this.taskAddObject.keyId || null
        this.copyProps.cycle_id = this.taskAddObject.cycleId || null
        this.copyProps.title = this.taskAddObject.keyTitle || ''
        this.copyProps.selectBooleans = this.taskAddObject.selectBooleans
        this.copyProps.default = this.taskAddObject.default
        this.copyProps.projectId = this.taskAddObject.projectId || null // 目标id
        this.copyProps.checked = this.taskAddObject.checked || null // 目标id
      } else if (this.propsAddObj.formPage === 'pm') {
        this.form.projectId = this.copyProps.projectId || null
        this.form.processId = this.copyProps.processId || null
      } else if (this.propsAddObj.formPage === 'memberCard') {
        // 个人名片分配任务
        this.originatorAvatar = this.copyProps.avatar || ''
        this.originatorName = this.copyProps.name || ''
        this.form.originatorId = this.copyProps.id || ''
        // this.placeholder = ""
      }
    },
    selectEndTime() {
      this.$refs.startTimeRef.$el.focus()
      const el = this.$refs.startTimeRef.$el
      el.children[0].focus()
    },
    // 改变优先级
    selectClick(id) {
      const obj = this.priorityDict.find((item) => item.id === id)
      if (obj) {
        this.priorityInfo = JSON.parse(JSON.stringify(obj))
      }
    },
    // 获取选中的标签
    saveLabels(tags) {
      this.selectTag = tags
    },
    // 修改自定义字段的值
    changeParamVal(val, param) {
      param.value = val
      this.customFieldForm[param.id] = val
      this.$forceUpdate()
    },
    // 取消
    cancle() {
      this.taskAddForm.default = 1
      this.taskAddForm.title = this.form.title
      this.taskAddForm.keyTitle = this.form.keyTitle
      this.taskAddForm.priority = this.priorityInfo
      this.taskAddForm.startDate = this.taskAddObject.startDate || null
      this.taskAddForm.endDate = this.taskAddObject.endDate || null
      // this.taskAddForm.type = this.taskAddObject.type || null
      this.taskAddForm.type = null
      this.taskAddForm.switchValue = this.switchValue
      this.taskAddForm.taskCustom = this.taskAddObject.taskCustom
      this.taskAddForm.taskRemind = this.taskAddObject.taskRemind
      this.taskAddForm.cycleDateId = this.taskAddObject.cycleDateId || null
      this.taskAddForm.remindDateId = this.taskAddObject.remindDateId || null
      this.taskAddForm.type_remind = this.taskAddObject.type_remind
      if (
        this.propsAddObj.formPage === 'quadrants' ||
        this.propsAddObj.formPage === 'working'
      ) {
        this.$store.commit('common/saveTaskAddObject', this.taskAddForm)
      }
      this.handleClose()
    },
    // 回车失去焦点事件
    enterToBlur(event) {
      if (event.keyCode === 13) {
        this.save() // 发送文本
        event.preventDefault() // 阻止浏览器默认换行操作
        return false
      }
    },
    // 保存任务
    async save(type) {
      const loading = type === 'next' ? 'nextLoading' : 'loading'
      this[loading] = true
      // 参数校验
      const status = this.judge()
      console.log('status', status)
      if (status) {
        const taskCollaboratorDtoList = this.chargeUserListInit.map((item) => {
          return { userId: item.id, name: item.name }
        })
        this.form.remindDateId = this.taskAddObject.remindDateId || null
        if (this.switchValue === true) {
          // 公开
          this.$set(this.form, 'isShare', 0)
        } else {
          // 隐藏
          this.$set(this.form, 'isShare', 1)
        }
        if (
          !this.form.title ||
          this.form.title.replace(/\s*/g, '').length === 0
        ) {
          this.$message.error('任务标题不能为空!')
          this[loading] = false
          return
        } else if (this.form.title.length > 150) {
          this.$message.warn('标题最多可输入 150 个字符哦!')
          this[loading] = false
          return
        }
        // 负责人校验
        if (
          this.sysParamInfo['executor'] &&
          this.sysParamInfo['executor'].required === 0 &&
          !this.form.originatorId
        ) {
          this.$message.warn('请设置负责人')
          this[loading] = false
          return
        }
        // 开始时间校验
        if (
          this.sysParamInfo['startDate'] &&
          this.sysParamInfo['startDate'].required === 0 &&
          !this.form.startDate
        ) {
          this.$message.warn('请设置开始时间！')
          this[loading] = false
          return
        }
        console.log(333, this.form.keyId)
        // 任务所属校验
        if (
          this.classifytype === 'kr' &&
          !this.form.keyId &&
          this.propsAddObj.taskType != '子任务'
        ) {
          this.$message.warn('请选择任务所属！')
          this[loading] = false
          return
        }

        const fieldValueList = []
        // 更多字段校验
        // 优先级校验
        if (
          this.sysParamInfo['priority'] &&
          this.sysParamInfo['priority'].required === 0 &&
          !this.form.priorityId
        ) {
          this.validRequireField('请选择优先级！')
          this[loading] = false
          return
        }
        // 协作人校验
        if (
          this.sysParamInfo['involves'] &&
          this.sysParamInfo['involves'].required === 0 &&
          taskCollaboratorDtoList.length === 0
        ) {
          this.validRequireField('请选择协作人！')
          this[loading] = false
          return
        }
        // 时间校验
        if (
          this.sysParamInfo['endDate'] &&
          this.sysParamInfo['endDate'].required === 0 &&
          !this.form.endDate
        ) {
          this.validRequireField('请设置结束时间！')
          this[loading] = false
          return
        }
        // 标签校验
        if (
          this.sysParamInfo['tag'] &&
          this.sysParamInfo['tag'].required === 0 &&
          this.selectTag.length === 0
        ) {
          this.validRequireField('请选择标签！')
          this[loading] = false
          return
        }

        // 自定义字段校验
        const fieldList = this.paramList.filter(
          (item) => item.fieldType === 'customField'
        )
        let validField = {}
        const valid = fieldList.some((item) => {
          const valInput =
            !this.customFieldForm[item.id] ||
            (item.fieldAttribute === 'singleChoice ' &&
              this.customFieldForm[item.id].length === 0) ||
            (item.fieldAttribute === 'file' &&
              this.customFieldForm[item.id].length == 0)
          if (item.required === 0 && valInput) {
            // 必填
            validField = item
            return true
          }
          if (!valInput) {
            let value = null
            if (item.fieldAttribute === 'multipleChoice') {
              value = this.customFieldForm[item.id].join(',')
            } else if (item.fieldAttribute === 'file') {
              value = JSON.stringify(this.customFieldForm[item.id])
            } else {
              value = this.customFieldForm[item.id]
            }
            fieldValueList.push({
              fieldId: item.id,
              value,
              fieldAttribute: item.fieldAttribute
            })
          }
          return false
        })
        if (valid) {
          this.validRequireField(`${validField.name}为必填项，值不能为空`)
          this[loading] = false
          return
        }
        let params = {
          fieldCategoriesId: this.fieldCategoriesId,
          isShare: this.switchValue ? 0 : 1,
          title: this.form.title,
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          priorityId: this.form.priorityId,
          status: this.form.status ? this.form.status : 1, // 待处理
          originatorId: this.form.originatorId,
          labelList: this.selectTag.map((item) => {
            return { id: item.id, name: item.name }
          }),
          fatherTid: this.propsAddObj.fatherTid
            ? this.propsAddObj.fatherTid
            : null,
          cycleDateId: this.form.cycleDateId,
          remindDateId: this.form.remindDateId,
          fieldValueList,
          taskCollaboratorDtoList,
          businessType: this.form.businessType ? this.form.businessType : 1
        }

        if (this.classifytype === 'kr') {
          params = Object.assign(params, {
            objId: this.form.objId,
            keyId: this.form.keyId,
            cycleId: this.form.cycleId
          })
        }
        if (
          this.classifytype === 'project' &&
          this.propsAddObj.taskType != '子任务'
        ) {
          params = Object.assign(params, {
            projectId: this.form.projectId,
            processId: this.form.processId
          })
        }
        if ((params.objId || '') !== '') {
          params.businessType = 3
        } else if (
          (params.projectId || '') !== '' ||
          this.classifytype === 'project'
        ) {
          params.businessType = 2
        }
        // 分组添加--
        // if (
        //   this.propsAddObj &&
        //   this.propsAddObj.identification &&
        //   this.propsAddObj.key
        // ) {
        //   params[this.propsAddObj.identification] = this.propsAddObj.key
        // }
        // 分组添加 项目任务
        if (
          this.propsAddObj &&
          this.propsAddObj.identification == 'type' &&
          this.propsAddObj.key == 2
        ) {
          params['projectId'] = this.projectInfo.id
          params['processId'] = this.projectInfo.processId
        }

        if (
          this.classifytype === 'project' &&
          !params['projectId'] &&
          !this.propsAddObj.taskType
        ) {
          this.$message.warn('请选择任务归属!')
          this[loading] = false
          return
        }
        // 后台请求 默认企业信息 /task/add
        this.$apis.TASKADDE(params).then((res) => {
          if (res.status === 200) {
            this.$message.success('新增任务成功')
            // 数据埋点
            const ascription =
              this.classifytype === 'todo'
                ? 'temp'
                : this.classifytype === 'kr'
                ? 'okr'
                : 'project'
            this.$mixpanelUtil.track('Create Task', {
              ascription,
              is_public: this.switchValue ? 0 : 1,
              task_id: res.data.id || '',
              is_child: 0
            })
            // if (this.propsAddObj.todoType === 0) {
            if (this.source === 'todoGroup') {
              // todo页面传递过来的进行刷新todo页面
              console.log('taskNeedRefresh111')
              this.bus(this).$emit('taskNeedRefresh', res.data.id)
            } else {
              this.$emit('getHomeDetailsAddSubmit', res.data)
            }
            this.form = {}
            this.taskAddForm = {
              default: 0,
              taskCustom: {},
              taskRemind: {}
            }
            this.$store.commit('common/saveTaskAddObject', this.taskAddForm)
            if (
              this.propsAddObj.tabSelectId === 1 &&
              this.propsAddObj.formPage === 'pm'
            ) {
              // 列表刷新
              console.log('getProjectTaskList222')
              this.bus(this).$emit('getProjectTaskList') // 列表刷线
            }
            if (
              this.propsAddObj.tabSelectId === 2 &&
              this.propsAddObj.formPage === 'pm'
            ) {
              // 看板刷新
              this.bus.$emit('global:refreshBoardColWorkList', {
                processId: this.propsAddObj.processId,
                indexCol: this.propsAddObj.indexCol
              }) // 列表刷线
            }
            // 保存并创建下一个
            if (type === 'next') {
              this.setDateTimeVisible = false
              this.$nextTick(() => {
                this.setDateTimeVisible = true
              })
              if (this.copyProps.formPage != 'pm') {
                this.copyProps.keyId = ''
                this.copyProps.objId = null // 目标id
                this.copyProps.keyId = null
                this.copyProps.cycle_id = null
                this.copyProps.title = ''
                this.copyProps.projectId = null
                this.projectInfo = {}
                this.copyProps = JSON.parse(JSON.stringify(this.copyProps))
              }
              // 初始化
              this.init('next')
              // 清空协作人
              this.clearForm()
            } else {
              this.copyProps.objId = null // 目标id
              this.copyProps.keyId = null
              this.copyProps.cycle_id = null
              this.copyProps.title = ''
              this.copyProps.projectId = null
              this.dialogVisible = false
              this.$emit('change', false)
            }
          } else {
            this.$message({
              msg: res.message,
              type: 'error'
            })
          }
          this[loading] = false
        })
      } else {
        this[loading] = false
      }
    },
    validRequireField(msg) {
      if (this.moreParamShow) {
        this.$message.warn(msg)
      } else {
        this.moreParamShow = true
        this.$message.warn('还有必填的内容未填写哦～')
      }
    },
    addInput() {
      if (this.form.title && this.form.title.replace(/\s*/g, '').length > 150) {
        this.$message.warn('标题最多可输入 150 个字符哦!')
        this.form.title = this.form.title.substring(0, 150)
      }
    },
    // 参数校验
    judge(isHint) {
      this.addInput()
      const msgMap = {
        title: '任务名称不能为空',
        originatorId: '负责人不能为空'
      }
      // const status = this.$doom(msgMap, isHint)
      const status = true
      this.disabledSubmit = !status
      return status
    },
    // 负责人选择
    toriginatorPicker(row) {
      this.form.originatorId = row.id
      this.originatorAvatar = row.avatar
      this.originatorName = this.$isQw ? row.username : row.name
      this.taskAddForm.originatorId = row.id
      this.taskAddForm.originatorAvatar = row.avatar
      this.taskAddForm.originatorName = this.$isQw ? row.username : row.name
    },
    // 协作人选择
    chargeUserPicker(row) {
      var chargeUserList = []
      if (row.length > 40) {
        this.$message({
          msg: '最多可以选择 40 个协作人哦!',
          type: 'warn'
        })
      } else {
        row.forEach((item, index) => {
          item.userId = item.id
          chargeUserList.push(item)
        })
        this.chargeUserList = this.$utils.distincts(chargeUserList)
        this.chargeUserListInit = this.$utils.distincts(chargeUserList)
        this.taskAddForm.chargeUserList = this.chargeUserList
      }
    },
    // 协作人删除
    chargeUserDelete(index) {
      this.chargeUserId = ''
      this.chargeUserListInit.splice(index, 1)
      this.taskAddForm.chargeUserList = [...this.chargeUserList]
    },
    // 关联kr事件
    krSave(row) {
      console.log('krSave', row)
      this.form.objId = row.objId || null // 目标id
      this.form.keyId = row.keyId || null // krId
      this.form.cycleId = row.cycle_id || null // 周期id
      this.form.keyTitle = row.keyTitle || null
      this.form.projectId = row.projectId || null
      this.taskAddForm.type = 1
      this.copyProps.type = 1

      this.taskAddForm.objId = row.objId || null // 目标id
      this.taskAddForm.keyId = row.keyId || null // krId
      this.taskAddForm.cycleId = row.cycle_id || null // 周期id
      this.taskAddForm.keyTitle = row.keyTitle || null // 周期id
      this.taskAddForm.selectBooleans = row.selectBooleans
      this.taskAddForm.projectId = row.projectId
      this.taskAddForm.checked = row.checked

      this.copyProps.objId = row.objId || null // 目标id
      this.copyProps.keyId = row.keyId || null // krId
      this.copyProps.cycle_id = row.cycle_id || null // 周期id
      this.copyProps.projectId = row.projectId || null
      this.copyProps.selectBooleans = row.selectBooleans
      this.copyProps.checked = row.checked
      // this.propsAddObj.title = row.keyTitle || null // 周期id
      this.$set(this.copyProps, 'title', row.keyTitle)
    },
    // 关闭启动状态
    // 16
    async handleClose() {
      if (this.form.title) {
        await this.$confirm('有已填写的内容，是否关闭弹窗？', '是否关闭', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'confirmBox',
          closeOnPressEscape: false
        })
          .then(() => {
            this.dialogVisible = false
            this.$emit('change', false)
          })
          .catch(() => {
            this.dialogVisible = true
            this.$emit('change', true)
          })
      } else {
        this.dialogVisible = false
        this.$emit('change', false)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../assets/styles/dialog.less';
::v-deep {
  .top-item-right-box-kr {
    color: #a0a3bd !important;
    &:hover {
      color: #3a70ff !important;
    }
  }
  .priority-box {
    .el-input__inner {
      -webkit-box-shadow: 0 0 20px 20px #fff inset;
      box-shadow: 0 0 20px 20px #fff inset;
      border: 1px solid #f5f6fa !important;
      font-size: 14px;
      border-radius: 9px;
      height: 36px;
      line-height: 36px;
      padding: 0px 10px;
      color: inherit !important;
    }
    .el-input__suffix {
      display: none;
    }
    &:hover {
      .el-input__inner {
        background: rgba(217, 219, 233, 0.24) !important;
        box-shadow: none !important;
      }
    }
  }
}
.task-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #14142b;
  justify-content: space-between;
  .task-title-left {
    .flex-l();
  }
  .title {
    margin-right: 12px;
  }
}
::v-deep {
  .el-dialog.add-task-dialog {
    border-radius: 24px !important;
  }
  .el-input__inner {
    border-radius: 8px;
    height: auto;
    line-height: 20px;
  }
  .el-input__icon {
    line-height: 20px;
  }
  .el-dialog__header {
    display: block !important;
    padding: 24px 32px 16px !important;
  }
}
.set-date-time {
  -moz-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
  margin-left: -8px;
}
</style>
<style lang="scss" scoped>
@import '../../../../assets/styles/variable.scss';
.charge-avatar {
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.task-name {
  margin-bottom: 30px;
  padding: 12px 20px;
  width: 100%;
}
.task-info {
  flex-wrap: wrap;
  padding-top: 16px;
  max-height: 400px;
  overflow-y: auto;
  .task-info-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    min-height: 40px;
    .originator-box {
      cursor: pointer;
      display: flex;
      height: 36px;
      align-items: center;
      position: relative;
      background: #fff;
      border-radius: 38px 38px 38px 38px;
      border: 1px solid #fff;
      margin-left: -8px;
      .close-icon {
        position: absolute;
        right: -4px;
        top: -8px;
        font-size: 18px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        cursor: pointer;
        background: #fff;
        color: #a0a3bd;
        &:hover {
          color: #ff9900;
        }
      }
      .charge-person {
        padding: 4px 11px 4px 8px;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .head-icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('~@/assets/imgs/pm/head-icon.png') no-repeat;
          background-size: 100% 100%;
        }
        .head-icon-label {
          margin-left: 0px;
          font-weight: 500;
          color: #a0a3bd;
          display: flex;
          flex-direction: row;
          align-items: center;
          .avatar-icon {
            margin-right: 6px;
            font-size: 20px;
            background: #d4d5e0;
          }
        }
      }
    }
    .originator-box {
      &:hover {
        background: #f7f7fc;
        border: 1px solid #f7f7fc;
      }
    }
    .originator-box-hover {
      background: #fff;
      border: 1px solid #ff9900;
      &:hover {
        background: #fff;
        border: 1px solid #ff9900;
      }
    }
    .task-info-item-switch {
      float: right;
    }
  }
  .task-info-item-between {
    min-height: 36px;
    justify-content: space-between;
  }
  .el-select {
    width: 120px;
  }
  .priority-box {
    ::v-deep {
      .el-input__inner {
        color: inherit !important;
        height: auto;
        line-height: 20px;
        padding: 8px 12px !important;
      }
      .el-input__suffix {
        display: none;
      }
    }

    .el-input__inner {
      &:hover {
        background: #f7f7fc;
      }
    }
  }
}
.hide-date-border {
  left: 32px;
  opacity: 0;
  position: absolute;
  top: -20px;
  width: 20px;
  z-index: -10;
}
.date-picker {
  position: relative;
  ::v-deep {
    .el-input__inner {
      border: none;
    }
    .el-input--prefix .el-input__inner {
      padding-left: 0px;
    }
    .el-input--suffix .el-input__inner {
      padding-right: 0px;
    }
  }
}
.describe {
  display: flex;
  width: 126px;
  margin-right: 8px;
  color: #a0a3bd;
  flex-shrink: 0;
  .svg-icons {
    width: 18px;
    height: 18px;
    font-size: 18px;
    margin-right: 16px;
  }
  .param-name-text {
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 20px;
  }
  .required {
    margin-left: 2px;
  }
}
// 时间
.time-describe {
  margin-top: 2px;
  .svg-icons {
    margin-top: -1px;
  }
}
// 协作人
.involves-describe {
  .svg-icons {
    margin-top: -1px;
  }
}
// 优先级
.priority-describe {
  margin-top: 6px;
  .svg-icons {
    margin-top: 2px;
  }
}
// 文本
.text-describe {
  margin-top: 6px;
  .svg-icons {
    margin-top: 2px;
  }
}
// 任务所属kr
.kr-describe {
  margin-top: 4px;
}
// 任务所属项目
.project-describe {
}
.tag-describe {
  margin-top: 1px;
  .svg-icons {
    margin-top: 2px;
  }
}
.simple-descirbe {
  width: 32px;
  .svg-icons {
    margin-right: 0px;
  }
}
.more-btn {
  cursor: pointer;
  color: #a0a3bd;
  position: relative;
  .time-text-yes {
    color: #444558;
  }
  .time-text-no {
    color: #a0a3bd;
  }
  &:hover {
    color: #3a78f6;
    .time-text-yes {
      color: #3a78f6;
    }
    .close {
      color: #a0a3bd;
      display: block;
      font-size: 18px;
      &:hover {
        color: #ff9900;
      }
    }
  }
  .svg-icons {
    margin-right: 20px;
  }
  .close {
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 100% 100%;
    font-size: 14px;
    display: none;
    position: absolute;
    right: -9px;
    top: -6px;
    cursor: pointer;
  }
}
.select-start {
  padding: 4px 8px;
  border: 1px solid #fff;
  margin-left: -8px !important;

  &:hover {
    border: 1px solid #f5f6fa;
    color: #a0a3bd;
    background: #f5f6fa;
    border-radius: 16px;
  }
}
.select-hover-start {
  background-color: transparent;
  &:hover {
    background-color: transparent;
    border: 1px solid #ff9900;
  }
}
.charge-user-box {
  display: flex;
  align-items: center;
  .charge-avatar-box {
    margin-right: 8px;
    position: relative;
    border: 1px #fff solid;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    .charge-avatar {
      border-radius: 50%;
      height: 24px;
      width: 24px;
    }
    .close-icon {
      cursor: pointer;
      display: none;
      position: absolute;
      right: -10px;
      top: -5px;
      width: 18px;
      height: 18px;
      font-size: 18px;
      background: #fff;
      border-radius: 50%;
      color: #a0a3bd;
      z-index: 1;
      &:hover {
        color: #ff9900;
      }
    }
    &:hover {
      border: 1px #a0a3bd solid;
      background: #fff;
      .close-icon {
        display: block;
      }
    }
  }
  .charge-avatar-box + .charge-avatar-box {
    margin-left: 0px;
  }
  .charge-avatar-box-hover {
    border: 1px solid #ff9900;
    background-color: transparent;
    &:hover {
      background-color: transparent;
      border: 1px solid #ff9900;
    }
  }
  .cooperation-add {
    color: #a0a3bd;
    cursor: pointer;
    height: 25px;
    font-size: 24px;
    width: 25px;
    &:hover {
      color: #3068ff;
    }
  }
}
.footer {
  justify-content: space-between;
  cursor: pointer;
  .footer-box {
    display: flex;
    align-items: center;
    .footer-lf {
      color: #a0a3bd;
      line-height: 32px;
      margin-left: 4px;
      font-size: 14px;
    }
    &:hover {
      .footer-lf {
        color: #3068ff;
      }
      .task-add-img {
        color: #3068ff;
      }
      .task-not-img {
        color: #3068ff;
      }
    }
  }
  .task-add-img {
    font-size: 22px;
    width: 32px;
    height: 32px;
    color: #a0a3bd;
    cursor: pointer;
  }
  .task-not-img {
    font-size: 18px;
    width: 32px;
    height: 32px;
    color: #a0a3bd;
    cursor: pointer;
  }
  button {
    padding: 0px !important;
  }
  button.common_btn_next_add_able {
    &:hover {
      background: #f3f7fe !important;
      color: #548af7 !important;
    }
  }
  button.common_btn_next_add_able_blue {
    &:hover {
      border: 1px solid #548af7 !important;
      background: #548af7 !important;
    }
  }
  .cancel-btns {
    width: 120px;
    height: 32px;
    line-height: 32px;
    border-radius: 12px;
  }
  .next-primary {
    height: 32px !important;
    line-height: 32px !important;
    width: 90px !important;
    border-radius: 12px !important;
  }
  .next-btns {
    width: 160px;
    height: 32px;
    line-height: 32px;
    border-radius: 12px;
    margin: 0 10px;
  }
}
::v-deep .el-dialog__body {
  padding-top: 2px !important;
}
.add-task-bd-textarea {
  // width: 576px;
  border-radius: 16px;
  padding: 0px;
  ::v-deep {
    .el-textarea,
    .el-textarea__inner {
      border: 0 !important;
      border-radius: 16px;
      font-size: 16px !important;
      padding-top: 5px;
      background: #fafafc !important;
    }
    .el-textarea__inner {
      min-height: 128px;
    }
  }
}
</style>
<style lang="scss">
.footer .el-button + .el-button {
  margin-right: 10px !important;
  width: 156px;
  height: 40px;
  border-radius: 18px;
}
.footer .next-btn-orange {
  background: #3068ff !important;
  color: #fff !important;
  border-color: #3068ff !important;
}
</style>
