<template>
  <el-popover
    v-model="krProcessUpdateShow"
    placement="bottom"
    popper-class="kr-process-update"
    trigger="click"
    @show="initData"
    :disabled="disabled"
    :width="320"
  >
    <div class="obj-title-alert">
      <div class="at-content-wrap">
        <el-input
          v-model.lazy="dataObj.title"
          type="textarea"
          placeholder="+ 添加任务 例如：完成产品说明手册 ↵"
          class="input-focus"
          rows="6"
        />
      </div>
      <div class="bottom-step-buttons">
        <button @click="alertClose" class="left-button">取消</button>
        <L-button class="alert_box_submit_button2" @clickEvt="submit()">保存</L-button>
      </div>
    </div>
    <div slot="reference">
      <slot></slot>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: {
    objData: {
      type: Object,
      required: false
    },
    disabled: {
      default: false
    }
  },
  data() {
    return {
      krProcessUpdateShow: false,
      dataObj: {}
    }
  },
  methods: {
    initData() {
      this.dataObj = JSON.parse(JSON.stringify(this.objData))
    },
    async submit(obj) {
      if (this.dataObj.title.trim() === '') {
        this.$message.warn('任务标题不能为空哦！')
        return
      }
      this.$emit('refresh', this.dataObj)
      this.alertClose()
    },
    alertClose() {
      this.krProcessUpdateShow = false
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../assets/styles/dialog.less';
.table-content {
  .text-n(1);
}
::v-deep {
  // .el-input-group__append,
  // .el-input-group__prepend {
  //   border: none !important;
  // }
  .el-textarea {
    border: none !important;
  }
  .el-textarea,
  .el-textarea__inner {
    // border: none !important;
    // resize: none !important;
    background: none !important;
  }
  .el-textarea__inner {
    border: 1px solid #e7e8ef !important;
    border-radius: 8px;
    &::placeholder {
      font-size: 14px;
    }
  }
}
.bottom-step-buttons {
  margin: 16px 0px 0px 0px;
  .left-button,
  .load-btn {
    border-radius: 8px !important;
    height: 32px;
  }
}
</style>
