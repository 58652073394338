var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "task-item",
      class: _vm.item.checked ? "select" : "",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.selectClick.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "task-top" }, [
        _c("div", { staticClass: "task-left" }, [
          _c(
            "div",
            {
              staticClass: "task-status",
              class: "status-con" + _vm.item.status,
            },
            [
              _c(
                "state-picker",
                { attrs: { type: 2, status: _vm.item.status, disabled: true } },
                [
                  _c("div", { staticClass: "content flex-new" }, [
                    _c(
                      "div",
                      {
                        staticClass: "pane to-edit-input-not",
                        style:
                          "border: 1px solid " +
                          _vm.stateDictColor[_vm.item.status] +
                          " !important",
                      },
                      [
                        _vm.item.status == 3
                          ? _c("i", { staticClass: "el-icon-check checked" })
                          : !_vm.isDisabledEdit
                          ? _c("i", {
                              staticClass: "el-icon-caret-bottom hover",
                              style:
                                "color: " + _vm.stateDictColor[_vm.item.status],
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "left-box-todo-p",
                        class: _vm.statusModalColor,
                      },
                      [_vm._v(_vm._s(_vm.statusDict[_vm.item.status]))]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "task-content" },
            [
              _c(
                "el-tooltip",
                { attrs: { "open-delay": 300, content: "" + _vm.item.title } },
                [_c("span", [_vm._v(_vm._s(_vm.item.title))])]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "task-avatar" },
          [
            _vm.item.status !== 3 && _vm.item.status !== 4
              ? _c("div", { staticClass: "task-title-right" }, [
                  _vm.isSetTime
                    ? _c("div", { staticClass: "date-box minHeight" }, [
                        (_vm.item.startDate || "") != "" ||
                        (_vm.item.endDate || "") != ""
                          ? _c("div", { staticClass: "flex-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "date-txt",
                                  class:
                                    _vm.item.status != 3
                                      ? "dateColor" +
                                        _vm.$utils.getDateBgColor(
                                          _vm.item.startDate,
                                          _vm.item.endDate
                                        )
                                      : "",
                                },
                                [
                                  (_vm.item.startDate || "") === "" &&
                                  (_vm.item.endDate || "") != ""
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$utils.formatRecentTime(
                                                _vm.item.endDate,
                                                "page",
                                                1
                                              )
                                            ) +
                                            " 截止 "
                                        ),
                                      ]
                                    : _vm._e(),
                                  (_vm.item.startDate || "") != "" &&
                                  (_vm.item.endDate || "") != ""
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$utils.formatRecentTime(
                                                _vm.item.startDate,
                                                "page",
                                                1
                                              )
                                            ) +
                                            " - " +
                                            _vm._s(
                                              _vm.$utils.formatRecentTime(
                                                _vm.item.endDate,
                                                "page",
                                                1
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : _vm._e(),
                                  (_vm.item.startDate || "") != "" &&
                                  (_vm.item.endDate || "") === ""
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$utils.formatRecentTime(
                                                _vm.item.startDate,
                                                "page",
                                                1
                                              )
                                            ) +
                                            " 开始 "
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("el-image", {
              staticClass: "border-radius-20",
              attrs: {
                fit: "cover",
                src:
                  _vm.item.originatorAvatar ||
                  require("@/assets/imgs/pm/head-icon.png"),
              },
            }),
            _vm.canDelete
              ? _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      staticClass: "delete-icon",
                      attrs: { "icon-class": "icon-close" },
                      on: { click: _vm.taskDel },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "task-type-detail-wrap" }, [
        _c("div", { staticClass: "task-type-detail" }, [
          (_vm.item.cycleName && _vm.item.keyTitle) ||
          _vm.item.keyTitle ||
          _vm.item.projectName
            ? _c("div", { staticClass: "task-cycle-wrap" }, [
                _vm.item.cycleName && _vm.item.keyTitle
                  ? _c("div", { staticClass: "cycle-name" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$utils.tagFilter(_vm.item.cycleName)) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.item.keyTitle || _vm.item.projectName
                  ? _c(
                      "div",
                      { staticClass: "task-type" },
                      [
                        _c("Icon", {
                          staticClass: "belong-icon",
                          attrs: { "icon-class": "icon-suoshu" },
                        }),
                        _vm.item.processId
                          ? _c("span", { staticClass: "kr-box-kr" }, [
                              _vm._v("项目："),
                            ])
                          : _c("span", { staticClass: "kr-box-kr" }, [
                              _vm._v("KR："),
                            ]),
                        _vm.item.processId
                          ? _c(
                              "span",
                              { staticClass: "kr-text" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      "open-delay": 300,
                                      content: _vm.$utils.tagFilter(
                                        _vm.item.projectName +
                                          "/" +
                                          _vm.item.processName
                                      ),
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$utils.tagFilter(
                                              _vm.item.projectName
                                            )
                                          ) +
                                          "/" +
                                          _vm._s(_vm.item.processName) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              { staticClass: "kr-text" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      "open-delay": 300,
                                      content: _vm.$utils.tagFilter(
                                        _vm.$utils.changeToHtml(
                                          _vm.item.keyTitle
                                        )
                                      ),
                                    },
                                  },
                                  [
                                    _c("span", {
                                      attrs: { slot: "content" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.tagFilter(
                                            _vm.$utils.changeToHtml(
                                              _vm.item.keyTitle
                                            )
                                          )
                                        ),
                                      },
                                      slot: "content",
                                    }),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.tagFilter(
                                            _vm.$utils.changeToHtml(
                                              _vm.item.keyTitle
                                            )
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.item.fatherTaskTitle != null
            ? _c(
                "div",
                { staticClass: "task-children-wrap" },
                [
                  _c("Icon", {
                    staticClass: "belong-icon",
                    attrs: { "icon-class": "icon-furenwu" },
                  }),
                  _c("span", { staticClass: "kr-box-kr" }, [
                    _vm._v("父任务："),
                  ]),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        "open-delay": 300,
                        content: _vm.item.fatherTaskTitle,
                      },
                    },
                    [
                      _c("span", { staticClass: "kr-text" }, [
                        _vm._v(_vm._s(_vm.item.fatherTaskTitle)),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.item.checked
          ? _c(
              "div",
              { staticClass: "select-wrap" },
              [
                _c("el-checkbox", {
                  model: {
                    value: _vm.item.checked,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "checked", $$v)
                    },
                    expression: "item.checked",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }