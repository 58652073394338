var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ossFileEntityList.length > 0
    ? _c(
        "div",
        { staticClass: "file-list-wrap" },
        _vm._l(_vm.ossFileEntityList, function (item) {
          return _c(
            "div",
            {
              key: item.downloadUrl,
              staticClass: "file-list",
              on: {
                click: function ($event) {
                  return _vm.fileListClick(item)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "file-list-left" },
                [
                  item.mimeType == "png" ||
                  item.mimeType == "jpg" ||
                  item.mimeType == "jpeg" ||
                  item.mimeType == "gif" ||
                  item.mimeType == "svg" ||
                  item.mimeType == "PNG" ||
                  item.mimeType == "JPG" ||
                  item.mimeType == "JPEG" ||
                  item.mimeType == "GIF" ||
                  item.mimeType == "SVG"
                    ? _c("el-image", {
                        staticClass: "file-img",
                        attrs: {
                          fit: "contain",
                          src: item.downloadUrl,
                          alt: "",
                        },
                      })
                    : item.mimeType == "docx" ||
                      item.mimeType == "doc" ||
                      item.mimeType == "DOCX" ||
                      item.mimeType == "DOC"
                    ? _c("Icon", {
                        staticClass: "file-svg",
                        attrs: { "icon-class": "icon-DOCX" },
                      })
                    : item.mimeType == "mp3" || item.mimeType == "MP3"
                    ? _c("Icon", {
                        staticClass: "file-svg",
                        attrs: { "icon-class": "icon-MP3" },
                      })
                    : item.mimeType == "mp4" || item.mimeType == "MP4"
                    ? _c("Icon", {
                        staticClass: "file-svg",
                        attrs: { "icon-class": "icon-MP4" },
                      })
                    : item.mimeType == "pdf" || item.mimeType == "PDF"
                    ? _c("Icon", {
                        staticClass: "file-svg",
                        attrs: { "icon-class": "icon-PDF" },
                      })
                    : item.mimeType == "ppt" || item.mimeType == "PPT"
                    ? _c("Icon", {
                        staticClass: "file-svg",
                        attrs: { "icon-class": "icon-PPT" },
                      })
                    : item.mimeType == "txt" || item.mimeType == "TXT"
                    ? _c("Icon", {
                        staticClass: "file-svg",
                        attrs: { "icon-class": "icon-filetxt" },
                      })
                    : item.mimeType == "XLS" ||
                      item.mimeType == "xls" ||
                      item.mimeType == "XLSX" ||
                      item.mimeType == "xlsX"
                    ? _c("Icon", {
                        staticClass: "file-svg",
                        attrs: { "icon-class": "icon-XLS" },
                      })
                    : _c("Icon", {
                        staticClass: "file-svg",
                        attrs: { "icon-class": "icon-fileCommon" },
                      }),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "file-list-right" }, [
                _vm._v(_vm._s(item.fileSizeNew) + "KB"),
              ]),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }