var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          ref: "userPickerPop",
          attrs: {
            "popper-class": "user-picker-atdialog-pop",
            "visible-arrow": false,
            placement: "bottom-start",
            disabled: _vm.disabled,
          },
          on: {
            show: function ($event) {
              return _vm.init(1)
            },
          },
        },
        [
          _c("div", { staticClass: "user-picker-out" }, [
            !_vm.taskId &&
            _vm.pageType !== "workHours" &&
            _vm.pageType !== "pmAdminUser" &&
            _vm.pageType !== "adminUser" &&
            _vm.pageType !== "analyseDepartUser"
              ? _c(
                  "div",
                  { staticClass: "search-box" },
                  [
                    _c("el-input", {
                      staticClass: "user-el-input",
                      attrs: {
                        "prefix-icon": "el-icon-search",
                        placeholder: "搜索成员",
                      },
                      on: { input: _vm.input },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "people-title" }, [
              _vm._v("成员" + _vm._s(_vm.total)),
            ]),
            _c("div", { staticClass: "list-box" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "scroll-bottom",
                      rawName: "v-scroll-bottom",
                      value: _vm.$loadMoreList,
                      expression: "$loadMoreList",
                    },
                  ],
                  staticClass: "list-box-wrap",
                },
                _vm._l(_vm.list, function (item) {
                  return _c(
                    "div",
                    {
                      key: item && item.id,
                      staticClass: "list-item",
                      on: {
                        click: function ($event) {
                          return _vm.pick(item)
                        },
                      },
                    },
                    [
                      _c("el-image", {
                        staticClass: "avatar-img border-radius-20",
                        attrs: {
                          src:
                            item.avatar ||
                            require("@/assets/imgs/common/avatar.png"),
                          fit: "cover",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "p-l-10" },
                        [
                          _c("wx-open-data", {
                            attrs: {
                              id: item.username,
                              nameData: { name: item.name },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "departName" },
                        [
                          _c("DepartUserList", {
                            attrs: {
                              departName: item.departName,
                              departUserList: item.departUserList,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.pageType !== "workHours" &&
              _vm.pageType !== "pmAdminUser" &&
              _vm.pageType !== "adminUser" &&
              _vm.pageType !== "analyseDepartUser"
                ? _c(
                    "div",
                    {
                      staticClass: "list-item peopeAdd",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.departAdd()
                        },
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass: "avatar-img",
                        attrs: { "icon-class": "icon-a-tianjia2" },
                      }),
                      _c("div", { staticClass: "p-l-10" }, [
                        _vm._v("按组织选择"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._t("default", null, { slot: "reference" }),
        ],
        2
      ),
      _vm.closeInviterCodeBoolean === true
        ? _c("invite-by-code", {
            on: { closeInviterCode: _vm.closeInviterCode },
          })
        : _vm._e(),
      _vm.departUserVisible
        ? _c("depart-picker", {
            attrs: {
              type: "person",
              canDelete: true,
              multipleChoice: false,
              isIncludeMe: _vm.pageType == "taskSummary" ? 1 : 0,
              userScope: _vm.userScope,
            },
            on: { saveResult: _vm.chargeUserPicker },
            model: {
              value: _vm.departUserVisible,
              callback: function ($$v) {
                _vm.departUserVisible = $$v
              },
              expression: "departUserVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }