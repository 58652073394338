var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-list-wrap",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "el-popover",
        { attrs: { placement: "bottom-end", width: "330", trigger: "click" } },
        [
          _c("div", { staticClass: "video-content" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("观看视频介绍，快速上手源目标"),
            ]),
            _c(
              "div",
              { staticClass: "video-list-content" },
              _vm._l(_vm.videoList, function (item) {
                return _c(
                  "div",
                  { key: item.list, staticClass: "video-list-wrap" },
                  [
                    _c("div", { staticClass: "video-title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _vm._l(item.list, function (list) {
                      return _c(
                        "div",
                        {
                          key: list.title,
                          staticClass: "video-list",
                          on: {
                            click: function ($event) {
                              return _vm.openVideo(list)
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "icon",
                            attrs: { "icon-class": "icon-bofang1" },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(list.title)),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
          _c(
            "div",
            { attrs: { slot: "reference" }, slot: "reference" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    trigger: "manual",
                    width: 285,
                    "visible-arrow": false,
                    "popper-class": "video-popover animate-style-bottom",
                  },
                  model: {
                    value: _vm.$store.state.common.videoPopoverVisible,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$store.state.common,
                        "videoPopoverVisible",
                        $$v
                      )
                    },
                    expression: "$store.state.common.videoPopoverVisible",
                  },
                },
                [
                  _c("div", { staticClass: "video-popover-content" }, [
                    _c("div", { staticClass: "content" }, [
                      _c("div", { staticClass: "content-left" }, [
                        _vm._v("点击可查看更多 视频内容。"),
                      ]),
                      _c("div", { staticClass: "content-right" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: { click: _vm.popoverVisibleChange },
                          },
                          [_vm._v("我知道了")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      class:
                        "image-wrap image-wrap-" +
                        _vm.$store.state.common.videoPopoverVisible,
                      attrs: { slot: "reference" },
                      on: { click: _vm.popoverVisibleChange },
                      slot: "reference",
                    },
                    [
                      _vm.$store.state.common.videoPopoverVisible
                        ? _c(
                            "div",
                            { staticClass: "img animate-style-bottom" },
                            [
                              _c("Img", {
                                attrs: { src: "/advertisement/hand.png" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.videoDialogShow
        ? _c(
            "div",
            [
              _c("look-add-okr", {
                attrs: {
                  videoUrl: _vm.videoUrl,
                  videoName: _vm.videoName,
                  recordTime: true,
                  "video-dialog-show": _vm.videoDialogShow,
                },
                on: { closeVideoDialog: _vm.closeVideoDialog },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }