var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field-com" },
    [
      _c(_vm.CUSTOMCOMPONENTS[_vm.fieldType], {
        tag: "component",
        attrs: {
          value: _vm.value,
          "field-info": _vm.fieldInfo,
          disabled: _vm.disabled,
        },
        on: { change: _vm.changeVal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }